// Customizable Area Start
import { Input, Row } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import {
  searchshape,
  playButton,
} from "../../../../dashboard/src/assets";
import CtrlvideosController from "./CtrlvideosController.web";
import TrackDetails from "../TrackDetails.web";
import { nxzloader } from "blocks/dashboard/src/assets";

const RightsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "656px",
  display: "grid",
  scrollbarWidth: "none",
  overflowX: "hidden",
  gridTemplateColumns: "repeat(2, 1fr)",
  width: "fit-content",
  gap: "15px",
  "@media(max-width: 1700px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1624px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media(max-width: 1550px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1300px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  "@media(max-width: 1024px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
    minWidth:"100%"
  },

  "@media(max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 600px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    placeItems: "center",
  },
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});
const TotalSplits = styled(Box)({
  width: "100%",
  color: "white",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});

const DivWrapper = styled(Box)({
  display: "flex",
  padding: "30px 50px 0px 27px",
  gap: "1%",
   minHeight:"calc(100% - 3rem)",
  justifyContent: "space-between",

  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 5px",
    gap: "0%",
    minHeight:"0px"
  },
});

const SplitboxRightsidecontainer = styled(Box)({
  width: "48%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: "2%",
    gap: "20px",
    alignItems: "center",
  },
});
const Splitleftsidecontainer = styled(Box)({
  width: "46%",
  display: "flex",
  height: "max-content",
  flexDirection: "column" as "column",
  marginBottom:"10px",
  gap: "20px",
  "@media(max-width:1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "10px",
  },
});
const Borderbox = styled(Box)({
    "@media(max-width: 1024px)": {
    display: "none",
  },
});
const Inputboxwrapper = styled(Box)({
  borderRadius: "27px",
  padding: "10px",
  width: "100%",
  background: "#212121",
  position: "relative",
  "& .ant-input": {
    backgroundColor: "#212121",
    width: "88%",
    color: "white",
  },
  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
  },
  "@media(max-width: 1024px)": {
    width: "100%",
    "& .ant-input": {
      backgroundColor: "#212121",
      width: "50%",
    },
  },
});
const VideosboxRightside = styled(Box)({
  width: "fit-content",
  display: "flex",
  minWidth: "100%",
  marginBottom:'10px',
  flexDirection: "column" as "column",
  gap: "15px",
  '@media(max-width: 1700px)': {
    // width: "auto"
  },
  "@media(max-width: 1024px)": {
    // width: "auto",
    // height: "auto",
    // marginTop: "5px",
    gap: "20px"
  },
});
const LeftsideAlltracksbox = styled(Box)({
  width: "100%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});
const LeftSideSingletrackbox = styled(Box)({
  // marginTop: '6px',
  height: "95px",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  objectFit: "contain",
  cursor: "pointer",
  color: "white",
  "&:hover": {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    objectFit: "contain",
    background: "#141414",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
  },
});
const LeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  // height: "693px",
  scrollbarWidth: "none",
  maxHeight: "693px",
  marginTop: "-20px",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});
const Trackname = styled(Box)({
  fontSize: "22px",
  fontWeight: 700,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "20px",
  letterSpacing: "normal",
  whiteSpace: "nowrap" as "nowrap",
  color: "#ffffff",
  fontFamily: "Inter",
  "@media(max-width: 400px)": {
    whiteSpace: "unset",
    fontSize: "14px"
  },
});

export default class Ctrlvideos extends CtrlvideosController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { videoList, top40videoList, searchText } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        {this.state.loading ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <DivWrapper>
            <Splitleftsidecontainer>
              <Inputboxwrapper>
                <Input
                  test-id="test_search_term_id_handle_search_input"
                  onChange={(text: any) => this.searchFilterFunction(text)}
                  className="testCtrlVideo"
                  prefix={
                    //@ts-ignore
                    <SearchOutlined
                      className="text_white1"
                      value={searchText}
                    />
                  }
                  // placeholder={<p className="text_white"></p>}
                  placeholder="Search Videos"
                />
                <img src={searchshape} style={webStyle.searchimage} />
              </Inputboxwrapper>

              <TotalSplits>
                <Typography style={webStyle.trackname}>
                  <span style={webStyle.bluetext}>{videoList?.length}</span>{" "}
                  Videos
                </Typography>
              </TotalSplits>
              <LeftsideAlltracksbox>
                <LeftsideAlltracksboxwrapper>
                  {videoList.length > 0 ? (
                    videoList.map((item: any, index: any) => {
                      const { typeColor, status } = this.getStatusDetails(item);
                      return (
                        <LeftSideSingletrackbox
                          key={index}
                          onClick={() => {
                            this.selectedsong(index);
                            this.selectedSongId(item.id);
                          }}
                          style={
                            index === this.state.selectedIndex
                              ? webStyle.leftSideSingletrackbox
                              : {}
                          }
                          className="ctrl-videos-data-test"
                        >
                          <Box style={webStyle.singleArtinfo}>
                            <img
                              src={item.attributes.art_work}
                              style={webStyle.leftsideimage}
                            />

                            <Box style={webStyle.leftsideownerstats}>
                              <Trackname >
                                {item.attributes.title}
                              </Trackname>
                              <span style={webStyle.splittext}>
                                {item.attributes.artist_name}
                              </span>
                            </Box>
                          </Box>
                          <Box style={webStyle.leftsideownerstats}>
                            <Box>
                              <Button
                                style={{
                                  border: `1px solid ${typeColor}`,
                                  borderRadius: "20px",
                                  color: typeColor,
                                  height: "23px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {status}
                              </Button>
                            </Box>
                          </Box>
                        </LeftSideSingletrackbox>
                      );
                    })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#fff",
                        padding: "5",
                        justifyContent: "center", marginTop: "10%"
                      }}
                    >
                      Data Not Found.
                    </Typography>
                  )}
                </LeftsideAlltracksboxwrapper>
              </LeftsideAlltracksbox>
            </Splitleftsidecontainer>
            <Borderbox>
              {" "}
              <hr
                style={{
                  height: "100%",
                  width: "1px",
                  border: "1px solid #212121",
                }}
              />
            </Borderbox>
            <SplitboxRightsidecontainer>
              {this.state.showdetails ? (
                <TrackDetails
                  navigation={undefined}
                  id={"1"}
                  trackDetails={this.state.selectedSongId}
                  updateData={() => this.getVideos()}
                  topPlayMusic={this.redirectVideo}
                  index={"video"}
                  setShowDetails={() => this.setState({ showdetails: false })}

                />
              ) : (
                <>
                  <VideosboxRightside>
                    <Box
                      style={{
                        display: " flex ",
                        justifyContent: " space-between ",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={webStyle.trackname}>
                        <span style={webStyle.bluetext}>Top 40</span> Videos
                      </Typography>

                      <Typography style={webStyle.splittext}>
                        This week
                      </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      {top40videoList?.length > 0 ? (
                        <RightsideAlltracksboxwrapper>
                          {top40videoList.map((item: any, index: any) => (
                            <Box key={item?.id} className="video_image_tracks videos_card"
                              style={{
                                borderRadius: "10px",
                                position: "relative",
                                background: `url("${item.attributes?.art_work}") lightgray 50% / cover no-repeat`,
                                display: "flex",
                                alignItems: "end",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}>
                              <Box className="image_border">
                                <Typography style={webStyle.videoImagetex}>
                                  {item.attributes.title}
                                </Typography>
                                <Typography style={webStyle.videoArtisttext}>
                                  {item.attributes.artist_name}
                                </Typography>
                              </Box>

                              <img
                                src={playButton}
                                style={{
                                  height: "49px",
                                  width: "49px",
                                  position: "absolute",
                                  right: "45%",
                                  top: "37%",
                                }}
                                data-test-id="play-img"
                                onClick={() => this.redirectVideo(item)}
                              />

                            </Box>


                          ))}
                        </RightsideAlltracksboxwrapper>) :
                        <Typography
                          style={{
                            marginTop: "10%",
                            paddingTop:"52px"
                          }}
                          className="no_data"
                        >
                          Data Not Found.
                        </Typography>}

                    </Box>
                  </VideosboxRightside>
                </>
              )}
            </SplitboxRightsidecontainer>
          </DivWrapper>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  leftsideimage: {
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
    border: "1px solid #141414"
  },
  imageborder: {
    width: "380px",
    height: "76.275px",
    borderRadius: "8px",
    border: "0px solid #000",
    padding: "20px",
    background: "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)"

  },
  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
  },
  videoImagewrap: {
    position: "relative" as "relative",
    height: "193px",
    width: "335.152px",
    // background: `url(${})`
    // background: "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)"
  },
  imagevideo: {
    height: "193px",
    // width: "100%",
    width: "335.152px",
    minWidth: "300px",
    borderRadius: "8px",
  },
  singlebutton: {
    border: "1px solid blue",
    borderRadius: "20px",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  publicbutton: {
    border: "1px solid red",
    borderRadius: "20px",
    color: "red",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  searchimage: {
    height: "18px",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
    right: "6%",
  },

  videoImagetex: {
    // position: "relative" as "relative",
    top: "-22%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  videoArtisttext: {
    // position: "relative" as "relative",
    top: "-24%",
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    left: "4%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },

  singleArtinfo: {
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
    // width:"282px"
  },
  leftsideAlltracksbox: {
    width: "84%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    marginTop: "-11px",
  },
  leftSideSingletrackbox: {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
    background: "#141414",
    color: "white",
  },

  splittext: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#8f92a1",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontFamily: "Inter",
  },
  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    // alignItems:"center"
  },
  tracksimage: {
    height: "150px",
    width: "150px",
    borderRadius: "8px",
  },
  bluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },
};
// Customizable Area End
