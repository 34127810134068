// Customizable Area Start
import {  Layout, Menu, Row } from 'antd';
import React from 'react';
import {  nxzloader } from '../assets';
import CreativeBox from '../../../../components/src/CustomChart/PopUps/CreativeBox.web';
//@ts-ignore
import {
  Grid,
  Hidden,
} from "@material-ui/core";
import CustomCarousel from '../../../../components/src/CustomChart/PopUps/CustomCarousel.web';
import './index.css'
import { AudioContextApi } from '../AudioContext/AudioContext.web';
import { Content } from 'antd/lib/layout/layout';
import HomeHeader from '../../../../components/src/CustomChart/PopUps/HomeHeader.web';
import CreativeController from './CreativeController';
import AsideLeft from '../../../../components/src/CustomChart/PopUps/AsideLeft.web';


export default class CreativePage extends CreativeController {
  render() {
    
    const token: any = localStorage.getItem("token")
    const menu = () => {
      return (
        <div>
          <Menu  >
            <Menu.Item key="0">
              Dashboard
            </Menu.Item>
            <Menu.Item key="1">
              Profile
            </Menu.Item>

            <Menu.Item key="3">Share Profile</Menu.Item>
            <Menu.Item key="4">My Plan & Payment</Menu.Item>
            <Menu.Item key="5">Edit Profile</Menu.Item>
            <Menu.Item key="6">Sign out</Menu.Item>
          </Menu>
        </div>
      )
    }

    const filterMenu = () => {
      return (
        <div>
          <Menu className="custom-drop-menu">
            <Menu.Item key="0" style={{ color: "white", backgroundColor: "transparent" }}  >Today</Menu.Item>
            <Menu.Item key="1" style={{ color: "white", backgroundColor: "transparent" }} >This week</Menu.Item>
            <Menu.Item key="3" style={{ color: "white", backgroundColor: "transparent" }} > This month</Menu.Item>
            <Menu.Item key="4" style={{ color: "white", backgroundColor: "transparent" }}>This Year</Menu.Item>
          </Menu>
        </div>
      );
    };
    const { creativeList, banner }: any = this.state;
    const {isPlaylist} = this.context;
    return (
      <>
        <div
          style={{
            maxWidth: "100vw",
            overflowX: "hidden",
            overflowY: "hidden",
            backgroundColor: "rgb(31, 31, 31)",
            height: "120vh",
          }}
        >
          <Layout style={{ backgroundColor: "black", height: "100vh" }}>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content
              style={{
                margin: "0px",
                // height: "100%",
                backgroundColor: "black",
                // display: "flex", flexDirection: "column", justifyContent: "space-between"

              }}
            >
              <HomeHeader />
              {/* for content page */}

              <div className={isPlaylist?'MainBody creative-page-isPlaylist':'MainBody creative-page-body'}>


                <div className="head ml-15 mr_2 " style={{ marginBottom: "-25px" }}>
                  <div className=''><h2 style={{ fontWeight: 600, fontFamily: "Poppins", fontSize: "28px" }}>Creatives</h2></div>
                  <div style={{ position: "relative" }} >
                  
                  </div>
                </div>
                {creativeList.length === 0 || banner.length === 0

                  ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>) : (<>

                    <div >
                      <div>
                        <CustomCarousel data={banner}
                          handlePreview={this.handleBannerPreview}
                        />
                      </div>

                      {/* creative */}

                      <div className="boxes">
                        <div className='mt-1 mb_1 creative-height'>
                          <Grid container className="creative-block mostly-customized-scrollbar" style={{
                            padding: 18,
                            display:"flex",
                            justifyContent:"center"
                          }}>
                            {
                              creativeList?.length !== 0 &&
                              creativeList?.map((creativeLists: any, index: number) => {
                                return (

                                  <Grid item md={3} style={{ height: 83, marginBottom: 10 }} className="outer_card_width">
                                    <CreativeBox artistNo={index + 1} key={index}
                                      id={creativeLists?.attributes?.id != null && creativeLists?.attributes?.id}
                                      avatar={creativeLists?.attributes?.avatar != null && creativeLists?.attributes?.avatar}
                                      trackCount={creativeLists?.attributes?.track_count }
                                      userName={creativeLists?.attributes?.user_name != null && creativeLists?.attributes?.user_name}
                                      fullName={(creativeLists?.attributes?.first_name != null && creativeLists?.attributes?.last_name != null) && creativeLists?.attributes?.first_name + " " + creativeLists?.attributes?.last_name}
                                      userType={creativeLists?.type}
                                    />
                                  </Grid>
                                )
                              })}
                          </Grid>

                        </div>
                      </div>

                    </div>
                  </>
                  )
                }
                {/* player................. */}
                <div>


                </div>
              </div>
            </Content>
          </Layout>
        </div>

      </>
    );
  }
};
CreativePage.contextType = AudioContextApi;
// Customizable Area End
