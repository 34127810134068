// Customizable Area Start
//@ts-ignore
import React, { useContext } from "react";
import "./TopSongs.web.css";
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { playcountP, loopIc, chatPlaylist, defaultProfile, down, iconUp, expandMore } from "../../../dashboard/src/assets";
import millify from "millify";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
//@ts-ignore
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
//@ts-ignore
import Waveform from "../../../../components/src/CustomChart/Songs/Waveform";

const TopSongs = ({ songsList, beatList, audioPlay, trackName, playPlaylist, handleplay, handlePlayLists }: any) => {


  const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = useContext(AudioContextApi);
  const history = useHistory();
  console.log(audioPlay,playPlaylist,handleplay,"unused props")
  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if (trackName == "Playlists") {
      handlePlayLists(item)
    } else {
      if (handleAudioVideo === 1 || handleAudioVideo === 0) {
        setHandleAudioVideo(2)
        handlePlayList(item, index, songsList, trackName)
      } else {
        setHandleAudioVideo(1)
      }
    }
  }
  const onFullScreen = (e: any, item: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (item.type == "playlist") {
      handlePlayLists(item)
    } else {
   
    history.push({
      pathname: "/home/expand/song/songId=banner",
      state: {
        data: item,
      }
    })
  }
  }

 
  return (
    <Box className="topSongs-parent">
      <Grid className="topSongs-head-parent" container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">{songsList && 'Top ' + trackName}</Typography>
          </Row>
        </Grid>
        <Grid item xs={4} sm={5} md={5} lg={4} className="second-head-percentage dflex" >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore topSongs-24per">24hr%</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_white1 top_size_16 topSongs-7day">7d%</p>
            </Grid>
            <Grid item xs={5} className="hiddnDaysGraph align-center">
              <p className="text_white1 top_size_16 hiddnDaysGraph mt4"> Last 7d </p>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Box
        className={beatList > 0 ? "scroll-noslider mostly-customized-scrollbar" : "scroll mostly-customized-scrollbar"}>
        {songsList.length>0&&songsList.map((item: any, index: any) => {
        let srcImg;
let classesArtwork;
        if (trackName === "Playlists" && item?.attributes?.cover_image) {
          srcImg = item.attributes.cover_image;
          classesArtwork="topSong-without-artwork"
        } else if (item?.attributes?.art_work) {
          srcImg = item.attributes.art_work;
          classesArtwork="topSong-without-artwork"
        } else {
          srcImg = defaultProfile;
          classesArtwork="topSongs-artwork0default-img"
        }
        return (
            <Col key={item.id} span={24} onClick={() => topPlayMusic(item, item.id, songsList, trackName)} className="musicRow">
              <Grid container style={{ padding: "6px 0px 4px 0px" }} className="main-container-space">
                <Grid item md={4} lg={3} xs={3} sm={4}
                  className="profileDetailBlock">


                  <div className=" width_100 " style={{ display: "flex" }}>
                    <div>
                      <Row className="sn-grid mr_1 ml_05">
                        {index < 9 ? "0" + Number(index + 1) : index + 1}
                      </Row>
                      <img src={item.attributes.arrow ? iconUp : down} className="icon-up mr_05 ml_05" onClick={item.handlePlayList} />
                    </div>
                    <div>
                    <div
                        className="song-cover mr_1 topsongs-cover-listing topSongs-artwork">
                      <img 
                        src={srcImg} className={classesArtwork} alt="Profile"
                      />
                      </div>
                    </div>
                    <div >
                      <Row>
                        <Row justify="start" className="width_100 name_hiddne text_white cursor_pointer "> {trackName === "Playlists" ? item?.attributes?.name : item?.attributes?.title || 'Default Song(Unknown)'}</Row>
                        <Row justify="start" className="width_100 text_gray cursor_pointer artist-name-width">{item?.attributes?.artist_name || 'Unknown'}</Row>
                      </Row>
                    </div>
                  </div>
                </Grid>
                <Col xs={6} className="HiddnFullScreen hours-list">
                  <Row justify="end" className="iconDelete">
                    <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" />
                  </Row>
                </Col>
                <Hidden smDown >
                <Grid item
                  // md={3}
                  md={1}
                  xs={2} sm={4} lg={3}
                  className="playButtonContain topSongs-aligncenter">
                  <Row className=" " style={{width: '95%'}}>
                    
                    <Hidden mdDown>
                      <Col span={24} >
                          {trackName !== "Playlists" && item?.attributes?.track_file &&
                            <Waveform url={item?.attributes?.track_file} id={item?.id} height={24} />
                          }
                      </Col>
                        </Hidden>


                  </Row>
                </Grid>
                </Hidden>
                <Grid item xs={3} md={2} lg={2} className="iconButton play-repost-comment-container">
                  <div className=" display-play_dark_back" style={{width: '100%'}} >
                    <Col className="colButton width_100">
                      <div className='iconContent topSongs-aligncenter'>
                        <img className="iconImg" src={playcountP} height="13px" width="11.5px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}
                        </Box></div>
                    </Col>
                    <Col className="colButton width_100">
                      <div className='iconContent dflex' style={{alignItems:"center"}}>
                        <img className="iconImg" src={loopIc} height="19px" width="19px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}
                        </Box>
                      </div> </Col>
                    <Col className="colButton width_100">
                      <div className='iconContent topSongs-alignEnd'>
                        <img className="iconImg" src={chatPlaylist} height="20px" width="20px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"} </Box>
                      </div>
                    </Col>
                  </div>
                </Grid>
                <Grid item sm={5} md={5} lg={4} className="hidden_percentage" >
                  {/* <Hidden xsDown> */}
                  <LineChart
                       seven_days={item?.attributes?.last_seven_days_percentage}
                       twenty_four_days={item?.attributes?.last_twenty_four_hours_percentage}
                       seven_days_graph={item?.attributes?.last_seven_days_graph}
                       fullscreen={expandMore}
                       onFullScreen={(e: any) => onFullScreen(e, item)}
                     />
                  {/* </Hidden> */}
                </Grid>
              </Grid>
            </Col>
          );


        })}
      </Box>
    </Box>
  );
}
export default TopSongs;
// Customizable Area End