// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { Message } from "../../../../framework/src/Message";
import { HISTORY } from "components/src/common";
import { openNotification } from "components/src/Notification.web";
import { setStorageData } from "../../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  id: string;
  trackDetails: any;
  updateData: any;
  topPlayMusic: any;
  index: any;
  setShowDetails: any;
}

interface S {
  status: string;
  renderPrivate: boolean;
  renderPublic: boolean;
  isDeleteModelV: boolean;
  isInviteLinkV: boolean;
  track_id: any;
  track_details: any;
  chart_data: any;
  user_role: string;
  loading: boolean;
  editPlaylistName: string;
  uploadPhotoPath: string;
  isImage: boolean;
  isText: boolean;
  trackChartData: any;
  openPlaylistModal: boolean;
  track_whole_details: any;
  openInvitationModal: boolean;
  openMessageSendPopup: boolean;
  CopiedForLink: any;
  is_invite_user: boolean;
  is_invited_track_error: boolean;
  accessList: any;
  accessAvatarList: any;
  showAccessListView: boolean;
  accessListLoader: boolean;
}

interface SS {
  id: any;
}

export default class TracksController extends BlockComponent<Props, S, SS> {
  apiTracksDetails: any;
  apiDeleteTrack: any;
  apiChangeStatus: any;
  apiInviteUser: any;
  apiAccessListTrack: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      status: "Draft",
      renderPrivate: false,
      renderPublic: false,
      isDeleteModelV: false,
      isInviteLinkV: false,
      track_id: this.props.trackDetails,
      track_details: {},
      chart_data: {},
      user_role: "",
      loading: false,
      editPlaylistName: "",
      uploadPhotoPath: "",
      isImage: false,
      isText: false,
      trackChartData: [],
      openPlaylistModal: false,
      track_whole_details: [],
      openInvitationModal: false,
      openMessageSendPopup: false,
      CopiedForLink: "",
      is_invite_user: false,
      is_invited_track_error: false,
      accessList: [],
      accessAvatarList: [],
      showAccessListView: false,
      accessListLoader: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.trackDetails();
    this.accessListTrack();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.trackDetails !== this.props.trackDetails) {
      this.trackDetails();
      this.accessListTrack();
     this.setState({showAccessListView:false})
    }
  }

  changestatus = (status: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeStatus = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `${configJSON.changeStatus}?status=${status}&track_id=${this.props.trackDetails}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 editUpload =()=>{
    setStorageData('activeStepUpload', 2)
    setStorageData("edittrackdetails",this.state.track_whole_details)
    
    HISTORY.push('/uploadTrack/audio')
 }
  redirectToExpandScreen = () => {
    HISTORY.push(
      `/home/expand/song/songId=${this.state.track_whole_details.id}`
    );
  };
  handleOpenInvitation = () => {
    this.setState({ openInvitationModal: true });
  };

  handleCloseInvitation = () => {
    this.setState({ openInvitationModal: false });
  };

  handleOpenMessageSendPopup = () => {
    this.setState({ openMessageSendPopup: false });
  };

  handleOpenPlaylist = () => {
    this.setState({ openPlaylistModal: true });
  };
  handleClosePlaylist = () => {
    this.setState({ openPlaylistModal: false });
  };
  linkopenPopup = () => {
    if(this.props.index=="video"){
      this.setState({
        CopiedForLink: window.location.origin.concat(
          `/invitation/videoId=${this.state.track_whole_details.id}`
        ),
      });
    }else{
    this.setState({
      CopiedForLink: window.location.origin.concat(
        `/invitation/songId=${this.state.track_whole_details.id}`
      ),
    });
  }
    this.setState({ openMessageSendPopup: true });
    // this.onInviteUser()
  };

  onInviteUser = (selectedUser: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInviteUser = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/send_invite?account_id=${selectedUser?.id}&track_id=${this.state.track_whole_details.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  trackDetails = () => {
    this.setState({ loading: true, trackChartData: [] });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTracksDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `${configJSON.getTrackDetails}?id=${this.props.trackDetails}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showAccessListData = () => {
    this.setState({
      showAccessListView: !this.state.showAccessListView,
    });
  };

  accessListTrack = () => {
    this.setState({ accessListLoader: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAccessListTrack = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/access_list_of_track?track_id=${this.props.trackDetails}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteTrack = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteTrack = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `${configJSON.deleteTrack}?id=${this.props.trackDetails}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  openDMScreen = () => {
    localStorage.setItem(
      "trackUserListDataDM",
      JSON.stringify(this.state.track_whole_details)
    );
    HISTORY.push({
      pathname: "/comments",
    });
  };

  handleTrackList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        loading: false,
        track_details: responseJson.data[0].attributes,
        is_invite_user: responseJson.data[0].attributes?.is_invite,
        user_role:
          responseJson.data[0].attributes.user_tracks.data[0]?.attributes
            .user_role,
        track_whole_details: responseJson.data[0],
      });
      const chart_value = responseJson.data[0].attributes.last_seven_days_graph;
      Object.keys(chart_value).forEach((day) => {
        // Push each day's value to trackChartData
        this.setState((prevState) => ({
          trackChartData: [...prevState.trackChartData, chart_value[day]],
        }));
      });
      const { status } = responseJson.data[0].attributes;
      let statusValue = "Draft";

      if (status === "private_track") {
        statusValue = "Private";
      } else if (status === "public_track") {
        statusValue = "Public";
      }

      this.setState({
        renderPrivate:
          responseJson.data[0].attributes.status == "private_track"
            ? true
            : false,
        renderPublic:
          responseJson.data[0].attributes.status == "public_track"
            ? true
            : false,
        status: statusValue,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleDeleteModalTrue = () => {
    this.setState({ isDeleteModelV: true });
  };

  handleDeleteTrack = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        loading: false,
        isDeleteModelV: false,
      });
      this.props.updateData();
      this.props.setShowDetails();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleRedirectDots = () => {
    if (this.props.id == "1") {
      this.props.topPlayMusic(this.state.track_whole_details);
    } else {
      this.redirectToExpandScreen();
    }
  };

  handleArtClick = () => {
    if (this.props.id === "1") {
      this.props.topPlayMusic(this.state.track_whole_details);
    } else {
      this.props.topPlayMusic(
        this.state.track_whole_details,
        this.state.track_whole_details.id,
        {},
        this.props.index
      );
    }
  };
  handleChangeStatus = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(
        responseJson.message,
        responseJson.message.includes("Track status successfully update")
          ? "Success"
          : "Error"
      );
      this.trackDetails();
      this.props.updateData();
    }
  };

  handleInviteUser = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (
        responseJson.message == "You are not authorized for invite this track"
      ) {
        this.setState({ is_invited_track_error: true });
        openNotification(responseJson.message, "Error");
      } else {
        this.setState({ is_invited_track_error: false });
        openNotification(responseJson.message, "Success");
      }
    } else {
      openNotification(responseJson.errors, "Error");
    }
  };

  handleAccessUser = (responseJson: any) => {
    if (
      responseJson !== undefined &&
      !responseJson.errors &&
      !responseJson.message
    ) {
      this.setState({
        accessList: responseJson.data.data,
        accessAvatarList: responseJson.data.data.map(
          (item: any) => item.attributes.avatar
        ),
        accessListLoader: false,
      });
      this.trackDetails();
    
    } else {
      this.setState({
        accessList: [],
        accessAvatarList: [],
        accessListLoader: false,
        showAccessListView:false
      });

    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId == this.apiTracksDetails) {
      this.handleTrackList(responseJson);
    } else if (apiRequestCallId == this.apiDeleteTrack) {
      this.handleDeleteTrack(responseJson);
    } else if (apiRequestCallId == this.apiChangeStatus) {
      this.handleChangeStatus(responseJson);
    } else if (apiRequestCallId == this.apiInviteUser) {
      this.handleInviteUser(responseJson);
    } else if (apiRequestCallId == this.apiAccessListTrack) {
      this.handleAccessUser(responseJson);
    }

    // Customizable Area End
  }
}
// Customizable Area End
