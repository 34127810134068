// Customizable Area Start
import { notification } from "antd";
export const openNotification = (messages: any, types: any) => {
  notification.open({
    message: types,
    type: types.toLowerCase(),
    duration: 3,
    description: messages,
  });
};
// Customizable Area End
