// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config.js");
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

export interface Props {
    image: string;
    isTrack: boolean;
    handleTrackAndVideo:()=> void;
    trackType:string;
}

interface Attributes {
  art_work: string;
  // Add other properties as needed
}

interface PlaylistTrack {
  id: number;
  attributes: Attributes;
  // Add other properties as needed
}
interface S {
  userData: string;
  imageList: string[];
  isListner: boolean;
  token: any;
  playlistTracks: PlaylistTrack[];
}
interface SS {
  id: any;
}

export default class ProfileMessageCardController extends BlockComponent<
  Props,
  S,
  SS
> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      userData: "",
      imageList: [],
      isListner: false,
      token: localStorage.getItem("token"),
      playlistTracks: []
    };

  }

}
ProfileMessageCardController.contextType = AudioContextApi;
// Customizable Area End