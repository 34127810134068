// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout, Typography, Tabs,Carousel } from 'antd';
import {
    Box,
    Hidden,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Button
} from "@material-ui/core";
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { bluecheck, userlog0, videoimage, carsouelimage } from '../assets';
import AudioVideoController from './AudioVideoController';
export const settings = {

    autoplay: true,
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1770,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1566,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1492,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1393,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1295,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1094,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1038,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 895,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 878,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 735,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 604,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 514,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 455,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 406,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 353,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 326,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 308,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
  
  
    ]
  
  };
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LeftSideSingletrackbox = styled(Box)({
    // marginTop: '6px',
    height: "81px",
    // padding: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    // display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",

    color: "white",
    // "&:hover": {
    //     //   marginTop: '12px',
    //     height: "81px",
    //     // padding: "10px",
    //     borderRadius: "8px",
    //     objectFit: "contain",
    //     background: "#141414",
    //     display: "flex",
    //     justifyContent: "space-between",
    //     alignitems: "center",
    //     // alignItems:"center"
    // },
});
const LableAudiohistorycontainer = styled(Box)({
    display: 'flex',
    width: "100%",
    justifyContent: "space-between",
    paddingTop: "20px",

    "@media(max-width: 1000px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
        flexDirection: "column"
    },
});
const Carasouelmainbox = styled(Box)({
    display: "flex",
    flexDirection: "column", 
    gap: "10px",
    border: "1px solid #141414",
    background: "#090909",
    padding:"15px",

    "@media(max-width: 950px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
        paddingLeft:"15px"
    },
});
const LeftsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const LbalehistoryRightsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    // paddingTop: "60px",
    "@media(max-width: 1000px)": {
        width: "100%",
        padding: "0px 10px 0px 10px"
    },
});

const Trackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",

    fontFamily: "Inter",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});

const { TabPane } = Tabs;

export default class LablehistoryAudio extends AudioVideoController {
    render() {
      const { isPlaylist } = this.context;
        return (
            <>
                <Layout className='streamRepostWrapper'>
                    {/* .......................SideBar...................................... */}
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                           
                        }}
                    >
                      
                        <HomeHeader location="RepostHistory" redirect={this.redirectToProfile}/>
                        <Box style={{paddingTop:"10px",height:isPlaylist ? "75vh":"90vh" ,overflowY:"scroll"}} >
                            <Carasouelmainbox >
                                <Box >
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontSize: "22px",
                                        fontWeight: 700,
                                        lineHeight: "30px",
                                        color: "#F0F0F5"
                                    }}>Active Deals</Typography>
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        lineHeight: "30px",
                                        textAlign: "left",
                                        color: "#8F92A1"
                                    }}>Creatives I have deals with</Typography>
                                </Box>
                                <Box>
                                <Carousel
                               
                                {...settings}
                                infinite={false}
                              >
                               {Array.from({ length: 20 }).map((_, index) => (
                                            <Box>
                                                <img src={carsouelimage}  style={{width:"200px",height:'270px',background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",borderRadius:"15px",border: "5px solid #212121"
}}/>
                                            </Box>

                                        ))} 
                              </Carousel>
                                </Box>
                            </Carasouelmainbox>


                            {/* </Box> */}
                            <Box>
                                <LableAudiohistorycontainer  >
                                    <LeftsideAudiohistorycontainer >
                                        <Box>

                                            <Typography style={webStyle.trackname}>
                                                {" "}
                                                <span style={webStyle.bluetext}>
                                                    TOP 1K:
                                                </span>{" "}
                                                Listeners
                                            </Typography>
                                        </Box>



                                        <Box>
                                              <div className="chats-header" >
                            <Tabs
                          className='Streamrepost_custom_tab'
                         defaultActiveKey="1"
                          tabPosition="top"
                           style={{
                       //   height: 220,
                        }}
                         items={this.state.tabs.map((item:any, index:any) => {
                          const id = String(index);
                           return {
                            label: item.month,
                            key: id,
                          disabled: index === 12,
                            };
                              })}
        
                           />
                         </div>

                                        </Box>
                                        <Box style={{ paddingBottom: "5px" }}>
                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <LeftSideSingletrackbox
                                                    key={index}
                                                >
                                                    <Box style={webStyle.labelhistorysingleArtinfo}>
                                                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                          <span>{index+1}</span>
                                                            <img
                                                                src={userlog0}
                                                                style={webStyle.labelhistoryleftsideimage}

                                                            />
                                                            <Box style={webStyle.labelhistoryleftsideownerstats}>
                                                                <Trackname className="tracks_songs_text">
                                                                   Jiwang
                                                                    <span className="Jason-Derulo">
                                                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                                    </span>
                                                                </Trackname>
                                                                <span style={webStyle.lablehistorysplittext}>
                                                                   Streams

                                                                </span>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center"
                                                        }}>
                                                            <span style={webStyle.labelhistorysplittextwhite}>
                                                                487
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: "flex", justifyContent: "center" }} >
                                                        <Box style={{
                                                            width: "100%",
                                                            paddingLeft: "114px"

                                                        }} >

                                                            <BorderLinearProgress variant="determinate" value={64} />
                                                        </Box>
                                                    </Box>

                                                </LeftSideSingletrackbox>
                                            ))}
                                        </Box>
                                    </LeftsideAudiohistorycontainer>
                                    <LbalehistoryRightsideAudiohistorycontainer>

                                        <Box>
                                            <Typography style={webStyle.trackname}>
                                                {" "}
                                                <span style={webStyle.bluetext}>
                                                    Jiwang:
                                                </span>{" "}
                                                Streamed
                                            </Typography>
                                        </Box>
                                        <Box style={{ width: "100%" }}>
                                            <Box style={webStyle.LabelRightsidetabsbutton}>
                                                <Box style={webStyle.labelhistorytabbox}>
                                                    <Button

                                                        style={this.state.rightsideSelectedtab == "Audio" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}

                                                        onClick={() => this.handleAudiovideotab("Audio")}
                                                        data-test-id="lableaudiotab"
                                                    >
                                                        Audio
                                                    </Button>
                                                </Box>
                                                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        style={this.state.rightsideSelectedtab == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                                        onClick={() => this.handleAudiovideotab("Video")}
                                                        data-test-id="lablevideotab"
                                                    >
                                                        Video
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>

                                            {Array.from({ length: 8 }).map((_, index) => (
                                                <LeftSideSingletrackbox
                                                    key={index}
                                                >
                                                    <Box style={webStyle.labelhistorysingleArtinfo}>
                                                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                            {this.state.rightsideSelectedtab == "Audio" ? <img
                                                                src={videoimage}
                                                                style={webStyle.labelhistoryleftimage}

                                                            /> : <img
                                                                src={videoimage}
                                                                style={webStyle.labelhistoryleftvideoimage}

                                                            />}
                                                            <Box style={webStyle.labelhistoryleftsideownerstats}>
                                                                <Trackname className="tracks_songs_text">
                                                                   yfrs
                                                                    <span className="Jason-Derulo">
                                                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                                    </span>
                                                                </Trackname>
                                                                <span style={webStyle.lablehistorysplittext}>
                                                                    House Party

                                                                </span>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center"
                                                        }}>
                                                            <span style={webStyle.labelhistorysplittextwhite}>
                                                                487
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: "flex", justifyContent: "center" }} >
                                                        <Box style={{
                                                            width: "100%",
                                                            paddingLeft: this.state.rightsideSelectedtab == "Audio"? "91px":"135px"
                                                        }} >
                                                            <BorderLinearProgress variant="determinate" value={64} />
                                                        </Box>
                                                    </Box>

                                                </LeftSideSingletrackbox>
                                            ))}
                                        </Box>
                                    </LbalehistoryRightsideAudiohistorycontainer>
                                    <Box>
                                    </Box>
                                </LableAudiohistorycontainer>
                            </Box>
                        </Box>

                    </Content>
                </Layout>
            </>

        )
    }
}
const webStyle = {
    RightSidebuttonBox: {
        width: "100%"
    },
    LabelRightsidetabsbutton: {
        borderRadius: "30px",
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",

    },
    labelhistorytabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    labelhistoryleftimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        border: "1px solid #141414",
        height: "75px",
    },
    labelhistoryleftsideimage: {
      borderRadius: "60px",
      minWidth: "75px",
      height: "75px",
      border: "2px solid #FFFFFF",
      width: "75px",
  },
    labelhistoryleftvideoimage: {
        borderRadius: "10px",
        width: "120px",
        minWidth: "120px",
        height: "75px",
        border: "1px solid #141414"
    },

   labelhistorysingleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },

    leftsideAlltracksbox: {
        width: "85%",

    },


    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },

    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
    },

    lablehistorysplittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    labelhistorysplittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
   labelhistoryleftsideownerstats: {
        gap: "3px",
        display: "flex",
        flexDirection: "column" as "column",
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
};
LablehistoryAudio.contextType = AudioContextApi;
// Customizable Area End