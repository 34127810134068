// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { Avatar, Row } from "antd";
import { styled } from "@material-ui/styles";
import { deleteplaylist, calendaricon, inactive, time, } from "../../../dashboard/src/assets";
import CtrlChart from "./Common/CtrlChart.web";
import TrackDetailsController from "./TrackDetailsController";
import {
  defaultImage,
  defaultProfile,
  nxzloader,
} from "blocks/dashboard/src/assets";
import DeleteTracks from "./Tracks/DeleteTracks.web";
import moment from "moment";
import TrackInvitedUser from "./TrackInvitedUser.web";
import AccessList from "./AccessList.web";

const Totaltext = styled(Box)({
  color: "#FFF",
  fontFamily: "Inter",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "16px",
  "@media(max-width: 600px)": {
    fontSize: "12px",
  },
  "@media(max-width: 400px)": {
    fontSize: "10px",
  },
});

export default class TrackDetails extends TrackDetailsController {
  returnAvatarListData = (accessAvatarList: any) => {
    return (
      <>
        {accessAvatarList?.length <= 3 ? (
          <Avatar.Group maxCount={3} style={{cursor:"pointer"}}
          >
            {accessAvatarList?.map((item: any, index: any) => (
              <Avatar
                key={index}
                src={item || defaultProfile}
                style={webStyle.userimage}
          onClick={this.showAccessListData}
              />
            ))}
          </Avatar.Group>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
              cursor:"pointer"
            }}
            onClick={this.showAccessListData}
          >
            <Avatar.Group maxCount={3}>
              {accessAvatarList?.slice(0, 3).map((item: any, index: any) => (
                <Avatar
                  key={index}
                  src={item || defaultProfile}
                  style={webStyle.userimage}
                />
              ))}
            </Avatar.Group>
            <Typography
              className="details-access-number"
              
            >
              {accessAvatarList?.length > 0
                ? `+${accessAvatarList?.length - 3}`
                : 0}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  render() {
    const {
      loading,
      isDeleteModelV,
      track_details,
      user_role,
      renderPublic,
      trackChartData,
      showAccessListView,
      accessAvatarList,
      accessList,
      accessListLoader,
      is_invite_user
    } = this.state;

    let content;
    if (loading) {
      content = (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      );
    } else if (showAccessListView) {
      content = (
        <AccessList
          accessList={accessList}
          showAccessListData={this.showAccessListData}
          accessListLoader={accessListLoader}
          accessListTrack={this.accessListTrack}
          trackDetails={track_details}
          handleArtClick={this.handleArtClick}
          track_id={this.props.trackDetails}
        />
      );
    } else {
      content = (
        <>
            <TrackInvitedUser {...this.props} />
            <Box style={webStyle.rightSidetrackbox}>
              <Box style={webStyle.singleArtinfo}>
                <img
                  src={track_details.art_work || defaultImage}
                  style={{ borderRadius: "11px" }}
                  className="iconImg"
                  width="100px"
                  height="60px"
                  onClick={() => this.handleArtClick()}
                />

                <Box style={webStyle.leftsideownerstats}>
                  <span style={webStyle.trackname}>{track_details.title}</span>
                  <span style={webStyle.splittext}>
                    {track_details.artist_name}
                  </span>
                </Box>
              </Box>
              <Box
                style={webStyle.artistimagebox}
                onClick={this.handleDeleteModalTrue}
              >
                <img src={deleteplaylist} style={webStyle.artisitImage} />
              </Box>
            </Box>
            <Box style={{ width: "100%" }}>
              <CtrlChart
                navigation={undefined}
                id={""}
                chartData={trackChartData}
                isgradient={false}
                labellength={7}
              />
            </Box>
            <Box style={{ width: "100%", marginTop: "-21px" }}>
              <Box style={webStyle.weekdaysbox}>
                {["S", "M", "T", "W", "T", "F", "S"].map((item, index) => (
                  <Box style={webStyle.singledaybox} key={index}>
                    <Typography style={webStyle.weeknametext}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box style={webStyle.alltrackdetailswrapper}>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>Plays</Typography>
                  <Typography style={webStyle.totaltext}>
                    {track_details.play_count}
                  </Typography>
                </Box>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>Split</Typography>
                  <Typography style={webStyle.totaltext}>1</Typography>
                </Box>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>Role</Typography>
                  <Totaltext>{user_role}</Totaltext>
                </Box>
              </Box>
              <Box style={webStyle.alltrackdetailswrapper}>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>Comments</Typography>
                  <Typography style={webStyle.totaltext}>
                    {track_details.comments_count}
                  </Typography>
                </Box>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>DMs</Typography>
                  <Typography style={webStyle.totaltext}>
                    {track_details.track_dm_count}
                  </Typography>
                </Box>
                <Box style={webStyle.singletrackdetailsbox}>
                  <Typography style={webStyle.bluetext}>Type</Typography>
                  <Typography style={webStyle.totaltext}>
                    {track_details.track_upload_type}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={webStyle.statusbuttonbox}>
              <Box>
                <Typography style={webStyle.statustext}>Status:</Typography>
              </Box>
              <Box>
                <Button
                  style={
                    renderPublic
                      ? webStyle.statusbutton
                      : webStyle.statustextblue
                  }
                >
                  {this.state.status}
                </Button>
              </Box>
            </Box>
            {!is_invite_user && (
            <Box style={webStyle.statusbuttonbox}>
              <Box>
                <Typography style={webStyle.statustext}>
                  Who has Access:
                </Typography>
              </Box>
              {accessAvatarList?.length > 0 ? (
                this.returnAvatarListData(accessAvatarList)
              ) : (
                <Typography style={webStyle.statustext}>No Access</Typography>
              )}
            </Box>
            )}
            <Box style={webStyle.statusbuttonbox}>
              <Box>
                <Typography style={webStyle.statustext}>Released:</Typography>
                ,font
              </Box>
              <Box style={webStyle.releasebox}>
                <Box style={webStyle.singlereleaseboxstats}>
                  <img src={calendaricon} style={webStyle.releasedstatsimage} />
                  <Typography style={webStyle.totalreleasetext}>
                    {moment(track_details.created_at).format("DDMMM")}
                  </Typography>
                </Box>
                <Box style={webStyle.singlereleaseboxstats}>
                  <img src={time} style={webStyle.releasedstatsimage} />
                  <Typography style={webStyle.totalreleasetext}>
                    {moment(track_details.created_at).format("H:mm")}
                  </Typography>
                </Box>
                <Box style={webStyle.singlereleaseboxstats}>
                  <img src={inactive} style={webStyle.releasedstatsimage} />
                  <Typography style={webStyle.totalreleasetext}>MP3</Typography>
                </Box>
              </Box>
            </Box>
            <DeleteTracks
              title={"Delete Track"}
              subTitle={" Are You Sure You Want To Delete This?"}
              isOpen={isDeleteModelV}
              handleDMClose={() => {
                this.setState({ isDeleteModelV: false });
              }}
              handleDelete={() => this.deleteTrack()}
            />
          </>
      );
    }



    return (
      //Merge Engine DefaultContainer
      <>
       {content}
      </>

      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  trackdetailsfirstbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  trackdeatilsfunctionlitybox: {
    display: "flex",
    gap: "20px",
  },

  trackshareimagebox: {
    width: "60px",
    height: "60px",
    background: "#141414",
    borderRadius: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fill: "#141414",
    strokeWidth: "2px",
    stroke: "#212121",
    border: "2px solid #212121",
  },
  Plyalistimage: {
    height: "34px",
    width: "34px",
  },
  editimageWithoutcursor: {
    width: "60px",
    height: "60px",
    cursor: "not-allowed",
  },
  editimageCursor: {
    width: "60px",
    height: "60px",
    cursor: "pointer",
  },
  singlereleaseboxstats: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  releasebox: {
    display: "flex",
    gap: "15px",
  },
  artisitImage: {
    width: "24px",
    height: "24px",
  },
  artistimagebox: {
    width: "60px",
    height: "60px",
    background: "red",
    borderRadius: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  bluetext: {
    color: "#3959F9",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "16px",
  },
  totaltext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "16px",
  },
  weekdaysbox: {
    display: "flex",
    borderRadius: "5px",
    background: "#141414",
  },
  singledaybox: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
  },
  weeknametext: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    // line-height: 16px
  },
  alltrackdetailswrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "23px",
  },
  singletrackdetailsbox: {
    width: "30%",
    height: "68px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "7px",
    border: "1px solid #212121",
    background: "#070707",
    paddingLeft: "16px",
    gap: "10px",
    flexDirection: "column" as "column",
  },

  statustext: {
    color: "#8F92A1",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
  },
  statustextblue: {
    borderRadius: "30px",
    border: "1px solid #3959F9",
    background: "#000",
    color: "#3959F9",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
    textTransform: "capitalize" as "capitalize",
  },
  statusbutton: {
    borderRadius: "30px",
    border: "1px solid #FF0501",
    background: "#000",
    color: "#FF0501",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
    textTransform: "capitalize" as "capitalize",
  },

  singleArtinfo: {
    display: "flex",
    gap: "10%",
    justifyContent: "center",
    alignItems: "center",
  },

  trackname: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "Inter",
    lineHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: " #EFEFEF",
    maxWidth: "150px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    // whiteSpace: "nowrap" as 'nowrap' 
  },
  userimage: {
    width: "35px",
    height: "35px",
    marginLeft: "-10px",
  },
  releasedstatsimage: {
    width: "18.257px",
    height: "17.949px",
  },
  buttonbox: {
    borderRadius: "20px",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
  },
  statusbuttonbox: {
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
    width: "100%",
  },
  totalreleasetext: {
    color: "rgba(255, 255, 255, 0.50)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
  },

  splittext: {
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#8F92A1",
    fontFamily: "Inter",
  },

  rightSidetrackbox: {
    // marginTop: "12px",
    padding: "10px 30px 10px 16px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
    color: "white",
    border: "3px solid #212121",
    background: "#070707",
    width: "100%",
  },

  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
};
// Customizable Area End
