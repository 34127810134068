import React from "react";

// Customizable Area Start
import {
  Text,
  View,
  SafeAreaView,
  ImageBackground,
  TouchableOpacity,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Image,
  Platform,
  StatusBar,
  Linking,
  StyleSheet,
  Dimensions
} from "react-native";
import scale, { verticalScale } from '../../../components/src/Scale';
import { logoImg, bgImg} from "./assets";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeaderView = () => {
    return (
      <View style={styles.headerview}>
        <Image style={styles.logo} source={logoImg} />
        <View style={styles.headingview}>
          <Text style={styles.heading}>The Worlds First</Text>
          <Text style={styles.heading}>Digital Record Label</Text>
          <Text style={styles.subheading}>For Creatives By Creatives</Text>
        </View>
      </View>
    );
  };
  renderTextInput = () => {
    return (
      <View style={styles.textinputview}>
        <View style={styles.fullnameview}>
          <Text style={styles.titletxt}>Username</Text>
          <TextInput
          testID="nameTxtipt"
            style={styles.txtinput}
            keyboardType={
              Platform.OS === "ios" ? "ascii-capable" : "visible-password"
            }
            autoCapitalize="none"
            value={this.state.username}
            onChangeText={(text: any) =>
              this.setState({
                username: text.toLowerCase(),
                isInvalidUsername: false,
              })
            }
          />
          <View style={styles.line} />
        </View>
        <View style={styles.passwordview}>
          <Text style={styles.titletxt}>Password</Text>
          <TextInput
          testID="passTxtipt"
            style={styles.txtinput}
            secureTextEntry={true}
            onChangeText={(text: any) =>
              this.setState({ password: text, isInvalidpass: false })
            }
          />
          <View style={styles.line} />
        </View>
        <TouchableOpacity
        testID="forgotPassBtn"
          onPress={() => {
            Linking.openURL(`https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/forgot-password`)
            // this.props.navigation.navigate("ForgetPassword")
          }}
        >
          <Text style={styles.forgetlink}>FORGET PASSWORD?</Text>
        </TouchableOpacity>
      </View>
    );
  };
  renderButtonView = () => {
    return (
      <View style={styles.buttonview}>
        <TouchableOpacity
        testID="loginBtn"
          style={styles.loginbtn}
          onPress={() => this.onPressLogin()}
        >
          <Text style={styles.btntxt}>LOGIN</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={styles.googlebtn}
          onPress={() =>
            alert("Coming Soon!")
            //  this.onPressGoogleSignIn()
            }
        >
          <Image
            source={require("../assets/Google.png")}
            style={styles.googleicon}
          />
          <Text style={styles.btntxt}>CONNECT WITH GOOGLE</Text>
        </TouchableOpacity> */}
        {/* <View style={styles.signupview}>
          <Text style={styles.signuptxt}>DON'T HAVE AN ACCOUNT?</Text>
          <TouchableOpacity
            onPress={() =>
              // this.props.navigation.navigate("UserType")
              Linking.openURL(`https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/AccountType`)
            }
          >
            <Text style={styles.signuptxt1}> SIGN UP</Text>
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };
    // Customizable Area End
  render() {
    return (
      // Required for all blocks
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        {/* Required for all blocks */}
        <TouchableWithoutFeedback
          testID={"Background"}
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <SafeAreaView style={styles.container}>
        <StatusBar barStyle="light-content" />
        <ImageBackground
          source={bgImg}
          style={styles.bgimg}
        >
          {this.renderHeaderView()}
          {this.renderTextInput()}
          {this.renderButtonView()}
          </ImageBackground>
         </SafeAreaView>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#18191D'
},
bgimg: {
    flex: 1,
    height: screenHeight,
    width: screenWidth,
},
headerview: {
    marginTop: verticalScale(80),
    marginHorizontal: scale(30),
    alignItems: 'center',
    justifyContent: 'center'
},
logo:{
    height: scale(85),
    width: scale(105)
},
headingview:{
    marginTop: verticalScale(20),
    alignItems:'center',
    justifyContent:'center'
},
heading:{
    color: '#FFFFFF',
    fontSize: scale(25),
    fontWeight:'700',
    marginTop: verticalScale(2),
    letterSpacing: 0.4
},
subheading:{
    color:'#A7A7A8',
    marginTop: verticalScale(20),
},
textinputview:{
    marginTop: scale(50),
    marginHorizontal: scale(30),
},
fullnameview:{
    marginTop: verticalScale(20)
},
passwordview:{
    marginTop: verticalScale(50)
},
titletxt:{
    fontSize: scale(14),
    color: '#898C9A'
},
txtinput:{
    marginTop: verticalScale(10),
    color:'#FFFFFF',
    fontSize: scale(18),
    fontWeight:'500',
},
line:{
    marginTop: verticalScale(5),
    height: scale(1),
    backgroundColor: '#737373'
},
forgetlink:{
    alignSelf:'flex-end',
    color:'#FFFFFF',
    marginTop: verticalScale(20),
    fontWeight: 'bold',
    fontSize: scale(18)
},
buttonview:{
    marginTop: verticalScale(40),
    marginHorizontal: scale(40)
},
loginbtn:{
    height: scale(60),
    width: scale(330),
    borderRadius: scale(80),
    backgroundColor: '#FB0007',
    alignItems:'center',
    justifyContent:'center'
},
btntxt:{
    color:'#FFFFFF',
    fontSize: scale(20),
    fontWeight: 'bold'
},
googlebtn:{
    height: scale(60),
    width: scale(330),
    borderRadius: scale(80),
    backgroundColor: '#1A1A1D',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row',
    marginTop: verticalScale(20)
},
googleicon:{
    height: scale(30),
    width: scale(30),
    right: scale(20)
},
signupview:{
    flexDirection:'row',
    marginTop: scale(40),
    alignItems:'center',
    justifyContent:'center',
},
signuptxt:{
    color:'#FFFFFF',
    fontWeight: 'bold',
    fontSize: scale(16),
},
signuptxt1:{
    color:'#FB0007',
    fontWeight: 'bold',
    fontSize: scale(16),
},
});
// Customizable Area End