// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import "./MonthTrack.css";
import axios from "axios";
import { musicDownload } from "../../dashboard/src/assets";
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const getTypeStyles = (type:any) => {
  let border, color;

  if (type === 'Beat') {
    border = "1px solid #ff0000";
    color = "#ff0000";
  } else if (type === 'Song') {
    border = "solid 1px #3959f9";
    color = "#3959f9";
  } else {
    border = "solid 1px #919191";
    color = "#919191";
  }

  return { border, color };
};


const TrackInfo = ({ item, onClick,saveDownloadHistory }:any) => {
  const { border, color } = getTypeStyles(item.type)
  return(
  <Box className="downloadTrack" onClick={onClick}>
    <Box className="track_title">
      <img
        alt="image"
        src={item.art_work}
        style={{ width: "50px", height: "50px" }}
      />
      <Box className="track_name_detail">
        <span>{item.track_name}</span>
        <span>{item.artist_name}</span>
      </Box>
    </Box>
    <span
      className="track_types"
      style={{
        border,
        color
      }}
    >{item.type}</span>
    <span className="track_genre">{item.genre || "not available"}</span>
    <span className="track_genre">{item.bpm || "--"}</span>
    <span className="track_genre">{item.key || "--"}</span>
    <a
      href={`${item.track_file}?response-content-disposition=attachment`}
      download={item.title}
      onClick={(e) => saveDownloadHistory(e, item.id)}
    >
      <button className="downloadBtn">Download</button>
      <img src={musicDownload}
                          className="music-download" />
    </a>
  </Box>
  )
    }

const TracksSection = ({ items, isFilter, onClick }:any) => {
return (
  <>
    {items.map((item:any, index:any) => (
      <TrackInfo
        key={item.id}
        item={item}
        onClick={() => onClick(item, item.id, items, item.type, index, isFilter)}
      />
    ))}
  </>
)
    };



export const MonthTrack = ({ data, handleCurrentTrack, filteredData, isFilter, topPlayMusic }: any) => {
  const user_id: any = localStorage.getItem("user_id");
  const token: any = localStorage.getItem("token");
  const saveDownloadHistory = async (e: any, id: any) => {
    // e.preventDefault()
    e.stopPropagation()
    const dataSong = {
      account_id: user_id,
      add_track_id: id

    };
    await axios
      .post(`${baseUrl}/download_histories`, dataSong, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        // openNotification("This song is successfully added in playlist", "Success")
        console.log("successfully downloaded",res)

      })
      .catch((err: any) => {
        console.log("error in download",err)

      })
  };

  console.log(filteredData,isFilter,"checking downloadss")
  function filteredAndSortedData(data:any) {
    return [...data]
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => b.attributes.download_histories_id - a.attributes.download_histories_id);
  }

  const tracksSectionOrMessage = filteredData.length > 0 ? (
    
    <TracksSection
      // items={[
      //   ...filteredData
      //     .filter((value:any, index:any, array:any) => array.indexOf(value) === index)
      // ].sort((a: any, b: any) => b.attributes.download_histories_id - a.attributes.download_histories_id)}
      items={filteredAndSortedData(filteredData).map(item => item.attributes)}
      isFilter={isFilter}
      onClick={topPlayMusic}
    />
  ) : (
    <p className="no-downloads">
      You have no download history
    </p>
  );

  return (
  <Box style={{ height: "100%" }}>
    {!isFilter ? (
      Object.keys(data).map((month, monthIndex) => (
        <>
          <p className="month_wise_track">{month}</p>
          <TracksSection
            items={[
              ...new Map(data[month].map((item:any) => [item.id, item])).values()
            ].sort((a:any, b:any) => b.download_histories_id - a.download_histories_id)}
            isFilter={isFilter}
            onClick={topPlayMusic}
            saveDownloadHistory={saveDownloadHistory}
          />
        </>
      ))
    ) : (
      tracksSectionOrMessage
    )}
  </Box>
  )
};
// Customizable Area End