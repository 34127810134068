// Customizable Area Start
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import "./snapshot.css";
import StatsController from "./StatsController";
import { playStats, downloadStats, splitStats } from "../../assets";
export default class StatsBox extends StatsController {
  render() {
    const { data } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid container className="stats-grid-container">
        <Grid container item sm={6} md={6} lg={3} className="outer_card_width">
          <Grid container className="stats-outerDiv">
            <Grid item xs={9} className="stats-childGrid">
              <div className="stats-divFiller">
                <img src={playStats} className="stats-icon" />
              </div>
              <div className="stats-leftDiv">
                <Typography className="plays-stats">Plays</Typography>
                <Typography className="stats-overall">Overall</Typography>
              </div>
            </Grid>
            <Grid container item xs={3} className="stats-overallGrid">
              <Typography className="plays-stats">{data.play_count}</Typography>
              <Typography className="stats-overall">
                {this.renderPercentageIcon(data.last_two_weeks_play_percent)}
                {this.getPctTxt(data.last_two_weeks_play_percent)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Views */}
        <Grid container item sm={6} md={6} lg={3} className="outer_card_width">
          <Grid container className="stats-outerDiv">
            <Grid item xs={9} className="stats-childGrid">
              <div className="stats-divFiller">
                <img src={playStats} className="stats-icon" />
              </div>
              <div className="stats-leftDiv">
                <Typography className="plays-stats">Views</Typography>
                <Typography className="stats-overall">Overall</Typography>
              </div>
            </Grid>
            <Grid container item xs={3} className="stats-overallGrid">
              <Typography className="plays-stats">{data.view_count}</Typography>
              <Typography className="stats-overall">
                {this.renderPercentageIcon(data.last_two_weeks_view_percent)}

                {this.getPctTxt(data.last_two_weeks_view_percent)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Downloads */}

        <Grid container item sm={6} md={6} lg={3} className="outer_card_width">
          <Grid container className="stats-outerDiv">
            <Grid item xs={9} className="stats-childGrid">
              <div className="stats-divFiller">
                <img src={downloadStats} className="download-split-icon" />
              </div>
              <div className="stats-leftDiv">
                <Typography className="plays-stats">Downloads</Typography>
                <Typography className="stats-overall">Overall</Typography>
              </div>
            </Grid>
            <Grid container item xs={3} className="stats-overallGrid">
              <Typography className="plays-stats">
                {data.total_download_count}
              </Typography>
              <Typography className="stats-overall">
                {this.renderPercentageIcon(
                  data.last_two_weeks_download_percentage
                )}

                {this.getPctTxt(data.last_two_weeks_download_percentage)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Split Stats */}

        <Grid container item sm={6} md={6} lg={3} className="outer_card_width">
          <Grid container className="stats-outerDiv">
            <Grid item xs={9} className="stats-childGrid">
              <div className="stats-divFiller">
                <img src={splitStats} className="download-split-icon" />
              </div>
              <div className="stats-leftDiv">
                <Typography className="plays-stats">Splits</Typography>
                <Typography className="stats-overall">Overall</Typography>
              </div>
            </Grid>
            <Grid container item xs={3} className="stats-overallGrid">
              <Typography className="plays-stats">
                {data.splits_count}
              </Typography>
              <Typography className="stats-overall">
                {this.renderPercentageIcon(
                  data.last_two_weeks_split_count_percentage
                )}
                {this.getPctTxt(data.last_two_weeks_split_count_percentage)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  //Merge Engine End DefaultContainer
}

// Customizable Area End
