// Customizable Area Start
import React from 'react'
import { Grid, Box,  Typography } from "@material-ui/core";
import {revenue_arrow,arrowup} from '../../../../../blocks/dashboard/src/assets';
import './DashboardRevenue.css'
import DashboardRevenueChart from './DashboardRevenueChart.web';





export default class DashboardRevenue extends React.Component {
  render() {
    return (
      <Box sx={{ width: "100%" }}>
      <Box className='revenue_topheader_containers'>

        {Array.from({ length: 5 }, (_, index) => (
          <Box className='revenue_topheader' key={index}>
            <div className='revenue_topheader_title'>Ads</div>
            
            <Typography className='revenue_topheader_text'>$21,500.00</Typography>
            <Grid item className='arrow_up_lable'>
              <img  className='arrow_up' src={arrowup} />
              <Typography className='arrow_up_text'> 3.14% </Typography>
            </Grid>
          </Box>
        ))}

      </Box>
      <Box  className='revenue_topheader_container'>
        <Box  className='revenue_leftside_wrapper'>
          {Array.from({ length: 15 }, (_, index) => (
            <Box className='revenue_leftside_element_box'>
             <Typography className='revenue_month'>January</Typography>
              <img src={revenue_arrow} className='revenue_arrow'/>
             <Typography className='revenue_sales_data'>$2789.02</Typography>
            </Box>
          ))}

        </Box>
        <Box className='revenue_rightside_content'>
          <Box className='revenue_right_box'>
          <div  className='revenue_balance revenue_balance_media' style={{width:"50%"}}>
          <span className="balance_text">BALANCE</span>
        </div>
        <div>
        <span className="revenue_amount">
       $1,36,58<span style={{fontSize:"22px"}}>.19</span>
        </span>
        </div>
        <div>
        <span className="revenue_amount_data revenue_amount">
        <span  className='plus_icon'>+</span >135%
         </span>
        </div>
        <div className='revenue_chart'>
          <DashboardRevenueChart/>
        </div>
        <div className="revenue_lastquarter">
        <span className="Last-Quarter">
        Last Quarter
        </span>
        </div>
          </Box>
        
        </Box>
      </Box>
    </Box>
    )
  }
}




// Customizable Area End