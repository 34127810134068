// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedMedia: any;
    splitType: string;
    selectedUser: any;
    selectedPercentage: number;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SplitPercentageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sampleAudio: any = { id: 1, name: 'House Party', writer: 'YFRS', play_count: '1.7M' }
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: '60%'
            },
            selectedMedia: {
                id: 1,
                name: 'House Party',
                writer: 'yfrs',
                play_count: '1.7M'
            },
            splitType: 'audio',
            selectedPercentage: 0,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    onNextClick = async () => {
        if (this.state.selectedPercentage === 0) {
            openNotification("Select percentage", 'Error')
        }
        else {
            await setStorageData('selectedPercentage', this.state.selectedPercentage);
          
            HISTORY.push({
                pathname: `/deal/enter-deal-amount`,
                state: {}
            });
        }
    }

    onBackClick = () => {
        HISTORY.goBack();
    }
    onPercentageChange = (value: number) => { this.setState({ selectedPercentage: value }) }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        const splitType = await getStorageData('splitType')
        const selectedMedia = await getStorageData('media')
        const selectedUser = await getStorageData('selectedUser')
        if (splitType) {
            this.setState({
                splitType: splitType,
                selectedMedia: selectedMedia,
                selectedUser: selectedUser,
            }, async () => {
                localStorage.setItem('maxPercentage', this.state.selectedUser.equity);
            });
        } else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
}
