// Customizable Area Start
import React, { useState, useEffect,useContext, lazy, Suspense } from "react";
import {  Tabs, Tab,Hidden } from "@material-ui/core";

import { SearchOutlined } from "@ant-design/icons";
import {  Row,  Input ,Menu, Layout} from "antd";
import { Filter_black, triangle } from "../../../dashboard/src/assets";
import SearchHooksWeb from "./SearchHooks/SearchHooksWeb";
import SearchVersesWeb from "./SearchVerses/SearchVersesWeb";
import SearchVideosWeb from "./SearchVideos/SearchVideosWeb";
import SearchSongsWeb from "./SearchSongs/SearchSongsWeb";
import SearchPlaylistWeb from "./SearchPlaylist/SearchPlaylistWeb";
import { Content } from "antd/lib/layout/layout";
import {  useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./mainSearch.css";
import SearchUsers from "./SearchUsers/SearchUsers.web";
import FilterPopup from "./FilterPopup.web";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
import MainSearchTab from "../../../../components/src/CustomSpilt/meta-tag/MainSearchTab.web";
import AsideLeft from "../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { removeStorageData } from "../../../../framework/src/Utilities";

//@ts-ignore
const SearchBeatsWeb = lazy(() => import('./SearchBeats/SearchBeatsWeb'));
toast.configure();
const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const menu = () => {
  return (
    <div>
      <Menu>
        <Menu.Item key="0">Dashboard</Menu.Item>
        <Menu.Item key="1">Profile</Menu.Item>

        <Menu.Item key="3">Share Profile</Menu.Item>
        <Menu.Item key="4">My Plan & Payment</Menu.Item>
        <Menu.Item key="5">History</Menu.Item>
        <Menu.Item key="6">Sign out</Menu.Item>
      </Menu>
    </div>
  );
};
const MainSearchWeb = () => {
  const [active, setActive] = useState("0");
  const [curSearch, setCurSearch] = useState<any>("");
  const [searchData, setSearchData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
//@ts-ignore
  const { isPlaylist} = useContext(AudioContextApi);

  //my added code start

  const [checkType, setCheckType] = useState<any>("");
  const [selectedPlayList, setSelectedPlayList] = useState<any>([]);
  const [songIndex, setSongIndex] = useState<any>(0);
  const [open, setOpen] = useState<any>(false);
  const [alertPopup, setAlertPopup] = useState<any>(false);

  const [dialogText, setDialogText] = useState<any>("");
  const [loginOpen, setLoginOpen] = useState<any>(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedCreativeType, setSelectedCreativeType] = useState<any>('')
  const [selectedPlayCount, setSelectedPlayCount] = useState<any>('')
  const [selectedRanking, setSelectedRanking] = useState<any>('')
  const [selectedState, setSelectedState] = useState<any>('')
  const [selectedCity, setSelectedCity] = useState<any>('')



console.log(dialogText,loginOpen,alertPopup,open,selectedPlayList,selectedPlayList,selectedPlayList,checkType,"need to remove these unused state")
  const history = useHistory()

  const authToken: any = localStorage.getItem("token")

  if (!authToken) {
    history.push('/home')
  }

  const handlePopUp = () => {
    setOpenPopUp(false);
  }



  const setDialogTextFromChild = (value: any) => {
    setDialogText(value);
  }

  const setOpenFromChild = (value: any) => {
    setOpen(value);
  }


  //my added code end


  const token: any = localStorage.getItem("token");

  useEffect(() => {
    removeStorageData('filterValues')
  }, []);
  

  const callback = (event: React.ChangeEvent<{}>, key: any) => {

  if((searchData.length != 0) || (searchData.length == 0 && curSearch != '') || (!loading)){
    if(key == 0){
      history.push(`/home/search/beats`)
      setActive(`${key}`);
    }
    else if(key == 1){
      history.push(`/home/search/hooks`)
      setActive(`${key}`);
    }
    else if(key == 2){
      history.push(`/home/search/verses`)
      setActive(`${key}`);
    }
    else if(key == 3){
      history.push(`/home/search/songs`)
      setActive(`${key}`);
    }
    else if(key == 4){
      history.push(`/home/search/videos`)
      setActive(`${key}`);
    }
    else if(key == 5){
      history.push(`/home/search/playlist`)
      setActive(`${key}`);
    }
    else if(key == 6){
      history.push(`/home/search/users`)
      setActive(`${key}`);
    }
    else{
      history.push(`/home/search/beats`)
      setActive(`${key}`);
    }
   
  }
  if(key != active){
    setSearchData([])
  }
  };

  const screen = () => {
    console.log(active, 'active number')
    if (active === "" || active == "0") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <SearchBeatsWeb beatsList={searchData}  name="Beats" songIndex={songIndex}     
          setDialogText={setDialogTextFromChild} setOpen={setOpenFromChild} loading={loading} />
        </Suspense>

      );
    }
    if (active == "1") {
      return <SearchHooksWeb hooksList={searchData}  name="Hooks" songIndex={songIndex} setDialogText={setDialogTextFromChild} setOpen={setOpenFromChild} loading={loading} />;
    }
    if (active == "2") {
      return <SearchVersesWeb versesList={searchData}    name="Verses" songIndex={songIndex} setDialogText={setDialogTextFromChild} setOpen={setOpenFromChild} loading={loading} />;
    }
    if (active == "3") {
      return <SearchSongsWeb allSongsList={searchData}   name="Songs" songIndex={songIndex}  setDialogText={setDialogTextFromChild} setOpen={setOpenFromChild} loading={loading} />;
    }
    if (active == "4") {
      return <SearchVideosWeb videosList={searchData} loading={loading} />;
    }
    if (active == "5") {
      return <SearchPlaylistWeb allPlayLists={searchData} name="playlist" songIndex={songIndex}  loading={loading} />;
    }
    if (active == "6") {
      return <SearchUsers loading={loading} allUsers={searchData}/>;
    }
  };

  

  const haldleSearchInput = (e: any) => {
    if(token){
        setCurSearch(e);
  } else {
    history.push("/Welcome")
  }
  };
  let getCurrSearchType = 'Beat'  
 // console.log(active, 'active keyyyyyyyyyyyyyyyyyy')

  const getSlug = window.location.href.split('/')

  if(getSlug.length != 0 && getSlug[5] != ''){
    if (getSlug[5] == 'beats') {
      getCurrSearchType = 'Beat'
    }
    else if (getSlug[5] == 'hooks') {
      getCurrSearchType = 'Hook'
  
    } else if (getSlug[5] == 'verses') {
      getCurrSearchType = 'Verse'
  
    }
    else if (getSlug[5] == 'songs') {
      getCurrSearchType = 'Song'
  
    }
    else if (getSlug[5] == 'videos') {
      getCurrSearchType = 'Video'
  
    }
    else if (getSlug[5] == 'playlist') {
      getCurrSearchType = 'playlist'
  
    }
    else if (getSlug[5] == 'users') {
      getCurrSearchType = 'user'
  
    }
  
  }



  
  const getCurrSearch = async () => {
    setLoading(true); ///bx_block_audiomusic/home_search?type=${getCurrSearchType}&keyword=${curSearch}
    let selectedPlay = selectedPlayCount == '' ? '': `filter[play_count][]=${selectedPlayCount}&`
    let selectedCreative = selectedCreativeType == '' ? '' : `filter[creative_type][]=${selectedCreativeType}&`
    let selectedRank = selectedRanking == '' ? '' : `filter[ranking][]=${selectedRanking}&`
    let selectedCityFilter = selectedCity == '' ? '' : `filter[city][]=${selectedCity}&`
    let selectedStateFilter = selectedState == '' ? '' : `filter[state][]=${selectedState}&`
    
    await axios.get(`${baseUrl}/search_bar_filter?${selectedPlay}${selectedCreative}${selectedRank}${selectedCityFilter}${selectedStateFilter}search_content=${curSearch}&upload_type=${getCurrSearchType}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: token
      }
    })
      .then((response: any) => {
        console.log(response?.data, "bbbbbbbbbbbbbbbbb");
        if(curSearch != ''){
          setSearchData(response?.data?.data)
        }else{
          setSearchData(response?.data?.data.slice(0,40))
        }
        setLoading(false);
      }).catch((err: any) => {
        console.log(err.response, "eeeeeeeeeeeeeeeee");
        setLoading(false);

      });
  }

  const setCreativeTypesValues = (values :any) =>{
    setSelectedCreativeType(values);
    console.log(values, 'creative type selected values')
  }

  const setPlaycountValues =(values :any) =>{
    if(values !== ''){
      setSelectedPlayCount(1000);
    }
  }

  const setRankingValues = (values: any) =>{
    if(values == "Top 1K"){
      setSelectedRanking(1000)
    }else if(values == "Top 500"){
      setSelectedRanking(500)
    }else if(values == "Top 200"){
      setSelectedRanking(200)
    } else if(values == "Top 100"){
      setSelectedRanking(100)
    }else{
      setSelectedRanking(0)
    }
  }

  const setStateValues = (state: any) =>{
    setSelectedState(state)
  }

  const setCityValues = (city: any) =>{
    setSelectedCity(city)
  }

  useEffect(() => {
    const slug = window.location.href.split('/')

    if(slug.length != 0 && slug[5] != ''){
      
      if(slug[5] == 'hooks'){
        setActive('1')
      }
      else if(slug[5] == 'beats'){
        setActive('0')
      }
      else if(slug[5] == 'verses'){
        setActive('2')
      }
      else if(slug[5] == 'songs'){
        setActive('3')
      }
      else if(slug[5] == 'videos'){
        setActive('4')
      }
      else if(slug[5] == 'playlist'){
        setActive('5')
      }
      else if(slug[5] == 'users'){
        setActive('6')
      }
      else{
        setActive('0')
      }
    }
    getCurrSearch();
  }, [curSearch, active,selectedCreativeType,selectedPlayCount,selectedRanking])


  const returnTabName = () =>{
    if(active === "0"){
      return "Beats"
    }
    else if(active === "1"){
      return "Hooks"
    }
    else if(active === "2"){
      return "Verses"
    }
    else if(active === "3"){
      return "Songs"
    }
    else if(active === "4"){
      return "Videos"
    }
    else if(active === "5"){
      return "Playlists"
    }
    else{
      return "Users"
    }
  }

  const debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

  const debounceLog = debounce((text: any) => haldleSearchInput(text), 1000);

  const subscriptionPlan = localStorage.getItem("subscription_plans")
  const userType = localStorage.getItem("user_type")


  const changeSearchInput = (e: any) => {
    if (token) {
      const getSlug = window.location.href.split('/')
      if (userType == "listener" && subscriptionPlan == "NXZ" && ((getSlug[5] == 'beats') || (getSlug[5] == 'hooks') || (getSlug[5] == 'verses'))) {
        setDialogText("3")
        setOpen(true)
      }
      else {
        if (e.target.value.trim() != '' || e.target.value == '') {
          debounceLog(e.target.value)
        }
      }
    } else {
     
      history.push('/Welcome')
    }
  }

  return (
    <>

      <div
        className="example"
        style={
          {
            overflow: "hidden",
          }
        }
      >
        <Layout style={{ backgroundColor: "black", height: "100vh"  }}>
          {/* .......................SideBar...................................... */}
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content
            style={{
              margin: "0px",
              // height: "100%",
              backgroundColor: "black",

              display: "flex",
              flexDirection: "column",
            }}
          >
            <HomeHeader haldleSearchInput={haldleSearchInput} />
            {/* for content page */}
            {/* <ChartsNew /> */}
            <div
              //mostly-customized-scrollbar
              className={isPlaylist?"chart-body mainBody-search-audio-height":"chart-body"}
              style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
            >


              <Row
                // className="left_part"
                className="search_bar"
              >

                <Input
                  test-id= "test_search_term_id_handle_search_input"
                  onChange={(e: any) => changeSearchInput(e)}
                  //onClick={() => history.push("/home/MainSearchWeb")}
                  className=""
                  prefix={
                      //@ts-ignore
                    <SearchOutlined

                      className="text_white1"

                    />
                  }
                  style={{ width: "100%" }}
                  // placeholder={<p className="text_white"></p>}
                  placeholder="Search Anything you like"
                />
              </Row>


              <Row className="font_28 text_white mb_1">
                Search Results for {returnTabName()}: {curSearch}
              </Row>
              <div className="chats-header " style={{}}>

               <MainSearchTab active={active} callback={callback} />

                <div test-id="search_filter_popup_test_id" className="track_type" style={{ position: "relative" }} onClick={() => { setOpenPopUp(true) }}>
                  <div
                    className="ant-dropdown-link filter_design example"
                  //onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={Filter_black}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                    <p className="menuDrop" style={{ width: '75px' }}>Track Type</p>
                    <img
                      src={triangle}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {screen()}
            </div>
          </Content>
        </Layout>
        {
          openPopUp && 
          <FilterPopup
          openPopUp={openPopUp}
          handlePopUp={handlePopUp}
          setPlaycountValues={setPlaycountValues}
          setCreativeTypesValues={setCreativeTypesValues}
          setRankingValues={setRankingValues}
          setStateValues={setStateValues}
          setCityValues={setCityValues}
        >
          {/* handlePopUp = {handlePopUp} */}
        </FilterPopup>
        }
        
      </div>


    </>



  );
};
export default MainSearchWeb;
// Customizable Area End