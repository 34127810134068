// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";
import millify from "millify";
import { openNotification } from "components/src/Notification.web";
import { HISTORY } from "components/src/common";
import React from "react";

export interface Props {
  navigation: any;
  id: string;
  getStatusDetails: any;
  data: any;
  topPlayMusic: any;
  showSingleplaylistdeatils: string;
  setShowSingleplaylistdeatils: (data: any) => void;
}

type FormatNumberInput = number | string | null | undefined;

interface S {
  active: string;
  index: string;
  playList: any;
  top40playList: any;
  masterplayList: any;
  mastertop40playList: any;
  loading: boolean;
  selectedTab: any;
  searchText: any;
  selectedIndex: any;
  detailsLoading: boolean;

  isVideoPlaylist: boolean;
  playlistDetailsData: any;
  status: string;
  follow_playlist: boolean;
  apiToken: any;
  user_id: any;
  openMessageLink: any;
  CopiedForLink: any;
  openRepostPopup: boolean;
  openPopUp: boolean;
  textFieldValue: any;
  isDeleteModelV: boolean;
  current_playlist_id: any;
  playlist_details_data: any;
  imageUrl: string | null;
  followUserStatus:boolean;
  is_invite_user:boolean;
  accessList: any[];
  accessAvatarList: any[];
  accessListLoader: boolean;
  showAccessListView: boolean;
}

interface SS {
  id: any;
}

type PlaylistDetailsData = {
  attributes: {
    user_follow: boolean;
  };
};

export default class CtrlplaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  apigetAllPlayList: any;
  apigetPlaylistDetails: any;
  apiDeletePlaylist: any;
  apiRepostData: any;
  videosPlayRef: any;
  getFollowPublicPlaylistApi: string = "";
  apigetFollowUserCallId:string=""
  apiAccessListPlaylist:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.videosPlayRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      active: "0",
      index: "audio",
      playList: [],
      top40playList: [],
      masterplayList: [],
      mastertop40playList: [],
      loading: false,
      selectedTab: "audio",
      searchText: "",
      selectedIndex: "",
      detailsLoading: false,
      isVideoPlaylist: true,
      playlistDetailsData: { attributes: {} },
      status: "",
      follow_playlist: false,
      playlist_details_data: {},
      apiToken: localStorage.getItem("token"),
      user_id: localStorage.getItem("loggedIn_userId"),
      openMessageLink: false,
      CopiedForLink: "",
      openRepostPopup: false,
      openPopUp: false,
      textFieldValue: "",
      isDeleteModelV: false,
      current_playlist_id: null,
      imageUrl: null,
      followUserStatus:false,
      is_invite_user:false,
      accessList: [],
      accessAvatarList: [],
      accessListLoader: false,
      showAccessListView: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllPlaylist("audio");
  }

  followUserStatus = (userId: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };
    let getuserStatus;
    if(this.state.followUserStatus){
      getuserStatus='/account_block/unfollow_user'
    }else{
      getuserStatus='/account_block/follow_user'

    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apigetFollowUserCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${getuserStatus}?user_id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}



  selectedsong = (index: any, playlist_id: any, playlistLength: any) => {
    if (playlistLength <= 0) {
      openNotification("No Tracks Available", "Error");
    } else {
      this.getPlaylistDetails(playlist_id);
      
      this.setState({
        selectedIndex: index,
        current_playlist_id: playlist_id,
      });
      this.showDetailsPlaylistOpen("details");
    }
  };

  showAccessListData = () => {
    this.setState({
      showAccessListView: !this.state.showAccessListView,
    });
  };
  accessListTrack = (playlist_id:number) => {
    this.setState({ accessListLoader: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAccessListPlaylist = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/access_list_of_track?track_id=${playlist_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showDetailsPlaylistOpen = (str: any) => {
    this.props.setShowSingleplaylistdeatils(str);
  };

  showEditPlaylistOpen = () => {
    this.props.setShowSingleplaylistdeatils("edit");
  };

  showEditPlaylistVideoOpen = () => {
    this.props.setShowSingleplaylistdeatils("editvideo");
  };

  showDeletePlaylistOpen = () => {
    this.setState({ isDeleteModelV: true });
  };
  selectedTrack = (data: any) => {
    this.accessListTrack(data.id)
    this.setState({ 
      playlist_details_data: data ,
      followUserStatus:data?.attributes?.user_follow,
    
    });

  };

  followPlaylist = async (id: any) => {
    this.setState({ loading: true });
    let playlist_status;
    if (this.state.follow_playlist) {
      playlist_status = "unfollow_playlist";
    } else {
      playlist_status = "follow_playlist";
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowPublicPlaylistApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_playlist/${playlist_status}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  formatNumber = (value: FormatNumberInput) => {
    if (typeof value !== "number") {
      return millify(Number(value)); // or handle it in a way that makes sense for your application
    }

    return millify(value);
  };

  getPlusMinus = (data: number | string | null | undefined): string => {
    if (data) {
      if (data.toString().startsWith("-")) {
        return `-`;
      } else if (data.toString().startsWith("0.00")) {
        return "";
      } else {
        return `+`;
      }
    } else {
      return "";
    }
  };

  getPctTxt = (data: number | string | null | undefined): string => {
    if (data) {
      const dataStr = data.toString();
      if (dataStr.startsWith("-")) {
        return dataStr.replace("-", "");
      }
      return dataStr;
    } else {
      return "";
    }
  };

  linkopenPopup = () => {
    this.setState({ CopiedForLink: this.onScanQR() });
    this.setState({ openMessageLink: true });
  };

  onScanQR = () => {
    let scanTo: string | undefined; 
    let playlist = this.state.playlist_details_data;
    if (this.state.playlist_details_data.attributes['track_file'] !== null ){
    scanTo = window.location.origin.concat(
      `/home/expand/song/songId=${playlist.id}`
    );
    }
    else{
      scanTo = window.location.origin.concat(
        `/home/charts/videos?id=${playlist.id}`
      );
    }
    return scanTo;
  };

  handleRepost = () => {
    if (this.state.apiToken) {
      this.setState({
        openRepostPopup: !this.state.openRepostPopup,
        openPopUp: true,
      });
    }
  };

  handleRepostClose = () => {
    this.setState({
      openRepostPopup: false,
      openPopUp: false,
    });
  };

  respostSubmit = () => {
    this.handleChartTrackRepost(this.state.textFieldValue);
    this.handleRepostClose();
  };

  handleCaptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ textFieldValue: e.target.value });
  };

  handleChartTrackRepost = async (caption: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRepostData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/repost?id=${this.state.playlist_details_data.id}&caption=${caption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openPlaylistScreen = async (id: any) => {
    if (this.state.apiToken) {
      if (
        this.state.apiToken &&
        id?.attributes?.artist_id == this.state.user_id
      ) {
        HISTORY.push(`/home/self-view/${id.id}`);
        localStorage.setItem("playlist_type", "Private");
      } else if (
        this.state.apiToken &&
        id?.attributes?.artist_id !== this.state.user_id
      ) {
        HISTORY.push(`/home/playlist/public-view/${id.id}`);

        localStorage.setItem("playlist_type", "Public");
      }
    } else {
      openNotification("Please Login To Continue", "error");
    }
  };

  onDeletePlaylist = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletePlaylist = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_playlist/play_lists/` +
        this.state.current_playlist_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPlaylistDetails = (playlist_id: number) => {
    this.setState({ detailsLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetPlaylistDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_playlist/user_play_lists/audio_playlist?play_list_id=${playlist_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changetabs = (id: string) => {
    this.setState({ index: id });
  };

  searchFilterFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    if (text) {
      const top40data = this.state.mastertop40playList.filter(function(
        item: any
      ) {
        const itemData = item.attributes.name
          ? item.attributes.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ top40playList: top40data });

      const splitData = this.state.masterplayList.filter(function(item: any) {
        const itemData = item.attributes.name
          ? item.attributes.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({
        playList: splitData,
        searchText: text,
      });
    } else {
      this.setState({
        top40playList: this.state.mastertop40playList,
        playList: this.state.masterplayList,
        searchText: text,
      });
    }
  };

  getAllPlaylist = (type: string|undefined) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetAllPlayList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/get_all_playlists?type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStatusDetails = (item: any) => {
    let typeColor = "#919191";
    let status = "draft";

    if (item.attributes?.status.toLowerCase() === "public_playlist") {
      typeColor = "red";
      status = "Public";
    } else if (item.attributes?.status.toLowerCase() === "private_playlist") {
      typeColor = "#3959f9";
      status = "Private";
    }

    return { typeColor, status };
  };

  handlePlayList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.top_fourty_playlists?.data) {
        this.setState({
          top40playList: responseJson.top_fourty_playlists.data,
          mastertop40playList: responseJson.top_fourty_playlists.data,
          loading: false,
        });
      } else {
        this.setState({
          top40playList: [],
          mastertop40playList: [],
          loading: false,
        });
      }
      if (responseJson?.all_play_lists?.data) {
        this.setState({
          playList: responseJson.all_play_lists.data,
          masterplayList: responseJson.all_play_lists.data,
          loading: false,
        });
      } else {
        this.setState({
          playList: [],
          masterplayList: [],
          loading: false,
        });
      }
    } else {
      this.setState({
        top40playList: [],
        playList: [],
        masterplayList: [],
        mastertop40playList: [],
        loading: false,
      });
    }
  };

  handlePlayListDetails = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.play_list?.data) {
        this.setState({
          isVideoPlaylist:
            responseJson.play_list.data?.attributes?.playlist_type != "Audio",
          playlistDetailsData: responseJson.play_list.data,
          follow_playlist:responseJson.play_list.data?.attributes?.playlist_follow||true,
          followUserStatus:responseJson.play_list.data?.attributes?.user_follow,
          status:
            responseJson.play_list.data?.attributes?.status.toLowerCase() ===
            "public_playlist"
              ? "public"
              : "private",
        is_invite_user:responseJson.play_list.data?.attributes?.is_invite,

          playlist_details_data:
            responseJson.play_list.data?.attributes?.playlist_items.data[0],
          detailsLoading: false,
        });
        this.accessListTrack(responseJson.play_list.data?.attributes?.playlist_items.data[0].id)
      } else {
        this.setState({
          detailsLoading: false,
        });
      }
    } else {
      this.setState({
        detailsLoading: false,
      });
    }
  };

  handleRepostData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Successfully Reposted", "Success");
    } else {
      openNotification("Error Occurred", "Error");
    }
  };

  handleDeletedPlaylist = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ isDeleteModelV: false });
      openNotification("Successfully Playlist deleted", "Success");
      this.showDetailsPlaylistOpen("playlist");
      this.getAllPlaylist(this.state.selectedTab);
    } else {
      this.setState({ isDeleteModelV: false });

      openNotification("Error Occurred", "Error");
    }
  };
  handleFollowPublicPlaylist = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
      this.setState({ follow_playlist: !this.state.follow_playlist });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  getfollowUserApiSuccessCall = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if(responseJson.status==200){
      openNotification(responseJson.message, "Success")
      this.setState({ followUserStatus: !this.state.followUserStatus })
      }else{
      openNotification(responseJson.message, "Error")

      }
    }
  }

  handleAccessUser = (responseJson: any) => {
    if (
      responseJson !== undefined &&
      !responseJson.errors &&
      !responseJson.message
    ) {
      this.setState({
        accessList: responseJson.data.data,
        accessAvatarList: responseJson.data.data.map(
          (item: any) => item.attributes.avatar
        ),
        accessListLoader: false,
      });
    // this.
    
    } else {
      this.setState({
        accessList: [],
        accessAvatarList: [],
        accessListLoader: false,
        showAccessListView:false
      });

    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apigetAllPlayList) {
      this.handlePlayList(responseJson);
    } else if (apiRequestCallId == this.apigetPlaylistDetails) {
      this.handlePlayListDetails(responseJson);
    } else if (apiRequestCallId == this.apiRepostData) {
      this.handleRepostData(responseJson);
    } else if (apiRequestCallId == this.apiDeletePlaylist) {
      this.handleDeletedPlaylist(responseJson);
    } else if (apiRequestCallId == this.getFollowPublicPlaylistApi) {
      this.handleFollowPublicPlaylist(responseJson);
    }else if (apiRequestCallId === this.apigetFollowUserCallId) {
      this.getfollowUserApiSuccessCall(responseJson)
    } else if (apiRequestCallId == this.apiAccessListPlaylist) {
      this.handleAccessUser(responseJson);
    }
    // Customizable Area End
  }
  handleMouseMoveVal = () => {
    this.videosPlayRef.current.style.visibility = "visible";
  };

  hanldeMouseLeaveval = () => {};

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.setState({ imageUrl: e.target.result });
    };
    reader.readAsDataURL(file);
  };
}
// Customizable Area End
