// Customizable Area Start
import React from "react";
import "../Creatives/ComposeBlock.web.css";
import { play1, repost1, following1, follower1, messageProfile } from "blocks/dashboard/src/assets";
import { Typography, Grid, Button, ButtonGroup } from "@material-ui/core";
import { Spin,Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ProfileFollowing } from "../Creatives/ProfileFollowing.web";
import UserProfileBasicController from "../UserProfileBasicController";
import SendMessage from "components/src/CustomChart/PopUps/SendMessageDialog.web";
export const configJSON = require("../../../../framework/src/config.js");

export default class ProfileDataUserDiv extends UserProfileBasicController {
   handleFollowState=()=>{
    let currentFollowCheck = ""
    if (this.state.unfollowState) {
        currentFollowCheck = "Unfollow"
    } else {
        currentFollowCheck = "follow"
    }
    return currentFollowCheck

}

 handlePopupVisible=()=>{
  if (this.state.unfollowState) {
      this.setState({ visible: true })
  } else {
      this.setState({ loadingFollow: true })
      this.FolloweUser(this.state.creativeById?.id)
  }
}
  render() {
    const { creativeById, followerData, loading, openPopupFollowers, openPopupFollowing, allFollowerData, followingData } = this.state;
   {/* @ts-ignore */}
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'antiquewhite' }} spin translate/>;
    const token: any = localStorage.getItem("token")
    const user_id: any = localStorage.getItem("loggedIn_userId");
    const id_check = user_id


    if (Object.keys(creativeById).length > 0) {
      return (

        <Grid container xs={8} sm={4} className={creativeById.id !== id_check ? "icons-group listener-count-grid listener-public" : "icons-group listener-count-grid listener-private"}>
           <Grid container item xs={12} className="justify-center" style={{maxHeight:"200px"}} >
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={play1} className="listener-icons-img" style={{ cursor: "not-allowed" }} onClick={()=>this.redirectStreamhistory("playhistory")} data-test-id="listener-history"/>


                <Typography className="icons-text"><Typography className="icons-text" style={{ fontFamily: "karla" }}>

                  {creativeById?.attributes?.play_count||0}
                </Typography> </Typography>


              </Grid>
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={repost1} className="listener-icons-img" style={{ cursor: "not-allowed" }} onClick={()=>this.redirectStreamhistory("repost")} />

                <Typography className="icons-text"><Typography className="icons-text">

                  {creativeById?.attributes?.repost_count}

                </Typography></Typography>
              </Grid>

            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={following1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {
                  this.setState({ openPopupFollowing: true })
                  this.getFollowerData()
                }

                } />
                <ProfileFollowing open={openPopupFollowing}
                 handleClose={() => this.setState({ openPopupFollowing: false })}
                  data={allFollowerData} 
                  loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="followers" />

                <Typography className="icons-text"><Typography className="icons-text">
                  {creativeById?.attributes?.followers_count}
                </Typography> </Typography>

              </Grid>

              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={follower1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {
                  this.setState({ openPopupFollowers: true })
                  this.getFollowingData()
                }} />
                <ProfileFollowing open={openPopupFollowers} handleClose={() => this.setState({ openPopupFollowers: false })} data={followingData} loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="following" />
                {Object.keys(creativeById).length ?
                  <Typography className="icons-text">
                    <Typography className="icons-text" style={{ fontFamily: "karla" }}>
                      {creativeById?.attributes?.following_count || "0"}
                    </Typography></Typography>
                  :
                  <Typography className="icons-text">
                    <Typography className="icons-text" style={{ fontFamily: "karla" }}>
                      {followerData?.attributes?.following_count || "0"}
                    </Typography></Typography>
                }
              </Grid>

            </Grid>
          </Grid>
          {token && creativeById.id !== id_check && (
             <Grid container item xs={12} sm={8} lg={6} className="buttongroup-grid listener-follow-handle">
              <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" >
                <Button className="one-button"  onClick={() => this.setState({openScanQr: true})}>
                  <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} />
                </Button>
                <Button className="listener-follow-btn" id="followBtn" disabled={this.state.loadingFollow}
                  style={{ fontFamily: "karla" }}
                  onClick={this.handlePopupVisible}> {this.state.loadingFollow ?
                    <Spin indicator={antIcon} /> : this.handleFollowState()
                  }</Button>
              </ButtonGroup>
            </Grid>
          )}
           <div>
           <SendMessage openScanQr={this.state.openScanQr} setOpenScanQr={() =>
            this.setState({openScanQr:false})
            } handleRedirectToMessage={this.handleRedirectToMessage} avatar={creativeById?.attributes?.avatar}
              user_name={creativeById?.attributes?.user_name}
              CurrentId={creativeById?.id}
              />
                <Modal
                  title=""
                  visible={this.state.visible}
                  footer={null}
                  onCancel={() => {
                    this.setState({ visible: false })
                  }}
                  style={{ textAlign: 'center' }}
                >
                  <h4 style={{ color: 'white' }}>Do you really want to unfollow?</h4>
                  <Button style={{ color: 'white', background: 'red' }} onClick={() => {
                    this.setState({ visible: false })
                  }}>No</Button> &nbsp;&nbsp;
                  <Button style={{ color: 'white', background: 'green' }} onClick={() => {
                    if (creativeById?.id) {
                      this.setState({ loadingFollow: true, visible: false })
                      this.UnFolloweUser(creativeById?.id)
                    }
                  }}>Yes</Button>
                </Modal>
              </div>
        </Grid>
      );
    } else {
      return null
    }
  }
}
// Customizable Area End