// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    users:any[];
    selectedUser:any;
    splitType:string;
    media:any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AudioVideoSplitController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sampleAudio:any = {id:1, name:'House Party', writer:'YFRS', play_count:'1.7M'}

    getTrackidsplitId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            users: [],
            selectedUser: {},
            splitType: '',
            media: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    onUserChange = async (user: any) => {
        console.log("user", user)
        this.setState({
            selectedUser: user
        })

        await setStorageData("trackid", user.id)

    }

    onNextClick = async () => {
        if (Object.keys(this.state.selectedUser).length === 0) {
            openNotification("Select one to split", "Error")
        } else {
            await setStorageData('selectedUser', this.state.selectedUser);
            HISTORY.push({
                pathname: `/deal/select-split-percentage`,
                state: {}
            });
        }


    }
    tracksplitresponse=(responseJson:any)=>{
        if (responseJson != null) {
            if (!responseJson.error) {
                this.apiResponse(responseJson)
               
            }
        }
    }
  apiResponse=(responseJson:any)=>{
    if (responseJson.hasOwnProperty("message") || responseJson.hasOwnProperty("errors")) {
        console.log("error", responseJson)
        this.setState({ loading: false })
        openNotification(responseJson?.message || responseJson?.errors[0], 'Error')
    }
    else {
        this.setState({ users: responseJson?.data })
        this.setState({ loading: false })
        setStorageData("splitid", responseJson?.data[0]?.id || "")
    }
  }
    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const splitType = await getStorageData('splitType')
        const media = await getStorageData('media')
        const storedValue: string | null = localStorage.getItem("selectedAudio");
        const videoid: string | null = localStorage.getItem("selectedSingleVideo")
        if (splitType == "audio" && storedValue != null) {
            const numberid: any = JSON.parse(storedValue);
            this.gettracksplitid(numberid)
        }else if (splitType == "video" && videoid != null){
            const numberid: any = JSON.parse(videoid);
            this.gettracksplitid(numberid)
        }
        
        this.setState({ loading: true })
        if (splitType) {
            this.setState({
                splitType: splitType,
                media: media,

            })
        } else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId === this.getTrackidsplitId) {
                this.tracksplitresponse(responseJson)

            }


        }
        // Customizable Area End
    }

    // Customizable Area Start

    gettracksplitid = (numberid:number) => {
        
            // You might want to replace 'any' with the appropriate type
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                'token': localStorage.getItem('token')
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getTrackidsplitId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `/bx_block_audiomusic/track_split_users?id=${numberid}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
       





    }
 

    // Customizable Area End
}
