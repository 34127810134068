// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";

export interface Props {
  webStyle:any;
  searchFilterFunction:any;
  playList:any;
  getStatusDetails:any;
  selectedsong:any;
  selectedTab:any;
  getAllPlaylist:any;
  setSelectedTabAudio:any;
  setSelectedTabVideo:any;
  top40playList:any;
  openPlaylistScreen:any
}

interface S {
 
}

interface SS {
  id: any;
}

export default class AudioVideoPlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }
 
}
 
// Customizable Area End