// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout,  Tabs } from 'antd';
import {
    Hidden,
} from "@material-ui/core";
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import AudioVideo from './AudioVideo.web'
import AudioVideoController from './AudioVideoController';


const { TabPane } = Tabs;
export default class StreamRepost extends AudioVideoController {
    render() {
      return (
        <>
          <Layout  className='streamRepostWrapper'>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    // height: "100%",
                    backgroundColor: "black",
                    display: "flex", flexDirection: "column",
                    // justifyContent: "space-between"
                }}
            >
                <HomeHeader location="RepostHistory" redirect={this.redirectToProfile}/>
               
               <AudioVideo navigation={undefined} id={''}/>
            </Content>
        </Layout>
        </>
       
      )
    }
  }

// Customizable Area End