// Customizable Area Start

import { Typography, Tabs } from 'antd';
import {
    Box,
    Button,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme
} from "@material-ui/core";
import React from 'react'

import { bluecheck, userlog0, videoimage } from '../assets';
import AudioVideoController from './AudioVideoController';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LeftSideSingletrackbox = styled(Box)({
    // marginTop: '6px',
    height: "81px",
    padding: "0px 10px 0px 5px",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    gap: "10px",
    color: "white",
    "&:hover": {
    },
});
const RightSideSingletrackbox = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    gap:"10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",
    padding: "0px 10px 0px 5px",
    color: "white",
    "&:hover": {
    },
});
const Audiohistorycontainer = styled(Box)({
    display: 'flex',
    height: "90vh",
    overflowY: "scroll",
    width: "100%",
    justifyContent: "space-between",

    "@media(max-width: 1000px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
        flexDirection: "column"
    },
});

const Horizontaltag = styled(Box)({
    height: "100%",
    width: "1px",
    border: "1px solid #212121",

    "@media(max-width: 1100px)": {
        display: "none"
    },
});
const LeftsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const RightsideAudiohistorycontainer = styled(Box)({
    width: "48%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",

    "@media(max-width: 1000px)": {
        width: "100%",
        padding: "0px 10px 0px 10px"
    },
});

const Trackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",

    fontFamily: "Inter",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});

const { TabPane } = Tabs;
export default class AudioVideo extends AudioVideoController {
     rightSidehistory=()=>{
        return (
            <RightsideAudiohistorycontainer style={{ paddingTop: (this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ? "100px" : "0px" }}>
                    <Box>
                        <Box style={{ width: "100%" }}>
                            <Box style={webStyle.Rightsidetabsbutton}>
                                <Box style={webStyle.tabbox}>
                                    <Button

                                        style={this.state.rightsideSelectedtab == "Audio" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}

                                        onClick={() => this.handleAudiovideotab("Audio")}
                                        data-test-id="audiotab"
                                    >
                                        Audio
                                    </Button>
                                </Box>
                                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        style={this.state.rightsideSelectedtab == "Video" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                        onClick={() => this.handleAudiovideotab("Video")}
                                        data-test-id="Myvideotab"
                                    >
                                        Video
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus")? {paddingTop:"0px"} : {paddingTop:"22px"} }>
                        <Typography style={webStyle.trackname}>
                            {" "}
                            <span style={webStyle.bluetext}>
                            {(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ? "Jiwang:" : "You:"}
                            </span>{" "}
                            Streamed
                        </Typography>
                        {Array.from({ length: 8 }).map((_, index) => (
                            <RightSideSingletrackbox
                            key={index}
                            >
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                        {this.state.rightsideSelectedtab == "Audio" ? <img
                                            src={videoimage}
                                            style={webStyle.leftimage}

                                        /> : <img
                                            src={videoimage}
                                            style={webStyle.leftvideoimage}

                                        />}
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box>
                                            <Trackname className="tracks_songs_text">
                                                yfrs
                                                <span className="Jason-Derulo">

                                                    <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                </span>
                                            </Trackname>
                                            <Box>
                                                <span style={webStyle.rightsidesplittext}>
                                                    House Party

                                                </span>
                                            </Box>

                                        </Box>
                                        <Box>
                                            <Box style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}>
                                                <span style={webStyle.rightsidesplittextwhite}>
                                                    487
                                                </span>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <BorderLinearProgress variant="determinate" value={64} />
                                    </Box>
                                </Box>
                             

                            </RightSideSingletrackbox>
                        ))}
                    </Box>
                </RightsideAudiohistorycontainer>
        )
    }
    render() {
        const { isPlaylist } = this.context;
      
        return (
            <>

                <Audiohistorycontainer style={isPlaylist ? {height:"75vh"}:{height:"90vh"}} >
                    <LeftsideAudiohistorycontainer >
                        <div className="chats-header" >
                            <Tabs
                                className='Streamrepost_custom_tab'
                                defaultActiveKey="1"
                                tabPosition="top"
                                style={{
                                    //   height: 220,
                                }}
                                items={this.state.tabs.map((item: any, index: any) => {
                                    const id = String(index);
                                    return {
                                        label: item.month,
                                        key: id,
                                        disabled: index === 12,
                                    };
                                })}

                            />
                        </div>
                        {(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") && <Box style={webStyle.RightSidebuttonBox}>
                            <Box style={webStyle.Rightsidetabsbutton}>
                                <Box style={webStyle.tabbox}>
                                    <Button
                                        style={this.state.leftsideSelectedtab == "TopListeners" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                        onClick={() => this.handleTab("TopListeners")}
                                        data-test-id="TopListenerstab"
                                    >
                                        Top Listeners
                                    </Button>
                                </Box>
                                <Box style={{ width: "50%", display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        onClick={() => this.handleTab("My History")}
                                        data-test-id="Myhistorytab"
                                        style={this.state.leftsideSelectedtab == "My History" ? webStyle.tabactiveaudiovideobutton : webStyle.tabaudiovideobutton}
                                    >
                                        My History
                                    </Button>
                                </Box>
                            </Box>
                        </Box>}

                        {/* </Box> */}
                        <Box style={{ paddingBottom: "5px" }}>
                            <Typography style={webStyle.trackname}>
                                {" "}
                                <span style={webStyle.bluetext}>
                                    TOP 1K:
                                </span>{" "}
                                {(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus")? "Listeners" : "Creatives"} 
                            </Typography>
                            {Array.from({ length: 8 }).map((_, index) => (
                                <LeftSideSingletrackbox
                                    key={index}
                                    style={this.state.index == index ? { background: "#141414" } : {}}
                                    onClick={() => this.selectedTrack(_, index)}
                                >

                                    <Box>
                                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                            <span>{index + 1}</span>
                                            <img
                                                src={userlog0}
                                                style={webStyle.rightsideleftimage}

                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box>
                                                <Trackname className="tracks_songs_text">
                                                    playronie
                                                    <span className="Jason-Derulo">

                                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                    </span>
                                                </Trackname>
                                                <Box>
                                                    <span style={webStyle.rightsidesplittext}>
                                                        Streams

                                                    </span>
                                                </Box>

                                            </Box>
                                            <Box>
                                                <Box style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                }}>
                                                    <span style={webStyle.rightsidesplittextwhite}>
                                                        487
                                                    </span>
                                                </Box>
                                            </Box>

                                        </Box>
                                        <Box>
                                            <BorderLinearProgress variant="determinate" value={64} />
                                        </Box>
                                    </Box>
                                </LeftSideSingletrackbox>
                            ))}
                        </Box>
                    </LeftsideAudiohistorycontainer>
                    <Box>
                        <Horizontaltag
                        ></Horizontaltag>
                    </Box>
                    {this.rightSidehistory()}
                    <Box>
                    </Box>
                </Audiohistorycontainer>

            </>

        )
    }
}

const webStyle = {
    RightSidebuttonBox: {
        width: "100%"
    },
    Rightsidetabsbutton: {
        borderRadius: "30px",
        background: "#070707",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",

    },
    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    leftimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        border: "1px solid #141414"
    },
    rightsideleftimage: {
        borderRadius: "60px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "2px solid #FFFFFF"
    },
    leftvideoimage: {
        borderRadius: "10px",
        width: "120px",
        minWidth: "120px",
        height: "75px",
        border: "1px solid #141414"
    },

    singleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },
    rightsidesingleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },

    leftsideAlltracksbox: {
        width: "85%",

    },


    trackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },

    bluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
    },

    splittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    rightsidesplittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    splittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    rightsidesplittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    Audiovideoleftsideownerstats: {
        gap: "3px",
        display: "flex",
        flexDirection: "column" as "column",
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },

    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
};
AudioVideo.contextType = AudioContextApi;
// Customizable Area End