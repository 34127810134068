// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import AccessListController from "./AccessListController";
import { backArrow } from "../assets";
import "./CtrlStyle.css";
import { Row } from "antd";
import { blueCheck, CancelPng,  defaultProfile, nxzloader } from "blocks/dashboard/src/assets";

export default class AccessPlaylist extends AccessListController {
  render() {
    const { accessList, showAccessListData, accessListLoader,trackDetails ,handleArtClick} = this.props;

    return (
      <>
        {accessListLoader ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <>
            <Grid container className="access-grid-topDiv">
              <Grid item xs={3} className="access-grid-child1">
                <img
                  src={backArrow}
                  style={{
                    width: "26px",
                    height: "26px",
                    cursor: "pointer",
                  }}
                  onClick={showAccessListData}
                  data-test-id="back-arrow"
                />
                {/* <Typography className="access-headline">ACCESS</Typography> */}
              </Grid>
              <Grid
                item
                xs={9}
                className="contentEnd"
                onClick={this.removeAllAccessUser}
                data-test-id="contentEnd"
              >
                <Button
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "36px",
                    background: "#FF0400",
                    cursor:"pointer",
                    color:"white"
                  }}
                >
                  Remove All
                </Button>
              </Grid>
            </Grid>
            <Box style={webStyle.rightSidetrackbox1}>
              <Box style={webStyle.singleArtinfo}>
                <img
                  src={trackDetails.art_work || defaultProfile}
                  className="iconImg accessArtWork"
                  onClick={() => handleArtClick()}
                  data-test-id="track_details-img"
               />

                <Box style={webStyle.leftsideownerstats1}>
                  <span style={webStyle.trackname}>{trackDetails.title}</span>
                  <span style={webStyle.splittext1}>
                    {trackDetails.artist_name}
                  </span>
                </Box>
              </Box>
              </Box>
            {accessList.map((data: any) => {
              return (
                <Grid container alignItems="center">
                  <Grid item xs={2} lg={3}>
                    <img
                      src={data.attributes.avatar||defaultProfile}
                      className="access-icons"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    lg={7}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography className="access-names">
                      {data.attributes.user_name}
                      {data?.attributes?.is_verified && (
                        <img src={blueCheck} className="verified-snapshot" />
                      )}
                    </Typography>
                    <Typography className="access-userType">
                      {data.attributes.user_type}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className="contentEnd"
                    onClick={()=>this.removeAccessUser([data.id])}
                    data-test-id="cancel-img"
                 >
                    <img src={CancelPng} className="access-cancel" />
                  </Grid>
                </Grid>
              );
            })}
          </>

          //Merge Engine End DefaultContainer
        )}
      </>
    );
  }
}
const webStyle = {
    
    releasebox: {
      display: "flex",
      gap: "15px",
    },
    statustext: {
      color: "#8F92A1",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "-0.32px",
      fontFamily: "Inter",
    },
    statusbutton: {
      borderRadius: "30px",
      border: "1px solid #FF0501",
      background: "#000",
      color: "#FF0501",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "-0.32px",
      fontFamily: "Inter",
      textTransform: "capitalize" as "capitalize",
    },
  
    singleArtinfo: {
      display: "flex",
      gap: "10%",
      justifyContent: "center",
      alignItems: "center",
      cursor:"pointer"
    },
  
    trackname: {
      fontSize: "16px",
      fontWeight: 700,
      fontStretch: "normal",
      fontStyle: "Inter",
      lineHeight: "normal",
      letterSpacing: "normal",
      whiteSpace: "nowrap" as "nowrap",
      color: " #EFEFEF",
    },
    buttonbox: {
      borderRadius: "20px",
      backgroundColor: "#212121",
      display: "flex",
      justifyContent: "space-between",
      padding: "6px",
    },
    splittext1: {
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#8F92A1",
      fontFamily: "Inter",
    },
  
    rightSidetrackbox1: {
      // marginTop: "12px",
      padding: "10px 30px 10px 16px",
      borderRadius: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignitems: "center",
      color: "white",
      border: "3px solid #212121",
      background: "#070707",
      width: "100%",
    },
  
    leftsideownerstats1: {
      gap: "10px",
      display: "flex",
      flexDirection: "column" as "column",
    },
  };
// Customizable Area End