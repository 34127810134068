import React from "react";
import CustomBar from "./CustomChart.web";
import "../Snapshot/snapshot.css";
import ChartScreenController from "./ChartScreenController";

const extraHeight = 10;
const maxValue = 100 + extraHeight;

export default class CtrlChart extends ChartScreenController {
  render() {
    const { isChartLoading, chartData } = this.props;
    const { positiveData, negativeData } = this.state;
    return (
      <div style={{ flex: 1 }}>
        {isChartLoading || chartData == "" ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <ActivityIndicator size="large" color="red" /> */}
            <p style={{ color: "white" }}>changes</p>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                height: "150px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              {positiveData.map((item: any, index: any) => (
                <CustomBar
                  key={index}
                  count={item}
                  value={this.calculateBarValue(item + extraHeight)}
                  isActive={false}
                  onBarPress={() => {
                    // setActiveBar(key)
                  }}
                  barStyle={{
                    borderTopLeftRadius: "14px",
                    borderTopRightRadius: "14px",
                  }}
                />
              ))}
            </div>
            <div style={{ height: "2px", backgroundColor: "#000" }} />
            <div
              style={{
                display: "flex",
                height: "150px",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {negativeData.map((item: any, index: any) => (
                <CustomBar
                  key={index}
                  isInvert
                  count={item}
                  value={this.calculateBarValue(item + extraHeight)}
                  isActive={false}
                  onBarPress={() => {
                    // setActiveBar(key)
                  }}
                  barStyle={{
                    borderBottomLeftRadius: "14px",
                    borderBottomRightRadius: "14px",
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}
