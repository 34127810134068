// Customizable Area Start
import React,{useEffect, useState} from 'react'
import { Grid, Button, TextField, InputAdornment, MenuItem } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import axios from 'axios';
import { Form as FormAntd } from "antd";
import './index.web.css'
import { TrackDetailsCover, Calendar7, IconRight, WatchesFrontView, DrumSet, ElectronicMusic} from '../assets';
import { makeStyles } from '@material-ui/core/styles';
import { Formik,FormikErrors,Form} from "formik";
import { openNotification } from "../../../../components/src/Notification.web";
import ReleaseDatePopup from './ReleaseDatePopup.web';
import ReleaseTimePopup from './ReleaseTimePopup.web';
import { getStorageData, setStorageData } from '../../../../framework/src/Utilities';


const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderRadius: '10px',
        border: '2px solid'
        
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiInputLabel-outlined": {
        color: "white",
        fontSize: '16px',
        fontWeight: 'bold'
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white"
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      }
    },
    releaseDate: {
      "& .MuiFormLabel-root.Mui-focused":{
        color: "#8f8f95",
        fontFamily:'Karla'
       
      },
      "& .MuiInputBase-root": {
        color: 'white',
        paddingBottom: '10px'
        
      },
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid #212121',
      },

    }
  });

function TrackDetails({setActiveStep, setBackStep}: any) {

  const [uploadType, setUploadType] = useState<any>([]);
 

  const tag =["CHH","Christian","Classical","Country","EDM","Jazz","Latin","Pop","Rap","Rnb","Rock","Trap","Soul"]

  const [publicActive, setPublicActive] = useState("");
  const [openDatePopUp, setOpenDatePopUp] = useState(false);
  const [openTimePopUp, setOpenTimePopUp] = useState(false);
  const [fullDate, setFullDate] = useState('');
  const [showStartAdornment, setShowStartAdornment] = useState(true);
  const [showStartKeyAdornment, setShowStartKeyAdornment] = useState(true);
  const [keysList, setKeysList] = useState<any>([]);
  const [genre, setGenre] = useState<any>([]);
  const token: any = localStorage.getItem("token");
  const [loading,setloading]=useState(false)
  const [trackData,setTrackData]=useState<any>({})
  const [editdate,seteditdate]=useState<any>("")
  const [edittime,seteditime]=useState<any>("")
  const [edittags,setedittags]=useState<any>([])
  const uploadUrlType = window.location.href.split('/')[4]
    const classes = useStyles();
    
    const [form] = FormAntd.useForm();
    const onFinish = async(values: any) =>{
      console.log(values, 'values of form')  
     const payload =  {
      data : {
      title: values.title,
      release_date:values.releaseDate,
      release_time: values.releaseTime,
      // description: "deatil track",
      is_published: values.is_published,
      upload_type_id: values.uploadType,
      play_list_id: values.play_list_id,
      // "audio_tag_id": null,
      // "primary_genre_id": 1,
      genres: values.tags,
      status:publicActive,
      bpm_id: values.bpm,
      key_id: values.key
      }}
      
     
     await setStorageData("editPayload",payload)
        await setStorageData('uploaded_track_details', values)
        setActiveStep();
      
      
   
    
    }

    const getUploadType = async (token: any) => {
      await axios
        .get(`${baseUrl}/bx_block_categories/upload_types?`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            
            token: token,
          },
        })
        .then((response: any) => {
          if(uploadUrlType == 'audio'){
            const list = response.data.data.filter((type: any) => type.attributes.name != 'Video')
            setUploadType(list);
          }else{
            setUploadType(response.data.data);
          }
        })
        .catch((err: any) => {
  
          openNotification(err.response.data.errors[0].token, "Error");
        }).finally(() => {
  
        })
    };

    const getKeyList = async (token: any) => {
      await axios
        .get(`${baseUrl}/bx_block_music_types/keys`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            
            token: token,
          },
        })
        .then((response: any) => {
          setKeysList(response.data.data);
        })
        .catch((err: any) => {
  
          openNotification(err.response.data.errors[0].token, "Error");
        }).finally(() => {
  
        })
    };

    const getGenreType = async (token: any) => {
      await axios
        .get(`${baseUrl}/bx_block_music_types/genres`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            
            token: token,
          },
        })
        .then((response: any) => {
          console.log(response.data.data);
          setGenre(response.data.data)
        })
        .catch((err: any) => {
          
          openNotification(err.response.data.errors[0].token, "Error");
        }).finally(() => {
  
        })
    };

    


  const isFullDate = (date: any) => {
    if (date) {
      console.log("date",date)
      setOpenDatePopUp(false);
      let formattedReleaseDate = "";
      const [month, day, year] = date.split('/'); 
      formattedReleaseDate = `${day}/${month}/${year}`;
      console.log(formattedReleaseDate,"date")
      setFullDate(formattedReleaseDate);
      seteditdate(formattedReleaseDate)

      // console.log(date, "got full date?")
    }
  }

    const handleDatePopUp = () => {
      console.log('on close click');
      setOpenDatePopUp(false);
    }
  
    const handleDate = () => {
      setOpenDatePopUp(true);
    }

    const handleTime = () => {
      if (fullDate) {
        setOpenTimePopUp(true);
      } else {
        openNotification("Please select date first", "Error")
      }
    }

    const handleTimePopUp = () => {
      console.log('on close click');
      setOpenTimePopUp(false);
    }
  
  const isFullTime = (time: any) => {
    if (time) {
      setOpenTimePopUp(false);


      console.log(time, "got full time?")
    }
  }


    const setPrivate = (e: any, setFieldValue: any) => {
       setFieldValue('uploadStatus', e.target.value)
    
       setPublicActive(e.target.value);
    }

    const setPublic = (e: any, setFieldValue: any) => {
      setFieldValue('uploadStatus', e.target.value)
      setPublicActive(e.target.value);
    
   }

    function arrayRemove(arr: any, value: any) {

      return arr.filter(function (ele: any) {
        return ele != value;
      });
    }

    const saveDraftDetails = async(values: any) =>{
      const UploadFileType = window.location.href.split('/')[4]

      const file = await getStorageData('draft_file')
      const artWork = await getStorageData('uploaded_cover_image')


      const fmData: any = new FormData();

        if(UploadFileType == "audio"){
          fmData.append('data[track_file]', file);
        }
        else if(UploadFileType == "video"){
          fmData.append('data[video_file]', file);
        }

        if(artWork){
          fmData.append('data[art_work]', artWork)
        }

        
        fmData.append('upload_stage', 1);

        if(values.title != ""){
          fmData.append('data[title]', values.title)
        }
        if(values.releaseDate != ""){
          const dates = values.releaseDate.split('/')
          const releaseDate = `${dates[2]}-${dates[0]}-${dates[1]}`
          fmData.append('data[release_date]', releaseDate)
        }
        if(values.releaseTime != ""){
          fmData.append('data[release_time]', values.releaseTime)
        }
        if(values.uploadType != ''){
          fmData.append('data[upload_type_id]', values.uploadType)
        }
        if(values.uploadStatus != ""){
          fmData.append('data[status]', values.uploadStatus)
        }
        
        if(values.key != ""){
          fmData.append('data[key_id]', values.key)

        }
        if(values.tags.length > 0){
          fmData.append('data[genres]', values.tags)
        }
        
          const headers = {
            "Content-Type": "application/json",
            'token': token
          };

          await axios.post(`${ baseUrl + '/bx_block_audiomusic/save_as_draft'}`,fmData,{ headers }

        )
        .then((response: any) => {
          
          }).catch((err: any) => {

          });
    }


    useEffect(() =>{
     
    
      fetchData();
      getUploadType(token);
      getGenreType(token);
      getKeyList(token);
    }, [])
    const fetchData = async () => {
      setloading(true)
      const hello = await getStorageData("edittrackdetails");
      console.log('checking', hello);
      if(hello){
        updatingdetails(hello)
        setloading(false)
      }else{
        setloading(false)
      }
     
    };
    const updatingdetails=(hello:any)=>{
      
      let releaseDateString =hello?.attributes?.release_date; 
      let formattedReleaseDate = "";
      if (releaseDateString) {
       const releaseDate = new Date(releaseDateString);
       const day = releaseDate.getUTCDate().toString().padStart(2, '0');
       const month = (releaseDate.getUTCMonth() + 1).toString().padStart(2, '0');
       const year = releaseDate.getUTCFullYear();
       formattedReleaseDate = `${day}/${month}/${year}`;
       seteditdate(formattedReleaseDate);
     }
     
      formatedTime(hello?.attributes?.release_time)
      setedittags(hello.attributes?.genre)
      setTrackData(hello ? hello : {})
      setPublicActive(hello?.attributes?.status)
    }
    // useEffect(() => {
      
    // }, [])
    
    const formatedTime =(time:any)=>{
     let formatedtime = ""
     if(time){
        let date = new Date(time);
        let hoursNum: number = date.getUTCHours() % 12 || 12;
        let hours: string = hoursNum < 10 ? `0${hoursNum}` : `${hoursNum}`;
        let minutes: string = `0${date.getUTCMinutes()}`.slice(-2);
        let seconds: string = `0${date.getUTCSeconds()}`.slice(-2);
        seteditime(`${hours}:${minutes}:${seconds}`)
  }
    }
    console.log(edittags,"edittags")
   
  return (


<>

<Grid container className='UploadMainScroll'>
    {!loading ?  <div className='main-div'>
      {/* <Grid item xs={12} md={12} lg={7} > */}
        <Formik
          
          // onSubmit={(values: any) => console.log(values, "values getting")}
          //initialValues = {INITIAL_VALUES}
          initialValues={{          

                  uploadType: trackData?.attributes?.upload_type?.id ?? "",
                  tags: edittags?.length > 0 ? edittags.map((item:any) => item.genre_id) : [],
                  play_list_id: trackData?.id ?? "",
                  title: trackData?.attributes?.title ?? "",
                  genre: [],
                  is_published: trackData?.attributes?.is_published,
                  uploadStatus: trackData?.attributes?.status ?? "",
                  releaseDate: editdate ?? "",
                  releaseTime: edittime ?? "",
                  bpm: trackData?.attributes?.bpm?.id ?? "",
                  key: trackData?.attributes?.key?.id ?? ""
            
          }}

          validate={values => {
            console.log(values, 'validation values')
            let errors: FormikErrors<any> = {};
            if (!values.title) {
              errors.title = 'Title is Required';
            } else if(!values.uploadType){
              errors.uploadType = 'Upload Type is Required';
            }
            else if(values.tags.length == 0){
              errors.tags = 'Genre is Required';
            }
            else if ((!/^\d+$/.test(values.bpm)) && values.bpm) {
              errors.bpm = "please enter only numbers"
            }
            else if(!values.releaseDate){
              errors.releaseDate = 'Release Date is Required';
            }
            else if(!values.releaseTime){
              errors.releaseTime = 'Release Time is Required';
            }
            else if(!values.uploadStatus){
              errors.uploadStatus = 'Upload Status is Required';
            }

            return errors;
          }}

          onSubmit={(values: any) => onFinish(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
            setFieldValue,
            // initialValues,
            getFieldProps,
          }) => (
            
              
              <Form onSubmit={handleSubmit} style={{width: '100%'}}>
                <div style={{display: 'flex'}} className = "track-details-div">
                  <Grid container item xs={12} md={12} lg = {7} style={{padding: '50px'}}>
                       <Grid className='track-details-margin'>   
                            <Grid item xs={12} style={{justifyContent: 'center',alignItems: 'center', width: '100%', marginBottom: '20px'}}>
                              <TextField
                                name="title"
                                data-testid="title_change_in_trackdetails_form"
                                type='title'
                                fullWidth
                                value={values.title}
                                focused
                                className={classes && classes.root}                                
                                variant="outlined"
                                label="Title"
                                onChange={(e: any) => {
                                 setFieldValue('title', e.target.value) }}   
                                />
                                <div style={{color: 'red'}}>{errors?.title && errors.title}</div>
                            </Grid>

                            <Grid item container xs={12} style={{marginBottom: '20px'}}>

                                <Grid  xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '20px'}}>
                                  Upload Type
                                </Grid>

                                <Grid  xs={12} className="drop_btn"  >
                                  {
                                    uploadType?.length !== 0 &&
                                    uploadType?.map((uploadTypes: any, index: any) => {
                                      return (
                                        <button name="upload_type" type="button" className='button-upload-types'
                                          style={{ background: getFieldProps('uploadType').value == uploadTypes.attributes.id ? "#3959F9" : 'rgb(48, 48, 48)' }}
                                          onClick={() => {
                                            
                                            setFieldValue('uploadType', uploadTypes?.attributes?.id)
                                            
                                          }}
                                          key={index} id={uploadTypes?.attributes?.id}>{uploadTypes?.attributes?.name}</button>
                                      )
                                    })}

                                  <div style={{color: 'red'}}>{errors?.uploadType && errors.uploadType}</div>
                                </Grid>
                            
                            </Grid>    

                            <Grid item container xs={12} style={{marginBottom: '20px'}}>

                                <Grid item xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '20px'}}>
                                  Select Genre
                                </Grid>

                                <Grid item xs={12} className="drop_btn">
                              {
                                genre?.length !== 0 &&
                                genre.map((tags: any, index: any) => {
                                  return (
                                    <button test-id="tags_name_trackdetails" type="button" className='button-upload-types'
                                      style={{ background: getFieldProps('tags').value.includes(tags?.attributes?.id) ? "#3959F9" : 'rgb(48, 48, 48)' }}
                                      onClick={() => {
                                        let data: any = form.getFieldValue('tags')
                                        if (data == undefined) {
                                          const updatetags=edittags.map((item:any)=>item.genre_id)
                                          data = [...updatetags,tags?.attributes?.id]
                                        
                                        }
                                        // else if (data.length == 0) {
                                        //   data[0] = tags?.attributes?.id
                                        // }
                                        else {
                                          if (data.includes(tags?.attributes?.id)) {
                                            const filterdArray: any = arrayRemove(data, tags?.attributes?.id)
                                            data = filterdArray
                                          } else {
                                            data.push(tags?.attributes?.id)
                                          }
                                        }
                                        setFieldValue('tags', data)
                                        form.setFieldsValue({ tags: data })
                                      }}
                                      key={index} id={tags?.attributes?.id}>{tags?.attributes?.name}</button>
                                  )
                                })}

                                <div style={{color: 'red'}}>{errors?.tags && errors.tags}</div>

                                </Grid>
                            
                            </Grid>    

                            <Grid container item xs={12} sm={12} spacing={2} style={{marginTop: '15px'}}>
                            <Grid item className='Mui-focused' xs={12} sm={6} style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                              <Grid item xs={12} sm={6} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', display: 'flex'}}>
                                   BPM <div style={{color: 'gray', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', marginLeft: '8px'}}>{`(optional)`}</div>
                              </Grid>
                              
                              <TextField
                                name="handleBpm_trackdetails_test"
                                test-id="handlebpm-test-id"
                                type='BPM'
                                fullWidth
                                className={classes && classes.releaseDate}
                                focused
                                placeholder=""
                                variant="standard"
                                value={values.bpm}
                                label="What tempo is your song?"
                                onChange={(e: any) => {setFieldValue('bpm', e.target.value)}}
                                InputProps={{
                                  startAdornment: showStartAdornment && !values.bpm ?  (
                                    
                                    <InputAdornment position="end" onClick={() => setShowStartAdornment(false)}>
                                        <img style={{height: '19.8px', width: '19px', marginLeft: '-10px'}} src={DrumSet}/>
                                        <div style={{fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', color: 'gray', marginLeft: '8px'}}>Example: 120</div>
                                    </InputAdornment>
                                    
                                ): null,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <Grid style={{fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', color: 'White'}}>BPM</Grid>
                                      </InputAdornment>
                                  ),
                                  onFocus: () => {
                                    setShowStartAdornment(false);
                                  },
                                  onBlur: () => {
                                    setShowStartAdornment(!values.bpm);
                                  }
                                  }}  
                                />
                                
                                <div style={{color: 'red'}}>{errors?.bpm && errors.bpm}</div>
                            </Grid>

                            <Grid item xs={12} sm={6}  className='Mui-focused' style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                              <Grid item xs={12} sm={6} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', display: 'flex'}}>
                                Key <Grid style={{color: 'gray', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', marginLeft: '8px'}}>(optional)</Grid>
                              </Grid>
                              <TextField
                                name="handleKey_trackdetails_test"
                                type='key'
                                fullWidth
                                focused
                                select
                                className={classes && classes.releaseDate}
                                value={values.key}
                                variant="standard"
                                label="What key is your song in?"
                                SelectProps={{
                                  MenuProps: {
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                      style: {
                                        maxHeight: '200px', 
                                      },
                                    },
                                  },
                                }}
                                onChange ={(event) => {
                                  setFieldValue('key', event.target.value);
                                }}
                                InputProps={{
                                  startAdornment: showStartKeyAdornment && !values.key ?  (
                                    
                                    <InputAdornment position="end" onClick={() => setShowStartKeyAdornment(false)}>
                                        <img style={{height: '19.8px', width: '19px', marginLeft: '-10px'}} src={ElectronicMusic}/>
                                        <div style={{fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', color: 'gray', marginLeft: '8px'}}>Select Key</div>
                                    </InputAdornment>
                                    
                                ): null 
                                ,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <img style={{height: '18px'}} src={IconRight}/>
                                      </InputAdornment>
                                  ),
                                  onFocus: () => {
                                    setShowStartKeyAdornment(false);
                                  },
                                  onBlur: () => {
                                    setShowStartKeyAdornment(!values.bpm);
                                  }
                                  }}  
                                 >
                                  {keysList.length > 0 &&
                                  keysList.map((option: any) => (
                                    <MenuItem key={option.id} value={option.attributes.id} style={{color: 'white'}}>
                                      {option?.attributes?.name}
                                    </MenuItem>
                                  ))}
                                 </TextField>

                            </Grid>
                         </Grid>
                          
                          <Grid container item xs={12} sm={12} spacing={2} style={{marginTop: '15px'}}>
                            <Grid item className='Mui-focused' xs={12} sm={6} style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                              <ReleaseDatePopup openDatePopUpFromChild={openDatePopUp} handleDatePopUpFromChild={handleDatePopUp} getFullDateFromChild={isFullDate} setFieldValue={setFieldValue} values={values}>

                              </ReleaseDatePopup>
                              <Grid item xs={12} sm={6} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px'}}>
                                  Release Date
                              </Grid>
                              <div className='release-date-margin'>
                              <TextField
                                name="handleDate_trackdetails_test"
                                type='releaseDate'
                                fullWidth
                                className={classes && classes.releaseDate}
                                focused
                                defaultValue="Select Date"
                                placeholder=""
                                variant="standard"
                                value={values.releaseDate}
                                label="When do you want to release?"
                                onClick={handleDate}
                                InputProps={{
                                  startAdornment: values.releaseDate ? null : (
                                    
                                    <InputAdornment position="end">
                                        <img style={{height: '19.8px', width: '19px', marginLeft: '-10px'}} src={Calendar7}/>
                                        <div style={{fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', color: 'gray', marginLeft: '8px'}}>MM/DD/YYYY</div>
                                    </InputAdornment>
                                    
                                ),
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <img style={{height: '18px'}} src={IconRight}/>
                                      </InputAdornment>
                                  ),
                                  }}
                                // onChange={(e: any) => {
                                //   setFieldValue('releaseDate', e.target.value) }}   
                                />
                                </div>
                                <div style={{color: 'red'}}>{errors?.releaseDate && errors.releaseDate}</div>
                            </Grid>

                            <Grid item xs={12} sm={6}  className='Mui-focused' style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                             
                            <ReleaseTimePopup openTimePopUpFromChild={openTimePopUp} handleTimePopUpFromChild={handleTimePopUp} getFullTimeFromChild={isFullTime} setFieldValue={setFieldValue} values={values} date={fullDate}>

                            </ReleaseTimePopup>
                             <Grid item xs={12} sm={6} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px'}}>
                                  Release Time
                              </Grid>
                              <TextField
                                name="handleTime_trackdetails_test"
                                type='title'
                                fullWidth
                                focused
                                className={classes && classes.releaseDate}
                                //defaultValue="Select Time"
                                value={values.releaseTime}
                                placeholder=''
                                variant="standard"
                                label="What time do you want to release?"
                                onClick={handleTime}
                                InputProps={{
                                  startAdornment: values.releaseTime ? null : (
                                    
                                    <InputAdornment position="end">
                                        <img style={{height: '19.8px', width: '19px', marginLeft: '-10px'}} src={WatchesFrontView}/>
                                        <div style={{fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', color: 'gray', marginLeft: '8px'}}>HH:MM:SS</div>
                                    </InputAdornment>
                                    
                                ),
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <img style={{height: '18px'}} src={IconRight}/>
                                      </InputAdornment>
                                  ),
                                  }}
                                // onChange={(e: any) => {
                                //   setFieldValue('releaseTime', e.target.value) }}   
                                 />
                              <div style={{color: 'red'}}>{errors?.releaseTime && errors.releaseTime}</div>

                            </Grid>
                         </Grid>

                         <Grid item container xs={12} style={{marginTop: '20px'}}>
                            <Grid item xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', marginBottom: '10px'}}>
                                  Upload Status
                            </Grid>

                            {/* style={{backgroundColor: !amActive ? 'rgb(67, 32, 124)' : 'transparent'}} */}

                            <Grid item xs={12} sm={4} direction="row" justifyContent='center' alignItems='center'>
                              <div className='public-private-buttons'>
                                <button test-id="setting_private_trackdetails" type='button' className = "button1_private" style={{backgroundColor: publicActive == "private_track" ? 'blue' : 'transparent'}} value='private_track'  onClick={(e: any) => {
                                 setPrivate(e,setFieldValue)}}>Private</button>
                                <button test-id="setting_public_trackdetails" type='button' className= "button2_public" style={{backgroundColor: publicActive == "public_track" ? 'blue' : 'transparent'}} value='public_track'  onClick={(e: any) => {
                                  setPublic(e,setFieldValue)}}>Public</button>
                              </div>
                              <div style={{color: 'red'}}>{errors?.uploadStatus && errors.uploadStatus}</div>
                            </Grid>

                         </Grid>

                         </Grid>
                  </Grid>

                  <Grid container item xs={12} md={12} lg = {5} style={{justifyContent: 'center'}} className="hide-side-image-in-responsive">
                    <Grid item className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                      <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={TrackDetailsCover} />
                      <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                       <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Drag And Drop</div>
                      <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Or Click To Upload </p>


                      <Button type='submit' test-id = "track_details_form_submit_test_id" className=' buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} >Next</Button>
                      <Button type='button' test-id = "track_details_form_save_test_id_0"  className=' buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick = {() =>saveDraftDetails(values)}>Save</Button>
                      <Button type='button'  variant="text" className=' buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                      </div>
                      </div>
                    </Grid>
                    
                  </Grid>

                  <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                    <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                      <Button  type='submit' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}>Next</Button>
                      <Button  type='button' test-id = "track_details_form_save_test_id_1" style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={() => saveDraftDetails(values)} >Save</Button>
                      <Button  type='button' variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                    </div>

                  </Grid>

               </div>
             </Form>
            )}

        </Formik> 
      {/* </Grid> */}

     

    </div>:<div>loading</div>}      
   


 </Grid>
 </>

   )
}

export default TrackDetails
// Customizable Area End

