// Customizable Area Start
import React from "react"

import { createBrowserHistory } from "history";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { HISTORY } from "components/src/common";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../config");

const history = createBrowserHistory()
export interface Props {
  props?: any;
  navigation?: any;


}
interface S {
    token: any;
    creativeById: any;
    gridSizes: any;
    beatList: any;
    labeltabName: any;
    get_All_Activity_data: any;
    get_notification: any;
    labelValue: any;
    filterValue: any;
    musicButton: any;
    playlistVideo: any;
    getCreativeTabsLoader: any;
    apiToken: any;
    activityList: any;
    getTop100: any
    labelGraphandStatsData: any;
    labelTop100Creatives: any;
    labelUserDetails: any;
    TopPlaylists: any;
    openSendMessage: any;
    loadingFollow: any;
    visible: any;
    unfollowState: any;
    labelId: any;
    noActivity: any;
    idNotification: any;
    typeNotification: any;
    userNotification: any;
    apiFilterValue: any;
    isLabelUser: any;
}
interface SS {
  id: any;
}

export default class LabelController extends BlockComponent<Props, S, SS> {
    getactivityListId: string = "";
    getlabelGarphandstatsId: string = "";
    getTop100CreativesId: string = "";
    getlabelUserDetailsApiId: string = "";
    getTopPlaylistApiId: string = "";
    getFollowApiCallId: string = "";
    getUnFollowApiCallId: string = "";
    AllNotificationApiId: string = "";
    readNotificationApiCallId: string = ""
    prevHref: string = ""

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
            token: '',
            creativeById: {
                attributes: {
                    cover_image: "https://minio.b66173.dev.eastus.az.svc.builder.cafe/sbucket/chddrz5mutcsx14wdcm9kjxwsvad",
                    creative_type: [{name: ''}],
                    user_name: "soranga",
                    city: "ahmedabad",
                    country: "Alland Islands",
                    creative_type_name: "Producer"
                },
                id: "2184",
                type: "account"
            },
            gridSizes: {
              xsSize: 12,
              mdSize: 8,
              lgSize: 9,
              smSize: 6,
              xlSize: 4,
              xxlSize: 3,
            },
            beatList: [],
            labeltabName: [
              {
                tabName: "Creatives",
                value: 0
              },
              {
                tabName: "Activity",
                value: 1
              },
              {
                tabName: "Playlists",
                value: 2
              },
      
            ],
            get_All_Activity_data: [],
            get_notification: {},
            labelValue: 0,
            filterValue: "Filter",
            musicButton: false,
            playlistVideo: [],
            getCreativeTabsLoader: false,
            apiToken: localStorage.getItem("token"),
            activityList: [],
            getTop100: [],
            labelGraphandStatsData: '',
            labelTop100Creatives: [],
            labelUserDetails: '',
            TopPlaylists: [],
            openSendMessage: false,
            loadingFollow: false,
            visible: false,
            unfollowState: false,
            labelId: '',
            noActivity: false,
            idNotification: '',
            typeNotification: '',
            userNotification: '',
            apiFilterValue: '',
            isLabelUser: false,
      }
        this.checkLabelCurrentTab = this.checkLabelCurrentTab.bind(this)

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      super.componentDidMount();
      const labelID = window.location.href.split('=')[1]
      this.setState({labelId: labelID})
      this.getAllActivityData(labelID);
      this.getAllLabelNotifications(labelID);
      this.getLabelGraphandStatsData(labelID);
      this.getTop100CreativesData(labelID);
      this.getLabelUserData(labelID);
      this.getTopPlaylistsData(labelID);
      this.setIsLabel();
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
      if (this.state.musicButton !== prevState.musicButton) {
        this.getTopPlaylistsData(this.state.labelId);
      }

      const locationUrl =  window.location.href.split('=')[1]
      if(locationUrl != this.state.labelId){
        window.location.reload(); 
      }

     if(this.state.apiFilterValue != prevState.apiFilterValue){
       if(window.location.href.includes('playlists')){
         this.getTopPlaylistsData(this.state.labelId);
       }
       else if(window.location.href.includes('activity')){
        this.getAllActivityData(this.state.labelId);
       }
     }
      
    }
    redirectlabelStreamhistory = (history:string)=>{
      if (history == "labelhistory"){
        HISTORY.push('/lablehistory')
      }
      else if (history == "labelrepost"){
        HISTORY.push('/lablerepost')
      }
    
     }

    checkLabelCurrentTab = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({ labelValue: newValue, filterValue: 'Filter', apiFilterValue: '' }); 
      if (newValue === 0) {
        HISTORY.push(`/labels/creatives?label_id=${localStorage.getItem('user_id')}`)
      } else if (newValue === 1) {
        HISTORY.push(`/labels/activity?label_id=${localStorage.getItem('user_id')}`)
      } else if (newValue === 2) {
        HISTORY.push(`/labels/playlists?label_id=${localStorage.getItem('user_id')}`)
      }
    }

    setIsLabel = () =>{
      const user: any = localStorage.getItem("user_type");
      if(user == "label"){
        this.setState({isLabelUser: true })
      }
    }

    handleFilter = (value: any) => {
      if (value == "today") {
        this.setState({filterValue: "Today"})
      } else if (value == "this_week") {
        this.setState({filterValue: "This week"})
      } else if (value == "this_month") {
        this.setState({filterValue: "This month"})
      } else {
        this.setState({filterValue: "This Year"})
      }
      this.setState({apiFilterValue: value})
    }

    redirectDealUser = () => {
      const userType: any = localStorage.getItem("user_type");
      if(userType == "label"){
        localStorage.setItem('splitType', 'user')
        localStorage.setItem('dealUserId', this.state.labelId)
        localStorage.setItem('userDealDetails', JSON.stringify(this.state.labelUserDetails?.attributes))
        HISTORY.push({
          pathname: `/deal`
        })
      }
    }

    notificationURL = () =>{
      if(this.state.userNotification === "creative"){
        return `/creatives/beats?creative_id=${this.state.idNotification}`
      }else{
        return `/labels/creatives?labels_id=${this.state.idNotification}`
      }
 
    }

    redirectFromNotification = async () => {
      localStorage.removeItem("selectedTab");
     const id: any = localStorage.getItem("user_id");
      localStorage.setItem("temp_id", id);
      localStorage.removeItem("user_id");
      localStorage.setItem("user_id", this.state.idNotification);
      localStorage.setItem("creatives_screen", "true");
     const user = this.state.userNotification;
      localStorage.setItem("current_type", user);
       HISTORY.push({
         pathname:
         this.state.userNotification === "listener"
             ? `/listeners/activity?listener_id=${this.state.idNotification}`
             : this.notificationURL() ,
       });
      
     
   };

    filterActivityData = (data: any) => {
      if (data.attributes?.reposts?.data) {
        this.setState({ get_All_Activity_data: [...data.attributes?.reposts?.data] })
      }
  
      if (data.attributes?.followings?.data) {
        this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...data.attributes?.followings?.data] })
      }
      if (data.attributes?.uploads?.data) {
        let tempArray = Object.assign([], data.attributes?.uploads?.data);
        const key = 'id';
        const arrayUniqueByKey = [...new Map(tempArray.map((item: any) =>
          [item[key], item])).values()];
        this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...arrayUniqueByKey] })
      }
      //this.setState({ tabLoading: false })
  
  
      this.state.get_All_Activity_data.sort((a: any, b: any) => new Date(b?.attributes?.created_at).getTime() - new Date(a?.attributes?.created_at).getTime());
      this.setState({ getCreativeTabsLoader: false })
  
    }

    handleFollowState=()=>{
      let currentFollowCheck = ""
      if (this.state.unfollowState) {
          currentFollowCheck = "Unfollow"
      } else {
          currentFollowCheck = "follow"
      }
      return currentFollowCheck

  }

  handlePopupVisible=()=>{
      if (this.state.unfollowState) {
          this.setState({visible: true})
      } else {
          this.getFolloweUserData(this.state.labelId)
          this.setState({loadingFollow: true})
      }
  }

    setCloseMessage = () =>{
      this.setState({openSendMessage: false})
    }

    sendMessage = () =>{
      this.setState({openSendMessage: true})
    }

    handleRedirectToMessage = () => {
      const stateData = this.state.labelId !== "" ? this.state.labelId : localStorage.getItem("user_id");
      HISTORY.push({
        pathname: "/see-all-messages",
        state: { user_id: stateData }
      })
      window.location.reload()
  
    }

    notificationsRead = (NotificationId: string,typeUser:any,typeNotification:any,idUser:any) =>{
      this.setState({idNotification:idUser,userNotification:typeUser,typeNotification:typeNotification})

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_push_notifications/push_notifications/update?id=` + `${NotificationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

    }

    getFolloweUserData = (userId: any) => {

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getFollowApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.followUserUrl}` + userId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }

    getLabelUserData = (labelId: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getlabelUserDetailsApiId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_profile/user_profile?id=` + `${labelId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopPlaylistsData = (labelID: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getTopPlaylistApiId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_audiomusic/label_profile_filter?time_period=${this.state.apiFilterValue}&user_id=` + `${labelID}` +`&type=${this.state.musicButton ? 'video' : 'audio'}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

   
    getLabelGraphandStatsData = (labelID: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getlabelGarphandstatsId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/account_block/get_label_data?user_id=` + `${labelID}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     getTop100CreativesData = (labelID: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getTop100CreativesId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/account_block/top_hundred_creative?user_id=` + `${labelID}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }


    getAllActivityData = (labelID: any) =>{
      const header = {
        "Content-Type": "application/json",
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getactivityListId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_activityfeed/activity_tab?user_id=` + `${labelID}` + `&filter_value=${this.state.apiFilterValue}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     getAllLabelNotifications = async (labelId: any) => {
     
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          token: localStorage.getItem('token'),
        };
  
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.AllNotificationApiId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.getNotificationData}user_id=${labelId}`
        );
  
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
  
    }

     getUnFolloweUserData = (userId: any) => {

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getUnFollowApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.unfollowUserUrl}` + userId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }

     getActivityTest = (data: any) => {
       const values = Object.keys(data?.attributes).reduce((acc: any, key: any) => {
        acc[key] = data.attributes[key].data;
        return acc;
       }, {});

        if(values?.comments.length == 0 && values?.followings.length == 0 && values?.reposts.length == 0 && values?.uploads.length == 0 && this.state.get_notification.length == 0){
          this.setState({noActivity: true})
        }else{
          this.setState({noActivity: false})
        }
     }

    getActivityListApiSuccessCall = (response: any) =>{  
      if(response?.attributes){
        this.getActivityTest(response)
        this.setState({ activityList: response?.attributes })
      }
      else{
        this.setState({
          get_All_Activity_data: [],
          activityList: [],
        })
      }
       response?.attributes && this.filterActivityData(response)
    }

    getLabelGraphandStatsDataApiSuccessCall = (response: any) =>{
      this.setState({labelGraphandStatsData: response})
    }

    getTop100CreativesDataApiSuccessCall = (response: any) =>{
      this.setState({labelTop100Creatives: response.data})
    }

    getlabelUserDataApiSuccessCall = (response: any) =>{
      console.log(response,">>>response")
      this.setState({labelUserDetails: response.data, unfollowState: response?.data?.attributes?.follow_status})
    }

    getTopPlaylistApiSuccessCall = (response: any) =>{
      if(response.data){
        this.setState({TopPlaylists: response?.data})
      }else if(response.message == "No playlist found for this filter criteria"){
        this.setState({TopPlaylists: []})
      }
    }

    setFollowIdHandlerApiSuccessCall = (responseJson: any, value: boolean) => {
      if (responseJson !== undefined && !responseJson.errors) {
        openNotification(responseJson?.message, "Success")
        this.getLabelUserData(this.state.labelId);
        this.setState({ loadingFollow: false, unfollowState: value })
      }
    }

    setAllNotificationsLabelApiSuccessCall = (response: any) =>{
      

      if (response !== undefined && !response.errors && !response.message) {
        if (response.data) {
            this.setState({get_notification: response.data})
          
        } else {
          this.setState({get_notification: {}})
        }
      } 
    }

    readNotificationsLabelCallSuccess = (responseJson: any) =>{
      if (responseJson !== undefined && !responseJson.errors && this.state.idNotification && this.state.typeNotification !=="followers" ) {
          this.redirectFromNotification()
      }
      else if (this.state.typeNotification ==="followers"||this.state.idNotification === ""|| this.state.idNotification === null){
          this.getAllLabelNotifications(this.state.labelId);
      }else{
          console.log(responseJson.errors,"notification read msg")
      }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (apiRequestCallId === this.getactivityListId) {
          this.getActivityListApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else  if (apiRequestCallId === this.getlabelGarphandstatsId) {
            this.getLabelGraphandStatsDataApiSuccessCall(responseJson)
            this.parseApiCatchErrorResponse(errorReponse);
        }else if(apiRequestCallId === this.getTop100CreativesId){
          this.getTop100CreativesDataApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }else if( apiRequestCallId === this.getlabelUserDetailsApiId){
          this.getlabelUserDataApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }else if( apiRequestCallId === this.getTopPlaylistApiId){
          this.getTopPlaylistApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }else if (apiRequestCallId === this.getFollowApiCallId) {
          this.setFollowIdHandlerApiSuccessCall(responseJson, true)
        }else if (apiRequestCallId === this.getUnFollowApiCallId) {
          this.setFollowIdHandlerApiSuccessCall(responseJson, false)
        }else if(apiRequestCallId === this.AllNotificationApiId){
          this.setAllNotificationsLabelApiSuccessCall(responseJson)
        }else if(apiRequestCallId === this.readNotificationApiCallId){
          this.readNotificationsLabelCallSuccess(responseJson)
        }
    
      }
}
// Customizable Area End