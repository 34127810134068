// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/styles";
import {
  plays,
  reshared,
  commentBlue,
  Editicon,
  Linkicon,
  deleteicon,
  playblue,
  playlistMoreTwoDots,
} from "../../../../dashboard/src/assets";
import SinglePlaylistController from "./SinglePlaylistDetailsController";
import PlaylistLineChart from "./PlaylistLinechart.web";
import CtrlChart from "../Common/CtrlChart.web";
import {
  DMRound,
  FollowUserRound,
  defaultProfile,
} from "blocks/dashboard/src/assets";
import PercentageDisplay from "./PercentageDisplay.web";
import {
  Repost,
  Send,
} from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/assets";
import { follow } from "../../../../dashboard/src/Inbox/assets";
import InviteLink from "../Tracks/InviteLink.web";
import MessageLinkPopup from "components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";
import AccessPlaylist from "../AccessPlaylist.web";
import { Avatar } from "antd";

const Borderboxweb1 = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});

const SinglePlaylistDetailswrapper = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "10px",
  "@media(max-width: 1024px)": {
    width: "100%",
    flexDirection: "column",
  },
});
const SinglePlaylistRightideboxwrapper = styled(Box)({
  width: "30%",
  display: "flex",
  justifyContent: "center",
  marginTop: "-35px",
  marginBottom: "37px",
  "@media(max-width: 1024px)": {
    width: "100%",
    marginTop: "5px",
  },
});
const SinglePlaylistfirstbox = styled(Box)({
  width: "70%",
  "@media(max-width: 1024px)": {
    width: "95%",
  },
  "@media(max-width: 600px)": {
    width: "95%",
  },
});
const Allplayshareboxwrapper = styled(Box)({
  display: "flex",
  gap: "35px",
  alignItems: "center",
  "@media(max-width: 820px)": {
    display: "none",
  },
});
const ResponsiveBox = styled(Box)({
  display: "none",
  "@media(max-width: 820px)": {
    display: "flex",
    gap: "15px",
  },
});

export default class SinglePlaylistDetails extends SinglePlaylistController {
 
  returnAvatarListData = (accessAvatarList: any) => {
    return (
      <>
        {accessAvatarList?.length <= 3 ? (
          <Avatar.Group maxCount={3} style={{ cursor: "pointer" }}>
            {accessAvatarList?.map((item: any, index: any) => (
              <Avatar
                key={index}
                src={item || defaultProfile}
                style={this.props.webStyle.avatartAccessImg}
                onClick={this.props.setAccessListView}
              />
            ))}
          </Avatar.Group>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
              cursor: "pointer",
            }}
            onClick={this.props.setAccessListView}
          >
            <Avatar.Group maxCount={3}>
              {accessAvatarList?.slice(0, 3).map((item: any, index: any) => (
                <Avatar
                  key={index}
                  src={item || defaultProfile}
                  style={this.props.webStyle.userimage}
                />
              ))}
            </Avatar.Group>
            <Typography className="details-access-number">
              {accessAvatarList?.length > 0
                ? `+${accessAvatarList?.length - 3}`
                : 0}
            </Typography>
          </Box>
        )}
      </>
    );
  };

   FollowButton = () => (
    <Button
              style={
                this.props.follow_playlist
                  ? this.props.webStyle.unfollowbutton
                  : this.props.webStyle.followbutton
              }
              onClick={() => {
                this.props.followPlaylist();
              }}
              data-test-id="follow_singleplaylist"
            >
              {this.props.follow_playlist
                ? "Unfollow  Playlist"
                : "Follow Playlist"}
            </Button>
  );
  
   returnIsFollow = () => {
   
      return this.props.followUser ? (
        
        <Box
          style={this.props.webStyle.iconsboxwrapper}
          onClick={() => this.props.followUserStatus()}
          data-test-id="follow_user_playlist"
        >
          <img style={this.props.webStyle.editimage} src={follow} />
          <Typography
            style={this.props.webStyle.edittext}
            data-test-id="edit_playlist"
          >
            Unfollow
          </Typography>
        </Box>
        
      ) : (
  
        <Box
          style={this.props.webStyle.iconsboxwrapper}
          onClick={() => this.props.followUserStatus()}
          data-test-id="not_follow_user_playlist"
        >
          <img
            style={this.props.webStyle.editimage}
            src={FollowUserRound}
          />
          <Typography
            style={this.props.webStyle.edittext}
            data-test-id="edit_playlist"
          >
            Follow
          </Typography>
        </Box>
      
      )
      }
  
 
  renderFollow = (attributes: any) => {
    if (this.props.is_invite) {
      return (
        <>
          <Box style={{ width: "100%" }}>
           {this.FollowButton()}
          </Box>
          <Box
            style={{ display: "flex", justifyContent: "center", gap: "30px" }}
          >
            {this.returnIsFollow()}

            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={() => this.openInbox()}
              data-test-id="linkpopup1"
            >
              <img style={this.props.webStyle.editimage} src={DMRound} />

              <Typography style={this.props.webStyle.edittext}>DM</Typography>
            </Box>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              data-test-id="invite"
              onClick={() => {
                this.props.handleRepost();
              }}
            >
              <img style={this.props.webStyle.editimage} src={Repost} />

              <Typography style={this.props.webStyle.edittext}>
                Repost
              </Typography>
            </Box>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={this.props.showEditPlaylist}
              data-test-id="editPlaylist"
            >
              <img src={Editicon} className="edit_image" />
              <Typography style={this.props.webStyle.edittext}>Edit</Typography>
            </Box>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={this.handleOpenInvitation}
              data-test-id="invitePlaylist"
            >
              <img src={Linkicon} className="edit_image" />
              <Typography style={this.props.webStyle.edittext}>
                Invite
              </Typography>
            </Box>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={() => this.props.linkopenPopup()}
              data-test-id="linkpopup"
            >
              <img src={Send} className="edit_image" />
              <Typography style={this.props.webStyle.edittext}>DM</Typography>
            </Box>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={() => {
                this.props.handleRepost();
              }}
              data-test-id="edit_img"
            >
              <img src={Repost} className="edit_image" />
              <Typography style={this.props.webStyle.edittext}>
                Repost
              </Typography>
            </Box>
            <Box
              style={this.props.webStyle.iconsboxwrapper}
              onClick={this.props.showDeletePlaylist}
              data-test-id="deleted_icon"
            >
              <img src={deleteicon} className="edit_image" />
              <Typography style={this.props.webStyle.edittext}>
                Delete
              </Typography>
            </Box>
          </Box>
          <Box style={this.props.webStyle.statusbuttonbox}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {/* <img
                src={VideoUserAvatar}
                style={this.props.webStyle.userimage}
              />
              <img
                src={VideoUserAvatar3}
                style={this.props.webStyle.userimage}
              />
              <img
                src={VideoUserAvatar2}
                style={this.props.webStyle.userimage}
              /> */}

              {this.props.accessAvatarList?.length > 0 ? (
                this.returnAvatarListData(this.props.accessAvatarList)
              ) : (
                <Typography style={this.props.webStyle.statustext}>
                  No Access
                </Typography>
              )}

              {/* <Typography style={this.props.webStyle.totalreleasetext}>
                +12
              </Typography> */}
            </Box>
            <Box>
              <Button
                style={{
                  border:
                    attributes?.status == "public_playlist"
                      ? "1px solid #3959F9"
                      : "1px solid #FF0501",
                  background: "#000",
                  color:
                    attributes?.status == "public_playlist"
                      ? "#3959F9"
                      : "#FF0501",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  letterSpacing: "-0.32px",
                  fontFamily: "Inter",
                  textTransform: "capitalize" as "capitalize",
                  borderRadius: "30px",
                }}
              >
                {attributes?.status == "public_playlist" ? "Public" : "Private"}
              </Button>
            </Box>
          </Box>
        </>
      );
    }
  };

  render() {
    const {
      webStyle,
      selectedTab,
      selectedTrack,
      playlist_details_data,
      showAccessListView,
      accessList,
      accessListLoader,
    } = this.props;
    const { attributes } = this.props.playlistDetailsData;
    let content;

    if (showAccessListView) {
      content = (
        <SinglePlaylistRightideboxwrapper>
          <Box style={webStyle.singletrackrightsidebox}>
        <AccessPlaylist
          accessList={accessList}
          showAccessListData={this.props.setAccessListView}
          accessListLoader={accessListLoader}
          accessListTrack={()=>this.props.accessListPlayList(this.props.playlist_details_data.id)}
          trackDetails={playlist_details_data?.attributes}
          handleArtClick={()=>this.props.topPlayMusic(
            playlist_details_data,
            2,
            attributes?.playlist_items?.data,
            "track"
          )}
          track_id={playlist_details_data.id}
        />
        </Box>
        </SinglePlaylistRightideboxwrapper>
      );
    } else {
      content = (
        <SinglePlaylistRightideboxwrapper>
          <Box style={webStyle.singleplaylistrightsidebox}>
            <Box
              style={webStyle.singleplaylistrightsidevideobox}
              onClick={() => {
                this.setState({ localSeletedItem: false });
                this.props.topPlayMusic(
                  playlist_details_data,
                  2,
                  attributes?.playlist_items?.data,
                  "track"
                );
              }}
              data-test-id="topMusic"
            >
              <Box
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: `url("${playlist_details_data?.attributes?.art_work}") lightgray 50% / cover no-repeat`,
                  display: "flex",
                  width: "100%",
                  height: "349px",
                  alignItems: "end",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100px",
                    border: "0px solid #000",
                    // borderRadius: "13px",
                    display: "flex",
                    gap: "5px",
                    padding: "20px",
                    background:
                      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%",
                  }}
                >
                  <Box>
                    <img
                      src={playlist_details_data?.attributes?.art_work}
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Box>
                      <Typography style={webStyle.singlevideoartisttext}>
                        {playlist_details_data?.attributes?.artist_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={webStyle.singlevideoimagetext}>
                        {playlist_details_data.attributes?.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  width: "60px",
                  height: "60px",
                  border: "3px solid white",
                  background: "black",
                  position: "absolute",
                  right: "45%",
                  top: "40%",
                  borderRadius: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="music__button"
                  src={this.showPlayPause(playlist_details_data)}
                />
              </Box>
            </Box>
            <Box style={webStyle.followrepostbox} data-test-id="debugger">
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Typography style={webStyle.totalreleasetext}>Plays</Typography>
                <Typography style={webStyle.playtext}>
                  {playlist_details_data.attributes?.play_count}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Typography style={webStyle.totalreleasetext}>
                  Follows
                </Typography>
                <Typography style={webStyle.playtext}>
                  {attributes?.followers_count}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
                data-test-id="checkinng1"
              >
                <Typography style={webStyle.totalreleasetext}>
                  Reposts
                </Typography>
                <Typography style={webStyle.playtext}>
                  {playlist_details_data.attributes?.repost_count}
                </Typography>
              </Box>
            </Box>
            {this.renderFollow(attributes)}

            <Box style={{ width: "100%", height: "300px" }}>
              <CtrlChart
                navigation={undefined}
                id={""}
                chartData={Object.values(
                  attributes?.last_seven_days_graph || []
                )}
                isgradient={false}
                labellength={7}
              />
              <Box style={{ width: "100%" }}>
                <Box style={webStyle.weekdaysbox}>
                  {["S", "M", "T", "W", "T", "F", "S"].map((item, index) => (
                    <Box style={webStyle.singledaybox} key={index}>
                      <Typography style={webStyle.weeknametext}>
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </SinglePlaylistRightideboxwrapper>
      );
    }
    return (
      <SinglePlaylistDetailswrapper>
        <SinglePlaylistfirstbox>
          <Box style={webStyle.singlePlaylistleftsidefirstbox}>
            <Box>
              <Typography style={webStyle.trackname}>
                <span style={webStyle.bluetext}>
                  {attributes?.playlist_items?.data?.length}
                </span>{" "}
                {selectedTab == "audio" ? "Tracks" : "Videos"}
              </Typography>
            </Box>
            <Box style={webStyle.weekpercentbox} className="weekpercentbox">
              <Box>
                <Typography style={webStyle.percenttext}>24 %</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.percenttext}>7d %</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.percenttext}>Last 7d</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              height: "810px",
              overflowY: "scroll",
              scrollbarWidth: "none",
              paddingLeft: "23px",
            }}
          >
            {attributes?.playlist_items?.data.length > 0 ? (
              attributes?.playlist_items?.data?.map(
                (item: any, index: number) => {
                  let chartValuesArray: any[] = [];

                  if (item.attributes.last_seven_days_graph) {
                    chartValuesArray = Object.values(
                      item.attributes.last_seven_days_graph
                    );
                  }
                  return (
                    <Box
                      key={index}
                      style={webStyle.leftSideSingletrackbox}
                      onClick={() => {
                        this.setState({ localSeletedItem: true });
                        selectedTrack(item);
                      }}
                      data-test-id="singleplaylist-data"
                      className={
                        item.id == playlist_details_data.id
                          ? "playlist_track_border"
                          : "aaa"
                      }
                    >
                      <Box style={webStyle.singleArtinfo}>
                        <img
                          src={item?.attributes?.art_work || defaultProfile}
                          style={webStyle.audiocard}
                        />
                        <Box style={webStyle.leftsideownerstats}>
                          <span style={webStyle.trackname}>
                            {item?.attributes?.title}
                          </span>
                          <span
                            style={webStyle.splittext}
                            className="splittexthide"
                          >
                            {item?.attributes?.artist_name}
                          </span>
                          <ResponsiveBox>
                            <span style={webStyle.splittext}>
                              {item?.attributes?.artist_name}
                            </span>
                            <Box>
                              <img
                                src={playblue}
                                style={{ height: "12px", width: "12px" }}
                              />
                              <span
                                style={{
                                  color: "#3959F9",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  lineHeight: "20px",
                                }}
                              >
                                100 M
                              </span>
                            </Box>
                          </ResponsiveBox>
                        </Box>
                      </Box>
                      <Allplayshareboxwrapper>
                        <Box style={webStyle.allplaysharechildbox}>
                          <Box style={webStyle.allplaystatselement}>
                            <img className="analytics_icon_image" src={plays} />
                            <Typography style={webStyle.allplaystext}>
                              {item?.attributes?.play_count}
                            </Typography>
                          </Box>
                          <Box style={webStyle.allplaystatselement}>
                            <img
                              src={reshared}
                              className="analytics_icon_image"
                            />
                            <Typography style={webStyle.allplaystext}>
                              {item?.attributes?.repost_count}
                            </Typography>
                          </Box>
                          <Box style={webStyle.allplaystatselement}>
                            <img
                              src={commentBlue}
                              className="analytics_icon_image"
                            />
                            <Typography style={webStyle.allplaystext}>
                              {item?.attributes?.comments_count}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              minWidth: "4.5rem",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <PercentageDisplay
                              value={
                                item?.attributes
                                  ?.last_twenty_four_hours_percentage
                              }
                              style={webStyle}
                            />
                          </Box>

                          <Box
                            style={{
                              minWidth: "4.5rem",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <PercentageDisplay
                              value={
                                item?.attributes?.last_seven_days_percentage
                              }
                              style={webStyle}
                            />
                          </Box>

                          <PlaylistLineChart
                            navigation={undefined}
                            id={""}
                            getStatusDetails={undefined}
                            data={chartValuesArray}
                            topPlayMusic={this.props.topPlayMusic}
                            showSingleplaylistdeatils={""}
                            setShowSingleplaylistdeatils={(data: any) =>
                              this.handleShowPlaylist(data)
                            }
                          />
                        </Box>
                      </Allplayshareboxwrapper>
                      <Box
                        style={{ display: "none" }}
                        className="two_dots_image"
                      >
                        <img src={playlistMoreTwoDots} />
                      </Box>
                    </Box>
                  );
                }
              )
            ) : (
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                  padding: "5",
                  justifyContent: "center",
                }}
              >
                Data Not Found.
              </Typography>
            )}
          </Box>
        </SinglePlaylistfirstbox>
        <Borderboxweb1>
          <hr
            style={{
              height: "100%",
              width: "1px",
              border: "1px solid #212121",
            }}
            data-test-id="singleplaylistcontent"
          />
        </Borderboxweb1>
        {content}
        <InviteLink
          title={"Send Invite Link"}
          subTitle={""}
          isOpen={this.state.openInvitationModalPlaylist}
          handleDMClose={() => {
            this.setState({ openInvitationModalPlaylist: false });
          }}
          handleDelete={this.linkopenPopup}
        />
        <MessageLinkPopup
          id="Profile"
          copiedLinked={this.state.CopiedForLinkPlaylist}
          showMsgModal={this.state.openMessageSendPopupPlaylist}
          closeMsgModal={this.handleOpenMessageSendPopup}
          closeModalDialog={this.handleOpenMessageSendPopup}
          currentUserId={1}
          onInvitedUser={() => {}}
          track_whole_detailsId={this.props.playlist_details_data.id}
        />
      </SinglePlaylistDetailswrapper>
    );
  }
}

// Customizable Area End
