// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import React from "react";





export interface Props {
    chartData: any,
    isChartLoading?: boolean 
}


interface S {
    positiveData:any;
    negativeData:any;
}

interface SS {
    id: any;
}

export default class CustomChartBarController extends BlockComponent<
    Props,
    S,
    SS
> {




    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];

        this.state = {
             
            positiveData: [],
            negativeData: [],
            


        };

     
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }
    async componentDidMount() {
        super.componentDidMount();

            this.initializeChartData();
          }
        
          async componentDidUpdate(prevProps:any) {
            if (this.props.chartData !== prevProps.chartData) {
              this.initializeChartData();
            }
          }

        initializeChartData() {
            const { chartData } = this.props;
        
            if (Object.keys(chartData).length > 0) {
              let positiveData:any = [];
              let negativeData:any = [];
              chartData.map((item:any) => {
                if (item > 0) {
                  positiveData.push(item);
                  negativeData.push(0);
                } else {
                  negativeData.push(Math.abs(item));
                  positiveData.push(0);
                }
              });
              this.setState({ positiveData, negativeData });
            }
          }
           
          calculateBarValue = (value:any) => {
            if (value) {
                let num = value == 100 ? 100 : value
               
                if (num === Infinity) {
                    return 0;
                }
                return num;
            }
            return 0;
        };
     

}

// Customizable Area End