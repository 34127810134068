// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config");
import React from "react";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "components/src/Notification.web";
import { defaultProfile } from "blocks/dashboard/src/assets";
import axios from "axios";

export interface Props {
  data: any;
  selectedTrack: any;
  playlist_details_data: any;
  showDetailsPlaylistOpen: any;
  getAllPlaylist: any;
}

interface UploadOptions {
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
  file: File;
  onProgress: (event: { percent: number }) => void;
}


interface S {
  editData: any;
  isLoading: boolean;
  playlistName: any;
  updatedImage: any;
  coverImgURL: any;
  activateTab: any;
  wholeUrlImg: any;
  apiToken: any;
}

interface SS {
  id: any;
}

export default class EditPlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  containerRef: any;
  apiReorderTrackData: any;
  apiOnSaveData: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.containerRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      editData:[] ,
      isLoading: false,
      playlistName: "",
      updatedImage: "",
      coverImgURL:"",
      activateTab:"",
      wholeUrlImg: null,
      apiToken: localStorage.getItem("token"),
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      editData: this.props.data?.attributes?.playlist_items?.data,
      isLoading: false,
      playlistName: this.props.data?.attributes?.name,
      updatedImage: "",
      coverImgURL: this.props.data?.attributes?.cover_image,
      activateTab:
        this.props.data.attributes?.status == "public_playlist"
          ? "Public"
          : "Private",
      wholeUrlImg: null,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.playlist_details_data !== this.props.playlist_details_data) {
      this.setState({
        playlistName: this.props.data.attributes?.name,
        updatedImage: "",
        coverImgURL: this.props.data.attributes?.cover_image,
        activateTab:
          this.props.data.attributes?.status == "public_playlist"
            ? "Public"
            : "Private",
      });
      this.gerAvatarProfile();
    }
  }
  removeTrackByIndex = (id: any) => {
    this.setState({
      editData: [...this.state.editData.filter((item: any) => item.id !== id)],
    });
  };

  handleOnChangePlaylist=(event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ playlistName: event.target.value });
  }
  setActivateTab = (active: any) => {
    this.setState({ activateTab: active });
  };
  _onListChange = (newList: []) => {
    this.setState({ editData: newList });
  };

  onReorderTrack = async () => {
    let idArr = this.state.editData.map((item: any) => item.id);
    let url =
      configJSON.baseUrl +
      `/bx_block_playlist/user_play_lists/update_positions?play_list_id=` +
      this.props.data.id;

    idArr.forEach((item: string) => {
      url = url + "&play_list_items[]=" + item;
    });

    const header = {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReorderTrackData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  beforeUpload = (file: File): boolean => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      openNotification("You can only upload JPG/PNG file!", "error");
    }
    return isJpgOrPng;
  };

  getBase64 = (video: File, callback: (result: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader?.addEventListener("load", () => callback(reader.result));
    reader?.readAsDataURL(video);
  };

  uploadImage = async (options: UploadOptions, value: any) => {
    this.setState({ isLoading: true });

    const { onSuccess, onError, file, onProgress } = options;
    this.getBase64(file, (imageUrl: any) =>
      this.setState({ coverImgURL: imageUrl, wholeUrlImg: file })
    );

  };

  gerAvatarProfile = () => {
    if (this.state.coverImgURL !== "") {
      return this.state.coverImgURL;
    } else {
      return defaultProfile;
    }
  };

  onSave = async () => {
    if (this.state.playlistName == "") {
      openNotification("Please Enter Playlist Name", Error);
      return;
    }
    this.setState({ isLoading: true });

    const formData: any = new FormData();

    if (
      this.props.data.attributes?.name !==
      this.state.playlistName
    ) {
      formData.append(`name`, this.state.playlistName);
    }

    if (this.props.data?.attributes?.cover_image !== this.state.coverImgURL) {
      formData.append(`cover_image`, this.state.wholeUrlImg);
    }

    formData.append(
      `status`,
      this.state.activateTab == "Private"
        ? "private_playlist"
        : "public_playlist"
    );

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: this.state.apiToken,
      },
    };
    try {
      await axios
        .put(
          `${configJSON.baseUrl}/bx_block_playlist/play_lists/${this.props.data.id}`,
          formData,
          config
        )
        .then((res) => {
          this.handleOnSaveData(res.data);
        });
    } catch {
      openNotification("Error Occurred", "error");
    }
  };

  handleUpdatedPlaylist = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ isLoading: false });
      if (responseJson?.message) {
        openNotification("Playlist Updated", "success");
        this.props.getAllPlaylist();
        this.props.showDetailsPlaylistOpen("playist");
      }
    } else {
      this.setState({ isLoading: false });
      openNotification("Error Occurred", "Error");
    }
  };

  handleOnSaveData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (
        responseJson?.data[0]?.message == "Playlist of this name already exist."
      ) {
        this.setState({ isLoading: false });
        openNotification(responseJson?.data[0]?.message + "", "Error");
        return;
      }
      this.onReorderTrack();
    } else {
      this.setState({ isLoading: false });
      openNotification("Error Occurred", "Error");
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apiReorderTrackData) {
      this.handleUpdatedPlaylist(responseJson);
    } else if (apiRequestCallId == this.apiOnSaveData) {
      this.handleOnSaveData(responseJson);
    }
    // Customizable Area End
  }
}

// Customizable Area End
