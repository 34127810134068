// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { HISTORY } from "components/src/common";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

export interface Props {
  navigation: any;
  id: string;
  selectedVideoId?: any;
}

interface S {
  active: number;
  heading: string;
  seeAllTopDownloaders: boolean;
  status: string;
  showSingleplaylistdeatils:any;
  fetchedUserType:string;
}

interface SS {
  id: any;
}

export default class CtrlController extends BlockComponent<Props, S, SS> {
  subscriptionPlan = localStorage.getItem("subscription_plans");

  getTop100ListsApiCallId: any;
  previousHref: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      active: 0,
      heading: "Snapshot",
      seeAllTopDownloaders: false,
      status: "Private",
      showSingleplaylistdeatils: "playlist",
      fetchedUserType:localStorage.getItem("user_type")||"creative"
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.previousHref = window.location.href;
    if(this.state.fetchedUserType.includes('label')){
      this.handleLabelSlug(this.getSlugFromUrl());
    }else{
      this.handleSlug(this.getSlugFromUrl());
    }

    window.addEventListener("popstate", this.handlePopState);
  }

  async componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  handlePopState = () => {
    this.handleSlug(this.getSlugFromUrl());
  };

  getSlugFromUrl = () => {
    const urlArr = window.location.href.split("/");
    return urlArr.length !== 0 ? urlArr[4] : "";
  };

  handleSlug = (slug: any) => {
    if (slug === "snapshot") {
      this.setState({ active: 0 });
    } else if (slug === "splits") {
      this.setState({ active: 1 });
    }
     else if (slug === "tracks") {
      this.setState({ active: 2 });
    } else if (slug == "songs") {
      this.setState({ active: 3 });
    } else if (slug === "videos") {
      this.setState({ active: 4});
    } else {
      this.setState({ active: 5 });
    }
  };

  handleChange = (newValue: number) => {
    if (newValue === 0) {
      HISTORY.push(`/ctrl/snapshot`);
    } else if (newValue === 1) {
      HISTORY.push(`/ctrl/splits`);
    } else if (newValue === 2) {
      HISTORY.push(`/ctrl/tracks`);
    } else if (newValue === 3) {
      HISTORY.push(`/ctrl/songs`);
    } else if (newValue === 4) {
      HISTORY.push(`/ctrl/videos`);
    } else {
      HISTORY.push(`/ctrl/playlist`);
    }
    this.setState({ active: newValue, seeAllTopDownloaders: false,showSingleplaylistdeatils:"playlist" });
  };


  handleLabelSlug = (slug: any) => {
    if (slug === "splits") {
      this.setState({ active: 0 });
    } else {
      this.setState({ active: 1 });
    }
  };

  handleLabelChange = (newValue: number) => {
    if (newValue === 0) {
      HISTORY.push(`/ctrl/splits`);
    }  else {
      HISTORY.push(`/ctrl/playlist`);
    }
    this.setState({ active: newValue, seeAllTopDownloaders: false,showSingleplaylistdeatils:"playlist" });
  };

  changestatus = (tabs: string) => {
    this.setState({ status: tabs });
  };

  checkAllDownloaders = () => {
    this.setState({ seeAllTopDownloaders: true });
  };

  topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    let {
      handlePlayList,
      setHandleAudioVideo,
      handleAudioVideo,
    } = this.context;
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      setHandleAudioVideo(2);
      handlePlayList(item, index, songsList, trackName);
    } else {
      setHandleAudioVideo(1);
    }
  };

  setShowSingleplaylistdeatils=(data:any)=>{
    this.setState({showSingleplaylistdeatils:data})
  }
  getStatusDetails = (item: any, type: any) => {
    let typeColor = "#919191";
    let status = "draft";
    if (type == "tracks") {
      if (item.attributes?.status.toLowerCase() === "public_track") {
        typeColor = "red";
        status = "Public";
      } else if (item.attributes?.status.toLowerCase() === "private_track") {
        typeColor = "#3959f9";
        status = "Private";
      }
    } else {
      if (item.attributes?.status.toLowerCase() === "public_playlist") {
        typeColor = "red";
        status = "Public";
      } else if (item.attributes?.status.toLowerCase() === "private_playlist") {
        typeColor = "#3959f9";
        status = "Private";
      }
    }

    return { typeColor, status };
  };
}
CtrlController.contextType = AudioContextApi;
// Customizable Area End
