import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { Alert, Linking } from "react-native";
import { CommonActions } from "@react-navigation/native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  signuptoken: string,
  googlemail: any,
  username: string;
  isInvalidUsername: boolean;
  isInvalidpass: boolean;
  setGoogleLogin: boolean;
  setGoogleInitializing: boolean;
  errordata: any
  number: any;
  responseJsonInSignUp: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  signupAccountApiCallId: any;
  googleSignInAccountApiCallId: any;
  loadingButton: any;
  checkSubscriptioncallId: any;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      email: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      number: '',
      googlemail: '',
      signuptoken: '',
      errordata: '',
      username: "",
      password: "",
      isInvalidUsername: false,
      isInvalidpass: false,
      setGoogleInitializing: false,
      setGoogleLogin: false,
      responseJsonInSignUp: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setUserToken = async (userData: any) => {
    await StorageProvider?.set("USER_TOKEN", userData.meta.token);
    await StorageProvider?.set("USER_ID", userData.meta.id + "");
    await StorageProvider?.set("PROFILE_PIC", userData.meta.avatar);
    await StorageProvider?.set("USER_TYPE", userData.meta.user_type);
  };

  signUpSuccessCallBack = async (responseJson: any) => {
    if (responseJson?.failed_login === 'Account Not Activated') {
      Alert.alert(
        'Alert',
        'Your account has been deactivated. Please sign up to proceed.',
        [
          { text: 'Okay', onPress: () => console.log('Okay') },
        ],
      )
      this.setState({ signuptoken: responseJson.signup_token, number: responseJson.full_phone_number }
        // , () =>

      )
    } else if (responseJson?.failed_login === 'User is not registered. Please register the user first.') {
      Alert.alert(
        'Alert',
        'Please, Sign-Up first!!',
        [
          { text: 'Okay', onPress: () => console.log('Okay') },
        ],
      )
    } else if (responseJson?.failed_login === 'Login Failed') {
      Alert.alert(
        'Alert',
        'Login Failed, Please Enter Valid Credentials.',
        [
          { text: 'Okay', onPress: () => console.log('Okay') },
        ],
      )
    } else if (responseJson?.meta?.subscription === false) {
      Alert.alert(
        'Alert',
        'Please purchase a plan to continue!!',
        [
          { text: 'Okay', onPress: () => console.log('Okay') },
        ],
      )
      this.props.navigation.replace("VerificationTermsConditionScreen");
    } else {
      this._checkSubscriptionAPIcall(responseJson.meta.token);
      this.setState({
        responseJsonInSignUp: responseJson
      })
    }
  };

  signUpFailureCallBack = (responseJson: any) => {
    console.log("@@@ Signn  Error Response =========", responseJson);

  };

  onPressLogin = async () => {
    const fcmToken = "null001"

    if (this.state.username.length === 0 || this.state.username === " ") {
      this.setState({ isInvalidUsername: true });
      alert("Please Enter Your Username");
      return
    } else if (this.state.password.length === 0  || this.state.password === " ") {
      this.setState({ isInvalidpass: true });
      alert("Password Is Required");
      return
    }

    const header = {
      "Content-Type": "application/json",
    };

    const attrs = {
      user_name: this.state.username,
      password: this.state.password,
      "device_id": fcmToken
    };

    const data = {
      type: "name_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  _checkSubscriptionAPIcall = async (userToken: any) => {
    const checkSubscriptionEndPoint = `https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe//account_block/check_subscription`;
    const getMethod = "GET";

    // headers of API
    const header = {
      "Content-Type": "application/json",
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.checkSubscriptioncallId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      checkSubscriptionEndPoint
    );
    console.warn(
      "API URL ================>>>>>>>>>>>>>> ",
      checkSubscriptionEndPoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getMethod
    );
    console.warn("API Method ================>>>>>>>>>>>>>> ", getMethod);

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.signupAccountApiCallId !== null &&
      this.signupAccountApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.signupAccountApiCallId = null;

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.signUpSuccessCallBack(responseJson);
      } else {
        //Check Error Response
        this.signUpFailureCallBack(responseJson);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkSubscriptioncallId !== null &&
      this.checkSubscriptioncallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (
          responseJson?.meta?.terms_and_condition === false
        ) {
          Alert.alert(
            'Login Failed',
            'Please accept Terms and Condition!',
            [
              { text: 'Okay', onPress: () => Linking.openURL(`https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/planpayments?fromiphone`) },
            ],
          )
        } else {
          await this.setUserToken(this.state.responseJsonInSignUp);
          this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "TabStack" }],
            })
          );
        }

      } else {
        //Check Error Response
        this.loadingButton.showLoading(false);
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start 
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area End
}
