// Customizable Area Start
import { Input, Upload } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/styles";
import {
  deletecircle,
  menuicon,
  Editicon,
} from "../../../../dashboard/src/assets";
import EditPlaylistController from "./EditPlaylistController";
import { defaultImage } from "blocks/dashboard/src/assets";
import DraggableList from "react-draggable-list";

const MainWrapper = styled(Box)({
  display: "flex",
  padding: "30px 20px 0px 27px",
  gap: "1%",
  minHeight:"calc(100% - 3rem)",
  justifyContent: "space-between",
  "@media(max-width: 1400px)": {
    gap: "20px",
  },
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 5px",
    gap: "0%",
  },
});

const Editleftsidecontainer = styled(Box)({
  width: "65%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "2%",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "10px",
  },
});
const EditLeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "693px",
  scrollbarWidth: "none",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

const TotalSplits = styled(Box)({
  color: "white",
  width: "100%",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});
const Borderboxweb = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});

const Plyalistinputboxwrapper = styled(Box)({
  width: "100%",
  background: "transparent",
  borderBottom: "5px solid #212121",
  // position: "relative",
  // borderRadius: "27px",
  // padding: "10px",
  "& .ant-input": {
    backgroundColor: "transparent",
    width: "88%",
    color: "white",
    border: "unset",
    padding: "15px 0px 17px 0px",
  },
  "& :focus": {
    border: "unset", // Removes the border
    boxShadow: "none", // Removes the shadow if any
  },
  "& ::placeholder": {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
    marginLeft: "-10px",
  },

  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
    "&:focus, &:focus-within": {
      boxShadow: "none ", // Increase specificity
      border: "unset ",
    },
  },
  "@media(max-width: 1024px)": {
    width: "100%",
    "& .ant-input": {
      width: "50%",
    },
  },
});

const LeftsideAlltracksbox = styled(Box)({
  width: "100%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});

const SinglePlaylistRightideboxwrapper = styled(Box)({
  width: "30%",
  display: "flex",

  marginTop: "-35px",
  justifyContent: "center",
  "@media(max-width: 1400px)": {
    width: "100%",
    marginTop: "10px",
    paddingBottom: "60px",
  },
});

const Singleplaylistrightsideboxwrap = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "30px",
  flexDirection: "column" as "column",
  "@media(max-width: 1400px)": {
    width: "100%",
    // marginTop: "10px",
    // paddingBottom:'60px'
  },
});
interface PlaylistItemProps {
  item: {
    id: string; // Replace with the actual type of your 'id' property
    attributes: {
      art_work: string; // Replace with the actual type of your 'art_work' property
      title: string; // Replace with the actual type of your 'title' property
      artist_name: string; // Replace with the actual type of your 'artist_name' property
    };
  };
  dragHandleProps: any; // Adjust the type as needed
  selectedTrack: (item: any) => void; // Adjust the type as needed
  playlist_details_data: any; // Adjust the type as needed
  removeTrackByIndex: (id: string) => void; // Adjust the type as needed
}
export class PlaylistItem extends React.Component<PlaylistItemProps> {
  render() {
    const {
      item,
      dragHandleProps,
      selectedTrack,
      playlist_details_data,
      removeTrackByIndex,
    } = this.props;
    const { dragHandleProps: {onMouseDown, onTouchStart } } = dragHandleProps;
    return (
      <Box
        key={item.id}
        style={webStyle.editleftSideSingletrackbox}
        // onClick={() => {
        //   selectedTrack(item);
        // }}
        // className={
        //   item.id === playlist_details_data.id ? "playlist_track_border" : "aaa"
        // }
        data-test-id="edit-playlist-click"
      >
        <Box style={webStyle.singleArtinfo} className="disable-select">
          <img
            src={deletecircle}
            style={{ height: "24px", width: "24px" }}
            onClick={() => {
              removeTrackByIndex(item.id);
            }}
            data-test-id="edit-playlist-img"
          />
          <img
            src={item.attributes?.art_work || defaultImage}
            style={webStyle.imagecover}
          />

          <Box style={webStyle.editleftsideownerstats}>
            <span style={webStyle.trackname}>{item.attributes?.title}</span>
            <span style={webStyle.splittext}>
              {item.attributes?.artist_name}
            </span>
          </Box>
        </Box>
        <Box style={webStyle.editleftsideownerstats}>
          <Box
            className="disable-select dragHandle"
            onTouchStart={(e) => {
              e.preventDefault();
              console.log("touchStart");
              //@ts-ignore
              e.target.style.backgroundColor = "blue";
              document.body.style.overflow = "hidden";
              onTouchStart(e);
            }}
            onMouseDown={(e) => {
              console.log("mouseDown");
              document.body.style.overflow = "hidden";
              onMouseDown(e);
            }}
            onTouchEnd={(e) => {
              //@ts-ignore
              e.target.style.backgroundColor = "black";
              document.body.style.overflow = "visible";
            }}
            onMouseUp={() => {
              document.body.style.overflow = "visible";
            }}
          >
            <img src={menuicon} style={{ width: "24px", height: "24px" ,mixBlendMode: 'lighten'}} />
          </Box>
        </Box>
      </Box>
    );
  }
}

export class PlaylistItemTemplate extends React.Component<{
  dragHandleProps: any;
  item: any;
  selectedTrack: (item: any) => void;
  playlist_details_data: any;
  removeTrackByIndex: (id: string) => void;
}> {
  render() {
    const {
      dragHandleProps,
      item,
      selectedTrack,
      playlist_details_data,
      removeTrackByIndex,
    } = this.props;
    return (
      <PlaylistItem
        item={item}
        dragHandleProps={dragHandleProps}
        selectedTrack={selectedTrack}
        playlist_details_data={playlist_details_data}
        removeTrackByIndex={removeTrackByIndex}
      />
    );
  }
}
export default class Editplaylist extends EditPlaylistController {
  fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.fileInputRef = React.createRef<HTMLInputElement>();
  }

  handleIconClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  // Function to handle file input change

  render() {

    return (
      //Merge Engine DefaultContainer
      <MainWrapper>
        <Editleftsidecontainer>
          <TotalSplits>
            <Typography style={webStyle.trackname}>Edit Playlists</Typography>
          </TotalSplits>
          <LeftsideAlltracksbox>
            <EditLeftsideAlltracksboxwrapper>
              <div ref={this.containerRef}>
                <DraggableList
                  itemKey="id"
                  // template={PlaylistItemTemplate}
                  //@ts-ignore
                  template={(dragHandleProps: any, item: any) => (
                    <PlaylistItemTemplate
                      dragHandleProps={dragHandleProps}
                      item={dragHandleProps.item}
                      selectedTrack={this.props.selectedTrack}
                      playlist_details_data={this.props.playlist_details_data}
                      removeTrackByIndex={this.removeTrackByIndex}
                    />
                  )}
                
                  list={this.state.editData}
                  onMoveEnd={(newList: any) => this._onListChange(newList)}
                  container={() => this.containerRef.current}
                />
              </div>
            </EditLeftsideAlltracksboxwrapper>
          </LeftsideAlltracksbox>
        </Editleftsidecontainer>
        <Borderboxweb>
          <hr
            style={{
              height: "100%",
              width: "1px",
              border: "1px solid #212121",
            }}
          />
        </Borderboxweb>
        <SinglePlaylistRightideboxwrapper>
          <Singleplaylistrightsideboxwrap>
            <Box
              style={{
                width: "100%",
                height: "349px",
                position: "relative",
                display: "flex",
                borderRadius: "12px",
                alignItems: "end",
                background: `url("${this.state.coverImgURL}") lightgray 50% / cover no-repeat`,
              }}
            >
              <Upload
                name="avatar"
                showUploadList={false}
                customRequest={(options: any) =>
                  this.uploadImage(options, "avatar")
                }
                beforeUpload={this.beforeUpload}
              >
                <img
                  src={Editicon}
                  style={webStyle.edituploadimage}
                  onClick={this.handleIconClick}
                  data-test-id="editplaylistaudio"
                />
                <Typography style={webStyle.changeImagetext}>
                  Change Image
                </Typography>
              </Upload>
            </Box>

            <Box style={webStyle.editinputbuttonbox}>
              <Box>
                <Box>
                  <Typography style={webStyle.splittext}>
                    Update Playlist Name
                  </Typography>
                </Box>
                <Plyalistinputboxwrapper>
                  <Input
                    prefix=""
                    placeholder="Enter Playlist Name"
                    value={this.state.playlistName}
                    onChange={(e: any) => {
                      this.setState({ playlistName: e.target.value });
                    }}
                    // className="playlist_input"
                    data-test-id="playlist-enter"
                  />
                </Plyalistinputboxwrapper>
              </Box>

              <Box style={webStyle.buttonwrapper}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    style={
                      this.state.activateTab == "Private"
                        ? webStyle.privatebutton
                        : webStyle.publicbutton
                    }
                    data-test-id="playlist-activate-tab"
                    onClick={() => this.setActivateTab("Private")}
                  >
                    <span className="audio-text">Private</span>
                  </Button>
                  <Button
                    style={
                      this.state.activateTab == "Public"
                        ? webStyle.privatebutton
                        : webStyle.publicbutton
                    }
                    data-test-id="playlist-public-tab"
                    onClick={() => this.setActivateTab("Public")}
                  >
                    <span className="audio-text">Public</span>
                  </Button>
                </Box>
                <Box style={{ width: "100%", display: "flex", gap: "30px" }}>
                  <Button
                    style={{
                      borderRadius: "53px",
                      width: "50%",
                      border: "3px solid #FF0501",
                      background: "#FF0501",
                      color: "white",
                    }}
                    data-test-id="playlist-onSave"
                    onClick={() => this.onSave()}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      borderRadius: "53px",
                      width: "50%",
                      border: "3px solid #212121",
                      background: "#141414",
                      color: "white",
                    }}
                    onClick={()=>{
                      this.props.showDetailsPlaylistOpen("playist")
                    }}
                    data-test-id="playlist-cancel"

                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Singleplaylistrightsideboxwrap>
        </SinglePlaylistRightideboxwrapper>
      </MainWrapper>

      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  editinputbuttonbox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px",
  },
  imageborder: {
    width: "100%",
    height: "94px",
    border: "0px solid #000",
    // borderRadius: "12px",
    padding: "20px",
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
  statusbutton: {
    border: "1px solid #FF0501",
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "30px",
    background: "#000",
    color: "#FF0501",
    fontSize: "16px",
    fontWeight: 700,
  },
  changeImagetext: {
    color: "#8F92A1",
    // textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    position: "absolute" as "absolute",
    left: "37%",
    bottom: "33%",
    whiteSpace: "nowrap" as "nowrap",
  },
  edituploadimage: {
    position: "absolute" as "absolute",
    left: "44%",
    bottom: "45%",
    border: "2px solid white",
    borderRadius: "60px",
  },
  buttonwrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "15px",
    borderRadius: "53px",
    border: "3px solid #212121",
    background: "#070707",
    height: "53px",
    padding: "5px",
  },
  imagecover: {
    width: "75px",
    height: "75px",
    borderRadius: "10px",
    border: "1px solid #141414",
  },

  videoImagetex: {
    left: "4%",
    lineHeight: "20px",
    position: "relative" as "relative",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    top: "-22%",
  },

  singleArtinfo: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    gap: "10px",
  },
  leftsideAlltracksbox: {
    width: "84%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    // marginTop: "10px"
  },
  editleftSideSingletrackbox: {
    // marginTop: '6px',
    height: "75px",
    padding: "0px 14px 0px 0px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  editleftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },

  audiovideobutton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#ffff",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "60px",
  },
  bluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },

  splittext: {
    color: "#8F92A1",

    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
  },
  privatebutton: {
    width: "166px",
    height: "37px",
    // flexShrink: "0",
    borderRadius: "60px",
    background: "#3959F9",
    color: "white",
  },
  publicbutton: {
    width: "166px",
    height: "37px",
    // flexShrink: "0",
    borderRadius: "60px",
    // background: "#3959F9",
    color: "white",
  },

  singleplaylistrightsidebox: {
    width: "80%",
    display: "flex",
    gap: "30px",
    flexDirection: "column" as "column",
  },
  singleplaylistrightsidevideobox: {
    // width: "100%",
    // position: "relative" as "relative",
    width: "349px",
    height: "349px",
    background: "linear-gradient(180deg, rgba(7, 7, 7, 0.00) 0%, #070707 100%)",
  },
  singleplaylistrightsidevideoimage: {
    width: "349px",
    height: "349px",
  },
};
// Customizable Area End
