// Customizable Area Start
import { Row } from "antd";
import React from "react";
import CtrlplaylistController from "./CtrlplaylistController.web";
import { defaultProfile, nxzloader } from "blocks/dashboard/src/assets";
import Editplaylist from "./EditPlaylist.web";
import PlaylistDetailsvideo from "./PlaylistDetailsvideo.web";
import MessageLinkPopup from "components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import DeleteTracks from "../Tracks/DeleteTracks.web";
import AudioVideoPlaylist from "./AudioVideoPlaylist.web";
import SinglePlaylistDetails from "./SinglePlaylistDetails.web";
import EditplaylistVideo from "./EditPlaylistVideo.web";

export default class Ctrlplaylist extends CtrlplaylistController {

  singleplaylistdeatils = () => {
    const { playlist_details_data } = this.state;
    let content;

    if (this.state.detailsLoading) {
      content = (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
          id="singlePlaylist"
        >
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      );
    } else if (this.state.selectedTab === "audio") {
      content = (
        <SinglePlaylistDetails
          webStyle={webStyle}
          selectedTab={this.state.selectedTab}
          playlistDetailsData={this.state.playlistDetailsData}
          selectedTrack={this.selectedTrack}
          playlist_details_data={playlist_details_data}
          formatNumber={this.formatNumber}
          status={this.state.status}
          follow_playlist={this.state.follow_playlist}
          followPlaylist={() => this.followPlaylist(this.state.playlistDetailsData.id)}
          followUserStatus={() => this.followUserStatus(this.state.playlist_details_data.attributes.artist_id)}
          followUser={this.state.followUserStatus}
          linkopenPopup={this.linkopenPopup}
          handleRepost={this.handleRepost}
          showSingleplaylistdeatils={this.showEditPlaylistOpen}
          selectedsong={this.selectedsong}
          showEditPlaylist={this.showEditPlaylistOpen}
          showDeletePlaylist={this.showDeletePlaylistOpen}
          topPlayMusic={this.props.topPlayMusic}
          is_invite={this.state.is_invite_user}
          accessList={this.state.accessList}
      accessAvatarList={this.state.accessAvatarList}
      accessListLoader={this.state.accessListLoader}
      showAccessListView={this.state.showAccessListView}
      setAccessListView={this.showAccessListData}
      accessListPlayList={(trackId:number)=>this.accessListTrack(trackId)}
        />
      );
    } else {
      content = (
        <PlaylistDetailsvideo
          webStyle={webStyle}
          selectedTab={this.state.selectedTab}
          playlistDetailsData={this.state.playlistDetailsData}
          selectedTrack={this.selectedTrack}
          playlist_details_data={playlist_details_data}
          formatNumber={this.formatNumber}
          status={this.state.status}
          follow_playlist={this.state.follow_playlist}
          followUser={this.state.followUserStatus}
          followPlaylist={() => this.followPlaylist(this.state.playlistDetailsData.id)}
          followUserStatus={() => this.followUserStatus(this.state.playlistDetailsData.id)}
          linkopenPopup={this.linkopenPopup}
          handleRepost={this.handleRepost}
          showSingleplaylistdeatils={this.showEditPlaylistVideoOpen}
          selectedsong={this.selectedsong}
          showEditPlaylist={this.showEditPlaylistVideoOpen}
          showDeletePlaylist={this.showDeletePlaylistOpen}
          topPlayMusic={this.props.topPlayMusic}
          is_invite={this.state.is_invite_user}
          accessList={this.state.accessList}
      accessAvatarList={this.state.accessAvatarList}
      accessListLoader={this.state.accessListLoader}
      showAccessListView={this.state.showAccessListView}
      setAccessListView={this.showAccessListData}
      accessListPlayList={(trackId:number)=>this.accessListTrack(trackId)}


        />
      );
    }
    return (
      <>{content}</>
    );
  };


  audiovideoplaylist = () => {
    return (
      <>
        {this.state.loading ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
            id="audioVideoPlaylist"
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <AudioVideoPlaylist
            searchFilterFunction={this.searchFilterFunction}
            webStyle={webStyle}
            playList={this.state.playList}
            getStatusDetails={this.getStatusDetails}
            selectedsong={this.selectedsong}
            selectedTab={this.state.selectedTab}
            getAllPlaylist={this.getAllPlaylist}
            setSelectedTabAudio={() => this.setState({ selectedTab: "audio" })}
            setSelectedTabVideo={() => this.setState({ selectedTab: "video" })}
            top40playList={this.state.top40playList}
            openPlaylistScreen={this.openPlaylistScreen}
          />
        )}
      </>
    );
  };

  componentrender = () => {
    switch (this.props.showSingleplaylistdeatils) {
      case "playlist":
        return this.audiovideoplaylist();
      case "details":
        return this.singleplaylistdeatils();
      case "edit":
        return (
          <Editplaylist
            data={this.state.playlistDetailsData}
            selectedTrack={this.selectedTrack}
            playlist_details_data={this.state.playlist_details_data}
            showDetailsPlaylistOpen={(str: any) =>
              this.showDetailsPlaylistOpen(str)
            }
            getAllPlaylist={() => this.getAllPlaylist(this.state.selectedTab)}
          />
        );
      case "editvideo":
        return (
          <EditplaylistVideo
            data={this.state.playlistDetailsData}
            selectedTrack={this.selectedTrack}
            playlist_details_data={this.state.playlist_details_data}
            showDetailsPlaylistOpen={(str: any) =>
              this.showDetailsPlaylistOpen(str)
            }
            getAllPlaylist={() => this.getAllPlaylist(this.state.selectedTab)}
          />
        );
      default:
        return this.audiovideoplaylist();
    }
  };

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        {this.componentrender()}
        <MessageLinkPopup
          id="Playlist"
          showMsgModal={this.state.openMessageLink}
          closeMsgModal={() => this.setState({ openMessageLink: false })}
          closeModalDialog={() => this.setState({ openMessageLink: false })}
          copiedLinked={this.state.CopiedForLink}
          currentUserId={1}
        />

        <RepostPopup
          open={this.state.openPopUp}
          onClose={this.handleRepostClose}
          profileImage={
            this.state.playlist_details_data?.attributes?.art_work ||
            defaultProfile
          }
          artistName={this.state.playlist_details_data?.attributes?.artist_name}
          songName={this.state.playlist_details_data?.attributes?.title}
          repost_count={this.respostSubmit}
          _handleTextFieldChange={this.handleCaptionChange}
        />
        <DeleteTracks
          title={"Delete Playlist"}
          subTitle={" Are you sure you want to delete this playlist?"}
          isOpen={this.state.isDeleteModelV}
          handleDMClose={() => {
            this.setState({ isDeleteModelV: false });
          }}
          handleDelete={() => this.onDeletePlaylist()}
        />
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  tracksbutton: {
    borderRadius: "30px",
    backgroundColor: "#070707",
    display: "flex",
    justifyContent: "space-around",
    padding: "2px 0 2px 0",
    border: "3px solid #212121",
  },
  iconsboxwrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  iconsboxnotallowed: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "5px",
    cursor: "not-allowed",
  },
  weekdaysbox: {
    display: "flex",
    background: "#141414",
    borderRadius: "5px",
  },
  statusbutton: {
    border: "1px solid #FF0501",
    background: "#000",
    color: "#FF0501",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "30px",
  },
  leftsideimage: {
    border: "1px solid #141414",
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
  },
  edittext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
  },
  followbutton: {
    borderRadius: "53px",
    border: "3px solid #212121",
    background: "#3959F9",
    width: "100%",
    padding: "0px 118px",
    height: "60px",
    color: "#F0F0F5",
    // textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "16px",
    textTransform: "capitalize" as "capitalize",
  },
  unfollowbutton: {
    borderRadius: "53px",
    border: "3px solid #212121",
    background: "#FF0501",
    width: "100%",
    padding: "0px 118px",
    height: "60px",
    color: "#F0F0F5",
    // textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "16px",
    textTransform: "capitalize" as "capitalize",
  },
  minustext: {
    color: "#F00",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },
  plus: {
    color: "#3959F9",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },
  playtext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
  },
  totalreleasetext: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    color: "rgba(255, 255, 255, 0.50)",
  },
  weeknametext: {
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 700,
    color: "#FFF",
    // line-height: 16px
    marginLeft: "10px",
  },
  singledaybox: {
    display: "flex",
    justifyContent: "center",
    width: "14.2857%",
  },
  statusbuttonbox: {
    width: "100%",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
  },
  videoImagewrapper: {
    position: "relative" as "relative",
    // width: "332.848px",
    // height: "205px" ,
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
  userimage: {
    marginLeft: "-10px",
    width: "35px",
    height: "35px",
  },
  avatartAccessImg: {
    width: "35px",
    height: "35px",
    marginLeft: "-10px",
  },
  statustext: {
    color: "#8F92A1",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
  },
  audiocard: {
    borderRadius: "10px",
    width: "75px",
    minWidth: "75px",
    height: "75px",
  },
  videocard: {
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
    border: "1px solid #141414",
  },
  audioimagetext: {
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    // whiteSpace:"nowrap" as "nowrap"
  },
  audioartistext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    // wwhiteSpace:"nowrap" as "nowrap"
  },
  videoImagetex: {
    left: "4%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    position: "relative" as "relative",
    top: "-22%",
  },
  singlevideoimagetext: {
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    // position: "relative" as "relative",
    // top: "-22%",
    // left: "4%",
  },
  singlevideoartisttext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    // position: "relative" as "relative",
    // left: "4%",
    // top: "-22%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  videoArtisttext: {
    fontWeight: 700,
    fontSize: "14px",
    // position: "relative" as "relative",
    left: "4%",
    top: "-22%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#8f92a1",
  },

  singleArtinfo: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  leftsideAlltracksbox: {
    width: "84%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    // marginTop: "10px"
  },
  leftSideSingletrackbox: {
    marginTop: "6px",
    height: "86px",
    padding: "0px 14px 0px 8px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
  },
  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  editimage: {
    borderRadius: "120px",
    border: "2px solid #141414",
    width: "60px",
    height: "60px",
  },

  singlebutton: {
    border: "1px solid blue",
    borderRadius: "20px",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  searchimage: {
    height: "18px",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
    right: "6%",
  },

  tracksimage: {
    height: "148px",
    width: "100%",
    borderRadius: "12px",
  },
  percentagetext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
  },
  audiovideobutton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#ffff",
    borderRadius: "60px",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
  },
  bluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },
  activeaudiovideobutton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#ffff",
    borderRadius: "60px",
    width: "100%",
    background: "#274af4",
    textTransform: "capitalize" as "capitalize",
  },
  splittext: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
    whiteSpace: "nowrap" as "nowrap",
  },
  singlePlaylistDetailswrapper: {
    width: "100%",
    display: "flex",
    paddingBottom: "15px",
    gap: "10px",
  },
  singlePlaylistleftsidefirstbox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 41px 0px 20px",
  },
  weekpercentbox: {
    display: "flex",
    justifyContent: "space-between",
    gap: "64px",
  },
  percenttext: {
    color: "#8F92A1",
    fontFamily: "Inter",
    fontSsize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "30px",
  },
  allplayshareboxwrapper: {
    display: "flex",
    gap: "35px",
    alignItems: "center",
    // width:"60%"
  },
  allplaysharechildbox: {
    display: "flex",
    gap: "30px",
  },
  allplaystatselement: {
    display: "flex",
    gap: "9%",
    alignItems: "center",
    minWidth: "3rem",
  },
  allplaystext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
  },

  singleplaylistrightsidebox: {
    width: "80%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px",
  },
  singletrackrightsidebox:{
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px",
    width:"100%"
  },
  singleplaylistrightsidevideobox: {
    width: "100%",
    position: "relative" as "relative",
  },
  singleplaylistrightsidevideoimage: {
    width: "100%",
    height: "349px",
  },
  followrepostbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  imageborder: {
    width: "335.152px",
    height: "76.275px",
    border: "0px solid #000",
    borderRadius: "8px",
    padding: "20px",
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
};
// Customizable Area End
