// Customizable Area Start
import React from "react";
import DealController, {
    Props,
} from "./DealController.web";
import "./Deal.web.css";
import AsideLeft from "../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import { Layout } from "antd";
import { Hidden,Typography,Grid, Button, Avatar} from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import DealHeader from "./DealHeader.web";

import Loader from "./Loader.web";
import TrendingList from "./TrendingList.web"
import HomeHeader from "../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import { AudioContextApi } from '../AudioContext/AudioContext.web'; 



import {  CircledRight, MusicCover, PlaycirclefilledDeal, RectangleDealUser, VideoCover, VideoUserAvatar} from '../assets'

// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class Deal extends DealController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   setCoverImage= (item: any) =>{
     if(item.name == "Music Split"){
        
        return(
            <>
            <Grid style={{ position: 'relative', width: '111px', height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={this.state.AudioVideoDetails?.art_work} className='Image' style={{width: '100%', height: '100%'}}/>
              <img src={PlaycirclefilledDeal} style={{position: 'absolute', width: '30px', height: '30px'}} />
            </Grid>
            </>
        )
     }else if(item.name == "Video Split"){
       
        return(
            <>
            <Grid style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
              <Grid style={{ position: 'relative', width: '209px', height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <img src={this.state.AudioVideoDetails?.art_work} className='Image'  style={{width: '100%', height: '100%'}}/>
               <img src={PlaycirclefilledDeal} style={{position: 'absolute', width: '31px', height: '31px'}} />
              </Grid>
            </Grid>  
            </>
        )
     }else if(item.name == "User Split"){
        return(
            <>
            <Grid style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <Grid className="UserTypeDealDetails">
                    <Grid>
                        <Avatar src={this.state.AudioVideoDetails?.artist_image || this.state.AudioVideoDetails?.avatar} className="Avatar" />
                        <Typography className="SplitPercentageText">
                            User
                        </Typography>
                    </Grid>
                    <Grid className="MainLineBox">
                        <Grid className="ChildBox">
                            <Grid className="MainLine">
                                <Avatar src={CircledRight} className="Avatar" />
                            </Grid>
                        </Grid>
                        <Grid className="SplitDurationBox">
                            <Typography className="PVCommonText DurationText">FOR TERM</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Avatar src={this.state.LoggedInUserDetails?.attributes?.avatar} className="Avatar" />
                        <Typography className="SplitPercentageText">
                            You
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            </>
        )
     }

   }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, top20Creatives, top20Labels ,allDealtype} = this.state;
        const {isPlaylist} = this.context;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="DealContent" style={{height: isPlaylist ? '75vh' : '90vh' }}>
                                <DealHeader />
                                <Grid container>
            <Grid item xs={12}>
                <Typography className='DealTypeTitle'>Select Deal Type</Typography>
            </Grid>
            <Grid container spacing={1} >
            <Grid container item xs={12} spacing={3} className='DealTypeCardGrid' >
            {allDealtype?.map((item,i)=>
                <Grid className="DealTypeCard">
                    <div className='TextDiv'>
                        <Typography className='Title'>{item.name}</Typography>
                        <Typography className='SplitText'>SPLIT</Typography>
                        <Typography className='Description'>Buy ownership license in this <b>{this.setNameType(item)}</b> and monetize as it makes royalties</Typography>
                    </div>
                    <div className='MediaDiv'>
                        {this.setCoverImage(item)}
                    </div>
                    <div className='OfferButtonDiv'>
                        <Button data-test-id="MakeAudioOfferBtn1" className='Button'  onClick={()=>{this.onClickAudioOffer(item.id,item.name)}}>
                            Make An Offer
                        </Button>
                    </div>
                </Grid>
                
          )}
              </Grid> 
            </Grid>
         </Grid>
                                <Grid container className="TLContainer">
                                    <Grid item xs={12} md={6}>
                                        <TrendingList title={"Trending 20 Creatives"} list={top20Creatives} type="Creative" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TrendingList title={"Trending 20 Labels"} list={top20Labels} type="Label"/>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Content>
                </Layout>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default Deal;
Deal.contextType = AudioContextApi;
// Customizable Area End