// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import RightSideSplitDetailsController from "./RightSideSplitDetailsController";
import "./splits.css";
import { styled } from "@material-ui/styles";
import { playButton } from "../../../../dashboard/src/assets";

const VideolistboxRightside = styled(Box)({
  width: "fit-content",
  display: "flex",
  minWidth:"100%",
  flexDirection: "column" as "column",
  gap: "33px",
  "@media(max-width: 1024px)": {},
});
const RightsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "687px",
  display: "grid",
  scrollbarWidth: "none",
  // flexWrap: "",
  gridTemplateColumns: "repeat(4, 1fr)",
  width: "fit-content",
  gap: "25px",
  "@media(max-width: 1700px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media(max-width: 1550px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 1300px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1024px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  "@media(max-width: 768px)": {
    // justifyContent:"center" ,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 450px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});
const SplitboxRightsidecontainer = styled(Box)({
  width: "51%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "5%",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: "10%",
    gap: "20px",
  },
});
const VideosAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "676px",
  display: "grid",
  overflowX: "hidden",
  scrollbarWidth: "none",
  gridTemplateColumns: "repeat(2, 1fr)",
  width: "fit-content",
  gap: "15px",
  "@media(max-width: 1300px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1024px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media(max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 600px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

export default class RightSideSplitDetails extends RightSideSplitDetailsController {
  constructor(props: any) {
    super(props);
  }
  // Define a function to render the track items
  renderTrackItems = (items: any) => {
    return items.map((item: any, index: any) => (
      <Box key={index}>
        <img
          className="tracks_image"
          src={item.attributes.art_work}
          onClick={() => {
            this.props.topPlayMusic(
              item,
              index,
              this.props.top40SplitsList,
              item.type
            );
          }}
          data-test-id="topPlaylist"
        />
        <Typography style={webStyle.audioimagetext}>
          {index + 1}. {item.attributes.title}
        </Typography>
        <Typography style={webStyle.audioartistext}>
          {item.attributes.user_role}
        </Typography>
      </Box>
    ));
  };

  // Define a function to render the video items
  renderVideoItems = (items: any) => {
    return items.map((item: any, index: any) => (
      <VideosAlltracksboxwrapper>
        {this.props.top40SplitsList?.length > 0 ? (
          <Box key={this.props.index} style={webStyle.videoImagewrapper}>
            <img
              src={item.attributes.art_work}
              style={{
                height: "193px",
                width: "332.848px",
                borderRadius: "8px",
              }}
              onClick={() => this.redirectVideo(item)}
            />
            <img
              src={playButton}
              style={{
                height: "49px",
                width: "49px",
                position: "absolute",
                right: "45%",
                top: "30%",
              }}
            />
            <Typography style={webStyle.rightsidesingertext}>
              {this.props.index + 1}. {item.attributes.title}
            </Typography>
            <Typography style={webStyle.rightsideaudioartisttext}>
              {item.attributes.user_role}
            </Typography>
          </Box>
        ) : (
          <Typography
            style={{
              // marginTop: "10%",
              paddingTop: "72px",
            }}
            className="no_data"
          >
            Data Not Found.
          </Typography>
        )}
      </VideosAlltracksboxwrapper>
    ));
  };

  render() {
    const { top40SplitsList,index } = this.props;
    let content;
    if (top40SplitsList?.length > 0) {
      if (index !== "Videos") {
        content = (
          <RightsideAlltracksboxwrapper>
            {this.renderTrackItems(top40SplitsList)}
          </RightsideAlltracksboxwrapper>
        );
      } else {
        content = this.renderVideoItems(top40SplitsList);
      }
    } else {
      content = (
        <Typography
          style={{
            // marginTop: "10%",
            paddingTop: "72px",
          }}
          className="no_data"
        >
          Data Not Found.
        </Typography>
      );
    }
    return (
      <SplitboxRightsidecontainer>
        <VideolistboxRightside>
          <Box style={{ width: "100%" }}>
            <Box style={webStyle.tracksbutton}>
              <Box>
                <Button
                  data-test-id="states"
                  style={{
                    background: this.props.index == "Tracks" ? "#274af4" : "",
                  }}
                  onClick={this.props.changetabs.bind(this, "Tracks")}
                  className="stats_tab"
                >
                  Tracks
                </Button>
              </Box>

              <Box>
                <Button
                  className="stats_tab"
                  style={{
                    background: this.props.index == "Songs" ? "#274af4" : "",
                  }}
                  onClick={this.props.changetabs.bind(this, "Songs")}
                  data-test-id="cities"
                >
                  Songs
                </Button>
              </Box>
              <Box>
                <Button
                  style={{
                    background: this.props.index == "Videos" ? "#274af4" : "",
                  }}
                  className="stats_tab"
                  onClick={this.props.changetabs.bind(this, "Videos")}
                  data-test-id="listeners"
                >
                  Videos
                </Button>
              </Box>
            </Box>
          </Box>

          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={webStyle.trackname}>
              <span style={webStyle.bluetext}>Top 40</span> {this.props.index}
            </Typography>
            <Typography style={webStyle.splittext}>This week</Typography>
          </Box>
          <Box style={{ width: "100%", marginTop: "-12px" }}>
            {content}
          </Box>
        </VideolistboxRightside>
      </SplitboxRightsidecontainer>
    );
  }
}

const webStyle = {
  rightsidesingertext: {
    left: "4%",
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    position: "relative" as "relative",
    top: "-22%",
    maxWidth: "170px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap" as 'nowrap' 
  },
  rightsideaudioartisttext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    position: "relative" as "relative",
    left: "4%",
    top: "-22%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  videoImagewrapper: {
    position: "relative" as "relative",
    // width: "332.848px",
    height: "205px",
    background:
      "linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)",
  },
  tracksbutton: {
    borderRadius: "20px",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
  },
  leftsideAlltracksbox: {
    width: "100%",
    // height:"80vh"
    marginTop: "-18px",
  },
  audioimagetext: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    marginTop: "8px",
    color: "#F0F0F5",
    maxWidth: "150px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap" as 'nowrap' 
  },
  audioartistext: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    color: "#8f92a1",
  },

  trackname: {
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "Inter",
  },

  splittext: {
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontSize: "16px",
    fontFamily: "Inter",
  },
  tracksimage: {
    height: "148px",
    width: "148px",
    // width:"100%",
    borderRadius: "12px",
  },

  bluetext: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
    fontSize: "22px",
  },
};

// Customizable Area End
