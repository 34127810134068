// Customizable Area Start
import { Input, Row } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {styled } from "@material-ui/styles";
import SongsController from "./SongsController.web";
import { searchshape } from "../../../../dashboard/src/assets";
import TrackDetails from "../TrackDetails.web";
import { defaultImage, nxzloader } from "blocks/dashboard/src/assets";

const SongsWrapper = styled(Box)({
  display: "flex",
  padding: "30px 50px 0px 27px",
  minHeight:"calc(100% - 3rem)",
  gap: "1%",
  justifyContent: "space-between",
  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 10px",
    gap: "0%",
    minHeight:"0px"
  },
});
const Trackname = styled(Box)({
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "20px",
  letterSpacing: "normal",
  whiteSpace: "nowrap" as "nowrap",
  color: "#ffffff",
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: "22px",
  "@media(max-width: 400px)": {
    // whiteSpace: "unset",
    fontSize: "14px"
  },
});
const LeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "693px",
  scrollbarWidth: "none",
  marginTop: "-20px",
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});
const Borderbox = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});
const LeftSideSingletrackbox = styled(Box)({
  // marginTop: '6px',
  height: "95px",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  objectFit: "contain",
  color: "white",
  cursor: "pointer",
  "&:hover": {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    objectFit: "contain",
    background: "#141414",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
  },
});
const SongsboxRightside = styled(Box)({
  width: "fit-content",
  height: "50vh",
  display: "flex",


  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    // width:"auto",
    // height:"auto",
    // marginTop:" 22%",
    // gap:"20px"
  },
});

const Songsleftsidecontainer = styled(Box)({
  width: "46%",
  // height: "50vh",
  height: "max-content",
  flexDirection: "column" as "column",
  gap: "20px",
  display: "flex",
  marginBottom: "10px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "20px",
    marginTop: "12px",
  },
});

const SongsboxRightsidecontainer = styled(Box)({
  width: "47%",
  height: "50vh",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width:  1024px)": {
    width: "auto",
    height: "auto",
    marginTop: " 5%",
    // alignItems: "center",
    gap: "20px",
  },
});

const TotalSplits = styled(Box)({
  width: "100%",
  // height: "10vh",
  color: "white",
  "@media(max-width:  1024px)": {
    height: "auto",
  },
});
const Inputboxwrapper = styled(Box)({
  borderRadius: "27px",
  padding: "8px",
  width: "100%",
  position: "relative",
  background: "#212121",
  "& .ant-input": {
    backgroundColor: "#212121",
    width: "88%",
    color: "white",
  },
  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
  },
  "@media(max-width:  1024px)": {
    width: "100%",
    "& .ant-input": {
      backgroundColor: "#212121",
      width: "50%",
    },
  },
});
const TrackBoxfordtails = styled(Box)({
  width: "47%",
  // height: "50vh",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  gap: "20px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: " 5%",
    gap: "20px",

  },
});

const RightsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "810px",
  display: "grid",
  // flexWrap: "",
  gridTemplateColumns: "repeat(4, 1fr)",
  scrollbarWidth: "none",
  width: "fit-content",
  gap: "25px",
  "@media(max-width: 1700px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media(max-width: 1550px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 1300px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1024px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  "@media(max-width: 768px)": {
    // justifyContent:"center" ,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 450px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});
const LeftsideAlltracksbox = styled(Box)({
  width: "100%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});

export default class Songs extends SongsController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      loading,
      searchText,
      top40songList,
      songList,
      songDetailsId,
    } = this.state;
    const { topPlayMusic, getStatusDetails } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        {loading ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <SongsWrapper>
            <Songsleftsidecontainer>
              <Inputboxwrapper>
                <Input
                  test-id="test_search_term_id_handle_search_input"
                  className=""
                  onChange={(text) => this.searchFilterFunction(text)}
                  prefix={
                    //@ts-ignore
                    <SearchOutlined
                    
                      className="text_white1"
                      value={searchText}
                    />
                  }
                  style={webStyle.inputbox}
                  placeholder="Search Songs"
                />
                <img src={searchshape} style={webStyle.songsearchicon} />
              </Inputboxwrapper>
              <TotalSplits>
                <Typography style={webStyle.trackname}>
                  <span style={webStyle.bluetext}>{top40songList?.length}</span>{" "}
                  Songs
                </Typography>
              </TotalSplits>
              <LeftsideAlltracksbox>

                <LeftsideAlltracksboxwrapper>
                  {songList?.length > 0 ? (
                    songList.map((item: any, index: any) => {
                      const { typeColor, status } = getStatusDetails(item, "tracks");
                      return (
                        <LeftSideSingletrackbox
                          onClick={this.selectedsong.bind(this, index, item.id)}
                          style={
                            index === this.state.selectedIndex
                              ? webStyle.leftSideSingletrackbox
                              : {}
                          }
                        >
                          <Box style={webStyle.singleArtinfo}>
                            <img
                              src={item.attributes.art_work || defaultImage}
                              style={webStyle.leftsidesongimage}

                            />

                            <Box style={webStyle.leftsideownerstats}>
                              <Trackname className="tracks_songs_text">
                                {item.attributes.title}
                              </Trackname>
                              <span style={webStyle.splittext}>
                                {item.attributes.user_role}
                              </span>
                            </Box>
                          </Box>
                          <Box style={webStyle.leftsideownerstats}>
                            <Box>
                              <Button
                                style={{
                                  border: `1px solid ${typeColor}`,
                                  borderRadius: "20px",
                                  color: typeColor,
                                  height: "23px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {status}
                              </Button>
                            </Box>
                          </Box>
                        </LeftSideSingletrackbox>
                      );
                    })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#fff",
                        padding: "5",
                        justifyContent: "center",
                        marginTop:"10%"
                      }}
                    >
                      Data Not Found.
                    </Typography>
                  )}
                </LeftsideAlltracksboxwrapper>
              </LeftsideAlltracksbox>
            </Songsleftsidecontainer>
            <Borderbox>
              <hr
                style={{
                  height: "100%",
                  width: "1px",
                  border: "1px solid #212121",
                }}
              />
            </Borderbox>

            {this.state.showdetails ? (
              <TrackBoxfordtails>
                <TrackDetails
                  navigation={undefined}
                  id={""}
                  trackDetails={songDetailsId}
                  updateData={() => this.getSongs()}
                  topPlayMusic={topPlayMusic}
                  index={"song"}
                  setShowDetails={()=>this.setState({showdetails:false})}

                />
              </TrackBoxfordtails>

            ) : (
              <>
                {top40songList?.length > 0 ? (
                  <SongsboxRightsidecontainer>
                    <SongsboxRightside>
                      {" "}
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <Typography style={webStyle.trackname}>
                          <span style={webStyle.bluetext}>
                            Top {top40songList?.length}
                          </span>{" "}
                          Songs
                        </Typography>
                        <Typography style={webStyle.splittext}>
                          This week
                        </Typography>
                      </Box>
                      <Box style={{ width: "100%" }}>

                        <RightsideAlltracksboxwrapper>
                          {top40songList.map((item: any, index: any) => (
                            <Box
                              key={item.id || index}
                              style={{ height: "100%", cursor: "pointer" }}
                            >
                              <img
                                src={item.attributes.art_work || defaultImage}
                                alt={`Artwork for ${item.attributes.title}`}
                                className="tracks_image"
                                onClick={() => topPlayMusic(item, index, top40songList, "song")}
                              />
                              <Typography style={webStyle.audiotracktitle}>
                                {index + 1}. {item.attributes.title}
                              </Typography>
                              <Typography style={webStyle.audioartisttitle}>
                                {item.attributes.user_role}
                              </Typography>
                            </Box>
                          ))}
                        </RightsideAlltracksboxwrapper>
                      </Box>
                    </SongsboxRightside>
                  </SongsboxRightsidecontainer>
                ) : (
                  <SongsboxRightsidecontainer>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={webStyle.trackname}>
                        <span style={webStyle.bluetext}>
                          Top {top40songList?.length}
                        </span>{" "}
                        Songs
                      </Typography>
                      <Typography style={webStyle.splittext}>
                        This week
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        padding: "5",
                        marginTop:"10%",
                        paddingTop:"61px"
                      }}
                      className="no_data"
                    >
                      No Data Found.
                    </Typography>
                  </SongsboxRightsidecontainer>

                )}


              </>
            )}

          </SongsWrapper>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

const webStyle = {
  leftsideAlltracksbox: {
    width: "85%",
  },
  leftsideAlltracksboxwrapper: {
    overflowY: "scroll" as "scroll",
    height: "693px",
    marginTop: "-20px",
  },
  songsearchicon: {
    height: "18px",
    width: "21px",
    position: "absolute" as "absolute",
    top: "31%",
    right: "6%",
  },
  leftsidesongimage: {
    borderRadius: "10px",
    width: "75px",
    minWidth: "75px",
    height: "75px"
  },
  inputbox: {
    borderRadius: "27px",
    padding: "10px",
    width: "100%",
  },
  leftSideSingletrackbox: {
    height: "95px",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
    color: "white",
    background: "#141414",
  },
  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
  },
  singleArtinfo: {
    display: "flex",
    gap: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  tracksbutton: {
    borderRadius: "20px",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px",
  },
  audiotracktitle: {
    color: "#F0F0F5",
    marginTop: "12px",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Karla",
    lineHeight: "20px",
    maxWidth: "150px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap" as 'nowrap' 
  },
  audioartisttitle: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#8f92a1",
    fontFamily: "Karla",
    lineHeight: "20px",
    whiteSpace:"nowrap" as 'nowrap'

  },

  songsinlgebutton: {
    border: "1px solid red",
    borderRadius: "20px",
    color: "red",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  singlebutton: {
    border: "1px solid blue",
    borderRadius: "20px",
    color: "blue",
    height: "23px",
    textTransform: "capitalize" as "capitalize",
  },
  bluetext: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",

    fontWeight: 700,
    fontSize: "22px",
    letterSpacing: "normal",
    color: "#3959f9",
  },
  splittext: {
    fontWeight: 700,
    fontStretch: "normal",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
    whiteSpace:"nowrap" as 'nowrap'
  },
  leftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  songtracksimage: {
    // height:"80%",
    height: "148px",
    width: "100%",
    borderRadius: "12px"
  }

}
// Customizable Area End
