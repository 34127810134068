// Customizable Area Start
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import Comment from '../Common/Comment.web';
import CommentSendInput from '../Common/CommentSendInput.web';
import { dialogStyles, SlideTransition } from '../Common/Common.web';
// Customizable Area End


// Customizable Area Start
interface Props {
    isOpen: boolean;
    commentMedia: boolean;
    replies: any;
    selectedComment: any;
    closeCommentChat: () => void;
    sendComment: (mediaId: any) => void;
    selectedComentReply: (comment: any) => void;
    selectedCommentForReply: (comment: any) => void;
    closeCommentReply: () => void;
    newcomment: any;
    sendNewComment: (value: any) => void;
    setOnChangeNewComment: (value: any) => void;
}
// Customizable Area End

// Customizable Area Start
export const CommentChatDialog = (props: Props) => {
    const classes = dialogStyles();
    
    const handleScroll = () =>{
        const element = document.getElementById('scroll-new-comment-dialog')
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    return (
        <Dialog fullScreen open={props.isOpen} onClose={props.closeCommentChat} TransitionComponent={SlideTransition} className='CommentChatDialog'>

                 <AppBar className={classes && classes.appBar} style={{position: 'fixed',top: 0}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.closeCommentChat} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes && classes.title}>
                        Comments
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* <Box style={{display: 'flex'}}> */}
            <Box style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'90vh', flex: 1, padding:'0px 8px 15px 8px', marginTop: '65px'}}>
                <Box className='CommentChatBox'>
                    <Box className='CommentChatContainer'>
                      <div id='scroll-new-comment-dialog'></div>
                       {
                        props.selectedComment.map((comments: any, index: any) =>{
                            return(
                                <Comment selected={false} selectedComment={comments} unread={true} isVideo={true} onUserCommentClick={() => { }} onUserProfileClick={() => { }} selectedComentReply={(comment: any) => {props.selectedComentReply(comment)}} index ={index}/>
                            )
                        })
                       }       
                    </Box>
                    
                </Box >
            </Box>
            <Box className='CommentChatInput' style={{position: 'fixed',bottom: 5, backgroundColor: 'black',display: 'flex',width: '100%', justifyContent: 'center'}}>
                <CommentSendInput
                    value={props.newcomment}
                    sendComment={(value: string) => { props.sendNewComment(value) }}
                    onValueChange={(value: string) => { props.setOnChangeNewComment(value) }}
                    selectedCommentForReply={props.selectedCommentForReply}
                    closeCommentReply = {props.closeCommentReply}
                />
            </Box>
            {/* </Box> */}
        </Dialog >
    );
}
// Customizable Area End


// Customizable Area Start
export default CommentChatDialog;
// Customizable Area End