// Customizable Area Start
import React from "react";
import { findDuration } from "./ChatUtils";
import { defaultProfile } from "../../dashboard/src/assets";


interface ITextMsgCard {
    isRecived: boolean,
    avtar: string,
    index: number,
    senderName: string,
    timetoken: string,
    text: string,
    particularUserDataRes:any,
    onRedirect : (currentuserType: any,currentUserId:any)=>void;
}

function TextMsgCard(props: ITextMsgCard) {
    const { isRecived, avtar, timetoken, text,onRedirect,particularUserDataRes } = props
    const user_id: any = localStorage.getItem("loggedIn_userId")
    const user_type: any = localStorage.getItem("user_type")
    let currentuserType=isRecived?particularUserDataRes?.attributes?.artist_role:user_type
    let currentUserId=isRecived?particularUserDataRes?.id:user_id
    return (
        <>
            {isRecived === false ?

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
                        <div className="message margin-vertical-card " style={{ color: "white" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div className="timer ">
                                    <p style={{ fontSize: "10px" }}>{findDuration(parseInt(timetoken) / 10000000)}</p>
                                </div>
                            </div>
                            <p>{text}</p>
                        </div>
                    </div>
                </div>
                :

                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }} >
                        <div>
                        <img className="chatLogo" src={avtar?avtar:defaultProfile} onClick={()=>onRedirect(currentuserType,currentUserId)}/>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                <div className="default-message margin-vertical-card" style={{ color: "white" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div >
                                        </div>
                                        <div className="timer">
                                            <p style={{ fontSize: "10px" }}>{findDuration(parseInt(timetoken) / 10000000)}</p>
                                        </div>
                                    </div>
                                    <p>{text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}



export default TextMsgCard;
// Customizable Area End