// Customizable Area Start
// @ts-nocheck
import React, { useEffect, useRef , useState} from 'react'
import { Grid } from "@material-ui/core";
import {playButton, playedicon, Message, reposticon, playlistMoreTwoDots, defaultProfile } from '../../../../dashboard/src/assets';
import axios from 'axios'
import { HISTORY } from "../../../../../components/src/common";
const urlConfig = require("./../../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

function VideoActivity({ data, time, type, song, creativeById, handleAudioVideo, url,setHide,setHandleAudioVideo, selected, videos, setVideos,setUrl,setSelected, hide }: any) {
const vedioPlayer: any = useRef();

const [videosList, setVideosList] = useState<any>([]);

const pause = () => {
  vedioPlayer?.current?.pause();
  setSelected(null)
  setHide(true)
};

 // videos
  useEffect(() => {
    if (handleAudioVideo !== 1) {
      pause()
      setHandleAudioVideo(handleAudioVideo)

    }
  }, [handleAudioVideo])

  let vid = document.getElementById("myVideo");
  if (vid) {
    vid.onseeking = function (e) {
        e.stopPropagation()
    };
}

  const mediaClick = (e: any, key: any, video_file: any) => {
      e.stopPropagation();
      setHandleAudioVideo(1)
      setVideos(true)
      setUrl(video_file)
      setHide(false)
      setSelected(key)

  }

  useEffect(()=>{
    getTopVideos()
  },[])

  const getTopVideos = async() =>{
    const token = localStorage.getItem('token')
    await axios.get(`${baseUrl}/bx_block_videos/top_videos`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        token: token
      }
    })
      .then((response: any) => {
        setVideosList(response.data.data)
      }).catch((err: any) => {
        console.log(err.response, "eeeeeeeeeeeeeeeee");

      });
    
  }

  

  return (
    <>
      <Grid container spacing={1} className="activity-head-card" >
        <Grid item style={{ width: '100%' }} >
          <Grid style={{ color: 'white', borderColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <img src={creativeById?.attributes?.avatar || defaultProfile} style={{
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%"
                }}

                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <div className='activity-user_name'>
                    {creativeById?.attributes?.user_name || creativeById?.attributes?.full_name}

                  </div>
                  <div className='activity-duration'>{time} {time != "Just Now" && "ago"}</div>
                </div>
              </div>
              <div>
                <img src={type} style={{
                  height: "24px",
                  width: "24px",

                }} />
              </div>
            </div>
            <div className='caption-reposted'>{data?.caption || data?.repost_caption}</div>
            {videos && selected === data.track_id ?
              <div style={{ width: '100%', height: '100%', padding: '10px' }}>
               
                 <video 
                 id={data?.track_id}
                 poster={data?.art_work||data?.artwork}
                 src={url}
                 width="100%"
                 height="100%"
                 controls  
                //  autoPlay
                 style={{ objectFit: "cover", borderRadius: "0px" }}
                 ></video>
              </div>

              :

              <div style={{ display: 'flex', position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', padding: '10px' }}>
                  <img src={data?.artwork || data?.art_work} style={{
                    height: "167px",
                    width: "100%",
                    borderRadius: "12px",
                    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.21), #000)",
                    objectFit: "cover", cursor: "pointer",
                  }} />
                </div>
                <div style={{
                  position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "6px",
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, -10.79), #000)`
                }} onClick={(e) => {
                  mediaClick(e, data.track_id, data.video_file)
                }}>
                  <img
                    src={playButton}
                    style={{ height: '50px',width: '50px', cursor: 'pointer' }}
                  />
                </div>
                <div style={{ position: 'absolute', bottom: '10%', left: '8%' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{data?.title || data?.track_title}</div>
                  <div className='activity-followers'>{data?.track_creative_username || data?.artist_name}</div>
                </div>
                <div className='activity-video-length'>
                  {data?.video_length}
                </div>
              </div>
            }


            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className='activity-three-head'>
                <div className="activity-three-data"><img src={playedicon} /> {data?.play_count}</div>
                <div className="activity-three-data"> <img src={Message} style={{ height: '15px',marginRight:"4px" }} /> {data?.track_comments_count || "0"}</div>
                <div className="activity-three-data"> <img src={reposticon} /> {data?.repost_count || "0"}</div>
              </div>
              <div>
                <img src={playlistMoreTwoDots}
                style={{cursor:"pointer"}}
                onClick={() => {
HISTORY.push({
                    pathname: "/home/Charts/videos",
                    state: { video_list: data.video_file, activeNo: "5" ,currentList: videosList ,data:song}
                  })
                }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default VideoActivity
// Customizable Area End