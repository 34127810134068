// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../../framework/src/RunEngine";

import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { openNotification } from "../../../../components/src/Notification.web";
import axios from 'axios';

export const configJSON = require("../../../dashboard/src/config.js");
const baseUrl = configJSON.baseUrl;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    setBackStep: () => void;
}
interface S {
    token: any;
    loading: any;

    linkedId: any;
    AudioVideoList: any;
    SearchInput: any;
    selectDownload: any;
    selectSubscription: any;
    selectedPlan: any;
    apiData: any
}
interface SS {
    id: any;
}

export default class SellingPreferencesController extends BlockComponent<Props, S, SS> {

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
           
            linkedId: '',
            AudioVideoList: [],
            SearchInput: '',
            selectDownload: false,
            selectSubscription: false,
            selectedPlan: 0,
            apiData: {}
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        const file = await getStorageData('uploaded_file')
        const coverImage = await getStorageData('uploaded_cover_image')
        const trackDetails = await getStorageData("uploaded_track_details")
        const linkTrack = await getStorageData("uploaded_linkedID")
        const editdetails = await getStorageData("edittrackdetails")
        if(editdetails?.attributes?.level && editdetails){
          this.setSubscriptionPlan()
          const str =editdetails?.attributes?.level
          this.setState({selectedPlan:str.charAt(str.length - 1)})
        
        }else if(editdetails){
          this.setDownloadPlan()
        }
      
        this.setState({ apiData: { file, coverImage, trackDetails, linkTrack}})
    }

    
      setDownloadPlan = () =>{
        this.setState({selectDownload: true, selectSubscription: false,selectedPlan:0})
      }
    
       setSubscriptionPlan = () =>{
        this.setState({selectDownload: false, selectSubscription: true})

      }
    
       setPlan =(plan: any) =>{
        console.log("plan",plan)
        this.setState({selectedPlan: plan})
      }

      afterSellingPreferenceNext = async() =>{
        const hello = await getStorageData("edittrackdetails");
        const editpayload= await getStorageData("editPayload")
         if(!this.state.selectDownload && !this.state.selectSubscription){
            openNotification('Please Select Any Option To Upload Your Content', 'error')
        }else if ( this.state.selectSubscription && this.state.selectedPlan == 0){
            openNotification('Please Select Any Level To Lock Your Content', 'error')
        }
        else if(hello && editpayload){
          console.log("edit",editpayload)
          
         this.UpdateTrackdetails(this.state.token,editpayload)
        }
        else{      
            this.UploadTrackDeatils()
        }
      }


      UploadTrackDeatils = async() =>{
         this.setState({loading: true})
         console.log(this.state.apiData, 'track details data')
         const uploadTypeUrl = window.location.href.split('/')[4]

         const formData: any = new FormData();
         if(uploadTypeUrl == 'video'){
            formData.append('data[video_file]', this.state.apiData?.file);
         }else{
            formData.append('data[track_file]', this.state.apiData?.file);
         }
         formData.append('data[art_work]', this.state.apiData?.coverImage);
         if(this.state.apiData?.linkTrack != null){
           formData.append('data[linked_track_id]', this.state.apiData?.linkTrack)
         }

         formData.append('data[title]', this.state.apiData?.trackDetails.title)
         formData.append('data[upload_type_id]', this.state.apiData?.trackDetails.uploadType)
         formData.append('data[status]', this.state.apiData?.trackDetails.uploadStatus)
         if(this.state.apiData?.trackDetails.releaseDate != null){
          const dates = this.state.apiData?.trackDetails.releaseDate.split('/')
          const releaseDate = `${dates[2]}-${dates[0]}-${dates[1]}`
          formData.append('data[release_date]', releaseDate)
         }
         if(this.state.apiData?.trackDetails.releaseTime != null){
           const times = this.state.apiData?.trackDetails.releaseTime.split(':')
           const releaseTime =  `${times[0]}:${times[1]}:00`
           formData.append('data[release_time]', releaseTime)
         }

        if(this.state.apiData?.trackDetails.key != null){          
          formData.append('data[key_id]', this.state.apiData?.trackDetails.key)
        }

        if(this.state.apiData?.trackDetails.tags.length > 0){          
          formData.append('data[genres]', this.state.apiData?.trackDetails.tags)
        }

        
         if(this.state.selectedPlan){
            formData.append('data[level]', `level_${this.state.selectedPlan}`)
         }

         const headers = {
            "Content-Type": "application/json",
            'token': this.state.token
          };

          await axios.post(`${ baseUrl + '/bx_block_audiomusic/add_track_file'}`,formData,{ headers }

        )
        .then((response: any) => {
            this.setState({loading: false})
            setStorageData('uploaded_trackId', response?.data?.data?.data[0].id)
            console.log(response?.data, 'track details saved in DB')
            openNotification('Upload Successfull' , 'success')
            this.props.setActiveStep();
          }).catch((err: any) => {
            openNotification('Upload Failed' , 'error')
            this.setState({loading: false})
          });
         
      }
       UpdateTrackdetails = async (token: any,payload:any) => {
         this.setState({loading:true})
         const linkID= await getStorageData('uploaded_linkedID')
         let data;
         console.log("selectedplan",this.state.selectedPlan)
         if(this.state.selectedPlan && linkID){
          data ={data:{...payload.data,level:`level_${this.state.selectedPlan}`,linked_track_id:linkID}}
         }else if(linkID){
          data ={data:{...payload.data,linked_track_id:linkID,level:null}}
         }else if(this.state.selectedPlan){
          data ={data:{...payload.data,level:`level_${this.state.selectedPlan}`}}
         }else{
          data ={data:{...payload.data,level:null}}
         }

        await axios
          .put(`${baseUrl}/bx_block_audiomusic/add_track_details?id=${payload.data.play_list_id}`,data, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              
              token: token,
            },
          })
          .then((response: any) => {
            
              openNotification('Update Successfull' , 'success')
              this.setState({loading:true})
              this.props.setActiveStep();
           
          })
          .catch((err: any) => {
            this.setState({loading:false})
          openNotification('UPDATE Failed' , 'error')
            
          }).finally(() => {
    
          })
      };
    
      handleAudioVideoLinkResponse = (responseJson: any) =>{
         if(responseJson?.data?.length > 0){
            this.setState({AudioVideoList: responseJson?.data})
         }else{
            this.setState({AudioVideoList: []})
         }
      }

      afterSellingPreferencesSave = async() =>{
        const UploadFileType = window.location.href.split('/')[4]

        const file = await getStorageData('draft_file')
        const artWork = await getStorageData('uploaded_cover_image')
        const trackDetails = await getStorageData('uploaded_track_details')
        const linkTrackId = await getStorageData('uploaded_linkedID')
  
        const fmData: any = new FormData();
  
          if(UploadFileType == "audio"){
            fmData.append('data[track_file]', file);
          }
          else if(UploadFileType == "video"){
            fmData.append('data[video_file]', file);
          }
  
          if(artWork){
            fmData.append('data[art_work]', artWork)
          }
  
          
          fmData.append('upload_stage', 3);
  
          if(trackDetails.title != ""){
            fmData.append('data[title]', trackDetails.title)
          }
          if(trackDetails.releaseDate != ""){
            const dates = trackDetails.releaseDate.split('/')
            const releaseDate = `${dates[2]}-${dates[0]}-${dates[1]}`
            fmData.append('data[release_date]', releaseDate)
          }
          if(trackDetails.releaseTime != ""){
            fmData.append('data[release_time]', trackDetails.releaseTime)
          }
          if(trackDetails.uploadType != ''){
            fmData.append('data[upload_type_id]', trackDetails.uploadType)
          }
          if(trackDetails.uploadStatus != ""){
            fmData.append('data[status]', trackDetails.uploadStatus)
          }
          
          if(trackDetails.key != ""){
            fmData.append('data[key_id]', trackDetails.key)
  
          }
          if(trackDetails.tags.length > 0){
            fmData.append('data[genres]', trackDetails.tags)
          }
  
          if(linkTrackId != ''){
            fmData.append('data[linked_track_id]', linkTrackId)
          }

          if(this.state.selectedPlan){
            fmData.append('data[level]', `level_${this.state.selectedPlan}`)
         }
          
            const headers = {
              "Content-Type": "application/json",
              'token': this.state.token
            };
  
            await axios.post(`${ baseUrl + '/bx_block_audiomusic/save_as_draft'}`,fmData,{ headers }
  
          )
          .then((response: any) => {
            
            }).catch((err: any) => {
  
            });
      }

     
}
// Customizable Area End