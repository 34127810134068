// Customizable Area Start
import React from "react";
import "./DashboardMusicdeal.css";
import { Avatar, Box, Button, Grid,Typography,withStyles,createStyles,Theme } from "@material-ui/core";
import { blueCheck,nxzsound , nxzloader,backgroundblur,songPlay, CircledRight,defaultProfile} from "blocks/dashboard/src/assets";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import Buttongroup from "../../Common/Buttongroup.web";
import DashboardMusicDealController from "./DashboardMusicDealController.web";
import { jsonsinger, dealerslogo } from "../../assests"
import { Row } from "antd";
import LinearProgress from '@material-ui/core/LinearProgress';
const user_type: any = localStorage.getItem("user_type");
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
export default class MusicSplitDeal extends DashboardMusicDealController {
  constructor(props: any) {
    super(props);
  }
  showButtonGroup = () => {

    return (
      <Grid item md={2} xs={6} lg={2} className="audio-div">
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <Buttongroup
            musicButton={this.state.musicButton}
            buttonshow={true}
            width= "260px"
            setMusicButton={
              this.setVideoButtonTrue
            }
            setMusicButtonTrue={

              this.setMusicButtonTrue
            }
            setVideoButtonTrue={
              this.setUserButtontrue
            }
            setDealertrue={
              this.setDealerbuttontrue
            }
          />

        </Grid>

      </Grid>
    )

  }
   formatDuration(duration:any ) {
    if (duration.toLowerCase().includes("month")) {
      return duration.replace(/ months?/, "M");
    } else if (duration.toLowerCase().includes("year")) {
      return duration.replace(/ years?/, "Y");
    }
    return duration;
  }
   formatCurrency(amount:any) {
    amount = Number(amount);
  
    if (isNaN(amount)) return "Invalid amount";
  
    let suffix = '';
    let formattedAmount = amount;
  
    if (amount >= 1e6) {
      suffix = 'm';
      formattedAmount = Math.floor(amount / 1e5) / 10; 
    } else if (amount >= 1e3) {
      suffix = 'k';
      formattedAmount = Math.floor(amount / 100) / 10; 
    }
    
    let result = formattedAmount.toString();
    result = result.indexOf('.') !== -1 ? result.replace(/\.0+$/, '') : result;
  
    return result + suffix;
  }

  videosplitimage(item :any,musicButton:any,){

    if(musicButton == 2 ){
      return  <><img src={item.attributes?.track_art_work || jsonsinger} className="iconImg" style={{
        borderRadius: "10px", minWidth: "125px",
        border: "1px solid #141414"
      }} data-test-id="video_image"
        width="100px"
        height="60px" /><Box className="wallet_leftside_ownerstats">
          <span className="Swalla">
            {item.attributes?.track_title}

          </span>
          <span className="Jason-Derulo">
            {item.attributes?.creator_name} {item.attributes?.is_verifed && <img src={blueCheck} className="verfied" style={{ marginLeft: "4px" }} />}
          </span>
        </Box></>
    }
    else if(musicButton == 3){
    return <><img src={item.attributes?.user_image || jsonsinger} className="iconImg" style={{ borderRadius: '60px',minWidth:"60px" }}
      width="60px"
      height="60px" /><Box className="wallet_leftside_ownerstats">
        <span className="Jason-Derulo">
          {item.attributes?.creator_name} {item.attributes?.is_verified && <img src={blueCheck} className="verfied" style={{ marginLeft: "4px" }} />}
        </span>
      </Box></>
    
   
  
                     }
  }

  topanddealoffersbutton=()=>{
    return  <Box className="DealinsideBox">
    
    
    <span className={this.state.selecteddeals == "transactions" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"transaction")} data-test-id="transactions">
      Deal Offers
    </span>
    <span className={this.state.selecteddeals == "Topdeals" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"")}>
      Top Dealers
    </span>

  </Box>
  }

  offerstatuscomp=(userType:any,status:any,offerstatus:boolean,deal_type:any)=>{
    console.log("dealtype",deal_type)
    if(offerstatus){
      return <Box className="button_box"><span className="Accepted" style={{textTransform:"capitalize"}}>{this.state.offerStatus}</span></Box>
    }
    else{
      return <Box className="button_box">
      <Button className="Make-An-Offer---Button"   onClick={this.setofferStatus.bind(this,"accepted",deal_type)} data-test-id="offeraccept">
        Accept
      </Button>
      <Button className="Make-An-Offer---Button"  onClick={this.setofferStatus.bind(this,"rejected",deal_type)}>
        Reject
      </Button>
      {
    (userType === 'creative_plus' && status === "pending") || 
           (userType !== 'creative_plus' && status !== "negotiating" ) ? (
           <Button
            className="Make-An-Offer---Button"
             onClick={userType === 'creative_plus' ? this.negotiatedeal : this.setofferStatus.bind(this, "negotiating",deal_type)}
   >
     Negotiate
   </Button>
 ) : null
}
    </Box>
    }
   
  }

  dealstatuscomp(dealstatus:any,data:any){
    if(!dealstatus && data !==undefined){
         return (
          <Box className="top_dealers_musicdeal_right_side" style={{ flexDirection: "column" }}>
    <Box className="musicdeal_right_side_contain">
    <Box className="boximage" style={{ height: "250px" }}>
      <Box  className="deal_offer_wrapper_box">
        <div className="dealoffer_container">
         
         
          <div className="dealofferstats">
            <span className="Pending">Pending</span>
            <span className="pendingStats">{this.state.Topdealoffers?.pending_deal}</span>
          </div>
          <div className="dealofferstats">
            <span className="Pending">Accepted</span>
            <span className="pendingStats">{this.state.Topdealoffers?.accepted_deal}</span>
          </div>
        </div>
      
      
        <div style={{ marginTop: '10px' }} className="dealoffer_container">
          <div className="dealofferstats">
            <span className="Pending">Rejected</span>
            <span className="pendingStats">{this.state.Topdealoffers?.rejected_deal}</span>
          </div>
          <div className="dealofferstats">
            <span className="Pending">Negotiating</span>
            <span className="pendingStats">{this.state.Topdealoffers?.negotiating_deal}</span>
          </div>
        </div>
      </Box>

     {this.topanddealoffersbutton()}
    </Box>
    </Box>
     
     </Box>
         )
    }
    else if (dealstatus){
      const user_type: any = localStorage.getItem("user_type");
      console.log('selectedealoffer',this.state.selecteddealoffer)
      return (
        <Box  className="rightsidecontainer_deal_container " >
        <Box  className="wallet_rightside_container_box" style={{width:"510px"}}>
          <Box style={{ background: "black", width: "80%", height: "80%" }}>
            <Box className='dealselection_right_box'>
              <Box className="change_status" style={{marginLeft:"5px"}}>
                <div className='MainContentDiv deal_selection_maiancontent'>
                  <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                    <div className='ColumnCenter AICenter'
                    >


                      {/* Offer Preview START*/}
                      <Box
                        className="dashboardDeal_offer_preview"

                      >
                        {/* Label */}
                        <Box className="LabelBox">
                          <Box
                            className="AvatarBox"
                          >
                            <Avatar src={this.state.selecteddealoffer?.attributes?.user_image || defaultProfile}
                              className="Avater" />
                          </Box>
                          <Box
                            className="LabelInfoBox">
                            <Typography className="PVCommonText LabelTitleText label_text" >
                            {this.state.selecteddealoffer?.attributes?.creator_name}
                            </Typography>
                            <Typography
                              className="PVCommonText LabelSubTitleText label_text"
                            >
                              {this.state.selecteddealoffer?.attributes?.split_user_type}
                            </Typography>
                          </Box>
                        </Box>
                        {/* Label */}
                        <Box className="MT32">
                          <Typography className="PVCommonText OfferingText label_text">Is Offering:</Typography>
                        </Box>
                        <Box>
                          <Typography className="PVCommonText OfferingMoneyText OfferingdealText">${this.formatCurrency(this.state.selecteddealoffer?.attributes?.amount)}</Typography>
                        </Box>
                        <Box className="MT23">
                          <Typography className="PVCommonText ForText label_text">For:</Typography>
                        </Box>
                        <Box className="SplitDetailBox">
                          <Box>
                            <Avatar src={this.state.selecteddealoffer?.attributes?.label_profile || defaultProfile} className="Avatar" />
                            <Typography className="SplitPercentageTextindeals SplitPercentageText" >
                            {this.state.selecteddealoffer?.attributes?.deal_percentage}%   
                            </Typography>
                          </Box>
                          <Box className="LineBox">
                            <Box className="ChildBox">
                              <Box className="Line">
                                <Avatar src={CircledRight} className="Avatar" />
                              </Box>
                            </Box>
                            <Box className="SplitDurationBox">
                              <Typography className="PVCommonText DurationText">For {this.state.selecteddealoffer?.attributes?.interval}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Avatar src={this.state.selecteddealoffer?.attributes?.user_image || defaultProfile} className="Avatar" />
                            <Typography className="SplitPercentageText SplitPercentageTextindeals">
                            {this.state.selecteddealoffer?.attributes?.deal_percentage}%   
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="MT23">
                          <Typography className="PVCommonText FromText">From:</Typography>
                        </Box>
                        <Box className="PVAudioVideoContentDiv">

                          <Box className="PVAudioVideoImageDiv">
                            <img src={this.state.selecteddealoffer?.attributes?.track_art_work || this.state.selecteddealoffer?.attributes?.user_image} className="PVAudioVideoImage" />
                            {/* <IconButton className="PVAudioVideoPlayBtn" >
                              <Avatar src={album1} className="Icon" />
                            </IconButton> */}
                          </Box>


                          <Box className="PVAudioVideoInfoDiv">
                            <Box className="PVSongInfoBox">
                            <Typography
                              className="PVCommonText LabelSubTitleText label_text user_type"
                            >
                              {this.state.selecteddealoffer?.attributes?.deal_type}
                            </Typography>
                              <Typography className="SongNameText">
                              {this.state.selecteddealoffer?.attributes?.track_title} 
                              </Typography>
                              <Typography className="SongWriterText">by: {this.state.selecteddealoffer?.attributes?.track_artist_name || this.state.selecteddealoffer?.attributes?.current_user_name}</Typography>
                            </Box>
                          </Box>
                        </Box>

                        {this.offerstatuscomp(user_type,this.state.selecteddealoffer?.attributes?.status,this.state.showofferStatus,this.state.selecteddealoffer?.attributes?.deal_type)}

                      </Box>

                      {/* Offer Preview END */}
                    </div>
                  </Grid>

                </div>
              </Box>


            </Box>
          </Box>
        </Box>
      </Box> 
      )
    }
   
  }

   musicSplitDealrightside=(data:any)=>{
    return (
     <>
      {data?.length > 0 &&   <Box className="musicdeal_right_side_contain" style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.75)),url("${backgroundblur}")`}}>
            
            <Box className="boximage" style={{
                 width: '356px',
                 backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url("${this.state.selectedusersplit?.attributes?.track_art_work || this.state.selectedusersplit?.attributes?.user_image}")`,
                 backgroundSize: 'cover',
                //  paddingRight:"20px",
                 backgroundPosition: 'center',
              }}>
                <Box className="split">
                   <span className="Music-Split">{this.state.SplitType} Split</span>
                </Box>
                <Box  className="sold">
                  <div>
                       <img src={this.state.selectedusersplit?.attributes?.label_profile || nxzsound}   className="logoverified" style={{borderRadius:"50px"}}/>
                  </div>
      
                  <div>
                     <span className="soldTo ">  Sold To: <br></br></span>
                     <span className="Music-Split ">  {this.state.selectedusersplit?.attributes?.creator_name}  <img src={blueCheck} className="verfied" /></span>
                  </div>
      
                </Box>
                <div style={{ display: "flex", gap:  "90px",paddingRight:"20px" }}>
                  <div className="deal_elements" >
                    <span className="deal_elements_firstchild">
                       Split
                    </span>
                     <span className="deal_elements_secondchild">{this.state.selectedusersplit?.attributes?.split_user_track_percentage}%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                       Plays
                    </span>
                    <span className="deal_elements_secondchild">{this.state.selectedusersplit?.attributes?.total_play_count || this.state.selectedusersplit?.attributes?.play_count}</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                       Deal
                    </span>
                    <span className="deal_elements_secondchild" style={{fontSize:"18px"}}>${this.formatCurrency(this.state.selectedusersplit?.attributes?.amount)}</span>
                  </div>
                </div>
                <div style={{ display: "flex", gap: '90px',paddingRight:"20px" }}>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                        Term
                    </span>
                    <span className="deal_elements_secondchild">{this.formatDuration(this.state.selectedusersplit?.attributes?.interval || "0")}</span>
                  </div>
                  <div className="deal_elements" >
                    <span className="deal_elements_firstchild" >
                         Change
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements"  >
                    <span className="deal_elements_firstchild"  >
                        Made
                    </span>
                    <span className="deal_elements_secondchild"   >30%</span>
                  </div>
                </div>
      
                <Box className="deal_ownership_details" style={{position:"relative"}}>
                  <img src={this.state.selectedusersplit?.attributes?.track_art_work || this.state.selectedusersplit?.attributes?.user_image} className="iconImg"    style={{ borderRadius: '10px' ,position:"relative"}}
                     width="60px"
                     height="60px"
      
                  />
                   <img src={songPlay} style={{width:"30px",height:"31.3px",position:"absolute",left:"15px"}}/>
                
                  <Box className="wallet_leftside_ownerstats">
                    <span className="Swalla">
                    {this.state.selectedusersplit?.attributes?.track_title}
                    </span>
                    <span className="Jason-Derulo">
                    {this.state.selectedusersplit?.attributes?.creator_name}<img src={blueCheck} className="verfied" style={{marginLeft:"5px"}}/>
                    </span>
                  </Box>
                </Box>
                             <Box className="deal_timing">
                  <span className="Deal-Expires-in-72h">
                               Deal Expires in:
                    <span className="dealexpriesend"> {this.state.selectedusersplit?.attributes?.deal_expires_in}d</span>
                  </span>
                </Box>
              </Box>
      
            </Box>}
     </>
     
    )
   }

   musicSplitDeal = () => {

    return (
     <>
     { this.state.loading ?   <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row> : <Box  className="dashboarddeal_parent_container">
            <Grid  item xs={12} className="audio_wallet_button_group filter-div filter_align_right"  style={{marginTop:"-50px"}}>
            <Grid  className="buttonfilterbox">
            <div className="topheader_buttons">
                {this.showButtonGroup()}
            </div>

            <FilterDropDown filterValue="Filter"
            />

          </Grid>
           </Grid>
        <Box className="Musicdealtop_container">
        <Box className="Musicdealtop_container_element">
              <Box className="musicldealtop_element_child">
              <span className="Music-Royalties">
                 {this.state.SplitType} Split Deals
              </span>
              <span className="This-Week">
      
       This Week
              </span>
            </Box>
            
         {
           this.state.Dealdata.length > 0 ? 
           <Box  className="musicldeal_leftside_wrapper">
              { this.state.Dealdata.map((item:any, index:string) =>                                                       
               
               <Box className={index === this.state.firstindexdeal ? "selected_deal" : "musicdeal_leftside_container"} onClick={this.selectanysong.bind(this,index,item)}  key={index} data-test-id="selectsong">
                 <Box  className="musicldeal_imagebox">
                  {this.state.musicButton == 1 && <><img src={item.attributes?.track_art_work || jsonsinger} className="iconImg" style={{ borderRadius: '10px', minWidth: "60px" }}
                      width="60px"
                      height="60px" /><Box className="wallet_leftside_ownerstats">
                        <span className="Swalla">
                          {item.attributes?.track_title}

                        </span>
                        <span className="Jason-Derulo">
                          {item.attributes?.creator_name} {item.attributes?.is_verified && <img src={blueCheck} className="verfied" style={{ marginLeft: "4px" }} />}
                        </span>
                      </Box></> }
                 
                        {this.videosplitimage(item,this.state.musicButton)}
                 
                </Box>
            <Box className="wallet_leftside_ownerstats" style={{alignItems:"end"}}>
                   <span className="money cash">
                   ${item.attributes?.amount || 0}
                  </span>
                  <span  className="text-style-1">
                    <span className="plus">+</span>{item.attributes?.deal_percentage}%
                  </span>
                </Box>
              </Box>)}
           </Box>:<Typography style={{paddingTop:"200px",textAlign:"center", fontSize: 16, fontWeight: 600,
                    color: "#fff",}}>No Data Found</Typography>}
        
            </Box>
         
          <Box className="top_dealers_musicdeal_right_side">
            {this.musicSplitDealrightside(this.state.Dealdata)}
         
           
          </Box>
        </Box>
      </Box> }
     </>
     
    )
  }

  topdealersInfo=(topdedealers:any)=>{
    if (topdedealers.length > 0 ){
      return ( <Box className="musicdeal_right_side_contain">
      <Box className="dealers_box_image" style={{
  width: '355px', height: "230px",
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url(${dealerslogo})`,
  backgroundSize: 'cover',
  
  }}>
  <Box className="split">
  <span className="Music-Split">#1 Dealer</span>
  </Box>
  <Box className="sold" style={{gap:"5px",marginLeft: "-110px",paddingRight:"8px",display:"flex",justifyContent:"space-between"}}>
  
  <img src={this.state.selectedusersplit?.attributes?.label_profile || this.state.selectedusersplit?.attributes?.creative_profile} className="logoverified" style={{borderRadius:"60px"}}/>
  <span className="Music-Split ">{this.state.selectedusersplit?.attributes?.creator_name}</span>
  <img src={blueCheck} className="verfied" style={{  }} />
  
  
  </Box>
  
  <div style={{ display: "flex", gap: '80px' }}>
  <div className="deal_elements">
  <span className="deal_elements_firstchild">
              Paid
  </span>
  <span className="deal_elements_secondchild">{this.state.selectedusersplit?.attributes?.amount}</span>
  </div>
  <div className="deal_elements">
  <span className="deal_elements_firstchild">
               Made
  </span>
  <span className="deal_elements_secondchild">30%</span>
  </div>
  <div className="deal_elements">
  <span className="deal_elements_firstchild">
     Change
  </span>
  <span className="deal_elements_secondchild">30%</span>
  </div>
  </div>
  
  </Box>
  <Box className="DealinsideBox">
  <span className={this.state.selecteddeals == "transactions" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"transaction") } data-test-id="top-dealer">
  Deal Offers
  </span>
  <span className={this.state.selecteddeals == "Topdeals" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"")}>
  Top Dealers
  </span>
  
  </Box>
  </Box>)
    }
    
  }

  topDealers = () => {
 console.log(this.state.selectedusersplit,"selectedsplit")
    return (

   <>
   {this.state.loading ? <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>:  <Box className="dashboarddeal_parent_container">

<Grid style={{ backgroundColor: "black" ,marginTop:"-50px"}}    item xs={12} className=" audio_wallet_button_group filter-div filter_align_right" >
<Grid  className="buttonfilterbox">
    <div className="topheader_buttons" >


      {this.showButtonGroup()}
    </div>

    <FilterDropDown filterValue="Filter"
    />
  </Grid>
</Grid>

<Box className="Musicdealtop_container">

  <Box className="Musicdealtop_container_element">


           <Box className="musicldealtop_element_child">
     
           <span className="Music-Royalties">
        Top 100 Dealers
      </span>
      <span className="This-Week">
        This Week
      </span>
    </Box>
    { this.state.topDealersdata.length > 0 ? <Box className="musicldeal_leftside_wrapper">
    {this.state.topDealersdata.map((item:any, index:any) =>(
        
        <Box className= {index === this.state.firstindexdeal ? "leftside_top_deal_active" : "leftside_top_deal"}  key={index} onClick={this.selectanysong.bind(this,index,item)}>

           <Box >

             <img src={item.attributes.label_profile ||item.attributes?.creative_profile} className="iconImg deal_image" style={{objectFit:"cover"}}/>
           </Box>

           <Box style={{ width: "90%" ,display:"flex",flexDirection:"column",gap:"5px"}}>
             <div className="leftside_second_element" >
               <Box className="topdealers_leftside_ownerstats">
                 <span className="Swalla">
                 {item.attributes.creator_name} {item.attributes.is_verified && <img src={blueCheck} className="verfied" style={{marginLeft:'5px'}}/>}
                 </span>
                 <span className="Jason-Derulo">
                 {item.attributes.role_name}
                 </span>
               </Box>
               <Box className="topdealers_leftside_ownerstats">
                 <span className="cashier">
                 ${this.formatCurrency(item.attributes.amount)}
                 </span>
                
                   <span className="text_style_2">{item.attributes.deal_count} deals</span>
                
               </Box>

             </div>

             <Box className="leftside_second_element" >
               <Box style={{
                 width: "100%"


               }} >

           <BorderLinearProgress variant="determinate" value={64} />
               </Box>
             </Box>
           </Box>
         </Box>
       ))}

    </Box>:
    <Typography style={{paddingTop:"200px",textAlign:"center", fontSize: 16, fontWeight: 600,
                   color: "#fff",}}>No Data Found</Typography>
    }
    </Box>
 
  {this.topdealersInfo(this.state.topDealersdata)}
</Box>
</Box>}
   </>
     
    )
  }
renderImage=(userType:any,item:any)=>{
  if(userType === "creative_plus"){
   return  <img src={ item.attributes?.label_profile || defaultProfile} className="iconImg" style={{ borderRadius: '60px',minWidth:"60px",minHeight:"60px" }}
                   width="60px"
               height="60px"

             />
  }else {
    return (
      <img src={ item.attributes?.split_artist_image || defaultProfile} className="iconImg" style={{ borderRadius: '60px',minWidth:"60px",minHeight:"60px" }}
      width="60px"
      height="60px"

/>
    )
     
  }

}

   getStatusColor(status: any) {
    let color:Record<string,any> = {
      "accepted": "blue",
      "pending" : "rgba(255, 255, 255, 0.5)",
      "rejected":"red",
      "negotiated":"red"     
    }
    return color[status]
  }

  renderTopDealOffers = (data:any) => {
    const user_type: any = localStorage.getItem("user_type")
    console.log("data",data)
    return <>
    { data !== undefined  ? data?.map((item:any, index:any) =>
              
      <Box className={index === this.state.selectedIndex ? "selected_deal" : "musicdeal_leftside_container"} key={index} onClick={this.selectedDeal.bind(this,index,item)} data-test-id="selecteddeal">
           <Box style={{ gap: "11%", alignItems: "center", display: "flex", justifyContent: "center" }}>
            
             {this.renderImage(user_type,item)}
             <Box className="wallet_leftside_ownerstats">
               <span className="Swalla">
               {user_type === "creative_plus" ? item.attributes?.creator_name  :item.attributes?.user_track?.artist_name}  {item.attributes?.is_verified && <img src={blueCheck} className="verfied" style={{marginLeft:"5px"}}/>}
               </span>
               <span className="Jason-Derulo">
              For : {item.attributes?.deal_type}
               </span>
             </Box>
           </Box>
        
        
           <Box className="wallet_leftside_ownerstats">
             <span className="money cash">
                   ${item.attributes?.amount || 0}
             </span>
             <span className="Negotitating" style={{color:this.getStatusColor(item.attributes.status)}}>
             {item.attributes?.status}
             </span>
           </Box>
         </Box>):<Typography style={{paddingTop:"200px",textAlign:"center", fontSize: 16, fontWeight: 600,
                   color: "#fff",}}>No Data Found</Typography>}
    </>
  }

  dealOffers = () => {

      return (
        <>
        {this.state.loading ? <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>: <Box className="dashboarddeal_parent_container">
         
         
         <Grid   item xs={12} style={{ backgroundColor: "black" ,marginTop:"-50px"}} className="filter-div audio_wallet_button_group    filter_align_right" >
         <Grid  className="buttonfilterbox">
             <div className="topheader_buttons">
                     {this.showButtonGroup()}
             </div>
         
                               <FilterDropDown filterValue="Filter"
             />

           </Grid>
         </Grid>
         <Box className="Musicdealtop_container">


           <Box className="Musicdealtop_container_element">
                   <Box className="musicldealtop_element_child">
               <span className="Music-Royalties">
                 Deal Transactions
               </span>
               <span className="This-Week">
                 This Week
               </span>
             </Box>
             <Box className="musicldeal_leftside_wrapper">
              {this.renderTopDealOffers(this.state.Topdealoffers?.deals?.data)}
             </Box>
           </Box>


          {this.dealstatuscomp(this.state.showdealstatus,this.state.Topdealoffers?.deals?.data)}

         </Box>
       </Box>} 
        </>
       

      )
    
  }

   maindealcontent=(type:any)=>{
    if (type == 'transactions') {
      return this.dealOffers()
    }
    else{
      return this.topDealers()
    }
  }
  compRender = () => {
    const { musicButton } = this.state
    switch (musicButton) {
      case 1:
      case 2:
        case 3:{
        return this.musicSplitDeal()       
      }        
      case 4:
            return this.maindealcontent(this.state.selecteddeals)
    }
  }
  render() {
    
   


   

    

    
    return (
      //Merge Engine DefaultContainer
    this.compRender()

      //Merge Engine End DefaultContainer
    );

  }
}

// Customizable Area End