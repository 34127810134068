// Customizable Area Start
import React from "react";
import {
  album11,
  loopIc,
  messageIcon,
  playlistMoreTwoDots,
  plays,
  down,
  iconUp,
} from "../../../dashboard/src/assets";
import { useHistory } from "react-router-dom";
import "./SingleSong.css";
import { Grid, Box, Hidden } from "@material-ui/core";
import { Col, Row } from "antd";
import PlaylistLineCharts from "components/src/CustomChart/PopUps/PlaylistLineCharts.web";
// @ts-ignore
import Waveform from "../../../../components/src/CustomChart/Songs/Waveform";

const SinglePlaylistCardWeb = (props: any) => {
  const history = useHistory();
   const onFullScreen = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.track_upload_type == "Video") {
      props.handlerSingleVideos(props.id, props.track_file);
    } else {
      history.push(`/home/expand/song/songId=${props.id}`);
    }
  };
  return (
    <div onClick={props.handlePlayList}>
      <Grid
        container
        className="main-container-space single-card-playlist"
        onClick={props.handlePlayList}
      >
        <Grid
          item
          md={4}
          lg={3}
          xs={3}
          sm={4}
          // onClick={item.handlePlayList}
          className="profileDetailBlock"
        >
          <div className="width_100 dflex">
            <div>
              <Row className="sn-grid mr_1 ml_05">
                {props?.number + 1 < 10
                  ? `${0}${props.number + 1}`
                  : props?.number + 1}
              </Row>

              <img
                src={props.arrow ? iconUp : down}
                className="icon-up mr_05 ml_05"
              />
            </div>
            <div>
              {props.play_list_type ? (
                <img
                  style={{ borderRadius: "8px", border: "solid 1px gray" }}
                  className="song-cover mr_1"
                  width="50px"
                  height="50px"
                  src={props?.image ? props?.image : album11}
                />
              ) : (
                <img
                  style={{ borderRadius: "8px", border: "solid 1px gray" }}
                  className="song-cover mr_1"
                  width="80px"
                  height="48px"
                  src={props?.image ? props?.image : album11}
                />
              )}
            </div>
            <div>
              <Row>
                <Row
                  justify="start"
                  className="width_100 playlist_video_title text_white cursor_pointer"
                >
                  {" "}
                  {props?.songName ? props?.songName : "Unknown"}
                </Row>
                <Row
                  justify="start"
                  className="width_100 text_gray font_12  cursor_pointer"
                >
                  {" "}
                  {props?.artist_name ? props?.artist_name : "Unknown"}
                </Row>
              </Row>
            </div>
          </div>
        </Grid>
        <Col xs={6} className="HiddnFullScreen hours-list">
          <Row justify="end" className="iconDelete">
            <img
              src={playlistMoreTwoDots}
              className=" ml-1 mr_1"
              height="28px"
              width="28px"
              onClick={onFullScreen}
              data-test-id="singleplaylistcard"
            />
          </Row>
        </Col>
        <Hidden mdDown>
          <Grid
            item
            md={1}
            xs={2}
            sm={4}
            lg={3}
            className="playButtonContain"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Row className=" width_100">
              <Hidden xsDown>
                {props.play_list_type && (
                  <Col
                    span={24}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Hidden mdDown>
                      <Waveform
                        url={props.track_file}
                        id={props.id}
                        height={40}
                      />
                    </Hidden>
                  </Col>
                )}
              </Hidden>
            </Row>
          </Grid>
        </Hidden>
        <Grid item sm={3} md={3} lg={2} className="iconButton">
          <div className=" display-play_dark_back">
            <Col className="colButton width_100">
              <div style={{ display: "flex" }} className="iconContent">
                <img
                  className="iconImg"
                  src={plays}
                  height="24px"
                  width="24px"
                />
                <Box className="play-times iconContent">
                  {props?.playCount ? props?.playCount : "0"}
                </Box>
              </div>
            </Col>
            <Col className="colButton width_100">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="iconContent"
              >
                <img
                  className="iconImg"
                  src={loopIc}
                  height="20px"
                  width="20px"
                />
                <Box className="play-times iconContent">
                  {props?.rePostCount || "0"}
                </Box>
              </div>{" "}
            </Col>
            <Col className="colButton width_100">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="iconContent"
              >
                <img
                  className="iconImg"
                  src={messageIcon}
                  height="19px"
                  width="20px"
                />
                <Box className="play-times iconContent">
                  {props?.commentCount || "0"}
                </Box>
              </div>
            </Col>
          </div>
        </Grid>
        <Grid item sm={5} lg={4} className="hidden_percentage">
          <PlaylistLineCharts
            seven_days={props?.lastSevenHours}
            twenty_four_days={props?.lastTwentyFourHours}
            seven_days_graph={props.last_seven_days_graph}
            fullscreen={playlistMoreTwoDots}
            onFullScreen={(e: any) => onFullScreen(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SinglePlaylistCardWeb;
// Customizable Area End
