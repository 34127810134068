// Customizable Area Start
import React from 'react'
import { Grid } from "@material-ui/core";
import { defaultProfile } from '../../../../dashboard/src/assets';
import { useHistory } from "react-router-dom";

function UserActivity({ data, time, type, creativeById, song }: any) {
  const history = useHistory()

  const handleRedirect= async()=>{
    const id: any = localStorage.getItem("user_id")
     localStorage.setItem("temp_id", id)
     localStorage.removeItem("user_id")
     localStorage.setItem("user_id", data.follower_id)
     localStorage.setItem("creatives_screen", "true")

    if(data.user_type=="creative"){
      history.push(`/creatives/beats?creative_id=${data.follower_id}`)
      } else if(data.user_type=="listener"){
        history.push(`/listeners/activity?listener_id=${data.follower_id}`)
      }else{
        history.push(`/originals/beats?originals_id=${data.follower_id}`)
      }
    // location.reload()
     window.location.reload()
  }
  return (
    <>
      <Grid container spacing={1} className="activity-head-card">
        <Grid item style={{ width: '100%' }} >
          <Grid style={{ color: 'white', borderColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }} >
                <img src={creativeById?.attributes?.avatar || defaultProfile} style={{
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%"
                }} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <div className='activity-user_name'>{creativeById?.attributes?.user_name || creativeById?.attributes?.full_name}</div>
                  <div className='activity-duration'>{time} {time != "Just Now" && "ago"}</div>
                </div>
              </div>
              <div>
                <img src={type} style={{
                  height: "24px",
                  width: "24px",

                }} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', padding: '15px 2px' }}>
                <div style={{ width: '100px', height: '100px', position: 'relative' }}>
                  <div style={{ position: 'absolute' }}>
                    <img src={data?.followed_avatar || defaultProfile}
                      style={{
                        height: "85px",
                        width: "86.5px",
                        borderRadius: "50px",
                        backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.21), #000)",
                        cursor: "pointer"
                      }}
                      onClick={handleRedirect}
                      
                    />
                  </div>
                  {/* <div style={{ position: 'absolute', bottom: '20%', right: '18%' }}>
                    <img src={blueCheck} style={{ height: '20px' }} />
                  </div> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <div className='activity-user_name'>
                    {data?.followed_user_name || "unknown"}
                  </div>
                  <div className='activity-followers'>
                    {data?.followed_followers_count || "0"} Followers
                  </div>

                </div>

              </div>
              {/* <div style={{marginTop: '50px'}} >
           <img src={ActivityMore} style = {{height: '25px'}} />
          </div> */}
            </div>

          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default UserActivity
// Customizable Area End