// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import {ArrowUp, messageIcon, repeatIcon} from 'blocks/dashboard/src/assets'
import LineChart from 'components/src/CustomChart/PopUps/LineCharts.web';
import { defaultProfile, playOutline } from '../../../dashboard/src/assets';
export const TopDesktopCreatives = (props: any) =>{
    const{data} = props;

    return(
        <>
        <Grid container style={{padding: '5px'}} className='label-top-song'>
          <Grid item xs={4}>
            <Grid container spacing={1} style={{display: 'flex'}} >
                <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>{props.index+1}</div>
                <img src={ArrowUp} style={{marginTop: '5px'}}/>
                </Grid>
                <Grid item>
                    <img style={{height: '48px', width: '48px', borderRadius: '50%',objectFit: 'cover'}} src={data?.attributes?.avatar ? data?.attributes?.avatar : defaultProfile}/>
                </Grid>
                <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>{data?.attributes?.user_name}</div>
                    <div style={{color: 'gray', fontFamily: 'Karla', fontSize: '14px', fontWeight: 'bold'}}>{data?.attributes?.role}</div>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>

            <Grid style={{display: 'flex', backgroundColor: 'black', padding: "2px", borderRadius: '10px', height: '100%', alignItems: 'center', width: '200px', marginRight: '25px'}}>
               <Grid item xs={4} className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={playOutline} height="24px" width="24px" />
                    <span className="play-songs-times">{data?.attributes?.total_play_count}</span>
                </Grid>
                <Grid item xs={4} className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{data?.attributes?.total_repost_count}</span>

                </Grid>
                <Grid item xs={4} className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{data?.attributes?.total_comment_count}</span>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <LineChart isLabelProfile={true} seven_days={data?.attributes?.seven_days_percentage} twenty_four_days ={data?.attributes?.last_twenty_four_hours_percentage} seven_days_graph ={data.attributes?.seven_days_graph}/>
          </Grid>

        </Grid>

          {/* <Grid>
              <Grid  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>1</div>
                <img src={ArrowUp}/>
                </Grid>
                <Grid >
                    <img style={{height: '48px', width: '48px'}} src={VideoUserAvatar}/>
                </Grid>
                <Grid  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>Cardi B</div>
                    <div style={{color: 'white'}}>Artist</div>
                </Grid>
                <Grid  className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={PlayIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{'22k'}</span>
                </Grid>
                <Grid  className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{ '22k'}</span>

                </Grid>
                <Grid  className="" style={{ display: "flex", alignItems: "center" , justifyContent: 'center'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{'22k'}</span>
                </Grid>
                <Grid >
                  <LineChart isLabelProfile={true}/>
               </Grid>
          </Grid> */}
        
        </>
    )
}

// Customizable Area End