// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");
import { Message } from "../../../../framework/src/Message";

import { HISTORY } from "components/src/common";
export interface Props {
  navigation: any;
  id: string;
  
}

interface S {
  leftsideSelectedtab: any
  rightsideSelectedtab: any
  usertype:string
  tabs:any
  index:any
}

interface SS {
  id: any;
}

export default class AudioVideoController extends BlockComponent<Props, S, SS> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      rightsideSelectedtab: "Audio",
      leftsideSelectedtab: "TopListeners",
      usertype:"",
      tabs:[{month:"This Month"},{month:"July '24"},{month:"Aug '24"},{month:"Sep '24"},{month:"Oct '24"},{month:"Nov '24"},{month:"Dec '24"},{month:"Jan '24"},{month:"Feb '24"},{month:"March '24"},{month:"April '24"}],
      index:""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    const user_type: any = await localStorage.getItem("user_type");
    if (user_type){
      this.setState({usertype:user_type})
    }
    
}
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);


    // Customizable Area End
  }
  handleTab = (tab: string) => {
    this.setState({ leftsideSelectedtab: tab })
  }
  handleAudiovideotab = (tab: string) => {
    this.setState({ rightsideSelectedtab: tab })
  }
  selectedTrack=(item:any,index:any)=>{
    this.setState({index:index})
  }
  redirectToProfile=()=>{
     const userType: any = localStorage.getItem("user_type");
    const loggedIn_userId: any = localStorage.getItem("loggedIn_userId");
    if (userType?.includes("listener")) {
      HISTORY.push({
        pathname: `/listeners/activity?listener_id=${loggedIn_userId}`,
      })
    } 
     else if (userType?.includes("label")) {
      HISTORY.push({
        pathname: `/labels/creatives?labels_id=${loggedIn_userId}`,
      })
    }
    // alert('hello')
  }
}
// Customizable Area End
