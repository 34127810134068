// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { defaultProfile, playButton } from "../../dashboard/src/assets";
export const configJSON = require("./config.js");
import TrackMsgCardController from "./TrackMsgCardController";
import { Row } from "antd";
import { nxzloader } from "blocks/dashboard/src/assets";
import ProfileTrackImg from './ProfileTrackImg.web'


export default class TrackMsgCard extends TrackMsgCardController {
  renderLoader = () => {
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>
    );
  };

  renderBox = (
    isTrack: any,
    onRedirect: any,
    trackData: any,
    currentuserType: any,
    currentUserId: any,
    isRecived: any,
    trackType:string
  ) => {
    return(
    <Box
      style={{
        height: "100%",
        // width: "100%",
        borderRadius: 15,
        backgroundColor: "#141414",
        position: "relative",
        padding: "10px",
      }}
    >
      <img
        style={{
          height: 60,
          width: 60,
          borderRadius: 60,
          borderWidth: 5,
          borderColor: "#141414",
          position: "absolute",
          marginTop: isTrack && trackType !=="videoinvitation" ? 140 : 85,
          marginLeft: -30,
          zIndex: 99,
          backgroundColor: "#141414",
          padding: 10,
        }}
        onClick={() => onRedirect(currentuserType, currentUserId)}
        data-test-id="onRedirect"
        src={this.props.avtar || defaultProfile}
      />

      <Box
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          style={{
            height: 29,
            width: 29,
            borderRadius: 29,
          }}
          src={
            trackData?.attributes?.artist_image||defaultProfile
          }
        />
        <Box style={{ marginLeft: 10 }}>
          <Typography
            style={{ color: "#fff", fontSize: 12, fontWeight: "bold" }}
          >
            {trackData?.attributes?.artist_name||"Unknown"}
          </Typography>
        </Box>
      </Box>
      <Box style={{ margin: 10 }}>
        <ProfileTrackImg
          isTrack={isTrack}
          image={trackData?.attributes?.art_work}
          handleTrackAndVideo={this.handleTrackAndVideo}
          trackType={trackType}
        />
      </Box>
      <div
        style={{
          height: 33,
          backgroundColor: "#000",
          borderRadius: 33,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {this.props.trackType.includes("invitation") ? (
          <Typography
            style={{
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            data-test-id="handleRedirectTracks"
            onClick={ this.handleRedirectTracks}
          >
            {isRecived
              ? "You're invited"
              : `${this.props.particularUserDataRes?.attributes?.artist_name} is invited`}
          </Typography>
        ) : (
          <Typography
            style={{
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={this.handleTrackAndVideo}
          >
            {isTrack ? "Listen Now" : "Watch Now"}
          </Typography>
        )}
      </div>
    </Box>
    )
  };
  render() {
    const {
      isRecived,
      isTrack,
      onRedirect,
      particularUserDataRes,
      isChartMessage,
      trackType
    } = this.props;

    const { trackData, loader } = this.state;
    const user_type: any = localStorage.getItem("user_type");
    const user_id: any = localStorage.getItem("loggedIn_userId");

    let currentuserType = isRecived
      ? particularUserDataRes?.attributes?.artist_role
      : user_type;
    let currentUserId = isRecived ? particularUserDataRes?.id : user_id;

    if (loader) {
      return this.renderLoader();
    } else {
      return (
          <div
            style={{
              height: isChartMessage ? "none" : "100%",
              width: "100%",
              padding: "5px 0px 20px 30px",
              display: "flex",
              alignItems: isRecived ? "flex-start" : "flex-end",
           justifyContent: isRecived? "flex-start" : "flex-end"
            }}
          >
            {this.renderBox(
              isTrack,
              onRedirect,
              trackData,
              currentuserType,
              currentUserId,
              isRecived,
              trackType
            )}
          </div>
        )}
      
  }
}

// Customizable Area End
