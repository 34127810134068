// Customizable Area Start
import React, { useState } from 'react';
import { Typography, Button, TextField } from "@material-ui/core";
import CustomSplitPercentageInput from "./CustomSplitPercentageInput.web";
import './SelectSplitPercentage.web.css';
// Customizable Area End


// Customizable Area Start
interface Props {
    maxPercentage: number;
    selectedPercentage: number;
    setSelectedPercentage: any;
}
// Customizable Area End

// Customizable Area Start
export const SelectSplitPercentage = (props: Props) => {
    const { maxPercentage, selectedPercentage } = props;
    const [value, setValue] = useState<number>(selectedPercentage);
    const [halfSelected, setHalfSelected] = useState<boolean>(false);
    const [maxSelected, setMaxSelected] = useState<boolean>(false);


    const handleHalfClick = () => {
        setSelected(maxPercentage / 2);
        setHalfSelected(true);
        setMaxSelected(false);
        console.log("percent",maxPercentage,selectedPercentage)
    }
    const handleFullClick = () => {
        setHalfSelected(false);
        setMaxSelected(true);
        setSelected(maxPercentage);
    }
    
    const setSelected = (changedValue: number) => {
        setValue(changedValue);
        props.setSelectedPercentage(changedValue)
    }
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const numberOut = Number(e.target.value);
        if (numberOut > maxPercentage) {
            setSelected(maxPercentage)
        } else {
            setSelected(numberOut)
        }
    }
    return (
        <>
            <div className={`SSPMainDiv`}>
                <div className='SSPInputDiv'>
                    <Button
                        data-testid='HalfSplitBtn'
                        className={`SelectSplitButton ${halfSelected && 'selectedSplit'}`}
                        onClick={handleHalfClick}
                    >
                        Half
                    </Button>

                    <TextField
                        data-testid='SplitInputField'
                        value={value}
                        type='text'
                        onChange={onChangeInput}
                        InputProps={{
                            inputComponent: CustomSplitPercentageInput as any,
                        }}
                    />

                    <Button
                        data-testid='MaxSplitBtn'
                        className={`SelectSplitButton ${maxSelected && 'selectedSplit'}`}
                        onClick={handleFullClick}
                    >
                        Max
                    </Button>
                </div>
                <div>
                    <Typography className='RemainingPercentageText'>{maxPercentage - selectedPercentage}% Remaining</Typography>
                </div>
            </div>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default SelectSplitPercentage;
// Customizable Area End
