// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import axios from 'axios';

import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../../../dashboard/src/config.js");


const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    setBackStep: () => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
    coverImage: any;
    linkedId: any;
    AudioVideoList: any;
    SearchInput: any;
}
interface SS {
    id: any;
}

export default class LinkTrackController extends BlockComponent<Props, S, SS> {
    getLinkAudioListApiId: string = ""

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4],
            coverImage: "",
            linkedId: '',
            AudioVideoList: [],
            SearchInput: ''
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getAudioVideoLinkList();
        const hello = await getStorageData("edittrackdetails");
      console.log('checking', hello);
      if(hello){
       this.setState({linkedId:hello?.attributes?.linked_track?.id})
       await setStorageData('uploaded_linkedID', hello?.attributes?.linked_track?.id)
      }
    }
    

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.SearchInput !== prevState.SearchInput) {
          this.getAudioVideoLinkList();
          console.log('audio video calll link')
        }
    }
   
     setValue = async(e: any) =>{
        this.setState({linkedId: e})
        await setStorageData('uploaded_linkedID', e)
      }


   debounceLink = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

   debounceLogLink = this.debounceLink((text: any) => this.haldleLinkSearchInput(text), 1000);

    searchLinkInput = (e: any) =>{
        this.debounceLogLink(e.target.value)
      }

    haldleLinkSearchInput = (text: any) =>{
       this.setState({SearchInput: text})
      }

      getAudioVideoLinkList = () =>{
        const uploadType = window.location.href.split('/')[4]

        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': localStorage.getItem('token')
        };
    
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getLinkAudioListApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${baseUrl}/bx_block_audiomusic/link_track?title=${this.state.SearchInput}&upload_type=${uploadType == 'audio'? "Video" : 'Audio'}`
      
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      handleAudioVideoLinkResponse = (responseJson: any) =>{
         if(responseJson?.data?.length > 0){
            this.setState({AudioVideoList: responseJson?.data})
         }else{
            this.setState({AudioVideoList: []})
         }
      }

      afterSaveLinkTrack = async() =>{
        const UploadFileType = window.location.href.split('/')[4]

        const file = await getStorageData('draft_file')
        const artWork = await getStorageData('uploaded_cover_image')
        const trackDetailsSaved = await getStorageData('uploaded_track_details')

        const fmData: any = new FormData();

        if(UploadFileType == "audio"){
          fmData.append('data[track_file]', file);
        }
        else if(UploadFileType == "video"){
          fmData.append('data[video_file]', file);
        }

        if(artWork){
          fmData.append('data[art_work]', artWork)
        }

        
        fmData.append('upload_stage', 2);

        if(trackDetailsSaved.title != ""){
          fmData.append('data[title]', trackDetailsSaved.title)
        }
        if(trackDetailsSaved.releaseDate != ""){
          const dates = trackDetailsSaved.releaseDate.split('/')
          const releaseDate = `${dates[2]}-${dates[0]}-${dates[1]}`
          fmData.append('data[release_date]', releaseDate)
        }
        if(trackDetailsSaved.releaseTime != ""){
          fmData.append('data[release_time]', trackDetailsSaved.releaseTime)
        }
        if(trackDetailsSaved.uploadType != ''){
          fmData.append('data[upload_type_id]', trackDetailsSaved.uploadType)
        }
        if(trackDetailsSaved.uploadStatus != ""){
          fmData.append('data[status]', trackDetailsSaved.uploadStatus)
        }
       
        if(trackDetailsSaved.key != ""){
          fmData.append('data[key_id]', trackDetailsSaved.key)

        }
        if(trackDetailsSaved.tags.length > 0){
          fmData.append('data[genres]', trackDetailsSaved.tags)
        }

        if(this.state.linkedId != ''){
          fmData.append('data[linked_track_id]', this.state.linkedId)
        }
        
          const headers = {
            "Content-Type": "application/json",
            'token': this.state.token
          };

          await axios.post(`${ baseUrl + '/bx_block_audiomusic/save_as_draft'}`,fmData,{ headers }

        )
        .then((response: any) => {
          
          }).catch((err: any) => {

          });
      }

      async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
         if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.getLinkAudioListApiId
      
              ) {
                if (responseJson !== undefined && !responseJson.errors) {
                   this.handleAudioVideoLinkResponse(responseJson)
                }
             }
         }

        }
}
    
}
// Customizable Area End