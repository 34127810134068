// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    userstats: any
    graph: any
    upload_types_count: any
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserSplitController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getusersplitdataId: string = ""

    getusersplitperformanceStatsId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            userstats: {},
            graph: [],
            upload_types_count: {},
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            },
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
   
    onNextClick = async () => {
        await setStorageData('selectedUser', this.state.selectedUser);
        HISTORY.push({
            pathname: `/deal/select-split-percentage`,
            state: {}
        });
    }
    allapicall=(storedValue:any)=>{
        if (storedValue !== null) {
            const numberid: any = JSON.parse(storedValue)
            this.getusersplit(numberid)
            this.getusersplitperformanceStats(numberid)
        }
       }
       usersplitresponse=(responseJson:any)=>{
        if (responseJson != null) {
            if (!responseJson.error) {
                if (responseJson.hasOwnProperty("message") || responseJson.hasOwnProperty("errors")) {
    
                    this.setState({ loading: false })
                    openNotification(responseJson?.message || responseJson?.errors[0], 'Error')
                }
                else {
                    this.setState({ selectedUser: responseJson.data })
                    console.log("datattatat", responseJson.data)
                    this.setState({ loading: false })
                    setStorageData("splitid", responseJson?.data[0]?.id || "")
                }
            }
        }
       }   
       splitperformanceResponse =(responseJson:any)=>{
    this.setState({ userstats: responseJson })
    const valuesarray = Object.values(responseJson?.last_twelve_week_play_count)
    this.setState({ graph: valuesarray })
    this.setState({ loading: false })
    this.setState({ upload_types_count: responseJson?.upload_types_count })
   }

    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const userData = await getStorageData('user');
        

        const storedValue: string | null = localStorage.getItem("dealUserId");
       this.allapicall(storedValue)
        
        this.setState({ loading: true })
        // if(userData){
        //     this.setState({
        //         selectedUser: userData,
        //     })
        // }else{
        //     HISTORY.push('/deal')
        // }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getusersplitdataId) {
                this.usersplitresponse(responseJson)
                
            }

            if (apiRequestCallId === this.getusersplitperformanceStatsId) {
                
                this.splitperformanceResponse(responseJson)
               

            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    getusersplit = (numberid:any) => {
        // this.setState({loading: true})
        
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                'token': localStorage.getItem('token')
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getusersplitdataId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `/account_block/list_user_splits?user_id=${numberid}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
    getusersplitperformanceStats = (numberid:any) => {
        // this.setState({loading: true})
        
            
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                'token': localStorage.getItem('token')
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getusersplitperformanceStatsId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_subscription_plan/show_user_stats?user_id=${numberid}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    // Customizable Area End
}
