// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
type userID=  string | number | null

type ResponseJson = {
    link_token:string;
    value:boolean;
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    dealAmount: number;
    amountValid: boolean;
    linkToken:string;
    isAccountLinkedToPlaid:boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DealAmountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    dealnegotiatingid:string=""
    getCreateLink: string="";
    addPlaidAccount:string="";
    checkLinkAccountToPlaid:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            },
            dealAmount: 0,
            amountValid: true,
            linkToken:'',
            isAccountLinkedToPlaid:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  

    onAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            dealAmount:Number(e.target.value)
        })
    }

    onNextClick = async () => {
        const data = await getStorageData('splitType');
        if (this.state.dealAmount <= 0 ) {
            openNotification("Enter amount", 'Error')
        }
        else if (data == "negotiate"){
            this.dealnegotiating()
        }
        else {
            await setStorageData('dealAmount', this.state.dealAmount)
            HISTORY.push({
                pathname: `/deal/select-deal-term`,
                state: {}
            });
        }
    }


    isLinkOpen=()=>{
        if (this.state.dealAmount <= 0 ) {
            openNotification("Enter amount", 'Error')
            return false
        }else{
            return true
        }
    }


    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const user_id:string|null|number = await localStorage.getItem('user_id');
        this._createLinkTokenAPICall();
        this._checkLinkAccountToPlaidAPICall(user_id)
        const user = await getStorageData('selectedUser')
        const deal_id=await localStorage.getItem("deal_id")

        if (user) {
            this.setState({
                selectedUser: user,
            })
        } 
        else if(deal_id){
            // alert("ok to go")
        }
        else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleCheckList=(responseJson:ResponseJson)=>{
        if(responseJson.link_token){
            this.setState({linkToken:responseJson.link_token})
        }
      
        else{
           openNotification("Error occurred while fetching the link token,Please try it after a while.","Error")
        }
    }

    handleCheckAccountToPlaid=(responseJson:ResponseJson)=>{
        if(responseJson.value){
            this.setState({isAccountLinkedToPlaid:true})
        }
      
        else{
            this.setState({isAccountLinkedToPlaid:false})
           
        }
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            if (apiRequestCallId === this.dealnegotiatingid) {
                console.log("success", responseJson)
                if(responseJson?.message){
                    openNotification(responseJson?.message, "success")
                    HISTORY.push({
                        pathname: `/deal/offer-sent`,
                        state: {}
                    });
                }
              
                else{
                    openNotification(responseJson?.error, "error")
                }
    
            }else if (apiRequestCallId === this.getCreateLink) {
               this.handleCheckList(responseJson)
              }else if (apiRequestCallId === this.addPlaidAccount) {
                this.handleCheckList(responseJson)
               }
              else if (apiRequestCallId === this.checkLinkAccountToPlaid) {
                this.handleCheckAccountToPlaid(responseJson)
              } 
              
        
        }
        // Customizable Area End
    }

    // Customizable Area Start
    dealnegotiating =async ()=>{
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };
        const item = localStorage.getItem("deal_id");
        const id = item ? JSON.parse(item) : null;
        const data = {
            amount:  this.state.dealAmount,
            
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.dealnegotiatingid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_subscription_plan/negotiate_deal?amount=${data.amount}&deal_id=${id}&negotiation_state=negotiating`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        


        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void>{
        if(prevState.isAccountLinkedToPlaid !== this.state.isAccountLinkedToPlaid){
            let user_id= await localStorage.getItem('user_id');
            this._checkLinkAccountToPlaidAPICall(user_id)
        }
    }

    _checkLinkAccountToPlaidAPICall= async (accountId: userID) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.checkLinkAccountToPlaid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_payments/check_account_link?account_id=${accountId}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    
    }

    _addPlaidAccountAPICall= async (plaid_acc_id: userID) => {
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addPlaidAccount = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_payments/add_plaid_account?plaid_account_id=${plaid_acc_id}`
    );


    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({})
      );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

    }

    _createLinkTokenAPICall = async () => {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: await localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getCreateLink = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `/bx_block_payments/create_link_token`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify({})
              );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        
    }

    accountLinkedState = () => {
    this.setState({isAccountLinkedToPlaid: !this.state.isAccountLinkedToPlaid})
    };
    // Customizable Area End
}
