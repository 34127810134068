// Customizable Area Start
import React, { useContext, useEffect, useRef, useState } from "react";
import "./customAudioPlayer.css";
import {
  album1,
  album2,
  album3,
  musicDownload,
} from "../../dashboard/src/assets";
import {
  Col,
  Form,
  Modal,
  Row,
  Select,
  Slider,
  Spin,
  Space,
  Input,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { openNotification } from "../../../components/src/Notification.web";
import { useHistory } from "react-router-dom";
import { Grid, Hidden, Button, ButtonGroup } from "@material-ui/core";
import CustomAudioPlayerCoverImage from "./CustomAudioPlayerCoverImage.web";
import CustomAudioPlayerCenterIcons from "./CustomAudioPlayerCenterIcons.web";
import CustomAudioPlayerRightIcons from "./CustomAudioPlayerRightIcons.web";
import CustomAudioCloseLogin from "./CustomAudioCloseLogin.web";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";
const urlConfig = require("./../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

const IconColor: any = {
  fontSize: "25px ",
  background: "blue ",
  borderRadius: "50%",
  padding: "7px",
};

const AudioPlayerFun = (props: any) => {
  const { track_file, songSrc, audioPlayer, onPlaying } = props;
  return (
    <audio
      autoPlay={true}
      src={track_file || songSrc}
      ref={audioPlayer}
      onTimeUpdate={onPlaying}
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
};

export const SmMobileView = (props: any) => {
  const { title, artist_name } = props;
  return (
    <div className="mobile-view">
      <div className="label">
        <h3 className="mobile-view-audio-title">{title || "Unknown"}</h3>
        <p>{artist_name || "Unknown"}</p>
      </div>
    </div>
  );
};

export const UploadCoverArt = (props: any) => {
  const { imageUrl } = props;
  return (
    <div style={{ display: "flex" }}>
      <img
        style={{
          height: "48px",
          width: "48px",
          borderRadius: "50%",
          background: "#f5f5f5",
        }}
        src={
          imageUrl
            ? imageUrl
            : "https://images.squarespace-cdn.com/content/v1/56e19ec5e3214084d69d4b7d/1473524254173-BGV5W2Z5FM46F67SYZHJ/PlusIcon_Small_Gray.png"
        }
        alt=""
      />
      &nbsp;&nbsp;
      <p
        aria-label="upload picture"
        style={{ color: "#8f92a1", marginTop: "11px" }}
      >
        Upload cover art
      </p>
    </div>
  );
};

const CustomAudioPlayer = ({
  setEndingTimeApp,
  setCurrentTimeApp,
  setPlaylistData,
  handleAudioVideo,
  setHandleAudioVideo,
  playlistData,
  playList,
  checkType,
  removeAudioPlayer,
  playlistQueue,
  contextQueueSongs,
  setNextPlaylistTrackFunction,
  playlistQueueSongs,
  setAudioLoader,
  audioLoader,
  removeNowPlayingSong,
}: any) => {
  const [playBt, setPlayBt] = useState<any>(true);
  const audioPlayer: any = useRef();
  const [currentTime, setCurrentTime] = useState<any>(0);
  const [seekValue, setSeekValue] = useState(0);
  const [endingTime, setEndingTime] = useState<any>(0);
  const [sound, setSound] = useState<any>(false);
  const [loopSys, setLoopSys] = useState<any>(false);
  const [nextTrack, setNextTrack] = useState<any>(playlistData);
  const [trackIndex, setTrackIndex] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState<any>("");
  const [openAlertLogin, setopenAlertLogin] = useState(false);
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [musicButton, setMusicButton] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [songId, setSongId] = useState<number>();
  const [closeAudio, setCloseAudio] = useState<any>(false);
  const [isplaylist, setIsPlaylist] = useState<any>(false);

  const token: any = localStorage.getItem("token");
  const user_id: any = localStorage.getItem("user_id");
  const userType: any = localStorage.getItem("user_type");
  const history = useHistory();
  useEffect(() => {
    if (typeof playlistData === "string") {
      setNextTrack(JSON.parse(playlistData));
    }
  }, [playlistData]);

  let wholeList = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");

  if (isplaylist) {
    let queueList = JSON.parse(
      localStorage.getItem("queuePlaylistSongs") || "[]"
    );
    if (queueList.length > 0) {
      wholeList = queueList;
    }
  }
  const [nextPlaylistTrack, setNextPlaylistTrack] = useState<any>(
    wholeList.length > 1 ? wholeList[1] : playlistData
  );

  const { attributes } = nextTrack;
  const {  artist_name, title, track_file } = attributes || {};

  const { setNextSongForPlay } = useContext(AudioContextApi);

  useEffect(() => {
    const removeId = removeNowPlayingSong.id;
    if (removeId) {
      if (removeId == nextTrack.id) {
        nextOrder();
        setNextSongForPlay(nextTrack);
      }
    }
  }, [removeNowPlayingSong]);

  useEffect(() => {
    let nextUpSongs = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");
    if (nextUpSongs.length > 0) {
      const index = nextUpSongs.findIndex(
        (item: any) => item.id === nextPlaylistTrack?.id
      );
      if (index == -1 && nextUpSongs.length > 0) {
        setNextPlaylistTrack(wholeList[0]);
      }
      if (nextUpSongs.length > 0) {
        wholeList = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");
      } else {
        wholeList =
          typeof playList === "string" ? JSON.parse(playList) : playList;
      }
    }
  }, [localStorage.getItem("nextUpSongs")]);

  useEffect(() => {
    const index = contextQueueSongs.findIndex(
      (item: any) => item.id === nextTrack?.id
    );
    if (index == 1) {
      contextQueueSongs.splice(0, 1);
      localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
    }
  }, [nextTrack]);

  useEffect(() => {
    setNextPlaylistTrackFunction(nextPlaylistTrack);
  }, [nextPlaylistTrack]);

  useEffect(() => {
    if (localStorage.getItem("queuePlaylistSongs")) {
      setIsPlaylist(true);
    } else {
      setIsPlaylist(false);
    }
  }, [localStorage.getItem("queuePlaylistSongs"), playlistQueueSongs]);

  const nextElseQueueSongs = (index: any) => {
    if (contextQueueSongs.length > 0) {
      setNextTrack(contextQueueSongs[index + 1]);
      setPlaylistData(contextQueueSongs[index + 1]);
      play(contextQueueSongs[index + 1]?.id);
    }
  };

  const queueSongsNextOrder = () => {
    const index = contextQueueSongs.findIndex(
      (item: any) => item.id === nextTrack?.id
    );

    if (index === -1) {
      setNextTrack(contextQueueSongs[0]);
      setPlaylistData(contextQueueSongs[0]);
      play(contextQueueSongs[0]?.id);
      contextQueueSongs.splice(0, 1);
      localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
    } else if (
      index == 0 &&
      nextTrack?.id == JSON.parse(localStorage.getItem("selectedSong") || "")
    ) {
      contextQueueSongs.splice(0, 1);
      localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
      console.log(nextPlaylistTrack);
      setNextTrack(nextPlaylistTrack);
      setPlaylistData(nextPlaylistTrack);
      play(nextPlaylistTrack?.id);
    } else if (index === contextQueueSongs.length - 1) {
      if (isplaylist) {
        const index = wholeList.findIndex(
          (item: any) => item.id === nextTrack?.id
        );
        if (index === -1) {
          setNextTrack(wholeList[0]);
          setPlaylistData(wholeList[0]);
          play(wholeList[0]?.id);
          contextQueueSongs.splice(0, 1);
          localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
        } else if (index === wholeList.length - 1) {
          return null;
        } else {
          setNextTrack(wholeList[index + 1]);
          setPlaylistData(wholeList[index + 1]);
          play(wholeList[index + 1]?.id);
          setNextPlaylistTrack(wholeList[index + 2]);
        }
      } else {
        return null;
      }
    } else {
      nextElseQueueSongs(index);
    }
  };

  const normalListPlaySongs = () => {
    const index = wholeList.findIndex((item: any) => item.id === nextTrack?.id);
    if (index === -1) {
      if (nextPlaylistTrack) {
        if (
          nextPlaylistTrack?.id !=
          JSON.parse(localStorage.getItem("selectedSong") || "")
        ) {
          setNextTrack(nextPlaylistTrack);
          setPlaylistData(nextPlaylistTrack);
          play(nextPlaylistTrack?.id);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else if (index === wholeList.length - 1) {
      return null;
    } else {
      setNextTrack(wholeList[index + 1]);
      setPlaylistData(wholeList[index + 1]);
      play(wholeList[index + 1]?.id);
      if (isplaylist) {
        setNextPlaylistTrack(wholeList[index + 2]);
      }
    }
  };

  const cryptoRandomNumber = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / 0xFFFFFFFF;
  };
  

  const nextOrder = async () => {
    setAudioLoader(true);
    await pause();
    setIsActive(false);
    if (loopSys) {
      await restart();
      setPlayBt(true);
    } else {
      if (isShuffle) {
        let item = wholeList[Math.floor(cryptoRandomNumber() * wholeList.length)];
        setNextTrack(item);
        await play(item?.id);
        setElapsedTime(0)
      } else {
        if (contextQueueSongs.length > 0) {
          queueSongsNextOrder();
        setElapsedTime(0)
        } else {
          normalListPlaySongs();
        setElapsedTime(0)
        }
      }
    }
    await setAudioLoader(false);
  };


  useEffect(() => {
    setIsActive(false);
    setNextTrack(playlistData);
    play(playlistData?.id);
  }, [playlistData]);
  const tracks: any = [
    {
      id: 1,
      songSrc:
        "http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3",
      songImg: `${album1}`,
      songName: "abcc",
      songArtist: "rock",
    },
    {
      id: 2,
      songSrc:
        "http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/lose.ogg",
      songImg: `${album2}`,
      songName: "abcc",
      songArtist: "rock",
    },
    {
      id: 3,
      songSrc:
        "http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/menu.ogg",
      songImg: `${album3}`,
      songName: "abcc",
      songArtist: "rock",
    },
  ];

  useEffect(() => {
    if (currentTime == 0) {
      stop();
    } else {
      if (playBt) {
        audioPlayer.current.play();
      } else {
      }
    }
  }, [trackIndex, playBt]);
  useEffect(() => {
    if (currentTime !== 0 && endingTime !== 0) {
      if (currentTime === endingTime) {
        nextOrder();
      }
    }
  }, [currentTime, endingTime]);


  useEffect(() => {
    let interval: any = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setElapsedTime((elapsedTime) => elapsedTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    elapsedTime == 30000 && addPlayTrackCount(songId);
  }, [songId, elapsedTime]);

  const addPlayTrackCount = async (id: any) => {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",

      token: token,
    };
    await axios
      .get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, { headers })
      .then((response: any) => {})
      .catch((err: any) => {
        if (token) {
          openNotification(
            err?.response?.data?.errors &&
              err?.response?.data?.errors[0]?.token,
            "Error"
          );
        } else {
          console.log("error in play_track api ");
        }
      });
  };

  const { songSrc, songImg } = tracks[trackIndex];
  const play = async (id?: any) => {
    localStorage.setItem("selectedSong", JSON.stringify(id));
    localStorage.setItem("selectedAudio",JSON.stringify(id))
    setPlayBt(true);
    setAudioLoader(true);
    const timer = setTimeout(async () => {
      await audioPlayer.current.play();
      setHandleAudioVideo(2);
      await setAudioLoader(false);
    }, 500);
    if (typeof id !== "object" && id) {
      setSongId(id);
    } else if (typeof id === "object" && id) {
      setSongId(nextTrack?.id);
    }

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    if (handleAudioVideo === 1) {
      audioPlayer.current.pause();
      setPlayBt(false);
      setIsActive(false)
      setIsPaused(true)
    } else {
      setIsActive(true)
      setIsPaused(false)
      setPlayBt(true);
    }
  }, [handleAudioVideo]);
  const pause = async () => {
    await setAudioLoader(true);
    await audioPlayer.current.pause();
    setPlayBt(false);
    setIsPaused(!isPaused);
    setHandleAudioVideo(1);
    setAudioLoader(false);
  };
  const stop = async () => {
    setAudioLoader(true);
    setIsActive(false);
    await audioPlayer.current.pause();
    audioPlayer.current.currentTime = 0;
    setAudioLoader(false);
  };
  const restart = async () => {
    setAudioLoader(true);
    audioPlayer.current.currentTime = 0;
    await audioPlayer.current.play();
    setHandleAudioVideo(2);
    setAudioLoader(false);

    // addPlayTrackCount(nextTrack?.id)
  };
  const setSpeed = (speed: any) => {
    audioPlayer.current.playbackRate = speed;
  };

  // todo...........
  // issue is : when ending Time not geting ui is stcukkkk.....

  const seekBar = (value: any) => {
    // setSeekValue((value / endingTime) * 100);
    const seek = (endingTime * value) / 100;
    audioPlayer.current.currentTime = seek;
    // audioPlayer.current.play();
    // setPlayBt(true)
  };

  const onPlaying = () => {
    //  setIsActive(true);
    //  setIsPaused(false);
    setCurrentTimeApp(audioPlayer.current.currentTime);
    setCurrentTime(audioPlayer.current.currentTime);
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
    );
    setEndingTimeApp(audioPlayer.current.duration);
    setEndingTime(audioPlayer.current.duration);
  };

  const StartTimeCalc = () => {
    const minute = Math.floor(currentTime / 60);
    const minutes: any = minute < 9 ? "0" + Number(minute) : minute;
    const second = parseInt(currentTime) - minutes * 60;
    const seconds: any = second < 9 ? "0" + Number(second) : second;
    return {
      minutes,
      seconds,
    };
  };
  const endTime = () => {
    const minute = Math.floor(endingTime / 60);
    const minutes: any = minute < 9 ? "0" + Number(minute) : minute;
    const second = parseInt(endingTime) - minutes * 60;
    const seconds: any = second < 9 ? "0" + Number(second) : second;
    return {
      minutes,
      seconds,
    };
  };

  const volumeChange: any = (value: any) => {
    audioPlayer.current.volume = value;
    setSound(false);
    soundOn();
  };

  const soundOff: any = () => {
    audioPlayer.current.muted = true;
    setSound(true);
  };
  const soundOn: any = () => {
    audioPlayer.current.muted = false;
    setSound(false);
  };

  const AudioLoop: any = (bool: any) => {
    audioPlayer.current.loop = bool;
    setLoopSys(bool);
  };

  const toPrevTrack = () => {
    pause();
    setIsActive(false);
    if (isShuffle) {
      let item = wholeList[Math.floor(cryptoRandomNumber())];
      setNextTrack(item);
      play(item?.id);
    } else {
      const index = wholeList.findIndex(
        (item: any) => item.id === nextTrack?.id
      );
      if (index === 0 || index === -1 || index === wholeList.length - 1) {
        // No such order or no next order
        return null;
      } else {
        setNextTrack(wholeList[index - 1]);
        setPlaylistData(wholeList[index - 1]);
        play(wholeList[index - 1]?.id);
        setElapsedTime(0)
    setIsActive(true);

      }
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };
  const finishCheck = () => {
    if (currentTime === endingTime) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogText(token ? "1" : "2");
  };

  const handleClose = async () => {
    await setAudioLoader(true);
    setOpen(false);
    await setAudioLoader(false);
  };

  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false);
  const [userplaylist, setUserplaylist] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [emptyPlaylist, setEmptyPlaylist] = useState(false);
  const [newPlaylistId, setNewPlaylistId] = useState<any>("");
  const [isShuffle, setIsShuffle] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const { Option } = Select;
  const handleAddToPlaylist = () => {
    // if (token) {

    //   setOpenPlaylistModal(true),
    //     getUserPlaylist()
    // } else {
    //   setopenAlertLogin(true),
    //     setDialogTextAlertLogin(token ? "1" : "2")
    // }

    // new sprint..
    if (playlistData.type == "playlist") {
      history.push({
        pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
        state: { data: playlistData, playlist: playlistData },
      });
    } else if (playList.type == "playlist" || playList.type == "Playlist") {
      history.push({
        pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
        state: { data: playlistData, playlist: playList },
      });
    } else {
      if (checkType.includes("Playlist") || checkType.includes("playlist")) {
        history.push({
          pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
          state: { data: playlistData, playlist: playlistQueue },
        });
      } else {
        if (playlistData?.attributes?.track_id != ("" || null)) {
          history.push(
            `/home/playlist-queue/audio/id=${playlistData?.attributes?.track_id}`
          );
        } else {
          history.push({
            pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
            state: { data: playlistData },
          });
        }
      }
    }
  };

  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };
  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        err?.response.status === 422 && setEmptyPlaylist(true);
      });
  };

  const handleAddTrackToPlaylist = async (values: any) => {
    setLoading(true);
    const data = {
      add_track_id: playlistData.id,
      play_list_id: playlistId,
    };
    await axios
      .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        openNotification(
          "This song is successfully added in playlist",
          "Success"
        );
        setOpenPlaylistModal(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl("");
      })
      .catch((err: any) => {
        if (err?.response?.status === 409) {
          openNotification(err.response.data.data[0].message, "Error");
        } else if (err?.response?.status === 400) {
          openNotification(err?.response?.data?.errors[0]?.token, "Error");
        } else {
          console.log("error in add_track_to_playlist");
        }
      })
      .finally(() => {
        setOpenPlaylistModal(false);
        setLoading(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl("");
      });
    setimageUrl("");
  };

  const [playlistId, setPlaylistId] = useState<any>("");
  const [addTrackId, setAddTrackId] = useState<any>(playlistData.id);
  function onChange(value: any) {
    setPlaylistId(value);
  }

  function onSearch(val: any) {
    console.log("search:", val);
  }
  const [imageUrl, setimageUrl] = useState<any>("");
  const [fileName, setfileName] = useState<any>("");
  const [playlistName, setplaylistName] = useState<any>("");
  const fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0) {
      let url = URL.createObjectURL(files[0]);
      setimageUrl(url);
      for (const file of files) {
        setfileName(files);
      }
    }
  };

  const UploadPlayLists = async (fileName: any) => {
    setLoading(true);
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",

      token: token,
    };
    if (fileName && playlistName) {
      let formData = new FormData();
      for (const key of fileName) {
        //formdata object
        formData.append("name", playlistName);
        formData.append("cover_image", key);
        // checking typingg.....
        formData.append("play_list_type", musicButton ? "Video" : "Audio");
      }
      //@ts-ignore
      document
        .getElementById("createPlayListBtn")
        ?.setAttribute("disabled", "true");  //changes done here true replaced with "true"
      document
        .getElementById("createPlayListBtn")
        ?.setAttribute("style", "background :#1890ff; color:#fff; width:100%;");
      await axios
        .post(`${baseUrl}/bx_block_playlist/play_lists`, formData, { headers })
        .then(async (response: any) => {
          openNotification("New PlayList Added Successfully", "Success");
          const data = {
            add_track_id: playlistData.id,
            play_list_id: response.data.data.id,
          };

          await axios
            .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
              },
            })
            .then((res) => {
              // openNotification("This song is successfully added in playlist", "Success")
              setOpenPlaylistModal(false);
              form1.resetFields();
            })
            .catch((err) => {
              openNotification(
                err?.response?.data?.errors[0]?.account,
                "Error"
              );
              openNotification(err.response.data[0].message, "Error");
            })
            .finally(() => {
              setOpenPlaylistModal(false);
              form.resetFields();
              form1.resetFields();
              setimageUrl("");
            });
        })
        .catch((err: any) => {
          openNotification("Playlist of this name already exist.", "Error");
          err?.response?.status === 422 &&
            openNotification(err?.response?.data?.errors[0]?.name, "Error");
          err?.response?.status === 400 &&
            openNotification(err?.response?.data?.errors[0]?.token, "Error");
        })
        .finally(() => {
          setLoading(false);
          form.resetFields();
          form1.resetFields();
          setimageUrl("");
        });
    }
  };

  const redirectToDownloads = () => {
    if (!token) {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2");
    } else {
      history.push("/home/downloadPage");
    }
  };
  const handleAddTrackToCreatePlaylist = async (values: any) => {
    const data = {
      add_track_id: playlistData.id,
      play_list_id: newPlaylistId,
    };
    await axios
      .post(`${baseUrl}/bx_block_playlist/add_track_to_playlis?`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        openNotification(
          "This song is successfully added in playlist",
          "Success"
        );
        setOpenPlaylistModal(false);
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.account, "Error");
      })
      .finally(() => {
        setOpenPlaylistModal(false);
      });
  };
  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setimageUrl("");
    setOpenPlaylistModal(false);
  };

  const saveFile = (url: any, name: any) => {
    const url1: any = window.URL.createObjectURL(new Blob([url]));
console.log(url1,"url..customAudioPlayer")
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const saveDownloadHistory = async () => {
    const lowerCaseCheckType = checkType.toLowerCase();
    if ((lowerCaseCheckType !== "songs") && (userType.includes("listener") || userType.includes("listener_plus"))) {
      if (lowerCaseCheckType !== "songs" && lowerCaseCheckType !== "song") {
        setOpen(true);
        setDialogText(userType.includes("listener") || userType.includes("listener_plus") ? "3" : "2");
    
    }
    } else {
      const data = {
        account_id: user_id,
        add_track_id: playlistData.id,
      };
      await axios
        .post(`${baseUrl}/download_histories`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        })
        .then((res) => {
          // openNotification("This song is successfully added in playlist", "Success")
          console.log("successfully downloaded");
        })
        .catch((err: any) => {
          console.log(err,"error in download");
        });
    }
  };

  let downloadType;

  const isDownload = () => {
    if (attributes?.is_download) {
      downloadType = (
        <img
          src={musicDownload}
          className="music-icon-size"
          onClick={redirectToDownloads}
        />
      );
    } else {
      downloadType = (
        <a
          href={!(userType?.includes("listener")|| userType?.includes("listener_plus")) || 
          ((userType?.includes("listener")||userType?.includes("listener_plus")) && (checkType.toLowerCase() == "songs"||checkType.toLowerCase()=="song"))
           ? `${track_file}?response-content-disposition=attachment` : undefined}
          
          download={title}
          onClick={saveDownloadHistory}
        >
          {token && (
            // <button id="download"

            <img
              src={musicDownload}
              className="music-icon-size"
              onClick={saveDownloadHistory}
            />
          )}
        </a>
      );
    }
  };

  const downloadElse = () => {
    downloadType = (
      <div>
        <img
          src={musicDownload}
          onClick={() => {
            setOpen(true);
            setDialogText((userType.includes("listener")||userType.includes("listener_plus")) && token ? "3" : "2");
          }}
          className="music-icon-size"
        />
      </div>
    );
  };

  switch (true) {
    case checkType == "Songs" || checkType == "Song":
      isDownload();
      break;
    case userType !== "listener"|| userType !== "listener_plus":
      isDownload();
      break;
    default:
      downloadElse();
  }

  const hoverAudioCancel = () => {
    setCloseAudio(true);
  };

  const hoverAudioLeave = () => {
    setCloseAudio(false);
  };

  const closeAudioPlayer = () => {
    removeAudioPlayer();
  };

  const loginAlertFun = () => {
    return (
      <>
        {open && (
          <LoginAlert
            open={open}
            handleClose={handleClose}
            dialogText={dialogText}
          />
        )}

        {openAlertLogin && (
          <LoginAlert
            open={openAlertLogin}
            handleClose={handleCloseAlertLogin}
            dialogText={dialogTextAlertLogin}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className="mainAudioPlayer"
        style={{ position: "relative" }}
        onMouseMove={hoverAudioCancel}
        onMouseLeave={hoverAudioLeave}
        data-test-id="mainAudioPlayer"
      >
        <div>
          <AudioPlayerFun
            track_file={track_file}
            songSrc={songSrc}
            audioPlayer={audioPlayer}
            onPlaying={onPlaying}
          />

          {/* ------------------------------------------------------------------------------------- */}
          <Hidden smDown>
            <Grid container xs={12}>
              <SmMobileView title={title} artist_name={artist_name} />

              <CustomAudioPlayerCoverImage
                checkType={checkType}
                playlistData={playlistData}
                attributes={attributes}
                artist_name={artist_name}
                title={title}
                loopSys={loopSys}
                AudioLoop={AudioLoop}
                toPrevTrack={toPrevTrack}
                playList={playList}
                loader={audioLoader}
                finishCheck={finishCheck}
                restart={restart}
                IconColor={IconColor}
                playBt={playBt}
                pause={pause}
                play={play}
                StartTimeCalc={StartTimeCalc}
                nextOrder={nextOrder}
                seekBar={seekBar}
                seekValue={seekValue}
                endingTime={endingTime}
                endTime={endTime}
                token={token}
                downloadType={downloadType}
                sound={sound}
                handleAddToPlaylist={handleAddToPlaylist}
                soundOn={soundOn}
                soundOff={soundOff}
                volumeChange={volumeChange}
                setHandleAudioVideo={setHandleAudioVideo}
              />
            </Grid>
          </Hidden>

          {/* ----------------------tab view-------------------------------------- */}
          <Hidden only={["xs"]} mdUp>
            <Slider
              className="seek-bar"
              onChange={seekBar}
              value={seekValue}
              trackStyle={{ backgroundColor: "blue" }}
              tooltip={{
                open: false,
              }}
              style={{ margin: "0px 0px -4px 0px" }}
            />
            <Grid container item xs={12} className="audio-player">
              <CustomAudioPlayerCenterIcons
                token={token}
                checkType={checkType}
                playlistData={playlistData}
                attributes={attributes}
                title={title}
                artist_name={artist_name}
                loopSys={loopSys}
                AudioLoop={AudioLoop}
                toPrevTrack={toPrevTrack}
                loader={audioLoader}
                finishCheck={finishCheck}
                restart={restart}
                IconColor={IconColor}
                playBt={playBt}
                downloadType={downloadType}
                pause={pause}
                nextOrder={nextOrder}
                play={play}
                handleAddToPlaylist={handleAddToPlaylist}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <CustomAudioPlayerRightIcons
              seekBar={seekBar}
              seekValue={seekValue}
              checkType={checkType}
              playlistData={playlistData}
              attributes={attributes}
              title={title}
              artist_name={artist_name}
              loader={audioLoader}
              finishCheck={finishCheck}
              restart={restart}
              IconColor={IconColor}
              playBt={playBt}
              pause={pause}
              play={play}
            />
          </Hidden>
        </div>
        {loginAlertFun()}
        {openPlaylistModal ? (
          <>
            <Row className="modal_playlist">
              <Modal
                centered
                className="admin-settings-pwd-update-modal"
                destroyOnClose
                // title=" Submitted Queries"
                footer={false}
                visible={openPlaylistModal}
                //  onOk={handleOk} onCancel={handleCancel}
              >
                {loading ? (
                  <Row
                    className="mt-6  width_100"
                    align="middle"
                    justify="center"
                    style={{
                      height: "250px",
                    }}
                  >
                    <Col>
                      {" "}
                      Add to Playlist
                    </Col>{" "}

                    <Col span={2}>
                        {/* @ts-ignore */}
                      <CloseCircleOutlined

                        onClick={handleClosePlaylistModal}

                        // translate={undefined}
                       
                      />
                      <Spin className="width_100" size="large" />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      className="font_25 font_W_400 mb_1"
                      align="middle"
                      justify="center"
                    >
                      <Col span={22} style={{ textAlignLast: "center" }}>
                        {" "}
                        Add to Playlist
                      </Col>{" "}
                      <Col span={2}>
                          {/* @ts-ignore */}
                        <CloseCircleOutlined
                          onClick={handleClosePlaylistModal}
                        />
                      </Col>
                    </Row>
                    <Row
                      // className="highlight-head"
                      className="width_100"
                      justify="center"
                      align="middle"
                    >
                      {!emptyPlaylist && (
                        <Form
                          form={form}
                          className="width_100 selectPlaylist title-input login_form"
                          onFinish={handleAddTrackToPlaylist}
                        >
                          <Form.Item
                            name="select_playlist"
                            className=" width_100 mb_4 mt-3"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Your Playlist",
                              },
                            ]}
                          >
                            <Select
                              className="width_100"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select playlist"
                              onChange={onChange}
                              onSearch={onSearch}
                              dropdownStyle={{}}
                              filterOption={(input: any, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {userplaylist &&
                                userplaylist.map((ind: any) => (
                                  <Option value={ind?.id} key={ind?.id}>
                                    {ind?.attributes?.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <Form.Item className="width_100 mt-5">
                            <Row justify="center">
                              {" "}
                              <Button
                                className="mt-5  width_100 red_custom_btn cust-btn"
                                // htmlType="submit"
                                type="submit"
                              >
                                ADD{" "}
                              </Button>
                            </Row>
                          </Form.Item>
                          <Row
                            align="middle"
                            justify="center"
                            className="text_white font_15"
                          >
                            or
                          </Row>
                        </Form>
                      )}
                      <Form
                        form={form1}
                        className="width_100 selectPlaylist"
                        onFinish={() => UploadPlayLists(fileName)}
                      >
                        <Col
                          span={22}
                          style={{ textAlignLast: "center", color: "white" }}
                          className="font_25 font_W_400 mb_1"
                        >
                          {" "}
                          Add New Playlist
                        </Col>{" "}
                        {/* <Row className="text_white1 font_16 mb_1">
                        Add new playlist
                      </Row> */}
                        <Form.Item
                          // label="Username"
                          className="width_100 "
                          name="title"
                          rules={[
                            { required: true, message: "Please Add Playlist." },
                          ]}
                        >
                          <Input
                            className="mui_input"
                            placeholder="New Playlist"
                            onChange={(e: any) => {
                              setplaylistName(e.target.value);
                            }}
                            name="newPlaylist"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item className="width_100 mt-5">
                          <Row justify="start">
                            <ButtonGroup
                              color="inherit"
                              className="mr_1"
                              aria-label="outlined primary button group"
                            >
                              <Button
                                className="audio-cust-button"
                                value="audio"
                                variant="contained"
                                disabled={musicButton === false}
                                onClick={() => setMusicButton(false)}
                              >
                                <span className="audio-text">Audio</span>
                              </Button>
                              <Button
                                variant="contained"
                                className="video-cust-button"
                                onClick={() => setMusicButton(true)}
                                disabled={musicButton === true}
                              >
                                <span className="audio-text">Video</span>
                              </Button>
                            </ButtonGroup>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          // label="Username"
                          className="width_100 mt-1"
                          name="fileName"
                          rules={[
                            {
                              required: true,
                              message: "Please Upload Cover Art.",
                            },
                          ]}
                        >
                          <Space style={{ textAlign: "left" }}>
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              // name="profilePic"
                              style={{ display: "none" }}
                              onChange={(event) => fileUploadHandler(event)}
                            />
                            <label htmlFor="icon-button-file">
                              <UploadCoverArt imageUrl={imageUrl} />
                            </label>
                          </Space>
                        </Form.Item>
                        <Form.Item className="width_100">
                          <Row justify="center">
                            <Button
                              style={{ marginTop: "17px" }}
                              className="width_100 red_custom_btn cust-btn"
                              // htmlType="submit"
                              typeof="submit"
                              onClick={() => UploadPlayLists(fileName)}
                            >
                              CREATE A PLAYLIST
                            </Button>
                          </Row>
                        </Form.Item>
                        {/* <Form.Item className="width_100 " >
                    <Row justify="center">  <Button type="primary" style={{marginTop:"17px"}} className='  width_100 red_custom_btn'
                      htmlType="submit"
                    >CREATE A PLAYLIST </Button></Row>
                  </Form.Item> */}
                      </Form>
                      {/* //   ))
              // } */}

                      {/* </>) : <Row> No Playlist created</Row>} */}
                    </Row>
                  </>
                )}
              </Modal>
            </Row>
          </>
        ) : (
          ""
        )}
        <CustomAudioCloseLogin
          closeAudio={closeAudio}
          open={open}
          handleClose={handleClose}
          dialogText={dialogText}
          closeAudioPlayer={closeAudioPlayer}
        />
      </div>
    </>
  );
};

export default React.memo(CustomAudioPlayer);
// Customizable Area End
