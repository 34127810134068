// Customizable Area Start

import { Typography, Tabs,Layout } from 'antd';
import {
    Box,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Hidden
} from "@material-ui/core";
import React from 'react'
import { bluecheck, RepostHistoryimage, videoimage, listnerimage } from '../assets';
import { Content } from 'antd/lib/layout/layout';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import AudioVideoController from './AudioVideoController';


const LeftSideSingletrackboxContainer = styled(Box)({
    height: "81px",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
    objectFit: "contain",
    color: "white",
    justifyContent: "space-between",
   
});
const BorderLineProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
    }),
)(LinearProgress);



const LeftsideReposthistorycontainer = styled(Box)({
    width: "50%",
    display: "flex",
    paddingTop:"20px",
    gap: "40px",
    flexDirection: "column",
    "@media(max-width: 1000px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Repostboxcontainer = styled(Box)({
    width: "100%" ,
    display:"flex" ,
    height:"90vh",
    overflowY:"scroll",
    padding:"15px 30px 0px 50px",
    gap:"40px",
    // flexDirection: "column",
    "@media(max-width: 1000px)": {
        flexDirection:"column",
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});
const Leftsidebox = styled(Box)({
    width: "50%" ,
    // flexDirection: "column",
    "@media(max-width: 1000px)": {
        
        width: "100%"
    },
});


const Tracknames = styled(Box)({
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});

const { TabPane } = Tabs;
export default class RepostHistory extends AudioVideoController {
    render() {
        const { isPlaylist } = this.context;
        return (
            <>
 <Layout  className='streamRepostWrapper'>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    // height: "100%",
                    backgroundColor: "black",
                    display: "flex", flexDirection: "column",
                    // justifyContent: "space-between"
                }}
            >
               <HomeHeader location="RepostHistory" redirect={this.redirectToProfile}/>
               
                <Repostboxcontainer style={isPlaylist ? {height:"75vh"}:{height:"90vh"}}>
                    <Leftsidebox>
                        <Box>
                            <img src={(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ? RepostHistoryimage: listnerimage} style={{
                                width:"100%",
                                gap: "0px",
                                height:"900px"
                                // border: "5px solid #ffff",
                                // opacity: "0px",
                            }} />
                        </Box>
                    </Leftsidebox>
                    {/* <Box style={{ width: "50%",paddingTop:"20px" }}> */}
                    <LeftsideReposthistorycontainer >
                    {(this.state.usertype !== "listener" && this.state.usertype !== "listener_plus") ?  <Typography style={webStyle.tracknames}>
                                {" "}
                                <span style={webStyle.bluetexts}>
                                    TOP 1K:
                                </span>{" "}
                               Reposters
                            </Typography>:  <Typography style={webStyle.tracknames}>
                                {" "}
                                <span style={webStyle.tracknames}>
                                    Your Repost History
                                </span>{" "}
                             
                            </Typography> }
                 
                        <Box>
                        <div className="chats-header" >
                            <Tabs
                          className='Streamrepost_custom_tab'
                         defaultActiveKey="1"
                          tabPosition="top"
                           style={{
                       //   height: 220,
                        }}
                         items={this.state.tabs.map((item:any, index:any) => {
                          const id = String(index);
                           return {
                            label: item.month,
                            key: id,
                          disabled: index === 12,
                            };
                              })}
        
                           />
                         </div>
                            
                        </Box>
                        <Box>
                           
                            {Array.from({ length: 8 }).map((_, index) => (
                                <LeftSideSingletrackboxContainer
                                    key={index}
                                >
                                                                          <Box style={webStyle.singleArtimginfo}>
                                      
                                        <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                        <span>{index + 1}</span>
                                            <img
                                                src={videoimage}
                                                style={webStyle.leftimage}

                                            />
                                            <Box style={webStyle.leftsideowners}>
                                                <Tracknames className="tracks_songs_text">
                                                  Playrone
                                                    <span className="Jason-Derulo">
                                                        <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                    </span>
                                                </Tracknames>
                                                <span style={webStyle.splittexts}>
                                                  Reposts

                                                </span>
                                            </Box>
                                        </Box>
                                        <Box style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center"
                                        }}>
                                            <span style={webStyle.splittextwhites}>
                                                487
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "center" }} >
                                        <Box style={{
                                            width: "100%",
                                            paddingLeft: "100px"

                                        }} >

                                            <BorderLineProgress variant="determinate" value={60} />
                                        </Box>
                                    </Box>

                                </LeftSideSingletrackboxContainer>
                            ))}
                        </Box>
                    </LeftsideReposthistorycontainer>
                    {/* </Box> */}
                </Repostboxcontainer>
            </Content>
        </Layout>
              

            </>

        )
    }
}

const webStyle = {

    leftimage: {
        borderRadius: "60px",
        width: "60px",
        minWidth: "60px",
        height: "60px",
        border: "2px solid #FFFFFF"
    },

    singleArtimginfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },

    tracknames: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },
    

    bluetexts: {
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
    },

    splittexts: {
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal"
    },
    splittextwhites: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    leftsideowners: {
        gap: "3px",
        flexDirection: "column" as "column",
        display: "flex",
    },
};
RepostHistory.contextType = AudioContextApi;
// Customizable Area End