// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { HISTORY } from "components/src/common";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
export const configJSON = require("./config.js");

export interface Props {
  isRecived: boolean;
  avtar: string;
  index: number;
  senderName: string;
  timetoken: string;
  text: string;
  isTrack: boolean;
  trackId: string;
  userid: any;
  particularUserDataRes: any;
  isChartMessage?: any;
  onRedirect: (currentuserType: any, currentUserId: any) => void;
  trackType: any;
}
interface S {
  trackData: any;
  loader: boolean;
  token: any;
}
interface SS {
  id: any;
}

export default class TrackMsgCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  apigetTrackCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      trackData: "",
      loader: false,
      token: localStorage.getItem("token"),
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this._getTrackData();
  }

  _getTrackData = () => {
    this.setState({ loader: true });
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetTrackCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/show_track?id=` +
        +this.props.trackId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTrackAndVideo = () => {
    if (this.props.isTrack && this.props.trackType !=="videoinvitation") {
        let { handlePlayList } = this.context;
      handlePlayList(this.state.trackData, 0, this.state.trackData, this.state.trackData.track_upload_type, "1");
    } else{
      HISTORY.push({
        pathname: "/home/Charts/videos",
        state: { video_list: this.state.trackData.video_file, activeNo: "5" },
      });
    }
  };

  handleRedirectTracks=()=>{
    if (this.props.trackType !== "videoinvitation") {
    HISTORY.push("/ctrl/tracks")
    }else{
    HISTORY.push("/ctrl/videos")

    }
  }

  getTrackApiSuccessCall = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        trackData: responseJson.data[0],
        loader: false,
      });
    } else {
      this.setState({ loader: false });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apigetTrackCallId) {
        this.getTrackApiSuccessCall(responseJson);
      }

      //this.setState({loading: false})
    }
  }
}
TrackMsgCardController.contextType = AudioContextApi;
// Customizable Area End
