// Customizable Area Start
import { MoreOutlined } from "@ant-design/icons";
import { Card, Col, Row, Menu, Dropdown, Form } from "antd";
import React, { useRef, useEffect, useState } from "react";
import '../../../../dashboard/assets/css/videoPlayer.css'
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { plays, reshared, commentBlue, DummyProfileImage, playButton } from "../../../../dashboard/src/assets";
import '../../../../user-profile-basic/src/Common/CardItem.web.css'
import './SearchVideos.css'
import axios from "axios";
import millify from "millify";
import { openNotification } from "../../../../../components/src/Notification.web";
const urlConfig = require("./../../../../../framework/src/config")
import { AnyNsRecord } from "dns";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import { useHistory } from "react-router-dom";
import SharePlaylistPopup from "../../../../dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import AddToQueuePopup from "../../../../dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web";
const baseUrl = urlConfig.baseURL;


const VideoPlayer = (props: any) => {
    const vedioPlayer: any = useRef();
    const [elapsedTime] = useState(500)
    const [hide, setHide] = React.useState(true)
    const [openPopup, setOpenPopup] = React.useState(false);
    const [openAlertLogin, setopenAlertLogin] = useState(false)
    const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false)
    const [openRepostPopup, setOpenRepostPopup] = useState<boolean>(false)
    const [openPopUp, setOpenPopUp] = useState<boolean>(false)
    const [textFieldValue, setTextFieldValue] = useState<any>('')
    // add to playlist
    const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [imageUrl, setimageUrl] = useState<any>('')
    const [playlistId, setPlaylistId] = useState<any>("")
    const [userplaylist, setUserplaylist] = useState<any>("")
    const [emptyPlaylist, setEmptyPlaylist] = useState(false)
    const [playlistName, setplaylistName] = useState<any>('')
    const [fileName, setfileName] = useState<any>('')
    const [musicButton, setMusicButton] = useState<boolean>(false)

    // add to playlist

    const history = useHistory()

    const token: any = localStorage.getItem("token")
    const pause = () => {
        vedioPlayer?.current?.pause();
        props.setSelected(null)
        setHide(true)

    };
    const addPlayTrackCount = async (id: any) => {
        const token: any = localStorage.getItem("token")
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
        }
        await axios.get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, { headers })
            .then((response: any) => {

            }).catch((err: any) => {

                if (token) {
                    openNotification(err?.response?.data?.errors && err?.response?.data?.errors[0]?.token, "Error")
                }
            });
        // }

    }
    console.log(props.playData, "playData")
    useEffect(() => {

        if (elapsedTime == 10000) {
            addPlayTrackCount(props.id)
        }
        if (props.handleAudioVideo !== 1) {
            pause()
            props.setHandleAudioVideo(props.handleAudioVideo)

        }
    }, [props.handleAudioVideo, elapsedTime])

    let vid = document.getElementById("myVideo");
    if (vid) {
        vid.onseeking = function (e) {
            e.stopPropagation()
        };
    }


    const userType = localStorage.getItem('user_type')
    const subscriptionPlan = localStorage.getItem("subscription_plans")

    const onShare = () => {
        const token: any = localStorage.getItem("token")
        console.log(token, 'token value')
        if (token) {
            if (userType == "listener" && subscriptionPlan == "NXZ") {
                setopenAlertLogin(true)
                setDialogTextAlertLogin("3")
            } else {
                setOpenPopup(true)
            }
        } else {
            setopenAlertLogin(true)
            setDialogTextAlertLogin("2")
        }

    }

    const handleCloseAlertLogin = () => {
        setopenAlertLogin(false);
    };


    const handleChartTrackRepost = async (caption: AnyNsRecord) => {
        await axios
            .post(
                `${baseUrl}/bx_block_audiomusic/repost_count?id=${props.playData.id}&caption=${caption}`,
                null,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        token: token,
                    },
                }
            )
            .then((res: any) => {

                openNotification("Successfully Reposted", "Success")
            })
            .catch((err) => {
                openNotification("Some error occurred", "Error")

            })
            .finally(() => {

            });
    };

    const respostSubmit = () => {
        handleChartTrackRepost(textFieldValue)
        handleRepostClose()
    }

    const handleCaptionChange = (e: any) => {
        setTextFieldValue(e.target.value)
    }


    const handleRepostClose = () => {
        setOpenRepostPopup(false)
    }

    

    const handleClosePlaylistModal = () => {
        form.resetFields();
        form1.resetFields();
        setimageUrl('')
        setOpenPlaylistModal(false)
    }

   
  


    const getUserPlaylist = async () => {
        setLoading(true);
        await axios
            .get(`${baseUrl}/bx_block_playlist/video_play_list`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: token,
                },
            })
            .then((response: any) => {
                setLoading(false);

                setUserplaylist(response.data.data);

            })
            .catch((err: any) => {
                setLoading(false);
                if (err?.response.status === 422) {
                    setEmptyPlaylist(true)
                }


            });
    };

    const handleAddToPlaylist = () => {

        if (token) {

            setOpenPlaylistModal(true);
            getUserPlaylist()
        } else {
            setopenAlertLogin(true);
            setDialogTextAlertLogin(token ? "1" : "2")
        }
    }


   
  
   


    //add to playlist end

    const redirectProfile = (id: any) => {
        history.push(`/creatives/beats?creative_id=${id}`)
    }

    const ButtonGrp = () => {
        return (
            <div>
                <Menu
                    className="custom-drop-menu"
                >
                    <Menu.Item key="0" style={{ color: "white", backgroundColor: "transparent" }} >Repost</Menu.Item>
                    <Menu.Item key="1" style={{ color: "white", backgroundColor: "transparent" }}>Share</Menu.Item>

                    <Menu.Item key="3" style={{ color: "white", backgroundColor: "transparent" }}> Add to playlist</Menu.Item>
                    <Menu.Item key="4" style={{ color: "white", backgroundColor: "transparent" }}>Download</Menu.Item>

                </Menu>
            </div>
        );
    }


    const handlerSingleVideos = async (id: any, trackFile: any) => {
        let outputData
        const token: any = localStorage.getItem("token")
        await axios.get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
            }
        })
            .then((response: any) => {

                outputData = response?.data?.data

            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {

            })

        console.log(props.currentList)

        if (token) {
            history.push({
                pathname: "/home/Charts/videos",
                state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: props.currentList ? props.currentList : [] }
            })
        }

    }



    return (
        <Card className='custom_card' style={{}}>
            <div style={{ backgroundColor: 'rgb(26 25 25)', borderRadius: '12px', border: "1.5px solid rgb(50 49 49)", padding: '10px', marginRight: '-30px' }}>
                <div className="small-div-flex" style={{ display: 'flex', width: '100%' }}>


                    <Grid item className="video-grid">
                        <Grid item className='cover-img cover-width' style={{ position: "relative", height: "120px", width: '100%', marginBottom: '4px' }}>

                            {!hide && props.selected === props.cardId ?
                                <video
                                    ref={vedioPlayer}
                                    id="myVideo"
                                    poster={props.img}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                    autoPlay={!hide}
                                    style={{ objectFit: "cover" }}
                                    // loop={true} 
                                    // muted={true} 
                                    //@ts-ignore
                                    playsinline
                                // webkit-playsinline
                                >
                                    <source src={props.trackFile} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                                </video>

                                :
                                <>
                                    <img style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: '15px',

                                    }}
                                        src={props?.img} alt=""

                                    />
                                    <img
                                        test-id = "playbutton_in_search_videos_test_id"
                                        id={props.cardId}
                                        style={{
                                            marginRight: "5px",
                                            position: "absolute",
                                            top: "35%",
                                            left: "40%",
                                            height: "30px",
                                            cursor: "pointer"

                                        }}
                                        onClick={(e) => {
                                            localStorage.removeItem("selectedSingleVideo");
                                            handlerSingleVideos(props.id, props.trackFile);
                                        }}
                                        src={playButton}
                                    />
                                </>
                            }

                        </Grid>
                    </Grid>
                    <Grid item className="name-grid">
                        <div className="justify_space ml_1" style={{ width: "100%" }}>
                            <div className="width_100">
                                <div>
                                    <Grid container className='buttonContainer' style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                                        <Grid item md={3} style={{ display: "flex" }}>
                                            <img
                                              test-id = "redirectProfile_in_search_videos_test_id"
                                                onClick={() => redirectProfile(props.playData?.attributes.account_id)}
                                                src={props.playData?.attributes.artist_image || props.playData?.attributes.avatar || DummyProfileImage} style={{ width: "40px", height: "40px", borderRadius: '50%', cursor: 'pointer' }} />
                                            <div className="ml_1">
                                                <div style={{ display: "flex" }}
                                                  test-id = "redirectProfile_in_search_videos_test_id_0"
                                                    onClick={() => redirectProfile(props.playData?.attributes.account_id)}
                                                >
                                                    <Typography className=" text_white mr_1"
                                                        style={{
                                                            fontSize: "12px",
                                                            overflow: "hidden",
                                                            display: "inlineBlock",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            maxWidth: "170px",
                                                            cursor: 'pointer'
                                                        }} >

                                                        {props.playData?.attributes.artist_name || 'Selena gomez'}
                                                    </Typography> <Typography style={{ color: "#99999f", marginTop: "2px", fontSize: "10px", cursor: "pointer" }}>
                                                        {props.playData?.attributes.creative_type_name ? props.playData?.attributes.creative_type_name : ""}
                                                    </Typography>
                                                </div>
                                                <Typography className="text_blue ml_1 font_16 font_W_600"
                                                    // onClick={onProfile} 
                                                    // onClick={() => { handlePlayList(data, songIndex, mainList, name) }}
                                                    // onClick={() => {onProfile (data, songIndex, mainList, name) }}
                                                    style={{
                                                        marginTop: "-3px",

                                                        overflow: "hidden",
                                                        display: "inlineBlock",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "270px",
                                                    }}>


                                                    {props.playData?.attributes.title || props.playData?.attributes.name || 'Remember me'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Row className="playTrackButton" style={{ display: 'flex', justifyContent: "flex-start", marginTop: '10px' }}>
                                                <Col className="hiddn_countGrp">
                                                    <Row className="display-charts-icons" style={{ marginRight: '45px' }} >
                                                        <Col className="display" style={{ padding: "0px 3px" }}>
                                                            <img src={plays} style={{ height: "28px", width: "28px", marginTop: "-4px" }} />
                                                            <p className="ml_05" style={{
                                                                textAlign: "start",
                                                                color: "#fff", fontSize: "13px"
                                                            }}>
                                                                {props.playData?.attributes.play_count ? millify(props.playData?.attributes.play_count) : "0"}
                                                            </p>
                                                        </Col>
                                                        <Col className="display" style={{ padding: "0px 3px" }}>
                                                            <img src={commentBlue} style={{ height: "24px", width: "24px" }} />
                                                            <p className="ml_05" style={{
                                                                textAlign: "start",
                                                                color: "#fff", fontSize: "13px"
                                                            }}>
                                                                {props.playData?.attributes.comments_count ? millify(props.playData?.attributes.comments_count) : "0"}
                                                            </p>
                                                        </Col>
                                                        <Col className="display" style={{
                                                            padding: "0px 3px"
                                                        }}>
                                                            <img src={reshared} style={{ height: "28px", width: "30px", marginTop: "-4px" }} />
                                                            <p className="ml_05" style={{
                                                                color: "#fff", fontSize: "13px"
                                                            }}>
                                                                {props.playData?.attributes.repost_count ? millify(props.playData?.attributes.repost_count) : "0"}

                                                            </p>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="show_btnGrp_menu" style={{ display: "none" }}>
                                                    <Dropdown overlay={ButtonGrp} trigger={['click']}>
                                                          {/* @ts-ignore */}
                                                        <MoreOutlined style={{ fontSize: "30px", marginTop: "-9px", color: "gray" }} translate={undefined} />
                                                    </Dropdown>
                                                </Col>
                                                <Col className="hiddn_btnGrp" style={{ marginTop: '5px' }}>
                                                    <Box sx={{ width: { xl: 300, lg: 300 } }}>
                                                        <Row className="display  display-column-chart " >
                                                            <Col style={{
                                                                justifyContent: "center",
                                                                display: "flex",
                                                            }} className="marginBottom-chart">
                                                                <Button id = "search_videos_repost_button_test_id" style={{
                                                                    borderRadius: "13px",
                                                                    backgroundColor: "#22252a",
                                                                    //padding: "4px 15px 4px 12px",
                                                                    fontSize: "11px",
                                                                    fontWeight: 600,
                                                                    textTransform: "capitalize",
                                                                    height: "25px",
                                                                    width: '20px',
                                                                    // width: "-webkit-fill-available",
                                                                    marginRight: "5px",
                                                                    color: "#8f9092"

                                                                }}
                                                               
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    //onClick={props.handleChartTrackRepost}
                                                                    onClick={() => {
                                                                        setOpenRepostPopup(!openRepostPopup)
                                                                        setOpenPopUp(true)
                                                                    }
                                                                    }
                                                                    

                                                                >
                                                                    Repost
                                                                </Button>
                                                            </Col>
                                                            <Col style={{
                                                                justifyContent: "center",
                                                                display: "flex",

                                                            }}>
                                                                <Button id = "search_videos_share_button_test_id" style={{
                                                                    borderRadius: "13px",
                                                                    backgroundColor: "#22252a",
                                                                    // padding: "4px 15px 4px 12px",
                                                                    fontSize: "11px",
                                                                    fontWeight: 600,
                                                                    textTransform: "capitalize",
                                                                    height: "25px",
                                                                    width: '20px',

                                                                    //width: "-webkit-fill-available",
                                                                    marginRight: "5px",
                                                                    color: "#8f9092"

                                                                }}
                                                                    variant="contained"
                                                                    color="secondary"

                                                                    // onClick={() => setOpenPopup(true)}
                                                                    // onClick={onShare}
                                                                    onClick={onShare}


                                                                >
                                                                    Share
                                                                </Button>
                                                            </Col>
                                                            <Col style={{
                                                                justifyContent: "center",
                                                                display: "flex",

                                                            }} className="marginBottom-chart">
                                                                <Button id = "search_videos_add_to_playlist_button_test_id" style={{
                                                                    borderRadius: "13px",
                                                                    backgroundColor: "#22252a",
                                                                    // padding: "4px 12px 4px 12px",
                                                                    fontSize: "11px",
                                                                    fontWeight: 600,
                                                                    textTransform: "capitalize",
                                                                    height: "25px",
                                                                    //width: "-webkit-fill-available",
                                                                    //width: '20px',
                                                                    marginRight: "5px",
                                                                    color: "#8f9092"
                                                                }}
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    // onClick={
                                                                    //     handleAddToPlaylist
                                                                    // }

                                                                    onClick={
                                                                        handleAddToPlaylist
                                                                    }
                                                                >
                                                                    Add to playlist
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </Grid>

                                </div>
                            </div>
                        </div>

                    </Grid>

                    {/* <ScanQrDialog
                        openPopup={openPopup}
                        handleOpenPopup={() => setOpenPopup(false)}

                        shareItem="Video"


                    /> */}

         <SharePlaylistPopup
             id="Video"
             open={openPopup}
             handleClose={() => setOpenPopup(false)}
             track={props.playData}
            />

                    {openRepostPopup && <RepostPopup open={openPopUp}
                        onClose={handleRepostClose}
                        profileImage={props.playData?.attributes?.art_work || props.playData?.attributes?.cover_image}
                        artistName={props.playData?.attributes?.artist_name}
                        songName={props.playData?.attributes?.title}
                        repost_count={respostSubmit}
                        _handleTextFieldChange={handleCaptionChange}
                    />}
                    {openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}

                    {
                        openPlaylistModal &&
<AddToQueuePopup id="Queue" open={openPlaylistModal} handleClose={handleClosePlaylistModal} playlistType = "video" trackData={props.playData}/>
                    }

                </div>
            </div>
        </Card>
    );
};

export default VideoPlayer;
// Customizable Area End
