// Customizable Area Start

import { Typography, Tabs, Layout } from 'antd';
import {
    Box,
    LinearProgress,
    createStyles,
    styled,
    withStyles,
    Theme,
    Hidden
} from "@material-ui/core";
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';

import { RepostHistoryimage, bluecheck, userlog0 } from '../assets';
import AudioVideoController from './AudioVideoController';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
const LbaelrepostLeftSideSingletrackbox = styled(Box)({
    // marginTop: '6px',
    height: "81px",
    // padding: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    // display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "contain",

    color: "white",
    // "&:hover": {
    //     //   marginTop: '12px',
    //     height: "81px",
    //     // padding: "10px",
    //     borderRadius: "8px",
    //     objectFit: "contain",
    //     background: "#141414",
    //     display: "flex",
    //     justifyContent: "space-between",
    //     alignitems: "center",
    //     // alignItems:"center"
    // },
});
const Labelhistorycontainer = styled(Box)({
    height:"90vh",
    overflowY:"scroll",
    display: 'flex',
    width: "100%",
    justifyContent: "space-between",
    paddingTop:"15px",
    "@media(max-width: 1150px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
        flexDirection: "column"
    },
});
const LabelboxRightside = styled(Box)({
    width: "fit-content",
    // height: "50vh",
    display: "flex",
    minWidth: "100%",
    flexDirection: "column" as "column",
    gap: "15px",
    "@media(max-width: 1024px)": {
        width: "auto",
        // height:"auto",
        // marginTop:" 22%",
        // gap:"20px"
    },
});
const RightsideAlltracksboxwrapper = styled(Box)({
    // overflowY: "auto",
    // maxHeight: "810px",
    display: "grid",
    scrollbarWidth: "none",
    // flexWrap: "",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "fit-content",
    gap: "25px",
    "@media(max-width: 1700px)": {
       
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 1550px)": {
        
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 1300px)": {
        
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 1150px)": {
       
        gridTemplateColumns: "repeat(4, 1fr)",
    },
    "@media(max-width: 1129px)": {
       
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 1024px)": {
       
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 959px)": {
       
        gridTemplateColumns: "repeat(4, 1fr)",
    },
    "@media(max-width: 877px)": {
        
        gridTemplateColumns: "repeat(3, 1fr)",
    },
   
    

    "@media(max-width: 769px)": {
        
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media(max-width: 549px)": {
       
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 320px)": {
        gridTemplateColumns: "repeat(1, 1fr)",
    },
    "&::-webkit-scrollbar": {
        display: "none",
        width: "0px",
    },
});

const LabelboxRightsidecontainer = styled(Box)({
    width: "47%",
    // height: "50vh",
    alignItems: "center",
    gap: "5%",
    "@media(max-width: 1150px)": {
        width: "100%",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",

    },
});
const TrackBoxfordtails = styled(Box)({
    width: "47%",
    // height: "50vh",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "15px",
    "@media(max-width: 1024px)": {
        width: "auto",
        height: "auto",
        marginTop: " 5%",
        gap: "20px",

    },
});
const LabelRepostHorizontaltag = styled(Box)({
    height: "100%",
    width: "1px",
    border: "1px solid #212121",

    "@media(max-width: 1100px)": {
        display: "none"
    },
});
const LabelLeftsideAudiohistorycontainer = styled(Box)({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    "@media(max-width: 1150px)": {
        padding: "0px 10px 0px 10px",
        width: "100%"
    },
});


const LabelrepostTrackname = styled(Box)({
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.94px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",

    fontFamily: "Inter",
    "@media(max-width: 400px)": {
        // whiteSpace: "unset",
        // fontSize: "18px"
    },
});

const { TabPane } = Tabs;
export default class LableRepostHistory extends AudioVideoController {
    render() {
        const { isPlaylist } = this.context;
        return (
            <>
                <Layout className='streamRepostWrapper'>
                   
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        style={{
                            margin: "0px",
                            // height: "100%",
                            backgroundColor: "black",
                            display: "flex", flexDirection: "column",
                            // justifyContent: "space-between"
                        }}
                    >
                        <HomeHeader location="RepostHistory" redirect={this.redirectToProfile}/>

                        <Labelhistorycontainer style={isPlaylist ? {height:"83vh"}:{height:"90vh"}} >
                            <LabelboxRightsidecontainer>
                                <LabelboxRightside>
                                    {" "}
                                    <Box
                                        style={{ display: " flex ", justifyContent: "space-between", alignItems: "center" }}
                                    >
                                        <Typography style={webStyle.labeltrackname}>
                                            {" "}
                                            <span style={webStyle.labelbluetext}>
                                                TOP 40:
                                            </span>{" "}
                                            Creatives
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: "100%",
                                            // display: "flex",
                                            // justifyContent: "center",
                                        }}
                                    >

                                        <RightsideAlltracksboxwrapper>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <Box
                                                    key={index}
                                                    
                                                    style={{ height: "100%", cursor: "pointer" ,position:"relative"}}
                                                >
                                                    <img
                                                        src={RepostHistoryimage}
                                                        className="reposthistorimage"
                                                        style={{
                                                           height:"100%",
                                                           border: "5px solid #212121",
                                                           borderRadius:"15px"
                                                        }}

                                                    />

                                                    <Typography style={webStyle.labelrepostimagetext}>
                                                        djmustard
                                                    </Typography>

                                                    <Typography style={webStyle.labelrepostaudioartistext}>
                                                        producer
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </RightsideAlltracksboxwrapper>
                                    </Box>
                                </LabelboxRightside>
                            </LabelboxRightsidecontainer>
                            <Box>
                                <LabelRepostHorizontaltag
                                ></LabelRepostHorizontaltag>
                            </Box>
                            <LabelLeftsideAudiohistorycontainer >
                                <Box>

                                    <Typography style={webStyle.labeltrackname}>
                                        {" "}
                                        <span style={webStyle.labelbluetext}>
                                            TOP 1K:
                                        </span>{" "}
                                        Respoters
                                    </Typography>
                                </Box>

                                <Box>
                                <div className="chats-header" >
                                      <Tabs

                                   className='Streamrepost_custom_tab'
                                       defaultActiveKey="1"
                                     tabPosition="top"
                                      items={this.state.tabs.map((item:any, i:any) => {
                                       const index = String(i);
                                          return {

                                         label: item.month,
                                       key: index,
                                      disabled: i == 12,
                                     };
                                      })}
        
                           />
                         </div>
                                </Box>
                                <Box style={ { paddingBottom: " 5px " } }>
                                    {Array.from({ length: 8 }).map((_, index) => (
                                        <LbaelrepostLeftSideSingletrackbox
                                            key={index}
                                        >
                                            <Box style={webStyle.labelrepsotsingleArtinfo}>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                                    <span>{index+1}</span>
                                                    <img
                                                        src={userlog0}
                                                        style={webStyle.labelrepostleftimage}

                                                    />
                                                    <Box style={webStyle.labelrepostleftsideownerstats}>
                                                        <LabelrepostTrackname className="tracks_songs_text">
                                                            Jiwang
                                                            <span className="Jason-Derulo">
                                                                <img src={bluecheck} className="verfied" style={{ marginLeft: "4px" }} />
                                                            </span>
                                                        </LabelrepostTrackname>
                                                        <span style={webStyle.labelrepostsplittext}>
                                                            Streams

                                                        </span>
                                                    </Box>
                                                </Box>
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center"
                                                }}>
                                                    <span style={webStyle.labelrepostsplittextwhite}>
                                                        487
                                                    </span>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: "flex", justifyContent: "center" }} >
                                                <Box style={{
                                                    width: "100%",
                                                    paddingLeft: "113px"

                                                }} >

                                                    <BorderLinearProgress variant="determinate" value={64} />
                                                </Box>
                                            </Box>

                                        </LbaelrepostLeftSideSingletrackbox>
                                    ))}
                                </Box>
                            </LabelLeftsideAudiohistorycontainer>
                            <Box>
                            </Box>
                        </Labelhistorycontainer>
                    </Content>
                </Layout>


            </>

        )
    }
}

const webStyle = {
    RightSidebuttonBox: {
        width: "100%"
    },
    Rightsidetabsbutton: {
        borderRadius: "30px",
        backgroundColor: "#212121",
        display: "flex",
        justifyContent: "space-around",
        padding: "2px 0 2px 0",
        border: "3px solid #212121",

    },
    labelrepostaudioartistext: {
        color: "#8f92a1",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        position:"relative" as "relative",
        bottom: "24%",
        left: "15%"
    },
    labelrepostimagetext: {
        color: "#F0F0F5",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Karla",
        lineHeight: "20px",
        marginTop: "8px",
        maxWidth: "150px",
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap" as 'nowrap',
        position:"relative" as "relative",
        bottom: "24%",
        left: "15%"
    },
    tabbox: {
        width: "50%",
        display: 'flex',
        justifyContent: 'center'
    },

    labelrepostleftimage: {
        borderRadius: "60px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        border: "2px solid #FFFFFF"
    },
    leftvideoimage: {
        borderRadius: "10px",
        width: "75px",
        minWidth: "75px",
        height: "75px",
        border: "1px solid #141414"
    },

    labelrepsotsingleArtinfo: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",

    },

    leftsideAlltracksbox: {
        width: "85%",

    },


    labeltrackname: {
        fontSize: "22px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        whiteSpace: "nowrap" as "nowrap",
        color: "#ffffff",
        fontFamily: "Inter",
    },

    labelbluetext: {
        fontSize: "22px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: "normal",
        color: "#3959f9",
        fontWeight: 700,
    },

    labelrepostsplittext: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    

    leftSideSingletrackbox: {
        height: "95px",
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    labelrepostsplittextwhite: {
        fontSize: "14px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "normal",
        color: "#8f92a1",
        fontFamily: "Inter",
    },
    labelrepostleftsideownerstats: {
        gap: "3px",
        display: "flex",
        flexDirection: "column" as "column",
    },
    tabaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        textTransform: "capitalize" as "capitalize",
    },
    rightSideSingletrackbox: {
        marginTop: "12px",
        height: "54px",
        padding: "0px 14px 0px 5px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
        color: "white",
        background: "#141414",
    },
    tabactiveaudiovideobutton: {
        fontSize: "16px",
        fontWeight: 800,
        color: "#ffff",
        borderRadius: "60px",
        width: "100%",
        background: "#274af4",
        textTransform: "capitalize" as "capitalize",
    },
};
LableRepostHistory.contextType = AudioContextApi;
// Customizable Area End