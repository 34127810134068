// Customizable Area Start
import { DownOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import ScanQrDialog from "./ScanQrDialog.web";
import "blocks/dashboard/assets/css/HomeHeader.css";
import { dashbordicon, defaultProfile, Homeblue, Downloadblue, Chartsblue, Playlistblue, Creativeblue, Videoblue, profileIcon, shareIcon, emptyWalletIcon, historyIcon, logoutIcon, Homeicon, Chartsicon, Videoicon, Playlisticon, DownloadIcon, Creativesicon, originalIcon, OriginalBlue } from "blocks/dashboard/src/assets";
import LoginAlert from './LoginAlert.web'
import DeactivateUser from 'blocks/user-profile-basic/src/DialogBox/DeactivateUser.web'
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
import PlanCancelledPopup from "./PlanCancelledPopup.web"
import { Hidden, Menu as MaterialMenu, MenuItem, } from "@material-ui/core";
import axios from "axios";
import SharePlaylistPopup from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { openNotification } from "../../Notification.web";
import { loadStripe } from "@stripe/stripe-js";

export const configJSON = require('blocks/dashboard/src/config');
const HomeHeaderDropdown = ({ sizexl, dmActive, handleClose, anchorEl, dialogText, handleLoginClose, open, currentKeyCharts, setCurrentKeyCharts, currentKeyPlaylist, setCurrentKeyPlaylist }: any) => {

  const history = useHistory();
  const location = useLocation();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [profileImg, setProfileImg] = useState<any>("");
  const [userData, setUserData] = useState<any>("");
  const [profileId, setProfileId] = useState<any>("");
  const userType = localStorage.getItem("user_type")
  const creatives_screen = localStorage.getItem("creatives_screen")
  const { setIsPlaylistHandle } = useContext(AudioContextApi);
  const [menuList, setMenuList] = useState<any>([])
  const [isplanPopupShow, setIsplanPopupShow] = useState<boolean>(false)
  const [isdeactivePopupShow, setIsdeactivePopupShow] = useState<boolean>(false)


  useEffect(() => {

    if (token) {
      getOriginals()
    }
  }, []);

const ctrlRedirection=()=>{
  if(userType?.includes("label")){
    history.push("/ctrl/splits")
  }else{
     history.push("/ctrl/snapshot")
  }
}
const streamRepostRedirection=(url:any)=>{
  if(url == "history"){
    history.push("/lableHistory")
  }else{
     history.push("/lableRepost")
  }
}

  const getOriginals = async () => {
    await axios.get(`${configJSON.baseUrl}/bx_block_audiomusic/original_profile`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        let menuItems = [
          { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
          { key: "2", label: "Originals", path: `/originals/beats?originals_id=${response?.data?.data?.id}`, icon: originalIcon, defaultIcon: originalIcon },
          { key: "3", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
          { key: "4", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
          { key: "5", label: "Playlist", path: "/home/all-playlist", icon: Playlistblue, defaultIcon: Playlisticon },
          { key: "6", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
          { key: "7", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },
          // { key: "8", label: "Comments & DM", path: "/comments", icon: Creativeblue, defaultIcon: Creativesicon },

        ]

        setMenuList(menuItems)
      }).catch((err) => {
        console.log(err);
      });
  }

  const redirectHandle = async (id?: any) => {
    localStorage.removeItem("selectedTab")
    localStorage.removeItem("creatives_screen")
    localStorage.setItem("creatives_screen", "false")
    const userType: any = localStorage.getItem("user_type");
    const loggedIn_userId: any = localStorage.getItem("loggedIn_userId");
    localStorage.setItem("current_type", userType);
    localStorage.removeItem("user_id")
    localStorage.setItem("user_id", loggedIn_userId)
    if (userType?.includes("listener")) {
      history.push({
        pathname: `/listeners/activity?listener_id=${loggedIn_userId}`,
        state: { user_id: profileId.id }
      })
      localStorage.removeItem('temp_id')
    } else if (userType?.includes("original")) {
      history.push({
        pathname: `/originals/beats?originals_id=${loggedIn_userId}`,
        state: { user_id: profileId.id }
      })
      localStorage.removeItem('temp_id')
    }
     else if (userType?.includes("label")) {
      history.push({
        pathname: `/labels/creatives?labels_id=${loggedIn_userId}`,
        state: { user_id: profileId.id }
      })
      localStorage.removeItem('temp_id')
    }

    else {
      history.push({
        pathname: `/creatives/beats?creative_id=${loggedIn_userId}`,
        state: { user_id: profileId.id }
      })

      localStorage.removeItem('temp_id')

    }
  }
  const Menus = () => (
    <Menu className="custom-drop-menu mobile_view_menu">
      <Menu.Item className="item" key="1" style={{ color: "white", backgroundColor: "transparent" }} onClick={() => redirectHandle()}>
        <img src={profileIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Profile
      </Menu.Item>
      {(userType?.includes("creative_plus")|| userType?.includes("listener_plus") || userType?.includes("label") )&&
        <Menu.Item className="item" key="2" style={{ color: "white", backgroundColor: "transparent" }}
          onClick={() => history.push("/dashboard/revenue")} >
          <img src={dashbordicon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Dashboard
        </Menu.Item>
      }
      {userType?.includes("creative") &&
        <Menu.Item className="item" key="3" style={{ color: "white", backgroundColor: "transparent" }}
          onClick={() => setOpenPopup(true)} >
          <img src={shareIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Share Profile
        </Menu.Item>
      }
      {userType !== "original" &&
        <Menu.Item className="item" key="4" style={{ color: "white", backgroundColor: "transparent" }} onClick={() => {

          token && history.push("/planpayments")
        }}>
          <img src={emptyWalletIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} />  My Plan & Payment
        </Menu.Item>

      }
      <Menu.Item className="item" key="5" style={{ color: "white", backgroundColor: "transparent" }} onClick={() => history.push("/edit-profile")}>
        <img src={historyIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Edit Profile
      </Menu.Item>
      <Menu.Item className="item" key="6" style={{ color: "white", backgroundColor: "transparent" }} onClick={() => history.push("/comments")}>
        <img src={dashbordicon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Comments & DM
      </Menu.Item>
      {(userType?.includes("creative_plus") )&&
        <Menu.Item className="item" key="7" style={{ color: "white", backgroundColor: "transparent" }}
          onClick={() => history.push("/deal")} >
          <img src={historyIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Deal
        </Menu.Item>
      }
       {(userType?.includes("creative_plus") || userType?.includes("label"))&&
        <Menu.Item className="item" key="8" style={{ color: "white", backgroundColor: "transparent" }}
          onClick={() =>
            ctrlRedirection()} >
          <img src={profileIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> CTRL
        </Menu.Item>
      }
       {(userType?.includes("creative_plus") || userType?.includes("creative"))&&
        <Menu.Item className="item" key="9" style={{ color: "white", backgroundColor: "transparent" }}
        onClick={() =>
          streamRepostRedirection("history")} >
          <img src={profileIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Streaming History
        </Menu.Item>
      }
       {(userType?.includes("creative_plus") || userType?.includes("creative"))&&
        <Menu.Item className="item" key="10" style={{ color: "white", backgroundColor: "transparent" }}
        onClick={() =>
            streamRepostRedirection("repost")} >
          <img src={profileIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Repost History
        </Menu.Item>
      }
      <Menu.Item
        className="item"
        key="9"
        onClick={() => {
          localStorage.clear();
          history.go(0)
          history.push("/home");
          setIsPlaylistHandle()
        }}
        style={{ color: "white", backgroundColor: "transparent" }}
      >
        <img src={logoutIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} /> Sign out
      </Menu.Item>
      <Menu.Item
        className="item"
        key="10"
        onClick={() => {
          deactivateUserAccount()
        }}
      >
        <img src={logoutIcon} style={{ height: '18px', width: '18px', marginRight: '10px' }} />
        <span style={{ color: "red", backgroundColor: "transparent" }}> Close Account </span>
      </Menu.Item>
      

      
    </Menu>
  );

  const urlReturn = () => {
    if (baseUrl.includes("prod")) {
      return "https://app.nxzsound.com"
    } else if (baseUrl.includes("uat")) {
      return "https://nxzsound10-66173-react-native.b66173.uat.eastus.az.svc.builder.ai"
    }
    else {
      return "https://nxzsound10-66173-react-native.b66173.stage.eastus.az.svc.builder.ai"
    }
  }
  const setKey = () => {
    if(configJSON.baseUrl.includes("prod") ){
        return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
    }else{
        return `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
    }
    //return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
}
  const baseStripePromise: any = setKey()
  //    baseUrl.includes("prod") ? `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}` : `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
  const stripePromise = loadStripe(baseStripePromise);



  const checkSubscription = async () => {
    const userType = localStorage.getItem('user_type')
    var redirected_url
    var cancelled_url
    if (userType?.includes("creative")) {
      redirected_url = "/CreativeProfile"
      cancelled_url = "/ForCreative"
    } else {
      redirected_url = "/ListenerProfile"
      cancelled_url = "/ForListener"
    }

    const dev_url = "https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe"
    const currentRedirect = baseUrl.includes("dev") ? dev_url : urlReturn()
    const data = {
      success_url:
        `${currentRedirect}${redirected_url}`,
      cancel_url: `${currentRedirect}${cancelled_url}`
    }
    await axios.post(`${configJSON.baseUrl}/bx_block_subscription_plan/checkout_session_for_admin`, data, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then(async (response) => {
        const stripe: any = await stripePromise;
        localStorage.setItem("session_id", response.data.session_id.id)
        // eslint-disable-next-line
        const { error }: any = await stripe
          .redirectToCheckout({
            sessionId: response.data.session_id.id,
          })
          .then(async function (result: any) {
            openNotification("success", "Success");
          });
          UpdateSubsriptionPlan(response.data.session_id.id)

      }).catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    let items = [
      { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
      { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
      { key: "3", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
      { key: "4", label: "Playlist", path: "/home/all-playlist", icon: Playlistblue, defaultIcon: Playlisticon },
      { key: "5", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
      { key: "6", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },
      { key: "7", label: "Comments & DM", path: "/comments", icon: Creativeblue, defaultIcon: Creativesicon },

    ];
    if (userType === "original") {
      setMenuList(items)
      setCurrentKeyCharts("2")
      setCurrentKeyPlaylist('4')
    }
  }, [userType])

  const items1 = [
    { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
    { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
    // { key: "3", label: "Stream", path: "/home/Stream", icon: Creativeblue, defaultIcon: Creativesicon },
  ];
  const token: any = localStorage.getItem("token");

  const returnLabel = (labelName: any, label: any) => {
    if (userType !== 'original') {
      return (
        <span className={labelName}> {label}</span>
      )

    }
  }

  const returnLoginSidebar = (items: any) => {
    return items.map((_item: any) => {
      let pathName: any;
      let labelName: any = 'seleted_text '
      let widthSpecific: any = 24
      let heightSpecific: any = 24
      let marginRightSpecific: any = "10px"
      if (location.pathname.split('/').includes('originals') && _item.key == "2") {
        pathName = OriginalBlue
      } else if (location.pathname.split('/').includes('Charts') && _item.key == currentKeyCharts) {
        pathName = Chartsblue
      } else if (
        (location.pathname.split('/').includes('all-playlist') || location.pathname.split('/').includes('public-view') || location.pathname.split('/').includes('self-view')) && _item.key == currentKeyPlaylist) {
        pathName = Playlistblue
      } else if (location.pathname === _item.path) {
        pathName = _item.icon
      } else {
        pathName = _item.defaultIcon
        labelName = 'non_seleted_text'
        if (_item.key === "4") {
          widthSpecific = 29
          heightSpecific = 26
          marginRightSpecific = "5px"
        } else if (_item.key === "6") {
          heightSpecific = 20
        }
      }

      return (

        <MenuItem
          style={{ display: "flex", alignItems: "center", marginBottom: "13px" }}
          key={_item.path}
          onClick={() => {
            pageNavigate(_item.path);
          }}>
          <img
            src={pathName}
            width={widthSpecific}
            height={heightSpecific}
            alt=""
            style={{ marginRight: marginRightSpecific }}
          />
          {returnLabel(labelName, _item.label)}
        </MenuItem>
      )
    }

    )
  }

  const returnWithoutLoginSidebar = (items: any) => {
    return items1.map((_item) => {
      let pathName: any = ""
      let labelName: any = ""
      /*eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/
      if (location.pathname.split('/').includes('Charts') && _item.key == '2') {
        pathName = Chartsblue
        labelName = 'seleted_text'
      } else if (location.pathname === _item.path) {
        pathName = _item.icon
        labelName = 'seleted_text '
      } else {
        pathName = _item.defaultIcon
        labelName = 'non_seleted_text'
      }
      return (

        <MenuItem
          key={_item.path}
          onClick={() => {
            pageNavigate(_item.path);
          }}>
          <img
            src={pathName}
            width={24}
            height={24}
            alt=""
            style={{ marginRight: "10px" }}
          />
          {returnLabel(labelName, _item.label)}

        </MenuItem>
      )
    })
  }
  const UpdateSubsriptionPlan = async (val: any) => {
    await axios
      .put(`${configJSON.baseUrl}/bx_block_subscription_plan/update_subscription?cs_id=${val}`, null, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        
      });
  }


  const getProfile = async (token: any) => {
    await axios
      .get(`${configJSON.baseUrl}/bx_block_profile/profile`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((response) => {
        setProfileImg(response.data.data?.attributes?.avatar);
        setUserData(response.data.data?.attributes);
        setProfileId(response.data.data)

        localStorage.setItem("user_type", response.data.data?.attributes?.user_type);
        localStorage.setItem("user_name", response.data.data?.attributes?.user_name);
        localStorage.setItem("profile_img", response.data.data?.attributes?.avatar);
        localStorage.setItem("cover_img", response.data.data?.attributes?.cover_image);
        localStorage.setItem("subscription_plans", response.data.data?.attributes?.subscription_plans);
        creatives_screen !== "true" && localStorage.setItem("user_id", response.data.data?.id);
        localStorage.setItem("loggedIn_userId", response.data.data?.id);
        // !response.data.data?.attributes?.plan_status && setIsplanPopupShow(true)
        const { data } = response.data;
        const { attributes } = data;
        if(data.type=="account" && !attributes?.checkout_id){
          console.log(data.type,"data in resp")

        }else{
          if (!attributes?.payment_status && attributes?.checkout_id ) {
          window.location.href = attributes.checkout_id;
        } else if (!attributes?.subscription_plan_status) {
          
          checkSubscription()
        }
        else if (!attributes?.payment_status && !attributes?.plan_status) {
          setIsplanPopupShow(true);
        }
      }
      })
      .catch((err) => {
        console.log(err.response, "response");

      })
  };


  const deactivateUserAccount = async () => {
    setIsdeactivePopupShow(true)
   
  };

  useEffect(() => {
    const token: any = localStorage.getItem("token");

    if (token) { getProfile(token) }

  }, []);

  const pageNavigate = (path: any) => {
    history.push(path);
  };


  const uploadTrackRedirection = () => {

    return (
      <>
        {(userType?.includes("creative") && !dmActive ) &&

          <Row style={{width:"100%"}}>
            <Link to="/uploadTrack" style={{width:"100%"}}>
              <Row justify="end" > <button className="upload_button" style={{ border: "1px solid red" }}
              // style={{ display: 'none' }}
              >Upload</button></Row>
            </Link>
          </Row>

        }
      </>
    )
  }
  const sizeXl = sizexl || 24;


  let xsFontSize = 24;
  let smFontSize = 24;
  let mdFontSize = 24;

  let lgFontSize;
  let xlFontSize;
  let xxlFontSize;

  if (userType?.includes("creative") || userType === "original") {
    lgFontSize = 22;
    xlFontSize = 24;
    xxlFontSize = 18;
  } else if (userType?.includes("listener")) {
    lgFontSize = 20;
    xlFontSize = 24;
    xxlFontSize = 12;
  } else {
    lgFontSize = sizeXl;
    xlFontSize = sizeXl;
    xxlFontSize = 12;
  }

  const colSize = {
    xs: xsFontSize,
    sm: smFontSize,
    md: mdFontSize,
    lg: lgFontSize,
    xl: xlFontSize,
    xxl: xxlFontSize
  };

  const usernameClassName =
    userType?.includes("creative") || dmActive ? "username_ellipse" : "listener_username";
  
const headerDmActiveRow= dmActive? 24: 8
const headerDmActiveRowSm= dmActive? 24: 15
  const renderLoggedInHeader = () => {
    console.log(profileImg==true,">>>profileImg")
    return (
      <Col {...colSize} xl={!dmActive ? 20 : colSize.xl} xxl={!dmActive ? colSize.xxl : 24}>
        <Row className="right_part upload-justify" style={{}}>
          {!dmActive &&
          
        <Col xs={15} sm={8}>
          {uploadTrackRedirection()}
          </Col>
          }
          <Col xs={headerDmActiveRow} sm={headerDmActiveRowSm}>
          <div style={{
            display: "flex", alignItems: "center",
            width:dmActive?"220px":"100%"
          }} className={!dmActive ? "border-line" : ""} >
            <Row className="user_avatar">
              <Avatar
                className={profileImg?"user_avatar_dm":"default-profile-icon-header"}
                src={<img src={profileImg ? profileImg:defaultProfile}
                  style={{ borderRadius: "50px" }}
                />} />
            </Row>
            <Row className="header-dropdown-row" style={{
              gap: dmActive?"15px":"none"
            }}>
              <Hidden xsDown>
                <Dropdown
                  className="custom-root-dropdown"
                  open={false}
                  overlay={<Menus />}
                  trigger={["click"]}
                  placement="bottomRight"
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <a className="ant-dropdown-link custom_dropdown_profile" onClick={e => e.stopPropagation()} style={{ display: "flex", alignItems: "center" ,width:"80%",flex: dmActive? 1 :1}}>

                    <span className={usernameClassName}>
                      {userData?.user_name}
                    </span>
                  </a>
                </Dropdown>
              </Hidden>
              <Hidden xsUp>
                <a className="ant-dropdown-link custom_dropdown_profile" onClick={e => e.stopPropagation()} style={{ display: "flex", alignItems: "center" }}>
                  <span className={usernameClassName}>
                    {userData?.user_name}
                  </span>
                </a>
              </Hidden>
              {dmActive &&
                <Hidden smUp>
                  <a className="ant-dropdown-link custom_dropdown_profile" onClick={e => e.stopPropagation()} style={{ display: "flex", alignItems: "center" }}>
                    <span className={usernameClassName}>
                      {userData?.user_name}
                    </span>
                  </a>
                </Hidden>
              }
              <Dropdown overlay={<Menus />} trigger={["click"]} getPopupContainer={(trigger: any) => trigger.parentNode} placement="bottomRight">
                {/* @ts-ignore */}
                <DownOutlined style={{ fontWeight: "bolder", fontSize: "12px", alignItems: "center", display: "flex", color: "white" }} translate="true" />
              </Dropdown>
            </Row>
          </div>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderLoggedOutHeader = () => (
    <Col xs={24} sm={20}>
      <Row justify="end" className="right_part_loggedOut " style={{ justifyContent: "end" }}>
        <Col style={{ alignSelf: "center" }}>
          <Row align="middle" justify="center" className="text_white font_W_600 mr_2 cursor_pointer sm_fontsize" onClick={() => {
            localStorage.clear();
            history.push("/AccountType");
          }}>
            SIGN UP{" "}
          </Row>
        </Col>
        <Col>
          <button className="mr_2 custom-hovor-effect" id="loginBtn" onClick={() => history.push("/Welcome")}>LOGIN</button>
        </Col>
      </Row>
    </Col>
  );


  // const commonSizeXL = sizexl !== 9 ? 24 : 12;
  const commonSizeXL = sizexl !== 9 ? 24 : 15;

  const xsSizeXL = commonSizeXL;
  const smSizeXL = sizexl !== 9 ? 24 : 12;
  const mdSizeXL = sizexl !== 9 ? 24 : 11;
  const xxlSizeXL = sizexl !== 9 ? 24 : 10;

  return (
    <>
      {/* <Col xs={xsSizeXL} lg={dmActive ? 24 : 12} sm={smSizeXL} md={mdSizeXL} xl={sizexl} xxl={xxlSizeXL}> */}
      <Col xs={xsSizeXL} lg={dmActive ? 24 : 12} sm={smSizeXL} md={mdSizeXL} xl={sizexl} xxl={xxlSizeXL}>
       
        <Row
          className="header_justify"
        >
          {token ? renderLoggedInHeader() : renderLoggedOutHeader()}
        </Row>


        {/* <ScanQrDialog
          openPopup={openPopup}
          shareItem={"Profile"}
          // setShareItem={"Profile"}

          handleOpenPopup={() => setOpenPopup(false)}
        /> */}

        <SharePlaylistPopup
          id="Profile"
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
          track={{}}
        />
      </Col>

      <MaterialMenu
        elevation={0}
        getContentAnchorEl={null}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // open={open}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ zIndex: 1 }}
        PaperProps={{
          style: {
            backgroundColor: "rgb(9, 9, 9)",
          }
        }}
      >

        {token ?
          returnLoginSidebar(menuList)
          :
          returnWithoutLoginSidebar(items1)
        }

      </MaterialMenu>
      {open && <LoginAlert open={open} handleClose={handleLoginClose} dialogText={dialogText} />}
      {isplanPopupShow && <PlanCancelledPopup open={isplanPopupShow} setIsplanPopupShow={setIsplanPopupShow} />}
      {isdeactivePopupShow && <DeactivateUser
        open={isdeactivePopupShow}
        setIsdeactivePopupShow={setIsdeactivePopupShow}

      />}
    </>
  );
};

export default HomeHeaderDropdown;
// Customizable Area End