// Customizable Area Start
import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Grid } from "@material-ui/core";
import { circleBlue } from '../../../../components/src/CustomMessage/MessageLinkPopup/assets'
import { SearchOutlined } from "@ant-design/icons";


interface Props {
    selectCity: boolean;
    selectedSingleCity: (city: any) => void;
}

interface SS {
    value: any;
    majorCities: any;
    selectedCity: any;
}

class CityFilterApply extends Component<Props,SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: null,
            majorCities: ['Atlanta, GA, USA', 'New Orleans, LA, USA', 'Ayodhya, Uttar Pradesh, India', 'Austin, TX, USA', 'Niagara Falls, ON, Canada'],
            selectedCity: ''
        };
    }
    

    componentDidMount() {
      if(this.props.selectCity){
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBPAAZ67lS_QAFq6sSj0a9Ejix3e2PTyHY&libraries=places`;
        script.defer = true;
        script.async = true;
        script.onload = () => {
          this.fetchMajorCities('');
        };
        document.head.appendChild(script);
      }

     }

  
      fetchMajorCities = (input: any) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        
        autocompleteService.getPlacePredictions(
          { input: input, types: ['(cities)'] },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const cities = predictions?.map(prediction => prediction.description);
              this.setState({ majorCities: cities });
            }
          }
        );
      };




    handleInputChange = (inputValue: any) =>{
      if(inputValue =="" && this.state.majorCities.length == 0){
         this.setState({majorCities: ['Atlanta, GA, USA', 'New Orleans, LA, USA', 'Ayodhya, Uttar Pradesh, India', 'Austin, TX, USA', 'Niagara Falls, ON, Canada'] })
      }else{
        this.fetchMajorCities(inputValue)
      }
      }
    

 
    setCityLoc = (loc: any) =>{
       if(this.state.selectedCity == loc){
        this.setState({ selectedCity: ''})
        this.props.selectedSingleCity('');
       }else{
        this.setState({ selectedCity: loc})
        this.props.selectedSingleCity(loc);
       }
    }

    render() {
        const { majorCities } = this.state;
        return (
       
            <>
             {
                this.props.selectCity && 
                <div style={{marginTop: '10px'}}>
                
               <GooglePlacesAutocomplete
                apiKey="AIzaSyBPAAZ67lS_QAFq6sSj0a9Ejix3e2PTyHY"
                autocompletionRequest={{
                    types: ['(cities)'],
                }}
               
                selectProps={{
                    className: "search_city",
                    defaultValue: this.state.value || '', 
                    onInputChange: this.handleInputChange,
                    placeholder: (
                      <div style={{display: 'flex'}}>  
                       {/* @ts-ignore */} 
                      <SearchOutlined
                        className="text_white1"
                        style={{
                          backgroundColor: "rgb(40 40 40)",
                          fontSize: "18px",
                          padding: "2px",
                        }}
                    
                      />
                      <div className="text_white1" style={{ fontFamily: 'inter', fontWeight: 'bold', marginLeft: '15px'}} >Search City</div>
                      </div>
                    )
                  
                  }}
            />
            </div>
                }
            {this.props.selectCity && majorCities.length !== 0 &&
                majorCities.map((loc: any) => {
                  return (
                    <Grid
                      style={{
                        height: "30px",
                        margin: "10px 5px",
                        borderRadius: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={() => this.setCityLoc(loc)}
                    >
                      <div
                        className="hoverloc"
                        style={{
                          textAlign: "left",
                          color: "grey",
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {loc}

                        { loc == this.state.selectedCity && (
                              <Grid item>
                                <img
                                  style={{height: '15px', width: '15px'}}
                                  src={circleBlue}
                                />
                              </Grid>
                            )
                     }
                      </div>
                      
                     
                    </Grid>
                  );
                })}
                
                
                </>
        );
    }
}

export default CityFilterApply;
// Customizable Area End

