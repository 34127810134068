// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { HISTORY } from "components/src/common";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { pauseA, playsong } from "blocks/dashboard/src/assets";
import { openNotification } from "components/src/Notification.web";
import { Message } from "../../../../../framework/src/Message";

export const configJSON = require("../../config");

export interface Props {
  webStyle: any;
  selectedTab: any;
  playlistDetailsData: any;
  selectedTrack: any;
  playlist_details_data: any;
  formatNumber: any;
  status: any;
  follow_playlist: any;
  followPlaylist: any;
  linkopenPopup: any;
  handleRepost: any;
  showSingleplaylistdeatils: any;
  selectedsong: any;
  showEditPlaylist: () => void;
  showDeletePlaylist: () => void;
  topPlayMusic: any;
  followUserStatus: any;
  followUser: boolean;
  is_invite: boolean;


  accessList: any[];
  accessAvatarList: any[];
  accessListLoader: boolean;
  showAccessListView: boolean;
  setAccessListView:()=>void
  accessListPlayList:(trackId:number)=>void
}

interface S {
  localSeletedItem: any;
  openMessageSendPopupPlaylist: boolean;
  CopiedForLinkPlaylist: any;
  is_invite_user_playlist: boolean;
  openInvitationModalPlaylist: boolean;

}

interface SS {
  id: any;
}

export default class SinglePlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiInviteUser: any;
  apiAccessListPlaylist:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      localSeletedItem: false,
      openInvitationModalPlaylist: false,
      openMessageSendPopupPlaylist: false,
      CopiedForLinkPlaylist: "",
      is_invite_user_playlist: false,
      // accessList: [],
      // accessAvatarList: [],
      // accessListLoader: false,
      // showAccessListView: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.context.playlistData?.hasOwnProperty("id")) {
      this.props.selectedTrack(this.context.playlistData);
      // this.accessListTrack()
      this.props.accessListPlayList(this.props.playlist_details_data.id)

    //  this.setState({showAccessListView:false})
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    let { playlistData } = this.context;
    if (
      !this.state.localSeletedItem &&
      playlistData.hasOwnProperty("id") &&
      playlistData.id !== prevProps.playlist_details_data.id
    ) {
      this.props.selectedTrack(playlistData);
      this.props.accessListPlayList(this.props.playlist_details_data.id)
    //  this.setState({showAccessListView:false})
    }
  }

 

  handleShowPlaylist = (data: any) => {
    console.log(data);
  };

  openInbox = () => {
    let loggedInUser = localStorage.getItem("loggedIn_userId");
    if (
      this.props.playlist_details_data.attributes.artist_id == loggedInUser
    ) {
      openNotification("You cannot message yourself", "Error");
    } else {
      localStorage.setItem(
        "trackUserListDataDM",
        JSON.stringify(this.props.playlist_details_data)
      );
      HISTORY.push({
        pathname: "/comments",
      });
    }
  };

  showPlayPause = (item: any) => {
    let { handleAudioVideo, playlistData } = this.context;
    if (playlistData?.id == item.id && handleAudioVideo === 2) {
      return pauseA;
    } else {
      return playsong;
    }
  };
  redirectVideo = (item: any) => {
    HISTORY.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: item.attributes.video_file,
        activeNo: "5",
        data: item,
        currentList: this.props.playlistDetailsData
          ? this.props.playlistDetailsData.attributes?.playlist_items?.data
          : [],
      },
    });
  };
  handleOpenInvitation = () => {
    this.setState({ openInvitationModalPlaylist: true });
  };

  handleCloseInvitation = () => {
    this.setState({ openInvitationModalPlaylist: false });
  };

  linkopenPopup = () => {
    this.setState({
      CopiedForLinkPlaylist: window.location.origin.concat(
        `/invitation/playlistId=${this.props.playlistDetailsData.id}`
      ),
    });
    this.setState({ openMessageSendPopupPlaylist: true });
    // this.onInviteUser()
  };
  handleOpenMessageSendPopup = () => {
    this.setState({ openMessageSendPopupPlaylist: false });
  };
  onInviteUser = (selectedUser: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInviteUser = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/send_invite?account_id=${selectedUser?.id}&track_id=${this.props.playlist_details_data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  handleInviteUserPlaylist = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
    } else {
      openNotification(responseJson.errors, "Error");
    }
  };
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apiInviteUser) {
      this.handleInviteUserPlaylist(responseJson);
    }
    

    // Customizable Area End
  }
}
SinglePlaylistController.contextType = AudioContextApi;
// Customizable Area End
