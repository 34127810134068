// Customizable Area Start
import { Grid,LinearProgress,Typography } from "@material-ui/core";
import React from "react";
import "./snapshot.css";
import DownloaderListController from "./DownloaderListController";
import {  blueCheck, defaultProfile } from "blocks/dashboard/src/assets";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

export default class DownloaderDetails extends DownloaderListController {
  render() {
    const { index, selectIndex,handleExpandDownloaders,item,totalTracksCount } = this.props;

    return (
        <Grid
        container
        item
        xs={12}
        key={index}
        className={
          selectIndex == index
            ? "openDownloaders"
            : "closeDownloaders"
        }
        onClick={() =>
          handleExpandDownloaders(index, item)
        }
        data-test-id="downloads"
      >
        <Grid
          item
          xs={4}
          sm={3}
          md={3}
          lg={2}
          className="downloaderListSpaceAround"
        >
          <Typography className="indexStyle">
            {index + 1}
          </Typography>
          <img
            src={
              item.attributes?.avatar || defaultProfile
            }
            className="downloadArtWork"
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={9}
          md={9}
          lg={10}
          className="downloadArtWorkGrid"
        >
          <span className="downloadArtName">
            {item.attributes?.user_name}
            {item?.attributes?.is_verified && (
              <img
                src={blueCheck}
                className="verified-snapshot"
              />
            )}
          </span>
          <Grid
            item
            xs={12}
            className="downloaderListSecondGrid"
          >
            <span className="downloadTypeName">
              Downloads
            </span>
            <span className="downloadArtName">
              {item?.attributes?.downloads_count}
            </span>
          </Grid>
          <div className="linearProgressDiv">
            <BorderLinearProgress
              variant="determinate"
              value={
                item?.attributes?.downloads_count /
               totalTracksCount
              }
            />
          </div>
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
  }
}
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#212121 !important",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#3959f9",
    },
  })
)(LinearProgress);

// Customizable Area End