// Customizable Area Start
import React,{useEffect} from "react";
import { Row, Col, Form, Input, Button } from "antd";
import GoogleLogin from "react-google-login";
import { useHistory, Link } from "react-router-dom";
import "../assets/Welcome.css";
import { nxzborderlogo, loginShade, loginBanner } from "./assets";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { setStorageData } from "../../../framework/src/Utilities";
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function Welcome() {
  const history = useHistory();
  const [form] = Form.useForm();


  useEffect(() => {
localStorage.clear()
   
  })

  const determineRedirectPath = (userType: any) => {
    if (userType.includes("creative")) {
      return '/ForCreative';
    } else if (userType.includes("label")) {
      return '/ForLabel';
    } else {
      return '/ForListener';
    }
  };

  const isSubscriptionCompleted = async (token: any, user_type: any, userAgent: any) => {
    try {
      const response = await axios.get(`${baseUrl}/account_block/check_subscription`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token
        }
      });

      const meta = response?.data?.meta;
      switch (true) {
        case meta.terms_and_condition === false:
          history.push('/TermAndConditions');
          break;

        case meta.otp_verified === false:
          history.push("/reset-password")
          break;

        case meta.subscription === false:
          if (meta.existing_user) {
            history.push('/planpayments');
          } else {
            const redirectPath = determineRedirectPath(user_type);
            history.push(redirectPath);
          }
          break;

        case /iPhone/.test(userAgent) && !(window as any).MSStream:
          history.push(window.location.href.includes('iphoneplans') ? '/planpayments' : '/home');
          break;

        default:
          history.push('/home');
      }
    } finally {
      console.log("error in welcome screen");
    }
  };

  const onFinish = async (values: any) => {

    const data = {
      data: {
        type: "name_account",
        attributes: {
          user_name: values.username,
          password: values.password,
        },
      },
    };
    await axios
      .post(`${baseUrl}/bx_block_login/login`, data)
      .then((res) => {
        localStorage.clear()
        localStorage.setItem("token", res.data.meta.token)
        localStorage.setItem("user_type", res.data.meta.user_type)
        setStorageData("user_type", res.data.meta.user_type)
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        isSubscriptionCompleted(res.data.meta.token, res.data.meta.user_type, userAgent)
      })
      .catch((err) => {
        if (err.response.hasOwnProperty("data")) {
          localStorage.setItem("token", err?.response?.data?.signup_token)
          localStorage.setItem("full_phone_number", err?.response?.data?.full_phone_number)
        }
        if (err?.response?.data?.signup_token) {
          openNotification(err?.response?.data?.failed_login, "Error")
          history.push("/PhoneNumberVerification")
        } else {
          openNotification(err?.response?.data?.failed_login, "Error")
        }
      }).finally(() => {
        form.resetFields();
      });
  };

  const responseGoogle = (response: any) => {
    localStorage.setItem("email", response.profileObj.email)
    handleGoogleLogin(response.tokenObj.id_token);

  };
  const handleGoogleLogin = async (token: any) => {
    const data = {
      id_token: token
    }
    await axios
      .post(`${baseUrl}/account_block/google_login`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        }
      })
      .then((res) => {
        localStorage.setItem("token", res.data.meta.token)
        history.push("/home")


      })
      .catch((err) => {
        {
          err.response.status === 422 &&


            history.push("/AccountType")

        }

        openNotification("Please sign-up first", "Error")

      }).finally(() => {
        // form.resetFields();
      });
  }



  return (
    <Row className="bgDark"
      style={{ height: "100%" }}
    >
      <Col lg={12} md={12} sm={24} xs={12} className="bgImg-welcome">
        <img className="image1" src={loginBanner} />
        <img className="image2" src={loginShade} />
      </Col>
      <Col lg={12} md={12} sm={24} xs={24} className="welcome-form ">
        <Row align="middle" justify="center" style={{ height: "100%", width: "75%", margin: "auto", textAlign: "center" }}>
          <Col xxl={15} xl={18} lg={20} md={21} sm={16} xs={21}>
            <Link to="/home">
              <Row justify="center" className="welcomeLogo mt-1">
                <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
              </Row>
            </Link>
            <Row className="welcome-text mt-1">
              <Row justify='center' className='nxzloginhead'>NXZSOUND</Row>
              <Row justify='center' className="text_white1 width_100 nxzloginCreatives">For Creatives By Creatives</Row>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <Form
                  form={form}
                  name="basic"
                  className="login_form"
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Your Username!",
                      },
                      {
                        pattern: new RegExp(
                          "^[a-z0-9_\-_@.]+$"
                        ),
                        message:
                          "Username must have lowercase only ",
                      },
                    ]}
                  >
                    <Input autoComplete="chrome-off" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    className="mb_0"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Your Password!",
                      },
                      // {
                      //   pattern: new RegExp(
                      //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      //   ),
                      //   message:
                      //     "Password Must Be 8 Character And Contains At Least One A-Z, a-z, 0-9, @$%#",
                      // },
                    ]}

                  // extra={
                  //   <span className="font_12" onClick={() => history.push("/forgot-password")}>
                  //     FORGOT PASSWORD?
                  //   </span>
                  // }
                  >
                    <Input.Password />
                  </Form.Item>
                  <Row justify="end" onClick={() => history.push("/forgot-password")} className="font_12 mt-1 mb_2 text_white cursor_pointer">FORGOT PASSWORD? </Row>
                  <Form.Item>
                    <Button
                      className="red_custom_btn login_btn mt-1 login_btn_nxz"
                      type="primary"
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>


                  {/* <GoogleLogin
                    clientId="77770250702-j0cpn87jrs84mlptjfljkqj5ooq69uff.apps.googleusercontent.com"
                    render={(renderProps: any) => (
                      <Button
                        className="google_btn width_100 font_16 login_btn_nxz"
                        type="primary"
                      >
                        Connect With Google
                      </Button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseFailGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="mb_2"
                  /> */}


                  <Row justify="center">
                    <Col className="text_white pt_2 mb_2 font_16 login_btn_nxz"
                      onClick={() => history.push("/AccountType")}
                    >
                      Sign Up

                    </Col>
                  </Row>
                </Form>


              </Col>
            </Row>
            {/* <div className="signup-btn">
              <button
                style={{ backgroundColor: "rgb(255 0 0)" }}
                onClick={() => {
                  history.push("/AccountType");
                }}
              >
                SIGN UP
              </button>
              <button className="gglebtn">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                </span>
                <span>CONNECT WITH GOOGLE</span>
              </button>
            </div> */}
          </Col>
        </Row>
      </Col>
      {/* </Row> */}
    </Row>
  );
}
// Customizable Area End
