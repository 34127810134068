import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
import { loadStripe } from "@stripe/stripe-js";
import { openNotification } from "../../../../components/src/Notification.web";
export const configJSON = require("../config.js");

const setKey = () => {
  if (configJSON.baseUrl.includes("prod")) {
    return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`;
  } else {
    return `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`;
  }
  //return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
};
const baseStripePromise: any = setKey();
//configJSON.baseUrl.includes("prod" || "uat") ? `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}` : `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`

const stripePromise = loadStripe(baseStripePromise);
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;

  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: any;
  errorMsg: string;
  getsubscriptions: any;
  loading: boolean;
  current_plan: any;
  openConfirmPaymentPopup: boolean;
  cancelledSubscription: boolean;
  currentPaymentData: any;
  next_invoice_no: any;
  status_of_plan: any;
  isConfirmPlan: boolean;
  strip_plan_id: any;
  isMobileView: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PlanAndPaymentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllSubscriptionData: string = "";
  getCurrentPaymentDataApi: string = "";
  getHandleUpdateCardApi: string = "";
  setDefaultCardApi: string = "";
  apiAddNewSubscription: string = "";
  apiAfterAddSubscription: string = "";
  apiCancelSubscription: string = "";
  apiUpdateSubscription: string = "";
  apiCreatePayment: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      dashboardData: [],
      token: localStorage.getItem("token"),
      errorMsg: "",
      getsubscriptions: [],
      loading: false,
      current_plan: [],
      openConfirmPaymentPopup: false,
      cancelledSubscription: false,
      currentPaymentData: [],
      next_invoice_no: "",
      status_of_plan: "",
      isConfirmPlan: false,
      strip_plan_id: "",
      isMobileView: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    localStorage.removeItem("selectedTab");

    if (this.state.token) {
      if (window.innerWidth <= 600) {
        this.setState({
          isMobileView: true,
        });
      }
      this.setupSubscriptionAndPayment();
    } else {
      this.handleNonTokenState();
    }

    // Customizable Area Start
    // Customizable Area End
  }

  setupSubscriptionAndPayment() {
    this.getallSubscription();
    this.getCurrentPaymentData();
    this.setDefaultCard();

    if (window.location.href.includes("CardUpdate")) {
      this.handleCardUpdate();
    }
  }

  handleCardUpdate() {
    let session = window.location.href.split("?")[1];
    HISTORY.push("/planpayments");

    if (session !== "") {
      this.iphoneRedirection();
    }
  }

  handleNonTokenState() {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/iPhone/.test(userAgent) && !(window as any).MSStream) {
      this.handleiPhoneRedirect();
    } else {
      HISTORY.push("/");
    }
  }

  handleiPhoneRedirect() {
    if (!window.location.href.includes("planpayments?fromiphone")) {
      HISTORY.push("/");
    }
  }

  // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  closePayModal = () => {
    this.setState({ openConfirmPaymentPopup: false });
  };

  closeCancelledModal = () => {
    // this.getallSubscription()
    this.cancelSubscription();
    this.setState({ cancelledSubscription: !this.state.cancelledSubscription });
  };

  iphoneRedirection = () => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/iPhone/.test(userAgent) && !(window as any).MSStream) {
      window.location.href = `nxzsound://ProfileTabScreen`;
      setTimeout(function() {
        openNotification("success", "Success");
      }, 3000);
    }
  };

  getallSubscription = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubscriptionData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getSubscriptionsData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCurrentPaymentData = async () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentPaymentDataApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getCurrentPaymentData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  urlReturn = () => {
    if (configJSON.baseUrl.includes("prod")) {
      return "https://app.nxzsound.com";
    } else if (configJSON.baseUrl.includes("uat")) {
      return "https://nxzsound10-66173-react-native.b66173.uat.eastus.az.svc.builder.ai";
    } else {
      return "https://nxzsound10-66173-react-native.b66173.stage.eastus.az.svc.builder.ai";
    }
  };

  handleUpdateCard = async () => {
    const dev_url =
      "https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe";
    const currentRedirect = configJSON.baseUrl.includes("dev")
      ? dev_url
      : this.urlReturn();
    const data = {
      // success_url: `${currentRedirect}${configJSON.getCardUpdate}`,
      success_url: `${currentRedirect}/planpayments`,
      cancel_url: `${currentRedirect}/planpayments`,
      device_type: "web",
    };

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHandleUpdateCardApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getSubscriptionCardUpdate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setDefaultCard = async () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setDefaultCardApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getDefaultCard}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSubscription = async (strip_id: any) => {
    if (this.state.currentPaymentData.message) {
      openNotification(
        "Add Your Debit/Credit Card to Procceed the Payment",
        "Error"
      );
    } else {
      const plan_id = { stripe_plan_id: strip_id };
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem("token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiUpdateSubscription = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.updateSubscription}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(plan_id)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  CreateNewPayment = async (plan_id: any, strip_plan_id: any) => {
    const planId = { subscription_plan_id: plan_id };
    this.setState({ strip_plan_id: strip_plan_id });

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreatePayment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.createNewPayment}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(planId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  AddNewSubscription = async (strip_plan_id: any) => {
    const new_subs = { price: strip_plan_id };
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddNewSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getAddNewSubscription}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(new_subs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  AfterAddSubscription = async (subscription_id: any) => {
    const subscriptionId = { subscription_id: subscription_id };

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAfterAddSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.getAfterAddSubscription}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(subscriptionId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  cancelSubscription = async () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCancelSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.cancelAddSubscription}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubscriptionData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        loading: false,
        getsubscriptions: responseJson.subscription_plans.data,
        current_plan: responseJson.current_plan.data,
        next_invoice_no: responseJson.next_invoice,
        status_of_plan: responseJson.status_of_plan,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleCurrentPlanData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        currentPaymentData: responseJson,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleUpdateCardApi = async (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      const stripe: any = await stripePromise;
      localStorage.setItem("session_id", responseJson.session_id.id);

      // eslint-disable-next-line
      const { error }: any = await stripe
        .redirectToCheckout({
          sessionId: responseJson.session_id.id,
        })
        .then(async function(result: any) {
          openNotification("Card has been updated successfully", "Success");
        });
      console.log(error, "Error");
      this.iphoneRedirection();
      this.setDefaultCard();
    } else {
      openNotification(
        responseJson.errors.message
          ? responseJson.errors.message
          : responseJson.errors?.response?.data?.message,
        "Error"
      );
    }
  };

  handleDefaultCard = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.getCurrentPaymentData();
    } else {
      console.log(responseJson.errors, "payments block");
    }
  };

  handleNewSubscription = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.AfterAddSubscription(responseJson.session_id.id);
      this.iphoneRedirection();
    } else {
      openNotification("There is an Error While Creating Payment", "Error");
    }
  };

  handleAfterSubscription = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Your Payment Has Successfully Completed", "Success");
      if (this.state.isMobileView) {
        HISTORY.push("/mupdateplan");
      } else {
        this.setState({ openConfirmPaymentPopup: true });
        window.location.reload();
      }
      this.iphoneRedirection();
    } else {
      openNotification("There is an Error While Creating Payment", "Error");
    }
  };

  handleCancelSubscription = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Your Subscription has been cancelled", "Success");
      this.setState({
        cancelledSubscription: true,
        openConfirmPaymentPopup: false,
      });
      this.iphoneRedirection();
    } else {
      openNotification(
        responseJson.errors.message
          ? responseJson.errors.message
          : "We unable to procceed your request",
        "Error"
      );
    }
  };

  handleUpdateCardSubs = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Payment done", "Success");
      if (this.state.isMobileView) {
        HISTORY.push("/mupdateplan");
      } else {
        this.setState({ openConfirmPaymentPopup: true });
        HISTORY.push("/planpayments");
      }
      this.iphoneRedirection();
    } else {
      openNotification(
        responseJson.errors.message
          ? responseJson.errors.message
          : responseJson.errors,
        "Error"
      );
    }
  };

  handleCreateNewPayment = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.AddNewSubscription(this.state.strip_plan_id);
      this.iphoneRedirection();
    } else {
      openNotification(
        responseJson.errors.message
          ? responseJson.errors.message
          : "There is an Error While Creating Payment",
        "Error"
      );
    }
  };

  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getAllSubscriptionData:
        this.handleSubscriptionData(responseJson);
        break;
      case this.getCurrentPaymentDataApi:
        this.handleCurrentPlanData(responseJson);
        break;
      case this.getHandleUpdateCardApi:
        this.handleUpdateCardApi(responseJson);
        break;
      case this.setDefaultCardApi:
        this.handleDefaultCard(responseJson);
        break;
      case this.apiAddNewSubscription:
        this.handleNewSubscription(responseJson);
        break;
      case this.apiAfterAddSubscription:
        this.handleAfterSubscription(responseJson);
        break;
      case this.apiCancelSubscription:
        this.handleCancelSubscription(responseJson);
        break;
      case this.apiUpdateSubscription:
        this.handleUpdateCardSubs(responseJson);
        break;
      case this.apiCreatePayment:
        this.handleCreateNewPayment(responseJson);
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
        return;
    }

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));


      this.handleRestApiCall(apiRequestCallId, responseJson);
    
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
