// Customizable Area Start
import React from 'react';
import { Button } from '@material-ui/core';
import { usePlaidLink } from 'react-plaid-link';

// Customizable Area End


// Customizable Area Start
interface Props {
    onNextClick: () => void;
    onBackClick: () => void;
    nextButtonName?: string;
    backButtonName?: string;
    isPlaid:boolean;
    linkToken:string;
    onSucessCallApi:(plaid_id:string)=>void;
    isLinkOpen:()=>boolean;
}
// Customizable Area End

// Customizable Area Start


export const NextBackButtonGroup = (props: Props) => {
    const { open } = usePlaidLink({
        token: props.linkToken,
        onSuccess: (public_token, metadata) => {
          // send public_token to server
                props.onSucessCallApi(public_token)
        },
      })
    const handleLinkAccountClick=()=>{
        if(props.isLinkOpen()){
        open()
        }
    }
    return (
        <>
        {!props.isPlaid?
         <Button
            data-test-id="nextbuttonid"
            className='SplitButton Next'
            onClick={handleLinkAccountClick}>
             LINK ACCOUNT
         </Button>
         :
            <Button
            data-test-id="nextbuttonid"
                className='SplitButton Next'
                onClick={props.onNextClick}
            >
                {props.nextButtonName || 'Next'}
            </Button>
}
            <Button
            data-test-id="prevbuttonid"
                className='SplitButton Back'
                onClick={props.onBackClick}
            >
                {props.backButtonName || 'Back'}
            </Button>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default NextBackButtonGroup;
// Customizable Area End
