// Customizable Area Start
import React from "react";
import "../Creatives/ComposeBlock.web.css";
import { play1, repost1, following1, follower1, messageProfile, keyIcon } from "blocks/dashboard/src/assets";
import { Typography, Grid, Button, ButtonGroup } from "@material-ui/core";
import LabelController from './LabelController.web'
import SendMessage from 'components/src/CustomChart/PopUps/SendMessageDialog.web';
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import {UnFollowMessageDialog} from "../Common/UnFollowMessageDialog.web";
import { dollarSign } from "../assets";



export const configJSON = require("../../../../framework/src/config.js");
export default class LabelProfileUserDiv extends LabelController {

  render() {
    const {labelUserDetails, labelGraphandStatsData} = this.state;
    const token: any = localStorage.getItem("token")
    const user_id: any = localStorage.getItem("loggedIn_userId");
    const id_check = user_id
      {/* @ts-ignore */}
    const antIcon = <LoadingOutlined  style={{ fontSize: 24, color: 'antiquewhite' }} spin translate />;

      return (

        <Grid container xs={8} sm={4} style={{marginTop: '15px'}} className={labelUserDetails.id !== id_check ? "icons-group listener-count-grid listener-public" : " icons-group listener-count-grid label-private"}>
           <Grid container item xs={12} className="justify-center" style={{padding: '10px'}} >
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={play1} className="listener-icons-img" style={{ cursor: "not-allowed" }} onClick={()=>this.redirectlabelStreamhistory("labelhistory")} data-test-id="redirectlabelhistory"/>


                <Typography className="icons-text"><Typography className="icons-text" style={{ fontFamily: "karla" }}>

                  {labelGraphandStatsData?.total_play_count||0}
                </Typography> </Typography>


              </Grid>
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={repost1} className="listener-icons-img" style={{ cursor: "not-allowed" }} onClick={()=>this.redirectlabelStreamhistory("labelrepost")} data-test-id="redirectlabelreposthistory"/>

                <Typography className="icons-text"><Typography className="icons-text">
                {labelGraphandStatsData?.total_repost_count}
                </Typography></Typography>
              </Grid>

            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={following1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {} } />
                {/* <ProfileFollowing open={openPopupFollowing}
                 handleClose={() => this.setState({ openPopupFollowing: false })}
                  data={allFollowerData} 
                  loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="followers" /> */}

                <Typography className="icons-text"><Typography className="icons-text">
                {labelUserDetails?.attributes?.followers_count}
                </Typography> </Typography>

              </Grid>

              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={follower1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {}} />
                {/* <ProfileFollowing open={openPopupFollowers} handleClose={() => this.setState({ openPopupFollowers: false })} data={followingData} loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="following" /> */}
               
                  <Typography className="icons-text">
                    <Typography className="icons-text" style={{ fontFamily: "karla" }}>
                      {labelUserDetails?.attributes?.following_count || "0"}
                    </Typography>
                  </Typography>
                
              </Grid>

            </Grid>
          </Grid>
          {token &&  user_id != this.state.labelId &&   (
             <Grid container item xs={12} sm={8} lg={6} className="buttongroup-grid label-follow-handle">
              {/* <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" >
                <Button className="one-button" >
                  <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} />
                </Button>
                <Button className="listener-follow-btn" id="followBtn" 
                  style={{ fontFamily: "karla" }}
                  > 
                  {/* {this.state.loadingFollow ?
                    <Spin indicator={antIcon} /> : this.handleFollowState()
                  } *
                  </Button>
              </ButtonGroup> */}
              <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" style={{alignItems: 'center', display: 'flex', width: '300px', height: '55px', justifyContent: 'center', padding: '15px'}} >
                  <Button className="one-button" style={{border:"1px solid red"}} >
                      <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} onClick = {this.sendMessage}/>
                  </Button>
                  <Button className="follow-button" id="followBtn" disabled={this.state.loadingFollow}
                      style={{ fontFamily: "karla", height: '38px', width: '106px' }}
                      onClick={this.handlePopupVisible}>
                        {this.state.loadingFollow ?
                                  <Spin indicator={antIcon} /> : this.handleFollowState()}
                      </Button>

                  <Button className="" disabled={!this.state.isLabelUser} style={{ fontFamily: "karla", cursor: this.state.isLabelUser ? 'pointer' : 'not-allowed' }}>
                      <img src={dollarSign} className="dollar-icon" style={{ fontFamily: "karla" }} onClick = {this.redirectDealUser}/>
                  </Button>
              </ButtonGroup>
              <SendMessage openScanQr={this.state.openSendMessage} setOpenScanQr={() =>
          this.setCloseMessage()
          } handleRedirectToMessage={this.handleRedirectToMessage} avatar={labelUserDetails?.attributes?.avatar}
            user_name={labelUserDetails?.attributes?.user_name}
            CurrentId={labelUserDetails?.id}
            />
            <UnFollowMessageDialog labelId={this.state.labelId}  visible={this.state.visible} 
              setVisible={(value: any) => {this.setState({visible: value})}} 
              setLoadingFollow = {(value: any) => {this.setState({loadingFollow: value})}} 
              UnFolloweUser={this.getUnFolloweUserData} />
            </Grid>
          )}
         
        </Grid>
      );
    
  }
}
// Customizable Area End